import React from "react";
import { Formik } from "formik";
import { Modal } from "react-bootstrap";
import { Loader } from "rsuite";
import {
  Button,
  FeedDropdown,
  FeedInput,
  Heading,
  LabelFeed,
} from "../../../utils";
import { CrossIcon } from "../../../FeedLibraryIcon";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addUserDetailsAction } from "../../../store/actions/permissions";

const AddUser = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const { btnloader } = useSelector((state) => state.uiSettings);
  const { allTypeUsers } = useSelector((state) => state.permissions);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName="meal-multiselect-modal add-user-modal"
    >
      <Modal.Header className="w-full flex flex-col !items-start justify-start text-left">
        <Heading
          text={"Add new user"}
          fontSize="!text-[22px]"
          fontweight="!font-bild"
          color="text-textPrimary"
          className="font-medium !leading-10 mb-[2px] text-left"
        />
        <p className="text-sm font-normal text-whiteGray text-left">
          Description or instructions here.
        </p>

        {/* <span onClick={handleClose} className="cursor-pointer">
          <Cross />
        </span> */}
      </Modal.Header>
      <Modal.Body className="!w-full">
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            userType: "",
            countryCode: "",
            coachId: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (!values.email) {
              errors.email = "Required";
            }
            if (!values.phone || !values.countryCode) {
              errors.phone = "Required";
            }
            if (!values.userType) {
              errors.userType = "Required";
            }
            if (!values.coachId && values.userType === "client") {
              errors.coachId = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const res = await addUserDetailsAction(values);
            toast("User has been added successfully");
            setSubmitting(false);
            handleClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form className="w-full" onSubmit={handleSubmit}>
              <div className="w-full grid grid-cols-1  gap-x-6 gap-y-[16px]">
                <div className="flex-column  gap-[8px] w-full ">
                  <LabelFeed
                    text={
                      <>
                        Name <span className="text-primary1"> *</span>
                      </>
                    }
                    fontSize={"!text-[14px]"}
                    color="text-textPrimary"
                    className="leading-[22px] "
                  />
                  <FeedInput
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    crossicon={values?.name ? <CrossIcon /> : ""}
                    placeholder="User full name"
                    type="text"
                    setFieldValue={setFieldValue}
                    className="!text-sm !rounded-[4px]"
                  />
                  {errors.name && touched.name && (
                    <LabelFeed
                      text={errors.name}
                      color="text-red"
                      className="!text-sm"
                    />
                  )}
                </div>
                <div className="flex-column  gap-[8px] w-full ">
                  <LabelFeed
                    text={
                      <>
                        Email <span className="text-primary1"> *</span>
                      </>
                    }
                    fontSize={"!text-[14px]"}
                    color="text-textPrimary"
                    className="leading-[22px] "
                  />
                  <FeedInput
                    name="email"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    value={values.email}
                    crossicon={values?.email ? <CrossIcon /> : ""}
                    placeholder="email@example.com"
                    className="!text-sm !rounded-[4px]"
                  />
                  {errors.email && touched.email && (
                    <LabelFeed
                      text={errors.email}
                      color="text-red"
                      className="!text-sm"
                    />
                  )}
                </div>
                <div className="flex-column  gap-[8px] w-full ">
                  <LabelFeed
                    text={
                      <>
                        Phone Number <span className="text-primary1"> *</span>
                      </>
                    }
                    fontSize={"!text-[14px]"}
                    color="text-textPrimary"
                    className="leading-[22px] "
                  />
                  <div className="grid grid-cols-[20%_78%] gap-[8px]">
                    <FeedDropdown
                      name="countryCode"
                      type="select"
                      options={[
                        { id: "+1", name: "+1" },
                        { id: "+44", name: "+44" },
                        { id: "+61", name: "+61" },
                        { id: "+49", name: "+49" },
                        { id: "+33", name: "+33" },
                        { id: "+86", name: "+86" },
                      ]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={`${values.countryCode}`}
                      placeholder="+1"
                      toggleBtnClass={`!text-sm !text-text2 !h-[44px] !rounded-[4px]
                    `}
                    />
                    <FeedInput
                      name="phone"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.phone}
                      crossicon={values?.phone ? <CrossIcon /> : ""}
                      placeholder="xxx xxx xxxx"
                      className="w-full !text-sm !rounded-[4px]"
                    />
                  </div>
                  {errors.phone && touched.phone && (
                    <LabelFeed
                      text={errors.phone}
                      color="text-red"
                      className="!text-sm"
                    />
                  )}
                </div>

                <div className="flex flex-col gap-[8px] w-full ">
                  <LabelFeed
                    text={
                      <>
                        Role <span className="text-primary1">*</span>
                      </>
                    }
                    fontSize={"!text-[14px]"}
                    color="text-textPrimary"
                    className="leading-[22px] "
                  />
                  <FeedDropdown
                    name="userType"
                    type="select"
                    options={[
                      { id: "coach", name: "Coach" },
                      { id: "client", name: "Client" },
                    ]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    value={values.userType}
                    crossicon={values?.userType ? <CrossIcon /> : ""}
                    placeholder="Select user role"
                    className="!text-sm !rounded-[4px] role-dropdown "
                    toggleBtnClass={`!text-sm ${
                      values.userType?.includes("client")
                        ? "!text-primary1"
                        : values?.userType?.includes("coach")
                        ? "!text-purpalBg"
                        : "!text-text2"
                    }
                    `}
                  />
                  {errors.userType && touched.userType && (
                    <LabelFeed
                      text={errors.userType}
                      color="text-red"
                      className="!text-sm"
                    />
                  )}
                </div>
                {values.userType === "client" && (
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text={
                        <>
                          Coach <span className="text-primary1">*</span>
                        </>
                      }
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedDropdown
                      name="coachId"
                      type="select"
                      options={allTypeUsers
                        ?.filter((f) => f.userType === "coach")
                        ?.map((user) => {
                          return {
                            id: user.docId,
                            name: user.name,
                          };
                        })}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.coachId}
                      crossicon={values?.coachId ? <CrossIcon /> : ""}
                      placeholder="Select Coach"
                      className="!text-sm !rounded-[4px]"
                    />
                    {errors.coachId && touched.coachId && (
                      <LabelFeed
                        text={errors.coachId}
                        color="text-red"
                        className="!text-sm"
                      />
                    )}
                  </div>
                )}
              </div>

              <div className="w-full flex items-center gap-4 justify-between mt-[36px]">
                <Button
                  text="Cancel"
                  className="w-fit px-[12px] py-[7px] font-medium rounded-xl text-[14px] leading-6 border border-solid border-textPrimary "
                  bg="!bg-transparent"
                  color="text-textPrimary "
                  cta={() => handleClose()}
                  type="button"
                />
                <Button
                  text={
                    isSubmitting ? (
                      <Loader content="Add User" size="sm" />
                    ) : (
                      "Add User"
                    )
                  }
                  className="w-fit px-[12px] py-[7px] font-medium rounded-xl text-[14px] leading-6"
                  bg="bg-primary1"
                  color="text-background"
                  type="submit"
                  disabled={isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddUser;

import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "rsuite";
import {
  Cross,
  CrossIcon,
  FilterIcon,
  SearchIcon,
} from "../../../FeedLibraryIcon";

import { customDateFormat } from "../../../helper";
import { Button, Text, DatePicker } from "../../../utils";

const SearchFilter = ({
  data,
  setallUsersData,
  setOpenModal,
  setUserProfile,
}) => {
  const dropdownRef = useRef(null);
  const [query, setQuery] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [statusActive, setStatusActive] = useState();
  const [activeDate, setActiveDate] = useState();
  const [tableFilterData, setTableFilterData] = useState([]);
  const [toDate, setToDate] = useState("yyyy-mm-dd");
  const [fromDate, setFromDate] = useState("yyyy-mm-dd");

  const filterApply = () => {
    if (statusActive) {
      const filteredByStatus = data?.filter(
        (item) => item.status === statusActive,
      );
      setallUsersData(filteredByStatus);
    } else if (activeDate) {
      const filteredByDate = data?.filter(
        (item) => item.status === statusActive,
      );
      setallUsersData(filteredByDate);
    } else if (toDate !== "yyyy-mm-dd" || fromDate !== "yyyy-mm-dd") {
      const filteredByDate = data?.filter(
        (item) => item.status === statusActive,
      );
      setallUsersData(filteredByDate);
    } else {
      setallUsersData(data);
    }
  };
  const resetFilterData = () => {
    setallUsersData(data);
    setStatusActive();
    setActiveDate();
    setOpenFilter(false);
  };

  useEffect(() => {
    if (dropdownRef.current) {
      const buttons =
        dropdownRef.current.querySelectorAll('div[role="button"]');
      buttons.forEach((button) => {
        button.removeAttribute("role");
      });
    }
  }, []);

  const uniqueStatusData = Object.values(
    data?.reduce((uniqueMap, item) => {
      uniqueMap[item.status] = item;
      return uniqueMap;
    }, {}),
  );

  useEffect(() => {
    filterApply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setallUsersData]);

  useEffect(() => {
    if (query) {
      const filteredData = data?.filter(
        (item) =>
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          item.email.toLowerCase().includes(query.toLowerCase()),
      );
      setTableFilterData(filteredData);
    }
    if (query === "") {
      setTableFilterData(data);
    }
  }, [data, query, tableFilterData]);

  return (
    <div className=" lg2:absolute top-0 right-0 ">
      <div className="w-full flex-center-end gap-3">
        <div className="relative w-fit">
          <div
            className="w-max flex items-center justify-start gap-2 rounded-lg px-[12px] py-[11px] bg-background2 cursor-pointer "
            onClick={() => setOpenFilter(!openFilter)}
          >
            <FilterIcon />

            <Text
              text="Filters (3)"
              color="text-background9"
              className={"font-normal text-sm "}
            />
          </div>
          {openFilter && (
            <div className="!w-[375px]  absolute top-[55px] right-0 z-[4] rounded-lg bg-background2 cursor-auto ">
              <div className="flex items-center justify-between p-[12px] border-b border-solid border-background9">
                <Text
                  text="Filters"
                  color="text-textPrimary"
                  className={"font-normal text-sm "}
                />
                <span
                  className="cursor-pointer"
                  onClick={() => setOpenFilter(false)}
                  role="button"
                >
                  <Cross />
                </span>
              </div>
              <div className="w-full flex flex-col gap-[16px] py-[16px] px-[12px]">
                <div>
                  <Text
                    text="Date added"
                    color="text-textPrimary"
                    className={"font-normal text-xs "}
                  />
                  <div className="flex items-center gap-2 mt-2">
                    {months.map((item, index) => (
                      <Button
                        key={index}
                        text={
                          <div className="flex items-center gap-[10px]">
                            {activeDate === item.id && (
                              <span className="w-[6px] h-[6px] rounded-full bg-primary1" />
                            )}
                            {item.name}
                          </div>
                        }
                        className={`w-fit !min-h-[34px] !px-[9px] !py-[6px] !font-medium rounded-[5px] text-[14px] leading-6 border border-solid ${
                          activeDate === item.id
                            ? "!border-text13 text-primary1"
                            : "!border-background9 text-textPrimary "
                        }`}
                        bg="!bg-transparent"
                        cta={() => setActiveDate(item.id)}
                        type="button"
                      />
                    ))}
                  </div>
                </div>
                <div>
                  <Text
                    text="Date added"
                    color="text-textPrimary"
                    className={"font-normal text-xs "}
                  />
                  <div className="w-full flex items-center gap-2 mt-2">
                    <div className=" w-full">
                      <DatePicker
                        value={
                          fromDate !== "yyyy-mm-dd" ? new Date(fromDate) : null
                        }
                        placeholder="From "
                        onChange={(e) => setFromDate(customDateFormat(e))}
                      />
                    </div>
                    <div className=" w-full">
                      <DatePicker
                        value={
                          toDate !== "yyyy-mm-dd" ? new Date(toDate) : null
                        }
                        placeholder="To"
                        onChange={(e) => setToDate(customDateFormat(e))}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Text
                    text=" Status"
                    color="text-textPrimary"
                    className={"font-normal text-xs "}
                  />
                  <div className="flex items-center gap-2 mt-2">
                    {uniqueStatusData?.map(
                      (item, index) =>
                        item.status && (
                          <Button
                            key={index}
                            text={
                              <div className="flex items-center gap-[8px]">
                                <span
                                  className={`w-[6px] h-[6px] rounded-full ${
                                    statusActive === item.status
                                      ? "bg-primary1"
                                      : "bg-text12"
                                  }`}
                                />
                                {item.status}
                              </div>
                            }
                            bg={
                              statusActive === item.status
                                ? "!bg-activeBackground"
                                : "!bg-text11"
                            }
                            className="w-fit !min-h-[34px] !px-[10px] !py-[7px] !font-medium rounded-[26px] text-[14px] leading-6 "
                            color="text-textPrimary "
                            cta={() => setStatusActive(item.status)}
                            type="button"
                          />
                        ),
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center px-[12px] py-[16px] gap-4 justify-between  border-t border-solid border-background9">
                <Button
                  text={"Reset"}
                  className="w-fit px-[12px] py-[7px] font-medium rounded-xl text-[14px] leading-6 border border-solid !border-background9 "
                  bg="!bg-transparent"
                  color="text-background9 "
                  cta={resetFilterData}
                  type="button"
                />
                <Button
                  text={"Apply"}
                  className="w-fit px-[12px] py-[7px] font-medium rounded-xl text-[14px] leading-6"
                  bg="bg-primary1"
                  color="text-background"
                  type="submit"
                  cta={() => {
                    filterApply();
                    setOpenFilter(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <Dropdown
          noCaret
          open={query !== "" ? true : false}
          ref={dropdownRef}
          toggleAs="section"
          title={
            <div className="relative w-fit">
              <span className="absolute top-[12px] left-[12px]">
                {<SearchIcon />}
              </span>
              <input
                placeholder="Search Inbox"
                value={query}
                onChange={(e) => {
                  e.preventDefault();
                  setQuery(e.target.value);
                }}
                className={`w-full md:w-[400px]  py-[12px] !outline-none text-sm text-background9 px-[46px]  rounded-lg bg-background2 `}
              />
              {query && (
                <span
                  onClick={() => setQuery("")}
                  className="absolute top-[12px] right-[12px] cursor-pointer"
                >
                  <CrossIcon />
                </span>
              )}
            </div>
          }
          className="[&>section]:!p-0 [&>section]:!z-0 [&>[role='menu']]:w-full [&>[role='menu']]:mt-2 [&>[role='menu']]:!py-2 [&>[role='menu']]:!px-[6px] [&>[role='menu']]:!rounded-lg [&>[role='menu']]:!bg-background2 "
        >
          {tableFilterData?.length > 0 ? (
            <div className="flex flex-col gap-1 overflow-y-scroll max-h-[250px] ">
              {tableFilterData?.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    setOpenModal("EditPermissions");
                    setUserProfile(item);
                  }}
                  className="w-full flex items-center justify-between  rounded-[4px] !px-2 !py-1 group hover:bg-activeBackground"
                >
                  <div className="w-fit flex-center-center gap-2   ">
                    <div className="flex-center-center w-[26px] h-[26px] rounded-full bg-background">
                      <Text
                        text={item.name
                          .split(" ")
                          ?.map((word) => word.charAt(0))
                          .slice(0, 2)}
                        color="text-textPrimary"
                        className={"font-normal text-xs"}
                      />
                    </div>
                    <Text
                      text={item.name}
                      color="text-textPrimary"
                      className={"font-normal text-xs tracking-[.5px]"}
                    />
                  </div>
                  <Text
                    text={item.userType}
                    color={
                      item.userType.includes("client")
                        ? "!text-primary1"
                        : item.userType.includes("coach")
                        ? "!text-purpalLight2"
                        : ""
                      // : item.userType.includes("Admin")
                      // ? "!text-successColor2 "
                      // : ""
                    }
                    className={
                      "font-normal text-xs  group-hover:!text-textPrimary"
                    }
                  />
                </Dropdown.Item>
              ))}
            </div>
          ) : (
            <Dropdown.Item className="w-full flex items-center justify-between  rounded-[4px] !px-2 !py-1 group ">
              <Text
                text="No, user search"
                color="text-textPrimary"
                className={"font-normal text-xs tracking-[.5px]"}
              />
            </Dropdown.Item>
          )}
        </Dropdown>
      </div>
    </div>
  );
};

export default SearchFilter;

const months = [
  {
    id: 1,
    name: "Last monts",
  },
  {
    id: 2,
    name: "This week",
  },
  {
    id: 3,
    name: "This year",
  },
];

import { useState, useEffect } from "react";

import { Heading, FeedLink, Text } from "../../utils";
import {
  Cross,
  Notify1,
  Notify2,
  Notify3,
  DeleteIcon,
} from "../../FeedLibraryIcon";
const allnotifications = [
  {
    id: 0,
    title: "Meal Plan Upadte",
    desc: "Gut health meal plan was added and ready to be used by client",
    time: "3 hours ago",
    Icon: <Notify1 />,
  },
  {
    id: 1,
    title: "Meal Plan Upadte",
    desc: "Gut health meal plan was added and ready to be used by client",
    time: "3 hours ago",
    Icon: <Notify2 />,
  },
  {
    id: 2,
    title: "Meal Plan Upadte",
    desc: "Gut health meal plan was added and ready to be used by client",
    time: "3 hours ago",
    Icon: <Notify3 />,
  },
  {
    id: 3,
    title: "Meal Plan Upadte",
    desc: "Gut health meal plan was added and ready to be used by client",
    time: "3 hours ago",
    Icon: <Notify3 />,
  },
];
function Notifications({ show, handleClose }) {
  const [transition, settransition] = useState(false);
  const [allnotificationsdata, setallnotificationsdata] = useState([]);
  useEffect(() => {
    if (!transition) {
      settransition(true);
      setallnotificationsdata(allnotifications);
    }
  }, [show, transition]);
  return (
    <>
      <div className="h-full z-[999] bg-[#000000c4] border-[#DEE2E6] fixed top-0 left-0 w-full flex justify-end  ">
        <div
          className={`bg-secondary1 screen-height p-[20px] transition-all ${
            transition ? "w-[411px]" : "w-0"
          }`}
        >
          <div className="flex items-center justify-between">
            <Heading
              text="Notification"
              className="leading-7"
              fontSize="text-[20px]"
              fontweight="font-semibold"
            />
            <div className="cursor-pointer" onClick={() => handleClose()}>
              <Cross />
            </div>
          </div>
          {allnotificationsdata?.length && (
            <div className="flex justify-end my-[32px]">
              <FeedLink
                text="Mark All Read"
                onClick={() => setallnotificationsdata([])}
              />
            </div>
          )}

          <div
            className={`h-[calc(100%-75px)]  overflow-auto flex flex-col items-center ${
              allnotificationsdata?.length ? "justify-start" : "justify-center"
            }`}
          >
            {allnotificationsdata?.length ? (
              allnotificationsdata?.map((notifi, index) => {
                return (
                  <div
                    key={index}
                    className="bg-background p-[20px] rounded-[12px] mb-[24px]"
                  >
                    <div className="flex">
                      <span>{notifi.Icon}</span>
                      <div className="flex flex-col gap-[12px] px-[16px]">
                        <Heading
                          text={notifi.title}
                          className="leading-6"
                          fontSize="text-[14px]"
                          fontweight="font-semibold"
                        />
                        <Text text={notifi.desc} color="text-text3" />
                        <Text text="3 hours ago" color="text-text3" />
                      </div>
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          setallnotificationsdata(
                            allnotificationsdata?.filter(
                              (f) => f.id !== notifi.id,
                            ),
                          );
                        }}
                      >
                        <DeleteIcon />
                      </span>
                    </div>
                    <div className="mt-[12px] pl-[40px] flex justify-between">
                      <Text
                        text="View"
                        className="text-text3 cursor-pointer"
                        fontweight="font-medium"
                      />
                      <FeedLink text="Mark as Read" />
                    </div>
                  </div>
                );
              })
            ) : (
              <Text
                text="You have no notification left unread!"
                fontSize="text-[16px]"
                color="text-text2"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Notifications;

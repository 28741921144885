/* eslint-disable no-unused-vars */
import {
  query,
  collection,
  getDocs,
  updateDoc,
  doc,
  addDoc,
  where,
  deleteDoc,
} from "firebase/firestore";
import store from "../store/store";
import { db } from "../configs/firebase";
import { updateProfileAction } from "../store/reducers/common";
import { toast } from "react-toastify";

const getUserProfileContext = async (uid, url) => {
  try {
    let userdata;
    const q = query(collection(db, url), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      userdata = { ...doc.data(), docId: doc.id };
    });
    return userdata;
  } catch (error) {
    console.error("Error finding users:", error);
    // Handle the error, show an error message, etc.
  }
};

const updateCoachProfileContext = async (uid, payload) => {
  try {
    let userdata;
    const q = query(collection(db, "users"), where("uid", "==", uid));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (doc) => {
      userdata = doc.data();
      const docRef = doc.ref;
      await updateDoc(docRef, {
        ...payload,
      });
    });
    store.dispatch(updateProfileAction(payload));
    return true;
  } catch (error) {
    console.error("Error finding or updating user profile:", error);
  }
};

const getAllUserClientContext = async (uid) => {
  try {
    const userFavoritesRef = collection(db, "users");
    const q = query(
      userFavoritesRef,
      where("coachId", "==", uid),
      where("userType", "==", "client")
    );
    const querySnapshot = await getDocs(q);
    const results = [];
    querySnapshot.forEach((doc) => {
      const user = doc.data();
      results.push({ docId: doc?.id, ...user });
    });
    return results;
  } catch (error) {
    console.error("Error searching for users:", error);
    // Handle the error, show an error message, etc.
  }
};

const createDocContext = async (url, payload) => {
  try {
    const docRef = await addDoc(collection(db, url), payload);
    if (docRef?.id) {
      return docRef;
    }
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};

const deletedocIDContext = async (collection, docId) => {
  try {
    const deleteRef = doc(db, collection, docId);
    await deleteDoc(deleteRef);
    return true;
  } catch (error) {
    console.error("Error deleting collection:", error);
  }
};

const getAllDocByCollectionContext = async (uid, collectName) => {
  try {
    const userFavoritesRef = collection(db, collectName);
    const q = query(userFavoritesRef, where("coachId", "==", uid));
    const querySnapshot = await getDocs(q);
    const results = [];
    querySnapshot.forEach((doc) => {
      const ctype = doc.data();
      results.push({ ...ctype, docId: doc.id });
    });
    return results;
  } catch (error) {
    console.error("Error searching for plan data:", error);
    // Handle the error, show an error message, etc.
  }
};

const updateDocCollectionContext = async (url, docId, payload) => {
  let docRef;
  try {
    docRef = doc(db, url, docId);
    // Update the document with the specified fields
    await updateDoc(docRef, payload);
    return true;
  } catch (error) {
    console.error("Error adding/updating document: ", error);
  }
};

const updatePlanContext = async (url, docId, payload) => {
  toast.dismiss();
  const toastId = toast.loading("Submitting...");
  let docRef;
  try {
    docRef = doc(db, url, docId);
    await updateDoc(docRef, payload);
    toast.update(toastId, {
      render: "Plan updated successfully",
      type: "success",
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });
    return true;
  } catch (error) {
    console.error("Error adding/updating document: ", error);
  }
};

export {
  getAllUserClientContext,
  getUserProfileContext,
  updateCoachProfileContext,
  deletedocIDContext,
  getAllDocByCollectionContext,
  updatePlanContext,
  updateDocCollectionContext,
  createDocContext,
};

import React from "react";

export const DropIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || "20"}
      height={size || "20"}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.692 7.94205L10.442 14.192C10.384 14.2502 10.3151 14.2963 10.2392 14.3277C10.1633 14.3592 10.082 14.3754 9.99986 14.3754C9.91772 14.3754 9.8364 14.3592 9.76052 14.3277C9.68465 14.2963 9.61572 14.2502 9.55767 14.192L3.30767 7.94205C3.1904 7.82477 3.12451 7.66571 3.12451 7.49986C3.12451 7.33401 3.1904 7.17495 3.30767 7.05767C3.42495 6.9404 3.58401 6.87451 3.74986 6.87451C3.91571 6.87451 4.07477 6.9404 4.19205 7.05767L9.99986 12.8663L15.8077 7.05767C15.8657 6.9996 15.9347 6.95354 16.0105 6.92211C16.0864 6.89069 16.1677 6.87451 16.2499 6.87451C16.332 6.87451 16.4133 6.89069 16.4892 6.92211C16.565 6.95354 16.634 6.9996 16.692 7.05767C16.7501 7.11574 16.7962 7.18468 16.8276 7.26055C16.859 7.33642 16.8752 7.41774 16.8752 7.49986C16.8752 7.58198 16.859 7.6633 16.8276 7.73917C16.7962 7.81504 16.7501 7.88398 16.692 7.94205Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};

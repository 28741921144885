import {
  query,
  collection,
  getDocs,
  deleteDoc,
  doc,
  getFirestore,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "../configs/firebase";
import store from "../store/store";
import { deleteChatReducer } from "../store/reducers/inbox";

const updateReadStatusContext = async (chatId, senderId) => {
  try {
    const firestore = getFirestore();
    const messagesQuery = query(
      collection(firestore, "Chats", chatId, "messages"),
      where("senderId", "==", senderId)
    );
    const messageDocs = await getDocs(messagesQuery);

    const batch = writeBatch(firestore);

    messageDocs.forEach((messageDoc) => {
      const messageRef = doc(
        firestore,
        "Chats",
        chatId,
        "messages",
        messageDoc.id
      );
      batch.update(messageRef, { isRead: true });
    });
    await batch.commit();
    console.log('Sender\'s messages updated to "isRead: true".');
  } catch (error) {
    console.error("Error updating sender's messages:", error);
  }
};

const deleteChatContext = async (chatId) => {
  try {
    const chatDocRef = doc(db, "Chats", chatId);
    await deleteDoc(chatDocRef);
    store.dispatch(deleteChatReducer(chatId));
    console.log("Chat deleted successfully.");
  } catch (error) {
    console.error("Error deleting chat:", error);
  }
};

export { updateReadStatusContext, deleteChatContext };

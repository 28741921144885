/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Heading } from "../../utils";
import { useLocation } from "react-router-dom";

export const SideNavigations = ({
  settemplateName,
  collapse,
  data,
  selectedTemplateName,
}) => {
  const location = useLocation();
  return (
    <>
      {data?.map((menu, index) => {
        return (
          <div
            key={index}
            className="flex-column mb-[24px] cursor-pointer relative "
            onClick={() => {
              settemplateName({ ...menu, active: true });
            }}
          >
            <div
              className={`flex-items-center p-[12px] rounded-[12px] ${
                menu.path === selectedTemplateName?.path
                  ? "bg-activeBackground"
                  : "bg-secondary1"
              } `}
            >
              <span
                className={`mr-[12px] ${
                  location.pathname === "/template" ? "" : !collapse && "!mr-0"
                }`}
              >
                {menu.icon}
              </span>
              <Heading
                text={menu.name}
                fontSize="text-[16px]"
                color="text-[#fff]"
                fontweight="font-normal"
                className={`leading-6 tracking-[0.5px]  ${
                  location.pathname === "/template"
                    ? ""
                    : collapse
                    ? "block"
                    : "hidden"
                }`}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Loader, Modal } from "rsuite";
import { Button, Heading } from "../../utils";

import AssignToClient from "../common/assignToClient";

export const TemplateModal = (props) => {
  const { handleClose, show, CTA, setselectedClient, selectedClient } = props;
  const common = useSelector((state) => state.common);
  const uiSettings = useSelector((state) => state.uiSettings);
  const [submitBtnText, setsubmitBtnText] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  useEffect(() => {
    if (uiSettings?.formName === "assign-template") {
      setsubmitBtnText("Assign");
      setModalTitle("Assign Template");
    } else {
      setsubmitBtnText("Save");
      setModalTitle("Modal Name");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal
      overflow
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }} // Center the modal
      className="flex-items-center  foodModal modal-black"
      open={show}
      onClose={handleClose}
    >
      <Modal.Header>
        <Modal.Title>
          <Heading
            text={modalTitle}
            fontSize="text-[18px]"
            fontweight="font-normal"
            color="text-textPrimary"
            className="font-medium text-center !leading-10"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {uiSettings?.formName === "assign-template" && (
          <div className="w-[500px]">
            <AssignToClient
              setselectedClient={setselectedClient}
              selectedClient={selectedClient}
              options={common?.allClients?.map((client) => {
                return { ...client, id: client.uid, name: client.name };
              })}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="w-full ">
        <div className="w-full flex-center-between gap-4  ">
          <Button
            text={"Cancel"}
            className="w-fit  px-[12px] py-[7px] font-medium rounded-xl text-[14px] leading-6 border border-solid border-textPrimary "
            bg="!bg-transparent"
            color="text-textPrimary "
            cta={handleClose}
            type="button"
          />
          <Button
            text={
              uiSettings?.btnloader ? (
                <Loader size="sm" content={submitBtnText} />
              ) : (
                submitBtnText
              )
            }
            className="w-fit flex  px-[12px] py-[7px] font-medium rounded-xl text-[14px] leading-6"
            bg="bg-primary1"
            color="text-background"
            type="button"
            cta={() => {
              // if (selectedClient?.length > 0) {
              CTA(selectedClient);
              // }
            }}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

import {
  ChecboxIcon,
  DownloadIcon,
  DropdownIcon,
  LinearScaleIcon,
  MultiChoice,
  MutlichoiceGrid,
  Paragraph,
  ShortAnswer,
  TimerIcon,
} from "../FeedLibraryIcon";

export const removeDuplicateArrayItem = (arryaProps) => {
  const uniqueProducts = {};
  const filteredProducts = arryaProps?.filter((product) => {
    if (!uniqueProducts[product.id]) {
      uniqueProducts[product.id] = true;
      return true;
    }
    return false;
  });
  return filteredProducts;
};

export const customDateFormat = (dateProps) => {
  if (dateProps) {
    // Get the current date
    const currentDate = new Date(dateProps);

    // Get year, month, and day from the current date
    const year = currentDate.getFullYear();
    // Months are zero-based, so add 1 to get the correct month
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    // Format the date as yyyy-MM-dd
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  } else {
    return `yyyy-mm-dd`;
  }
};

export const formatDateForDisplay = (updateTime) => {
  const now = new Date();
  const updated = new Date(updateTime);
  if (isNaN(updated)) {
    return "No recent activity";
  }
  const diff = Math.abs(now - updated);
  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(days / 7);

  if (minutes < 1) {
    return "just now";
  } else if (minutes < 60) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (days < 7) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (weeks === 1) {
    const formattedTime = updated.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `A week ago at ${formattedTime}`;
  } else if (weeks < 4) {
    const formattedDayTime = updated.toLocaleDateString([], {
      month: "short",
      day: "numeric",
    });
    const formattedTime = updated.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDayTime} at ${formattedTime}`;
  } else {
    const formattedDate = updated.toLocaleDateString([], {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    const formattedTime = updated.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDate} at ${formattedTime}`;
  }
};

export const formatChatDate = (timestamp) => {
  // Convert Firebase server date to JavaScript Date object
  const dateObject = new Date(
    timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000,
  );

  // Get current date and time
  const currentDate = new Date();
  const currentTime = currentDate.getTime();

  // Calculate the time difference in milliseconds
  const timeDifference = currentTime - dateObject.getTime();
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);

  if (daysDifference === 0) {
    // Today's message
    const hours = dateObject.getHours().toString().padStart(2, "0");
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    return `Today ${hours}:${minutes}`;
  } else {
    // Older messages
    // Format the date
    const day = dateObject.getDate().toString().padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[dateObject.getMonth()];
    const year = dateObject.getFullYear();
    const hours = dateObject.getHours().toString().padStart(2, "0");
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    return formattedDate;
  }
};

export const workoutSinlgeChecked = [
  "gender",
  "age",
  "commitDays",
  "timeFrameForOutput",
  "programType",
];

export function generateUniqueId() {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let autoId = "";

  for (let i = 0; i < 20; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    autoId += chars[randomIndex];
  }
  return autoId;
}

export const getQuestionLabel = (type, name) => {
  if (type === "control_textbox" && name === "file_upload") {
    return { name: "File Upload", icon: <DownloadIcon /> };
  } else if (type === "control_textbox") {
    return { name: "Short Text", icon: <ShortAnswer /> };
  } else if (type === "control_textarea") {
    return { name: "Long Text", icon: <Paragraph /> };
  } else if (type === "control_dropdown") {
    return { name: "Dropdown", icon: <DropdownIcon /> };
  } else if (type === "control_radio") {
    return { name: "Single Choice", icon: <ChecboxIcon /> };
  } else if (type === "control_checkbox") {
    return { name: "Multi Choice", icon: <MutlichoiceGrid /> };
  } else if (type === "control_number") {
    return { name: "Number", icon: <MultiChoice /> };
  } else if (type === "control_spinner") {
    return { name: "Spinner", icon: <MultiChoice /> };
  } else if (type === "control_scale") {
    return { name: "Scale Rating", icon: <LinearScaleIcon /> };
  } else if (type === "control_datetime") {
    return { name: "Date", icon: <TimerIcon /> };
  }
};
export const sortedData = (data) => {
  const dataArrayCopy = JSON.parse(JSON.stringify(data));
  const sortedArray = dataArrayCopy?.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  );
  return sortedArray;
};

export const duplicateItem = (items, title) => {
  let copyCount = 0;
  let copiedTitle = "";

  // Regular expression to match numbers within parentheses
  const regex = /\((\d+)\)/;

  // Check if the title already contains a number within parentheses
  const match = title.match(regex);
  if (match) {
    copyCount = parseInt(match[1]) + 1;
    copiedTitle = title.replace(regex, `(${copyCount})`);
  } else {
    copiedTitle = `copy(1) ${title}`;
  }

  // Check if the generated title already exists
  // eslint-disable-next-line no-loop-func
  while (items.some((item) => item.title === copiedTitle)) {
    copyCount++;
    copiedTitle = copiedTitle.replace(/\(\d+\)/, `(${copyCount})`);
  }

  return copiedTitle;
};

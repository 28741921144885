import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../configs/firebase";
import { setUserDetailsAction } from "../store/actions/common";
import { Navigate, useLocation } from "react-router-dom";
import { getUserProfileContext } from "../Services/commonContext";
import Loader from "../assets/images/threeDotLoader.svg";

const PrivateRoute = ({ children, setLoading, loading }) => {
  let location = useLocation();
  const [user, setUser] = useState(null);
  const [hasRunOnce, setHasRunOnce] = useState(false);

  // const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const isProtectedOrAuthRoute = true;
    if (isProtectedOrAuthRoute) {
      
      const proceed = onAuthStateChanged(auth, async (user) => {
        if (user && user.email) {
          const userData = await getUserProfileContext(user?.uid, "users");
          if (userData?.userType !== "client") {
            console.log("userData", user);

            setUserDetailsAction({
              uid: user?.uid,
              email: user?.email,
              avatar: userData?.avatar,
              emailVerified: user?.emailVerified,
              displayName: userData?.displayName || user?.displayName,
              firstName: userData?.firstName,
              lastName: userData?.lastName,
              phoneNumber: userData?.phoneNumber,
              docId: userData?.docId,
              userType: userData?.userType,
            });
            setUser({
              ...user,
            });
          }
        } else {
          setUser(null);
          setUserDetailsAction(null);
        }
        setLoading(false); // Set loading to false after the authentication check is done
      });

      if (!hasRunOnce) {
        setHasRunOnce(true);
        return () => proceed();
      }
    } else {
      setUser(null);
      setUserDetailsAction(null);
      setLoading(false); // Set loading to false if the route is not protected
    }
  }, [location, setLoading]);

  if (loading) {
    // Loading state, you can render a loading spinner or any loading indicator here
    return (
      <div className="w-full h-screen flex-center-center">
        <div className="flex-end-center-column text-center ">
          <span className="text-primary1 text-4xl font-medium">Kaboodle</span>
          <img
            src={Loader}
            className="w-[62px] h-[28px] object-cover"
            alt="Loading..."
          />
        </div>
      </div>
    );
  }

  if (!user?.accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;

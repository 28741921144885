import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button, Stepper } from "../../utils";
import SteppeOne from "./SteppeOne";
import SteppeTwo from "./SteppeTwo";
import SteppeThree from "./steppeThree";
import SteppeFour from "./SteppeFour";

const NewWorkoutPlan = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const uiSettings = useSelector((state) => state.uiSettings);

  const [stepper, setStepper] = useState([1]);
  const [planScreenState, setplanScreenState] = useState("create");
  const [aiLoader, setaiLoader] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(
    uiSettings?.editForm?.method || "Scratch",
  );

  const closePlanHandler = () => {
    navigate(uiSettings?.editForm?.closer || "/template?type=workout-plan");
  };

  const backButtonHandler = () => {
    if (selectedPlan === "AI") {
      if (planScreenState === "fillQuestions") {
        setStepper([1]);
        setplanScreenState("create");
      } else if (planScreenState === "addMovements") {
        setStepper([1, 2]);
        setplanScreenState("fillQuestions");
      } else if (planScreenState === "review&Save") {
        setStepper([1, 2, 3]);
        setplanScreenState("addMovements");
      } else if (planScreenState === "create") {
        navigate(uiSettings?.editForm?.closer || "/template");
      }
    } else if (selectedPlan === "Scratch") {
      if (planScreenState === "addMovements") {
        setStepper([1]);
        setplanScreenState("create");
      } else if (planScreenState === "review&Save") {
        setStepper([1, 2]);
        setplanScreenState("addMovements");
      } else if (planScreenState === "create") {
        navigate(uiSettings?.editForm?.closer || "/template");
      }
    }
  };
  const nextButtonHandler = () => {
    if (selectedPlan === "AI") {
      if (planScreenState === "create") {
        formRef.current?.submitForm();
      } else if (planScreenState === "fillQuestions") {
        formRef.current?.submitForm();
      } else if (planScreenState === "addMovements") {
        formRef.current?.submitForm();
      } else if (planScreenState === "review&Save") {
        formRef.current?.submitForm();
      }
    } else if (selectedPlan === "Scratch") {
      if (planScreenState === "create") {
        formRef.current?.submitForm();
      } else if (planScreenState === "addMovements") {
        formRef.current?.submitForm();
      } else if (planScreenState === "review&Save") {
        formRef.current?.submitForm();
      }
    }
  };

  return (
    <div className="main-container !py-[26px]">
      <div className="mb-[16px]">
        <Stepper stepper={stepper} total={selectedPlan === "AI" ? 4 : 3} />
      </div>
      {planScreenState === "create" && (
        <SteppeOne
          formRef={formRef}
          closePlanHandler={closePlanHandler}
          setStepper={setStepper}
          setplanScreenState={setplanScreenState}
          setSelectedPlan={setSelectedPlan}
          selectedPlan={selectedPlan}
        />
      )}
      {planScreenState === "fillQuestions" && (
        <SteppeTwo
          setaiLoader={setaiLoader}
          aiLoader={aiLoader}
          formRef={formRef}
          closePlanHandler={closePlanHandler}
          setStepper={setStepper}
          setplanScreenState={setplanScreenState}
        />
      )}
      {planScreenState === "addMovements" && (
        <SteppeThree
          formRef={formRef}
          closePlanHandler={closePlanHandler}
          setStepper={setStepper}
          setplanScreenState={setplanScreenState}
          selectedPlan={selectedPlan}
        />
      )}
      {planScreenState === "review&Save" && (
        <SteppeFour
          formRef={formRef}
          closePlanHandler={closePlanHandler}
          stepper={stepper}
        />
      )}

      <div className="w-full flex-center-between py-[48px]">
        <Button
          text="Back"
          type="button"
          bg="bg-background border border-textPrimary"
          className="px-[30px] rounded-[12px] leading-6 text-[14px]"
          color="text-textPrimary"
          cta={backButtonHandler}
        />
        {/* <Text
          text="Next: Review the plan"
          color="text-textPrimary"
          fontSize={"text-[15px]"}
          className={"leading-[21px]"}
        /> */}
        {uiSettings?.formName == "view-template" &&
        planScreenState === "review&Save" ? (
          ""
        ) : (
          <Button
            text={stepper.includes(4) ? "Save & Exit" : "Next"}
            type="button"
            bg="bg-primary1"
            disabled={aiLoader}
            className="px-[40px]  rounded-[12px] leading-6 text-[16px]"
            color="text-background"
            cta={nextButtonHandler}
          />
        )}
      </div>
    </div>
  );
};

export default NewWorkoutPlan;

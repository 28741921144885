import {
  IntakeIcon,
  MealPlanIcon,
  WorkoutIcon,
  SupplementIcon,
  CheckInIcon,
  ShortAnswer,
  Paragraph,
  MultiChoice,
  ChecboxIcon,
  DropdownIcon,
  DownloadIcon,
  LinearScaleIcon,
  MutlichoiceGrid,
} from "../FeedLibraryIcon";
export const Menus = [
  {
    id: 1,
    name: "Intake Form",
    path: "intake",
    icon: <IntakeIcon />,
    active: true,
    btnText: "Create a New Template",
    clientbtnText: "Create a Intake Form",
  },
  {
    id: 2,
    name: "Check-In Form",
    path: "check-in",
    icon: <CheckInIcon />,
    active: false,
    btnText: "Create a New Template",
    clientbtnText: "Create a Check-In Form",
  },
  {
    id: 3,
    name: "Meal Plan",
    path: "meal-plan",
    icon: <MealPlanIcon />,
    active: false,
    btnText: "Create Meal Plan Template",
    clientbtnText: "Create a Meal Plan",
  },
  {
    id: 4,
    name: "Workouts",
    path: "workout-plan",
    icon: <WorkoutIcon />,
    active: false,
    btnText: "Create Workout Template",
    clientbtnText: "Create a Workout Plan",
  },
  {
    id: 5,
    name: "Supplements",
    path: "supplement-plan",
    icon: <SupplementIcon />,
    active: false,
    btnText: "Create Supplement Template",
    clientbtnText: "Create a Supplement Plan",
  },
];

export const questionOptions = [
  {
    id: "control_textbox",
    name: "Short Text",
    icon: <ShortAnswer />,
    value: "control_textbox",
  },
  {
    id: "control_textarea",
    name: "Long Text",
    icon: <Paragraph />,
    value: "control_textarea",
  },
  {
    id: "control_dropdown",
    name: "Dropdown",
    icon: <DropdownIcon />,
    value: "control_dropdown",
  },
  {
    id: "control_radio",
    name: "Single Choice",
    icon: <ChecboxIcon />,
    value: "control_radio",
  },
  {
    id: "control_checkbox",
    name: "Multiple Choice",
    icon: <MutlichoiceGrid />,
    value: "control_checkbox",
  },
  {
    id: "control_number",
    name: "Number",
    icon: <MultiChoice />,
    value: "control_number",
  },
  {
    id: "control_fileupload",
    name: "File Upload",
    icon: <DownloadIcon />,
    value: "control_fileupload",
  },
  {
    id: "control_spinner",
    name: "Spinner",
    icon: <MultiChoice />,
    value: "control_spinner",
  },
  {
    id: "control_scale",
    name: "Scale Rating",
    icon: <LinearScaleIcon />,
    value: "control_scale",
  },

  // { id: 'control_datetime', name: 'Date Time', icon: <TimerIcon />, value: 'control_datetime' },
];

import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { FeedCheckbox, Heading, Text, ToggleButton } from "../../../utils";
import permissions from "../../../schema/permissions.json";
import { useDispatch } from "react-redux";
import { setRolePermissions } from "../../../store/reducers/permissions";
import { toast } from "react-toastify";

const PermissionsFormik = ({ formRef, setOpenModal }) => {
  const [allPermissions, setallPermissions] = useState(permissions);

  const [toggle, setToggle] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    setallPermissions(permissions);
  }, []);

  console.log("allPermissions", allPermissions);
  return (
    <div className='w-full h-full  px-[20px] py-[16px] overflow-x-clip overflow-y-scroll '>
      <Formik
        initialValues={{
          role: [],
        }}
        innerRef={formRef}
        enableReinitialize
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const result = {};
          allPermissions.forEach((item) => {
            const obj = { ...item };
            delete obj.permissions;
            result[item.key] = obj;
            item.permissions.forEach((permission) => {
              result[item.key][permission.key] = permission.checked;
            });
          });

          dispatch(setRolePermissions(result));
          setOpenModal("");
          toast("Permission has been updated Successfully");
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className='w-full'>
            <div className='w-full flex-column  gap-4'>
              {allPermissions?.map((item, index) => (
                <div
                  key={index}
                  className='w-full rounded-lg border border-solid !border-background2'>
                  <div className='flex-center-between gap-3 p-[16px] '>
                    <div>
                      <Heading
                        text={item.heading}
                        color='text-textPrimary'
                        className='!text-base !leading-[22px] mb-1'
                      />
                      <Text color='text-background9' text={item.description} />
                    </div>
                    <ToggleButton
                      checked={toggle?.includes(item.id)}
                      onChange={() => {
                        if (toggle.includes(item.id)) {
                          setToggle(toggle.filter((id) => id !== item.id));
                        } else {
                          setToggle([...toggle, item.id]);
                        }
                      }}
                      className='custom-check'
                    />
                  </div>
                  {toggle?.includes(item.id) && (
                    <div className='flex-start-start flex-col gap-2 p-[16px]  border-t border-solid !border-background2'>
                      {item?.permissions?.map((check, i) => (
                        <>
                          {check.label && (
                            <FeedCheckbox
                              key={i}
                              name={"role"}
                              text={check.label}
                              id={check.id}
                              className='!text-sm !text-background11'
                              onChange={(e) => {
                                setallPermissions(
                                  allPermissions?.map((temp) => {
                                    if (item.id === temp.id) {
                                      return {
                                        ...temp,
                                        permissions: temp?.permissions?.map(
                                          (perm) => {
                                            if (perm.id === check.id) {
                                              return {
                                                ...perm,
                                                checked: e.target.checked,
                                              };
                                            } else {
                                              return perm;
                                            }
                                          },
                                        ),
                                      };
                                    } else {
                                      return temp;
                                    }
                                  }),
                                );
                              }}
                              onBlur={handleBlur}
                              value={check.checked}
                              checked={check.checked}
                              setFieldValue={setFieldValue}
                            />
                          )}
                          <Text
                            text={check.subHeading}
                            className='!text-sm !text-background11'
                          />
                          {check?.subPermission?.map((ch, y) => (
                            <div className='pl-[16px]'>
                              <FeedCheckbox
                                key={y}
                                text={ch.label}
                                id={ch.id}
                                className='!text-sm !text-background11'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={ch.value}
                                setFieldValue={setFieldValue}
                              />
                            </div>
                          ))}
                        </>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PermissionsFormik;

import React from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { CalendarIcon, CrossIcon } from "../../FeedLibraryIcon";

const Index = ({ placeholder, value, onChange, className }) => {
  return (
    <div>
      <DatePicker
        onChange={onChange}
        value={
          value !== "yyyy-mm-dd" && value !== null ? new Date(value) : null
        }
        placeholder={placeholder}
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        calendarIcon={!value && <CalendarIcon />}
        clearIcon={value && <CrossIcon />}
        className={className}
      />
    </div>
  );
};

export default Index;

/* eslint-disable */
import {createSlice} from '@reduxjs/toolkit';
const initialState = {
  allTemplateForm: []
};

export const template = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setAllTemplateFormReducer: (state, action) => {
      state.allTemplateForm = action.payload;
    },
    addTemplateFormReducer: (state, action) => {
      state.allTemplateForm = [...state.allTemplateForm, action.payload];
    },
    deleteTemplateFormReducer: (state, action) => {
      state.allTemplateForm = state.allTemplateForm?.filter((f) => f.id !== action.payload);
    },
    updateTemplateFormReducer: (state, action) => {
      state.allTemplateForm = state.allTemplateForm?.map((temp) => {
        if (temp.docId === action.payload.docId) {
          return action.payload;
        } else {
          return temp;
        }
      });
    }
  }
});

export const {setAllTemplateFormReducer, addTemplateFormReducer, updateTemplateFormReducer, deleteTemplateFormReducer} = template.actions;

export default template.reducer;

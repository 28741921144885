/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Animation } from "rsuite";

import FeedBreadcrumbs from "../../../components/common/breadcrumbs";
import { MainHeading } from "../../../components";
import {
  Button,
  DatePicker,
  FeedCheckbox,
  FeedInput,
  Heading,
  LabelFeed,
  Text,
  TextArea,
  ToggleButton,
} from "../../../utils";
import {
  ArrowDown,
  ArrowUp,
  CrossIcon,
  LinkIcon,
} from "../../../FeedLibraryIcon";
import {
  setbtnLoaderReducer,
  seteditFormReducer,
} from "../../../store/reducers/uiSettings";
import { guidedWorkoutQuestions } from "../../../schema";
import { getallMyClientAction } from "../../../store/actions/common";

import { customDateFormat } from "../../../helper";
import AssignToClient from "../../../components/common/assignToClient";
import ActionDropdown from "../steppeThree/actionDropdown";

import {
  addWorkoutTemplateAction,
  updateWorkoutTemplateAction,
} from "../../../store/actions/workoutPlan";
import { toast } from "react-toastify";

const weeks = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
];

const SteppeFour = ({ closePlanHandler, formRef, stepper }) => {
  const initiallySelectedDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const uiSettings = useSelector((state) => state.uiSettings);
  const { editForm } = uiSettings;
  const common = useSelector((state) => state.common);
  const [guidedquestions, setguidedquestions] = useState([]);
  const [checked, setChecked] = useState(true);
  const [selectedClient, setselectedClient] = useState([]);
  const [startDate, setstartDate] = useState(
    uiSettings?.editForm?.start_date || "yyyy-mm-dd",
  );
  const [selectedDays, setSelectedDays] = useState(
    uiSettings?.editForm?.selectedDays || initiallySelectedDays,
  );
  const [show, setShow] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setselectedClient(
      common.allClients?.filter((f) =>
        uiSettings?.editForm?.clientIds?.includes?.(f.uid),
      ),
    );
  }, [uiSettings?.editForm?.clientIds]);

  const handleCheckboxChange = (value) => {
    const currentIndex = selectedDays.indexOf(value);
    const newSelectedDays = [...selectedDays];
    if (currentIndex === -1) {
      newSelectedDays.push(value);
    } else {
      newSelectedDays.splice(currentIndex, 1);
    }
    setSelectedDays(newSelectedDays);
  };

  useEffect(() => {
    getallMyClientAction(common?.user?.uid);
    setguidedquestions(
      guidedWorkoutQuestions?.map((question, index) => {
        return {
          ...question,
          questionsOption: question?.questionsOption?.map((q, key) => {
            return {
              ...q,
              checked: key === 0 ? true : false,
            };
          }),
        };
      }),
    );
  }, []);
  const handleDeleteMovements = (moveId) => {
    dispatch(
      seteditFormReducer({
        type: "create-supplement",
        data: {
          ...uiSettings.editForm,
          movements: editForm?.movements?.filter((f) => f.id !== moveId),
        },
      }),
    );
  };

  return (
    <>
      <div className="">
        <FeedBreadcrumbs
          backClick={closePlanHandler}
          selectedTemplateName={{ name: "Workouts" }}
          formRef={formRef}
          cancelBtnClass="!border-none !p-2"
          submitBtnClass="!rounded-[8px]"
          marginBottom="!mb-0"
          disabled={stepper.includes(4) ? false : true}
          crumbText="Workout Plan"
        />
        <MainHeading
          title="Review & Save"
          description={
            "Welcome to the Kaboodle Workout Plan Builder. Please complete all steps and proceed using the buttons at the bottom of each screen."
          }
        />
      </div>

      <div className="mt-6 w-full  ">
        <Formik
          initialValues={{
            name: uiSettings?.editForm?.name || "",
            description: uiSettings?.editForm?.description || "",
            start_date: startDate,
          }}
          enableReinitialize
          innerRef={formRef}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (values.start_date === "yyyy-mm-dd" || startDate === null) {
              errors.start_date = "Required";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            window.scrollTo(0, 0);
            toast.dismiss();
            dispatch(setbtnLoaderReducer(true));
            const payload = {
              ...uiSettings.editForm,
              ...values,
              start_date: customDateFormat(startDate),
              clientIds: selectedClient
                ?.filter((f) => f?.uid)
                ?.map((client) => client?.uid),
              selectedDays: selectedDays,
              usedCount: selectedClient?.length,
              coachId: common?.user?.uid,
              createdAt:
                uiSettings?.formName === "edit-workout"
                  ? editForm?.createdAt
                  : new Date().toLocaleString(),
              updatedAt: new Date().toLocaleString(),
            };
            let res;
            if (uiSettings?.formName === "edit-workout") {
              res = await updateWorkoutTemplateAction(
                `workoutplan`,
                payload?.docId,
                payload
              );
            } else {
              res = await addWorkoutTemplateAction(`workoutplan`, payload);
            }
            if (res?.id || res === true) {
              navigate(
                uiSettings?.editForm?.closer || "/template?type=workout-plan"
              );
              dispatch(setbtnLoaderReducer(false));
              // toast("Plan Created Successfully");
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <>
              <form
                onSubmit={handleSubmit}
                className={`flex-column w-full  ${
                  uiSettings?.formName === "view-template"
                    ? "pointer-events-none"
                    : "pointer-events-auto"
                } `}
              >
                <div className="w-full grid grid-cols-2 gap-[16px] mb-6">
                  <div className="w-full h-full ">
                    <div className="w-full flex-items-start-column mb-6 autofill-fix">
                      <LabelFeed
                        text={
                          <>
                            Workout Plan Name{" "}
                            <span className="text-primary1">*</span>
                          </>
                        }
                        className={"mb-[10px] !font-medium !text-lg"}
                      />
                      <FeedInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        setFieldValue={setFieldValue}
                        type="text"
                        placeholder="Add Name"
                        name="name"
                        crossicon={values?.name ? <CrossIcon /> : ""}
                        className="w-full  !px-5 !bg-secondary1"
                      />
                      {errors.name && touched.name && (
                        <div className="text-red text-[14px]  pt-[4px]">
                          {errors.name}
                        </div>
                      )}
                    </div>

                    <div className="w-full h-full flex-items-start-column ">
                      <LabelFeed
                        text="Description"
                        className={"mb-[12px] !font-medium !text-lg"}
                      />
                      <div className="w-full min-h-[258px] p-3 rounded-[8px] bg-secondary1">
                        <TextArea
                          placeholder="Add Instruction"
                          rows={5}
                          name={"description"}
                          setFieldValue={setFieldValue}
                          onChange={handleChange}
                          value={values.description}
                          onBlur={handleBlur}
                          className="leading-[24px] h-full !p-0 text-[16px] font-normal text-text6 !outline-none !border-none !bg-transparent"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full mt-[37px]">
                    <div className="w-full flex-column gap-[16px] px-[20px] pt-[16px] pb-6 rounded-lg bg-secondary1 mb-[10px]">
                      <div className="w-full flex items-center justify-between">
                        <Heading
                          text="Assign to client"
                          color="text-textPrimary"
                          fontSize="text-[20px]"
                          fontweight="font-medium"
                          className="leading-[40px] "
                        />
                        <ToggleButton
                          checked={checked}
                          onChange={setChecked}
                          className="custom-check"
                        />
                      </div>

                      {checked && (
                        <div className="flex-column gap-[8px] w-full ">
                          <LabelFeed
                            text="Client Name"
                            fontSize={"!text-[14px]"}
                            color="text-textPrimary"
                            className="leading-[24px] tracking-[0.5px]"
                          />

                          <AssignToClient
                            heading
                            setselectedClient={setselectedClient}
                            selectedClient={selectedClient}
                            options={common?.allClients?.map((client) => {
                              return {
                                ...client,
                                id: client.uid,
                                name: client.name,
                              };
                            })}
                          />
                          {errors.clientIds && (
                            <div className="text-red text-[14px]  pt-[4px]">
                              {errors.clientIds}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="w-full flex-column gap-[24px] px-[20px] py-6 rounded-lg bg-secondary1 ">
                      <div className="flex-columnl gap-[8px] w-full f4f-date-wrapper">
                        <LabelFeed
                          text={
                            <>
                              Start Date{" "}
                              <span className="text-primary1">*</span>
                            </>
                          }
                          fontSize={"!text-[14px]"}
                          color="text-textPrimary"
                          className="leading-[24px] tracking-[0.5px]"
                        />
                        <DatePicker
                          value={startDate}
                          placeholder="Select start date"
                          onChange={(e) => setstartDate(e)}
                        />
                        {errors.start_date && (
                          <LabelFeed
                            text="Required"
                            color="text-red"
                            className="!leading-[22px] !text-[14px]"
                          />
                        )}
                      </div>
                      <div className="flex-column gap-[8px] w-full ">
                        <LabelFeed
                          text={
                            <>
                              Frequency <span className="text-primary1">*</span>
                            </>
                          }
                          fontSize={"!text-[14px]"}
                          color="text-textPrimary"
                          className="leading-[24px] tracking-[0.5px]"
                        />
                        <div className="w-full rounded-lg bg-background">
                          <div className="w-full flex-center-between gap-3 pt-[11px] px-[12px] pb-[12px] ">
                            <LabelFeed
                              text={`${selectedDays.length} Days / Week`}
                              fontSize={"!text-[14px]"}
                              color="text-textPrimary"
                              className="leading-[24px] tracking-[0.5px]"
                            />
                            <Button
                              text={show ? "Save" : "Change"}
                              color="text-primary1"
                              className="!w-max !h-auto !p-0 !text-sm !leading-6 tracking-[0.5px] !border-none"
                              cta={() => setShow(!show)}
                              type="button"
                            />
                          </div>
                          <Animation.Collapse in={show} dimension="height">
                            <div className="w-full pt-[12px] px-[12px] pb-[11px] border-t border-solid border-background2">
                              <LabelFeed
                                text="Select Days"
                                fontSize={"!text-[14px]"}
                                color="text-textPrimary"
                                className="leading-[24px] tracking-[0.5px] mb-[10px]"
                              />
                              <div
                                className="w-full grid grid-cols-4 gap-x-2 gap-y-[10px] "
                                role="group"
                                aria-labelledby="checkbox-group"
                              >
                                {weeks.map((day, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center px-[10px] py-2"
                                  >
                                    <FeedCheckbox
                                      name="tags"
                                      text={day.label}
                                      value={day.value}
                                      checked={selectedDays.includes(day.value)}
                                      onChange={() =>
                                        handleCheckboxChange(day.value)
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                              {errors.days && touched.days && (
                                <LabelFeed
                                  text={errors.days}
                                  color="text-red"
                                  className="!leading-[22px] !text-[14px]"
                                />
                              )}
                            </div>
                          </Animation.Collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* d */}

                {/* <Text
                  text="Movements Summary"
                  fontSize={"!text-[20px]"}
                  color="text-white"
                />
                {editForm?.movements?.length ? (
                  editForm?.movements?.map((item) => (
                    <div className="w-[672px] flex-column gap-[10px] mt-[10px] pointer-events-none">
                      <div className=" w-full flex-column gap-6 p-[20px] rounded-xl bg-secondary1">
                        <div className="w-full flex-items-center  gap-2">
                          <Button
                            Icon={
                              openNotes ? (
                                <ArrowUp color={"#C0F121"} />
                              ) : (
                                <ArrowDown />
                              )
                            }
                            bg="bg-activeBackground"
                            className="!rounded-lg !w-[44px] !h-[44px] !p-3 flex-shrink-0"
                            type="button"
                            cta={() => setOpenNotes(!openNotes)}
                          />

                          <FeedInput
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={item.order}
                            setFieldValue={setFieldValue}
                            type="text"
                            placeholder="Movement Order"
                            name="movementOrder"
                            crossicon={
                              values?.movementOrder ? <CrossIcon /> : ""
                            }
                            width={"!w-[96px] flex-shrink-0"}
                            className="w-full  h-[44px] !font-light !text-base !rounded-lg !px-3 !py-2 !bg-background"
                          />
                          <FeedInput
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={item.excerciseName}
                            setFieldValue={setFieldValue}
                            type="text"
                            placeholder="Movement Name"
                            name="movementName"
                            crossicon={
                              values?.movementName ? <CrossIcon /> : ""
                            }
                            className="!w-full  h-[44px] !font-light !text-base !rounded-lg !px-3 !py-2 !bg-background"
                          />
                          {uiSettings?.formName !== "view-template" && (
                            <>
                              <Button
                                Icon={<LinkIcon />}
                                bg="bg-activeBackground"
                                className="!rounded-lg !w-[44px] !h-[44px] !p-3 flex-shrink-0"
                                type="button"
                                cta={() => {}}
                              />
                              <ActionDropdown
                                handleDeleteMovements={handleDeleteMovements}
                                item={item}
                              />
                            </>
                          )}
                        </div>
                        <div className="w-full flex-center-between gap-3">
                          <div className="flex items-end gap-[16px]">
                            <div className="w-full flex-items-start-column ">
                              <LabelFeed
                                text="Sets"
                                className={"mb-2 !text-sm"}
                              />
                              <FeedInput
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={item.sets}
                                setFieldValue={setFieldValue}
                                type="number"
                                placeholder="0"
                                name="sets"
                                className="w-full !h-[48px] !text-sm !rounded-lg !px-3 !py-[11px] !bg-background"
                              />
                            </div>
                            <div className="w-full flex-items-start-column ">
                              <LabelFeed
                                text="Repetitions"
                                className={"mb-2 !text-sm"}
                              />
                              <FeedInput
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={item.repititions}
                                setFieldValue={setFieldValue}
                                type="number"
                                placeholder="0"
                                name="repetitions"
                                className="w-full !h-[48px] !text-sm !rounded-lg !px-3 !py-[11px] !bg-background"
                              />
                            </div>
                            <div className="w-full flex-items-start-column ">
                              <LabelFeed
                                text="Rest"
                                className={"mb-2 !text-sm"}
                              />
                              <FeedInput
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={item.rest}
                                setFieldValue={setFieldValue}
                                type="number"
                                placeholder="0"
                                name="rest"
                                className="w-full !h-[48px] !text-sm !rounded-lg !px-3 !py-[11px] !bg-background"
                              />
                            </div>
                            <div className="w-full flex-items-start-column ">
                              <LabelFeed
                                text="Tempo"
                                className={"mb-2 !text-sm"}
                              />
                              <FeedInput
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  item.temp1 +
                                  "-" +
                                  item.temp2 +
                                  "-" +
                                  item.temp3 +
                                  "-" +
                                  item.temp4
                                }
                                setFieldValue={setFieldValue}
                                type="text"
                                placeholder="0"
                                name="tempo"
                                className="w-full !h-[48px] !text-sm !rounded-lg !px-3 !py-[11px] !bg-background"
                              />
                            </div>
                          </div>
                        </div>

                        <Animation.Collapse in={openNotes} dimension="height">
                          <div className="w-full ">
                            {openNotes && (
                              <div
                                className={`w-full flex-items-start-column `}
                              >
                                <LabelFeed
                                  text="Note for this exercise"
                                  className={"mb-[12px] !font-medium !text-lg"}
                                />

                                <TextArea
                                  placeholder="Add Note"
                                  rows={5}
                                  name={"exerciseNote"}
                                  setFieldValue={setFieldValue}
                                  onChange={handleChange}
                                  value={item.note}
                                  onBlur={handleBlur}
                                  className="leading-[24px] h-full !px-3 !py-[11px] text-[16px] font-normal text-text6 rounded-lg !outline-none !border-none !bg-background"
                                />
                              </div>
                            )}
                          </div>
                        </Animation.Collapse>
                      </div>
                    </div>
                  ))
                ) : (
                  <Text text={"There is no movement added"} />
                )} */}
              </form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SteppeFour;

import React from 'react';

export const MediaIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <path
        d="M12.433 10.1057L23.9235 10.1057M23.9235 10.1057L23.9235 21.5962M23.9235 10.1057L10.1349 23.8943"
        stroke="#C0F121"
        strokeWidth="2.0025"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import React from "react";

export const ScratchIcon = () => {
  return (
    <svg
      width="113"
      height="114"
      viewBox="0 0 113 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.5"
        width="113"
        height="113"
        rx="10"
        fill="#C0F121"
        fill-opacity="0.2"
      />
      <path
        d="M43 79.5H74.5C75.6935 79.5 76.8381 79.0259 77.682 78.182C78.5259 77.3381 79 76.1935 79 75V46.875L66.625 34.5H47.5C46.3065 34.5 45.1619 34.9741 44.318 35.818C43.4741 36.6619 43 37.8065 43 39V48"
        fill="#C0F121"
        fill-opacity="0.2"
      />
      <path
        d="M43 79.5H74.5C75.6935 79.5 76.8381 79.0259 77.682 78.182C78.5259 77.3381 79 76.1935 79 75V46.875L66.625 34.5H47.5C46.3065 34.5 45.1619 34.9741 44.318 35.818C43.4741 36.6619 43 37.8065 43 39V48"
        stroke="#C0F121"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.5 34.5V48H79"
        stroke="#C0F121"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.4058 65.5801L53.7584 60.6625"
        stroke="#C0F121"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.3955 53.5127L56.8896 66.5527"
        stroke="#C0F121"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.5405 63.0098L31.3523 69.7712"
        stroke="#C0F121"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M58.5186 55.2451L60.3303 62.0066"
        stroke="#C0F121"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.1108 58.9473L36.6049 71.9873"
        stroke="#C0F121"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

import store from "../store";

import {
  getAllDocByCollectionContext,
  getUserProfileContext,
  updateDocCollectionContext,
  deletedocIDContext,
} from "../../Services/commonContext";
import {
  setClientCheckinForm,
  setClientIntakeForm,
  setSelectedClientMealPlan,
  setSelectedClientReducder,
  setSelectedClientSupplement,
  setSelectedClientWorkout,
} from "../reducers/myClients";
import {
  deleteClientReducer,
  updateClientProfileReducer,
} from "../reducers/common";

import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { sortedData } from "../../helper";

const getIntakeFormByCoachAction = async (uid, cid) => {
  const allForm = await getAllDocByCollectionContext(uid, "templates");
  const filterForm = allForm?.filter(
    (f) => f.clientIds?.includes(cid) && f.templateType === "intake",
  );
  store.dispatch(setClientIntakeForm(sortedData(filterForm)));
};
const getCheckInFormByCoachAction = async (uid, cid) => {
  const allForm = await getAllDocByCollectionContext(uid, "templates");
  const checkinFiltered = allForm?.filter(
    (f) => f.clientIds?.includes(cid) && f.templateType === "check-in",
  );
  store.dispatch(setClientCheckinForm(sortedData(checkinFiltered)));
};
const getMyClientdetailAction = async (uid, url) => {
  const res = await getUserProfileContext(uid, url);
  if (res?.uid) {
    store.dispatch(setSelectedClientReducder(res));
  }
};

const getMyClientMealAction = async (uid, cid, url) => {
  const res = await getAllDocByCollectionContext(uid, url);
  if (url === "mealplan") {
    const mealfiltered = res?.filter((f) => f.clientIds?.includes(cid));
    store.dispatch(setSelectedClientMealPlan(sortedData(mealfiltered)));
  }
};

const getMyClientWorkoutAction = async (uid, cid, url) => {
  const allWOrkouts = await getAllDocByCollectionContext(uid, url);
  const clientWorkout = allWOrkouts?.filter((f) => f.clientIds.includes(cid));
  if (clientWorkout?.length) {
    store.dispatch(setSelectedClientWorkout(sortedData(clientWorkout)));
  }
};

const getMyClientSupplementAction = async (uid, cid, url) => {
  const res = await getAllDocByCollectionContext(uid, url);
  const supplementfilterd = res?.filter((f) => f.clientIds?.includes(cid));
  store.dispatch(setSelectedClientSupplement(sortedData(supplementfilterd)));
};
const deleteClientAction = async (docId, navigate, handleclose) => {
  Swal.fire({
    title: "Delete Client?",
    text: "Are you sure you want to proceed, this client will be  delete permanently and data will be lost",
    showCancelButton: true,
    confirmButtonText: "Yes",
    customClass: {
      confirmButton: "!bg-primary1 !text-background",
    },
    background: "#030303",
    showLoaderOnConfirm: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      const res = await deletedocIDContext("users", docId);
      if (res) {
        store.dispatch(deleteClientReducer(docId));
        navigate("/my-clients");
        handleclose(false);
        toast("Client has been removed from your list");
        return res;
      }
    }
  });
};
const updateClientdetailAction = async (payload) => {
  const res = await updateDocCollectionContext(
    "users",
    payload?.docId,
    payload,
  );
  if (res) {
    store.dispatch(updateClientProfileReducer(payload));
  }
};

export {
  getIntakeFormByCoachAction,
  getMyClientdetailAction,
  getMyClientSupplementAction,
  getMyClientMealAction,
  getMyClientWorkoutAction,
  getCheckInFormByCoachAction,
  updateClientdetailAction,
  deleteClientAction,
};

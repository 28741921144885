import React from "react";
import Skeleton from "react-loading-skeleton";

export const InboxSkeleton = () => {
  return (
    <>
      {[1, 2, 3, 4]?.map((index) => (
        <div
          key={index}
          className="min-h-[145px] teritary-grid !bg-background4 !p-[16px] gap-3 w-full flex items-start  "
        >
          <Skeleton height={46} width={46} className="rounded-full" />
          <div className="w-full">
            <div className="w-full flex-center-between">
              <Skeleton height={8} width={80} />
              <Skeleton height={8} width={100} />
            </div>
            <Skeleton height={12} width={220} />
            <Skeleton height={20} width={100} />
          </div>
        </div>
      ))}
    </>
  );
};

import React from 'react';

export const CirclePlus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        opacity="0.2"
        d="M21.5 12C21.5 13.78 20.9722 15.5201 19.9832 17.0001C18.9943 18.4802 17.5887 19.6337 15.9442 20.3149C14.2996 20.9961 12.49 21.1743 10.7442 20.8271C8.99836 20.4798 7.39472 19.6226 6.13604 18.364C4.87737 17.1053 4.0202 15.5016 3.67294 13.7558C3.32567 12.01 3.5039 10.2004 4.18509 8.55585C4.86628 6.91131 6.01983 5.50571 7.49987 4.51677C8.97991 3.52784 10.72 3 12.5 3C14.887 3 17.1761 3.94821 18.864 5.63604C20.5518 7.32387 21.5 9.61305 21.5 12Z"
        fill="#C0F121"
      />
      <path
        d="M12.5 2.25C10.5716 2.25 8.68657 2.82183 7.08319 3.89317C5.47982 4.96451 4.23013 6.48726 3.49218 8.26884C2.75422 10.0504 2.56114 12.0108 2.93735 13.9021C3.31355 15.7934 4.24215 17.5307 5.60571 18.8943C6.96928 20.2579 8.70656 21.1865 10.5979 21.5627C12.4892 21.9389 14.4496 21.7458 16.2312 21.0078C18.0127 20.2699 19.5355 19.0202 20.6068 17.4168C21.6782 15.8134 22.25 13.9284 22.25 12C22.2473 9.41498 21.2192 6.93661 19.3913 5.10872C17.5634 3.28084 15.085 2.25273 12.5 2.25ZM12.5 20.25C10.8683 20.25 9.27326 19.7661 7.91655 18.8596C6.55984 17.9531 5.50242 16.6646 4.878 15.1571C4.25358 13.6496 4.0902 11.9908 4.40853 10.3905C4.72685 8.79016 5.51259 7.32015 6.66637 6.16637C7.82016 5.01259 9.29017 4.22685 10.8905 3.90852C12.4909 3.59019 14.1497 3.75357 15.6571 4.37799C17.1646 5.00242 18.4531 6.05984 19.3596 7.41655C20.2661 8.77325 20.75 10.3683 20.75 12C20.7475 14.1873 19.8775 16.2843 18.3309 17.8309C16.7843 19.3775 14.6873 20.2475 12.5 20.25ZM17 12C17 12.1989 16.921 12.3897 16.7803 12.5303C16.6397 12.671 16.4489 12.75 16.25 12.75H13.25V15.75C13.25 15.9489 13.171 16.1397 13.0303 16.2803C12.8897 16.421 12.6989 16.5 12.5 16.5C12.3011 16.5 12.1103 16.421 11.9697 16.2803C11.829 16.1397 11.75 15.9489 11.75 15.75V12.75H8.75C8.55109 12.75 8.36033 12.671 8.21967 12.5303C8.07902 12.3897 8 12.1989 8 12C8 11.8011 8.07902 11.6103 8.21967 11.4697C8.36033 11.329 8.55109 11.25 8.75 11.25H11.75V8.25C11.75 8.05109 11.829 7.86032 11.9697 7.71967C12.1103 7.57902 12.3011 7.5 12.5 7.5C12.6989 7.5 12.8897 7.57902 13.0303 7.71967C13.171 7.86032 13.25 8.05109 13.25 8.25V11.25H16.25C16.4489 11.25 16.6397 11.329 16.7803 11.4697C16.921 11.6103 17 11.8011 17 12Z"
        fill="#C0F121"
      />
    </svg>
  );
};

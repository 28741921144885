import React from "react";

export const FavIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
    >
      <path
        d="M15.3151 2.43338C14.9388 2.05581 14.4917 1.75623 13.9994 1.55182C13.5071 1.34741 12.9794 1.24219 12.4463 1.24219C11.9133 1.24219 11.3855 1.34741 10.8932 1.55182C10.4009 1.75623 9.95382 2.05581 9.57757 2.43338L9.00006 3.01838L8.42256 2.43338C8.04631 2.05581 7.59921 1.75623 7.10693 1.55182C6.61465 1.34741 6.08685 1.24219 5.55381 1.24219C5.02078 1.24219 4.49298 1.34741 4.0007 1.55182C3.50841 1.75623 3.06132 2.05581 2.68506 2.43338C1.09506 4.02338 0.997565 6.70838 3.00006 8.74838L9.00006 14.7484L15.0001 8.74838C17.0026 6.70838 16.9051 4.02338 15.3151 2.43338Z"
        fill="#C0F121"
        fill-opacity="0.2"
      />
      <path
        d="M15.3151 2.43338C14.9388 2.05581 14.4917 1.75623 13.9994 1.55182C13.5071 1.34741 12.9794 1.24219 12.4463 1.24219C11.9133 1.24219 11.3855 1.34741 10.8932 1.55182C10.4009 1.75623 9.95382 2.05581 9.57757 2.43338L9.00007 3.01838L8.42256 2.43338C8.04631 2.05581 7.59921 1.75623 7.10693 1.55182C6.61465 1.34741 6.08685 1.24219 5.55381 1.24219C5.02078 1.24219 4.49298 1.34741 4.0007 1.55182C3.50841 1.75623 3.06132 2.05581 2.68506 2.43338C1.09506 4.02338 0.997565 6.70838 3.00006 8.74838L9.00007 14.7484L15.0001 8.74838C17.0026 6.70838 16.9051 4.02338 15.3151 2.43338Z"
        stroke="#C0F121"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

import React from "react";
import { Heading, Text, Button } from "../../../utils";
import {
  CopyIcon,
  DeleteIcon,
  DotsThreeVerticalIcon,
  PencilIcon,
} from "../../../FeedLibraryIcon";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import {
  setFormModal,
  setTemplateNameReducer,
  setbtnLoaderReducer,
  seteditFormReducer,
} from "../../../store/reducers/uiSettings";
import {
  assignTemplateFormAction,
  deleteTemplateFormAction,
} from "../../../store/actions/template";
import { duplicateItem, formatDateForDisplay } from "../../../helper";
import { toast } from "react-toastify";
import { deleteSupplementTemplateAction } from "../../../store/actions/supplementPlan";
import { deleteWorkoutTemplateAction } from "../../../store/actions/workoutPlan";
import { deleteMealPlanAction } from "../../../store/actions/mealPlan";
import Swal from "sweetalert2";

const Index = (props) => {
  const {
    tempo,
    setCTA,
    dispatch,
    selectedTemplateName,
    isClientView = false,
    clientId,
    setShow,
    setselectedView,
    alltypeTemplates,
    setselectedClient,
  } = props;
  const navigate = useNavigate();
  const common = useSelector((state) => state.common);
  const deleteTemplateForm = async (tempo) => {
    Swal.fire({
      title: "Delete Template?",
      html: "<p>Are you sure?<br/> Once the Template is deleted, it cannot be restored.</p>",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        confirmButton: "!bg-primary1 !text-background",
      },
      background: "#030303",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (
          selectedTemplateName?.path === "intake" ||
          selectedTemplateName?.path === "check-in"
        ) {
          await deleteTemplateFormAction(tempo?.docId, tempo?.id);
        }
        if (selectedTemplateName?.path === "supplement-plan") {
          await deleteSupplementTemplateAction(tempo?.docId);
        }
        if (selectedTemplateName?.path === "workout-plan") {
          await deleteWorkoutTemplateAction(tempo?.docId);
        }
        if (selectedTemplateName?.path === "meal-plan") {
          await deleteMealPlanAction(tempo?.docId);
        }
        toast.info("Template deleted successfully", {
          type: "success",
          autoClose: 5000,
        });
        dispatch(setbtnLoaderReducer(false));
      }
    });
  };
  const assignTemplateForm = async (tempo) => {
    setselectedClient(
      common?.allClients?.filter((f) => tempo.clientIds.includes(f.uid))
    );
    let collectionUrl = "";
    if (selectedTemplateName?.path === "supplement-plan") {
      collectionUrl = "supplementPlan";
    } else if (selectedTemplateName?.path === "meal-plan") {
      collectionUrl = "mealplan";
    } else if (selectedTemplateName?.path === "workout-plan") {
      collectionUrl = "workoutplan";
    } else if (
      selectedTemplateName?.path === "intake" ||
      selectedTemplateName?.path === "check-in"
    ) {
      collectionUrl = "templates";
    }
    setCTA(() => async (selectedData) => {
      dispatch(setbtnLoaderReducer(true));
      const allclients = [...selectedData?.map((client) => client.uid)];
      let uniqueArray = [...new Set(allclients)];
      await assignTemplateFormAction(tempo?.docId, collectionUrl, {
        ...tempo,
        clientIds: uniqueArray,
        usedCount: uniqueArray?.length,
        updatedAt: new Date().toLocaleString(),
      });

      dispatch(setFormModal(false));
      dispatch(setbtnLoaderReducer(false));
      toast("Template Assigned Successfully", {
        type: "success",
        autoClose: 5000,
      });
    });
  };

  const editHandlerAction = (tempo) => {
    dispatch(setTemplateNameReducer({ name: `Edit ${tempo?.name}` }));
    if (
      selectedTemplateName?.path === "intake" ||
      selectedTemplateName?.path === "check-in"
    ) {
      dispatch(
        seteditFormReducer({
          type: "edit-template",
          data: {
            ...tempo,
            closer: isClientView
              ? `/client-detail/${clientId}?type=${selectedTemplateName?.path}`
              : `/template?type=${selectedTemplateName?.path}`,
          },
        })
      );

      navigate(
        selectedTemplateName?.path === "intake"
          ? "/intake-form"
          : "/checkin-form"
      );
    } else if (selectedTemplateName?.path === "supplement-plan") {
      dispatch(
        seteditFormReducer({
          type: "edit-supplement",
          data: {
            ...tempo,
            isClientView: isClientView,
            closer: isClientView
              ? `/client-detail/${clientId}?type=${selectedTemplateName?.path}`
              : `/template?type=${selectedTemplateName?.path}`,
          },
        })
      );
      navigate("/supplement-plan");
    } else if (selectedTemplateName?.path === "workout-plan") {
      dispatch(
        seteditFormReducer({
          type: "edit-workout",
          data: {
            ...tempo,
            isClientView: isClientView,
            closer: isClientView
              ? `/client-detail/${clientId}?type=${selectedTemplateName?.path}`
              : `/template?type=${selectedTemplateName?.path}`,
          },
        })
      );
      navigate("/workout-plan");
    } else if (selectedTemplateName?.path === "meal-plan") {
      dispatch(
        seteditFormReducer({
          type: "edit-mealplan",
          data: {
            ...tempo,
            isClientView: isClientView,
            closer: isClientView
              ? `/client-detail/${clientId}?type=${selectedTemplateName?.path}`
              : `/template?type=${selectedTemplateName?.path}`,
          },
        })
      );
      navigate("/meal-plan");
    }
  };
  const duplicateTemplate = (tempo) => {
    const copiedTitle = duplicateItem(alltypeTemplates, tempo?.name);
    // const copyNumbers = alltypeTemplates
    //   ?.filter((template) => template.name.startsWith(tempo.name + " Copy"))
    //   .map((template) =>
    //     parseInt(template?.name.match(/\((\d+)\)/)?.[1] || 0, 10),
    //   );
    // const highestCopyNumber = Math.max(...copyNumbers, 0);
    // const newCopyNumber = highestCopyNumber + 1;

    dispatch(
      setTemplateNameReducer({
        name: copiedTitle,
      })
    );
    dispatch(
      seteditFormReducer({
        type: "duplicate-template",
        data: {
          ...tempo,
          isClientView: isClientView,
          name: copiedTitle,
          closer: isClientView
            ? `/client-detail/${clientId}?type=${selectedTemplateName?.path}`
            : `/template?type=${selectedTemplateName?.path}`,
        },
      })
    );
    if (
      selectedTemplateName?.path === "intake" ||
      selectedTemplateName?.path === "check-in"
    ) {
      navigate(
        selectedTemplateName?.path === "intake"
          ? "/intake-form"
          : "/checkin-form"
      );
    } else if (selectedTemplateName?.path === "supplement-plan") {
      navigate("/supplement-plan");
    } else if (selectedTemplateName?.path === "workout-plan") {
      navigate("/workout-plan");
    } else if (selectedTemplateName?.path === "meal-plan") {
      navigate("/meal-plan");
    }
  };
  return (
    <div className="teritary-grid !bg-background2 !p-[16px]  flex-between-column h-auto ">
      <div className="flex-center-between mb-[12px]">
        <Heading
          text={tempo?.name || "N/A"}
          color="text-primary1"
          fontSize="text-[18px]"
          fontweight="font-medium"
        />
        {!isClientView && (
          <Dropdown className="navbar-dropdown">
            <Dropdown.Toggle
              id="dropdown-basic"
              className="nav-link nav-dropdown flex items-baseline gap-2"
            >
              <DotsThreeVerticalIcon w="24px" h="24px" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className="nav-link flex items-center gap-[14px]"
                onClick={async () => {
                  duplicateTemplate(tempo);
                }}
              >
                <CopyIcon />
                Duplicate Template
              </Dropdown.Item>
              <Dropdown.Item
                className="nav-link flex items-center gap-[14px]"
                onClick={async () => {
                  editHandlerAction(tempo);
                }}
              >
                <PencilIcon />
                Edit Template
              </Dropdown.Item>
              <Dropdown.Item
                className="nav-link flex items-center gap-[14px]"
                onClick={async () => {
                  dispatch(seteditFormReducer({ type: "delete-template" }));
                  deleteTemplateForm(tempo);
                }}
              >
                <DeleteIcon />
                Delete Template
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {isClientView &&
          (selectedTemplateName?.path === "intake" ||
            selectedTemplateName?.path === "check-in") && (
            <Dropdown className="navbar-dropdown">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="nav-link nav-dropdown flex-items-baseline gap-2"
              >
                <DotsThreeVerticalIcon w="24px" h="24px" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  className="nav-link flex-items-center gap-[14px]"
                  onClick={async () => {
                    setShow(true);
                    setselectedView(tempo);
                  }}
                >
                  <CopyIcon />
                  View Submission
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
      </div>
      <Heading
        text={formatDateForDisplay(tempo?.updatedAt)}
        fontweight="font-normal"
        fontSize="text-[14px]"
        className="mb-[24px]"
      />
      <div>
        <div className=" flex-column">
          <Text
            color="text-whiteGray"
            text={tempo?.clientNote || "Client Note: N/A"}
          />
        </div>

        <Heading
          text={`Used for ${tempo?.usedCount} Clients`}
          fontweight="font-normal"
          fontSize="text-[14px]"
          className="pt-[16px] pb-[20px]"
        />
      </div>
      <div className="flex-items-center gap-[8px]  w-full">
        <Button
          text={isClientView ? "Edit" : "Assign to client"}
          bg={"bg-primary1"}
          color="text-background"
          className="h-[40px]  w-max px-[36px] rounded-[8px] font-medium text-[14px] leading-5"
          cta={() => {
            if (isClientView) {
              editHandlerAction(tempo);
            } else {
              dispatch(seteditFormReducer({ type: "assign-template" }));
              dispatch(setFormModal(true));
              assignTemplateForm(tempo);
            }
          }}
        />
        <Button
          text="View"
          color="text-textPrimary"
          className="w-max p-[20px] h-[40px] border border-textPrimary rounded-[8px]"
          cta={() => {
            dispatch(
              seteditFormReducer({
                type: "view-template",
                data: {
                  ...tempo,
                  closer: isClientView
                    ? `/client-detail/${clientId}?type=${selectedTemplateName?.path}`
                    : `/template?type=${selectedTemplateName?.path}`,
                },
              })
            );
            if (
              selectedTemplateName?.path === "intake" ||
              selectedTemplateName?.path === "check-in"
            ) {
              dispatch(
                setTemplateNameReducer({ name: `View  ${tempo?.name}` })
              );
              navigate(
                selectedTemplateName?.path === "intake"
                  ? "/intake-form"
                  : "/checkin-form"
              );
            } else if (selectedTemplateName?.path === "supplement-plan") {
              navigate("/supplement-plan");
            } else if (selectedTemplateName?.path === "workout-plan") {
              navigate("/workout-plan");
            } else if (selectedTemplateName?.path === "meal-plan") {
              navigate("/meal-plan");
            }
          }}
        />
      </div>
    </div>
  );
};

export default Index;

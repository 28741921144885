import React, { useState } from "react";
import { Heading, LabelFeed, Text } from "../../../utils";
import { CheckIcon, CrossIcon } from "../../../FeedLibraryIcon";
import { Modal, Message } from "rsuite";

const SimpleView = ({ formAnswers }) => {
  const [showFile, setshowFile] = useState("");
  const fileUloads = formAnswers?.filter((f) => f.name === "file_upload");

  return (
    <div>
      {formAnswers?.length ? (
        formAnswers?.map(
          (ans) =>
            ans.name !== "file_upload" &&
            ans.name !== "clientId" && (
              <div className="flex flex-col gap-[10px] w-full p-3 mb-3  rounded-xl  bg-background">
                <div className="flex">
                  <span className={"text-text1 mr-1 !text-primary1"}>
                    {`Question#${ans.order}`}
                  </span>
                  <span className={"text-text1 !break-all"}>
                    {`${ans.text}`}
                  </span>
                </div>
                <LabelFeed
                  text={`Answer: ${ans.answer || "Not Set"}`}
                  color={"text-text1"}
                  className={"!break-all"}
                />
              </div>
            ),
        )
      ) : (
        <Text text={"There is no submission Found"} color={"text-primary1"} />
      )}

      <Heading
        text="Uploads From The Clients"
        fontweight="!font-[400]"
        fontSize="!text-[18px]"
        color="text-textPrimary"
        className="leading-[24px] mb-3"
      />
      <div className="w-full flex items-center gap-3 ">
        {fileUloads?.length ? (
          fileUloads?.map((file, index) => (
            <div
              onClick={() => setshowFile(file)}
              className="w-[274px] flex items-center cursor-pointer justify-between gap-3 tracking-[0.1px] !px-[24px] !py-[7px] rounded-xl overflow-hidden !border !border-solid border-primary1  box-border"
            >
              <Text
                text={`Intake jpg ${++index}`}
                color="text-primary1"
                className="leading-[24px] "
              />
              <CheckIcon />
            </div>
          ))
        ) : (
          <Message>
            <Text text={"No Upload Found"} color={"text-primary1"} />
          </Message>
        )}
      </div>

      <Modal
        open={showFile?.name ? true : false}
        onClose={() => setshowFile("")}
        className=" !rounded-[20px] !w-[600px] foodModal modal-black"
      >
        <Modal.Body className="!w-full !max-w-full !p-0 !m-0">
          <div className="flex flex-col gap-[32px] p-1">
            <div className="flex justify-between gap-3">
              <Heading
                text="File Submission"
                fontweight="!font-[500]"
                fontSize="!text-[22px]"
                color="text-textPrimary"
                className="leading-[20px] "
              />
              <span onClick={() => setshowFile("")}>
                <CrossIcon />
              </span>
            </div>

            <div>
              <img src={showFile?.prettyFormat} alt="client file submission" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SimpleView;

import React, { useState } from 'react';

import { Dropdown } from 'react-bootstrap';
import { DeleteIcon, DotsThreeVerticalIconGreen, FavIcon, AiIcon, CopySimple } from '../../../../../../FeedLibraryIcon';
import MealPlanModal from '../../../../../../components/model/mealPlanModal';
import { Heading, Text } from '../../../../../../utils';

const MealDropdown = ({ meal, deleteMeal, duplicateMeal, addFavoriteFood }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Dropdown className="navbar-dropdown meal-dropdown">
        <Dropdown.Toggle id="dropdown-basic" className="nav-link nav-dropdown !p-0 !w-8 !h-8 bg">
          <DotsThreeVerticalIconGreen />
        </Dropdown.Toggle>

        <Dropdown.Menu className="!w-max">
          <Dropdown.Item className="nav-link flex items-center gap-[14px]" onClick={() => duplicateMeal(meal)}>
            <CopySimple />
            Duplicate
          </Dropdown.Item>
          <Dropdown.Item className="nav-link flex items-center gap-[14px]" onClick={() => setOpen(true)}>
            <div className="flex-shrink-0">
              <AiIcon />
            </div>
            Spin With Kaboodle A.I.
          </Dropdown.Item>
          <Dropdown.Item className="nav-link flex items-center gap-[14px]" onClick={() => addFavoriteFood(meal)}>
            <FavIcon />
            {meal?.favorite ? 'Remove Favorite' : 'Save as Favorite'}
          </Dropdown.Item>
          <Dropdown.Item className="nav-link flex items-center gap-[14px]" onClick={() => deleteMeal(meal?.id)}>
            <DeleteIcon />
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <MealPlanModal
        show={open}
        handleClose={() => {
          setOpen(false);
        }}
        centerBtn
        children={
          <div className="text-center ">
            <div className="w-full flex items-center justify-center mb-3">
              <AiIcon size="38" />
            </div>
            <Heading text={'coming soon'} className="!text-2xl mb-3" />

            <Text fontSize={'text-base'} color="text-text6" text="This feature will be update soon, please stay connect with kaboodle team" />
          </div>
        }
      />
    </div>
  );
};

export default MealDropdown;

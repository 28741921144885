import React from "react";

export const ArrowUp = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path
        d="M0.432465 6.68289L6.68246 0.432892C6.74051 0.374782 6.80944 0.328683 6.88531 0.29723C6.96119 0.265777 7.04252 0.249589 7.12465 0.249589C7.20679 0.249589 7.28812 0.265777 7.36399 0.29723C7.43986 0.328683 7.50879 0.374782 7.56684 0.432892L13.8168 6.68289C13.9341 6.80017 14 6.95923 14 7.12508C14 7.29093 13.9341 7.44999 13.8168 7.56727C13.6996 7.68454 13.5405 7.75043 13.3747 7.75043C13.2088 7.75043 13.0497 7.68454 12.9325 7.56727L7.12465 1.75867L1.31684 7.56727C1.25877 7.62534 1.18983 7.6714 1.11396 7.70283C1.03809 7.73425 0.956774 7.75043 0.874652 7.75043C0.79253 7.75043 0.711212 7.73425 0.635342 7.70283C0.559471 7.6714 0.490533 7.62534 0.432465 7.56727C0.374395 7.5092 0.328333 7.44026 0.296906 7.36439C0.26548 7.28852 0.249304 7.2072 0.249304 7.12508C0.249304 7.04296 0.26548 6.96164 0.296906 6.88577C0.328333 6.8099 0.374395 6.74096 0.432465 6.68289Z"
        fill={color || "#E6E6E6"}
      />
    </svg>
  );
};

import React from "react";

export const ExportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M10.8333 9.36702L17.6666 2.53369"
        stroke="#71717A"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3333 5.8667V1.8667H14.3333"
        stroke="#71717A"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16675 1.8667H7.50008C3.33341 1.8667 1.66675 3.53337 1.66675 7.70003V12.7C1.66675 16.8667 3.33341 18.5334 7.50008 18.5334H12.5001C16.6667 18.5334 18.3334 16.8667 18.3334 12.7V11.0334"
        stroke="#71717A"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

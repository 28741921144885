/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FeedSearch, SideNavigations } from "../../components";
import { CircleLeftIcon, CircleRightIcon, Plus } from "../../FeedLibraryIcon";
import {
  Heading,
  Text,
  ClientStatusSkeleton,
  FeedDropdown,
  TemplateSkeleton,
  FeedLink,
  Button,
} from "../../utils";

import TemplateCard from "../../components/common/templateCard";
import { Menus } from "../../schema/menus";
import { CreateTempo } from "./createTempo";
import {
  seteditFormReducer,
  setTemplateNameReducer,
} from "../../store/reducers/uiSettings";
import ClientStatus from "../../components/common/clientStatus";
import {
  getCheckInFormByCoachAction,
  getIntakeFormByCoachAction,
  getMyClientMealAction,
  getMyClientSupplementAction,
  getMyClientWorkoutAction,
  getMyClientdetailAction,
} from "../../store/actions/myClient";
import IntakeSubmissionModal from "../clientDetail/intakeFormSection/intakeSubmissionModal";
import { getAllFormSubmissionContext } from "../../Services/myClientsContext";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { cid } = useParams();
  const [searchParams] = useSearchParams();
  // all state
  const [searchQuery, setsearchQuery] = useState("");
  const [selectedTemplateName, setselectedTemplateName] = useState(Menus[0]);
  const [alltypeTemplates, setalltypeTemplates] = useState([]);
  const [cta, setCTA] = useState();
  const [loading, setloading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [collapse, setCollapse] = useState(true);
  const [show, setShow] = useState(false);
  const [viewLoader, setviewLoader] = useState(false);
  const [selectedView, setselectedView] = useState({});
  const [fromSubmission, setfromSubmission] = useState([]);
  // all redux state
  const { user } = useSelector((state) => state.common);
  const {
    selectedClient,
    clientIntake,
    clientCheckIn,
    clientSupplement,
    clientMealPlan,
    clientWorkout,
  } = useSelector((state) => state.myClient);
  // all functions
  const createTemplateCTA = () => {
    function dispatchEditReducer(closerId) {
      dispatch(
        seteditFormReducer({
          type: "create-template",
          data: {
            closer: closerId || `/client-detail/${cid}`,
            templateType: selectedTemplateName?.path,
            isClientView: true,
            clientIds: [cid],
          },
        })
      );
    }

    if (selectedTemplateName?.path === "meal-plan") {
      dispatchEditReducer(`/client-detail/${cid}?type=meal-plan`);
      navigate("/meal-plan");
    } else if (selectedTemplateName?.path === "workout-plan") {
      // dispatch(setTemplateNameReducer({ name: 'Create a New Supplement Plan' }));
      dispatchEditReducer(`/client-detail/${cid}?type=workout-plan`);
      navigate("/workout-plan");
    } else if (selectedTemplateName?.path === "supplement-plan") {
      dispatchEditReducer(`/client-detail/${cid}?type=supplement-plan`);
      dispatch(
        setTemplateNameReducer({ name: "Create a New Supplement Plan" })
      );
      navigate("/supplement-plan");
    } else if (selectedTemplateName?.path === "intake") {
      dispatchEditReducer(`/client-detail/${cid}?type=intake`);
      dispatch(setTemplateNameReducer({ name: "Create a Intake Form" }));
      navigate("/intake-form");
    } else if (selectedTemplateName?.path === "check-in") {
      dispatchEditReducer(`/client-detail/${cid}?type=check-in`);
      dispatch(setTemplateNameReducer({ name: "Create a Check-In Form" }));
      navigate("/checkin-form");
    }
  };
  // all side effects
  useEffect(() => {
    const tabToOpen = searchParams.get("type");
    const activeTab = Menus?.filter((menu) => menu.path === tabToOpen)?.[0];
    if (activeTab?.id) {
      setselectedTemplateName(activeTab);
    } else {
      setselectedTemplateName(Menus[0]);
    }
  }, [searchParams]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true);
        await Promise.all([
          getMyClientdetailAction(cid, "users"),
          getIntakeFormByCoachAction(user?.uid, cid, "intake"),
          getCheckInFormByCoachAction(user?.uid, cid, "check-in"),
          getMyClientMealAction(user?.uid, cid, "mealplan"),
          getMyClientSupplementAction(user?.uid, cid, "supplementPlan"),
          getMyClientWorkoutAction(user?.uid, cid, "workoutplan"),
        ]);
        setloading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (cid) {
      fetchData();
    }

    // setTimeout(() => {
    //   setloading(false);
    // }, 3000);

    // return () => {
    //   setloading(false);
    // };
  }, [cid]);

  useEffect(() => {
    (async () => {
      if (selectedView?.id) {
        setviewLoader(true);
        const res = await getAllFormSubmissionContext(selectedView?.id);
        if (res?.responseCode === 200) {
          setfromSubmission(res?.content);
        }
        setviewLoader(false);
      }
      setviewLoader(false);
    })();
  }, [selectedView]);

  useEffect(() => {
    if (selectedTemplateName?.path === "check-in") {
      setalltypeTemplates(clientCheckIn);
    } else if (selectedTemplateName?.path === "supplement-plan") {
      setalltypeTemplates(clientSupplement);
    } else if (selectedTemplateName?.path === "workout-plan") {
      setalltypeTemplates(clientWorkout);
    } else if (selectedTemplateName?.path === "meal-plan") {
      setalltypeTemplates(clientMealPlan);
    } else if (selectedTemplateName?.path === "intake") {
      setalltypeTemplates(clientIntake);
    } else {
      setalltypeTemplates([]);
    }
  }, [
    selectedTemplateName,
    clientIntake,
    clientCheckIn,
    clientSupplement,
    clientWorkout,
  ]);

  useEffect(() => {
    if (selectedTemplateName?.path === "supplement-plan") {
      if (searchQuery) {
        setalltypeTemplates(
          clientSupplement?.filter((f) =>
            f.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
          )
        );
      } else {
        setalltypeTemplates(clientSupplement);
      }
    } else if (selectedTemplateName?.path === "meal-plan") {
      if (searchQuery) {
        setalltypeTemplates(
          clientMealPlan?.filter((f) =>
            f.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
          )
        );
      } else {
        setalltypeTemplates(clientMealPlan);
      }
    } else if (selectedTemplateName?.path === "workout-plan") {
      if (searchQuery) {
        setalltypeTemplates(
          clientWorkout?.filter((f) =>
            f.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
          )
        );
      } else {
        setalltypeTemplates(clientWorkout);
      }
    } else if (selectedTemplateName?.path === "intake") {
      if (searchQuery) {
        setalltypeTemplates(
          clientIntake?.filter((f) =>
            f.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
          )
        );
      } else {
        setalltypeTemplates(clientIntake);
      }
    } else if (selectedTemplateName?.path === "check-in") {
      if (searchQuery) {
        setalltypeTemplates(
          clientCheckIn?.filter((f) =>
            f.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
          )
        );
      } else {
        setalltypeTemplates(clientCheckIn);
      }
    }
  }, [
    searchQuery,
    selectedTemplateName,
    clientIntake,
    clientCheckIn,
    clientSupplement,
    clientWorkout,
  ]);

  useEffect(() => {
    const dataArrayCopy = JSON.parse(JSON.stringify(alltypeTemplates));
    if (selectedFilter?.id === 1) {
      setalltypeTemplates(
        dataArrayCopy?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
    } else if (selectedFilter?.id === 2) {
      setalltypeTemplates(
        dataArrayCopy?.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      );
    } else if (selectedFilter?.id === 3) {
      setalltypeTemplates(
        dataArrayCopy?.slice().sort((a, b) => a.name.localeCompare(b.name))
      );
    } else if (selectedFilter?.id === 4) {
      setalltypeTemplates(
        dataArrayCopy?.slice().sort((a, b) => b.name.localeCompare(a.name))
      );
    }
  }, [selectedFilter]);

  return (
    <div className="main-container">
      <div className="w-full flex-center-between gap-[16px] !py-[40px]">
        <div className="flex-items-center gap-2">
          <FeedLink
            path="/my-clients"
            text="Clients"
            className="leading-[22px] text-text3"
          />
          <span className="text-white">/</span>
          <FeedLink
            text="Client Profile"
            className="leading-[22px] text-primary1"
          />
        </div>
        <Button
          text={"Close"}
          cta={() => {
            navigate("/my-clients");
          }}
          className="px-[24px] py-[7px] !font-normal rounded-xl "
          bg="bg-primary1"
          color="text-background"
        />
        {/* )} */}
      </div>
      <div className=" pb-[18px]">
        {selectedClient ? (
          <ClientStatus client={selectedClient} />
        ) : (
          <ClientStatusSkeleton />
        )}
      </div>

      <div className="flex-start-start gap-[20px]  ">
        <div
          className={`min-h-[700px]  relative ${
            collapse ? "w-max xl:w-[275px]" : "w-max"
          } flex-shrink-0 bg-secondary1 sticky p-[16px] rounded-[20px]`}
        >
          <SideNavigations
            settemplateName={setselectedTemplateName}
            collapse={collapse}
            data={Menus}
            selectedTemplateName={selectedTemplateName}
          />
          <div
            onClick={() => setCollapse(!collapse)}
            className="w-fit absolute right-[-10px] top-2/4 cursor-pointer"
          >
            {collapse ? <CircleLeftIcon /> : <CircleRightIcon />}
          </div>
        </div>
        <div
          className={`h-[698px] w-full  pt-[4px] rounded-[20px]  ${
            alltypeTemplates?.length ? "flex-start-start" : "flex-center-center"
          }`}
        >
          <div className="w-full flex flex-wrap align-content-start h-full">
            <div className="flex-center-between w-full p-[20px]  gap-4 flex-wrap bg-background2 rounded-[20px] mb-[20px]">
              <Heading
                text={`Templates (${
                  alltypeTemplates?.length ? alltypeTemplates?.length : "0"
                })`}
                fontSize="text-[24px]"
                fontweight="font-normal"
                className="leading-6"
              />
              <FeedSearch
                handlesearchQuery={setsearchQuery}
                searchQuery={searchQuery}
                placeholder={"Search Templates Name"}
                className="bg-background5 w-[400px]"
              />
              <div className="flex justify-end">
                <div className="flex items-center gap-[24px]">
                  <Text text="Sort by:" />
                  <div className=" w-[174px]">
                    <FeedDropdown
                      placeholder={"Most Recent"}
                      value={selectedFilter?.id}
                      options={[
                        { id: 3, name: "A to Z" },
                        { id: 4, name: "Z to A" },
                        { id: 1, name: "Newest First" },
                        { id: 2, name: "Oldest First " },
                      ]}
                      setFieldValueFull={(e) => {
                        setSelectedFilter(e);
                      }}
                      name="filter"
                      className="!rounded-xl [&>button]:!bg-transparent !border !border-solid !border-whiteGray"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-[580px]  overflow-y-auto ">
              {!loading ? (
                alltypeTemplates?.length ? (
                  <div
                    className={`w-full grid  grid-cols-2 lg2:grid-cols-3 gap-[19px] ${
                      alltypeTemplates?.length >= 6 && "pr-[16px]"
                    }`}
                  >
                    <div
                      className="teritary-grid !bg-background2 !p-[16px] flex-center-center cursor-pointer"
                      onClick={createTemplateCTA}
                    >
                      <div className="flex-items-center-column">
                        <span>
                          <Plus color={"#7D7D7D"} w={"18px"} />
                        </span>
                        <Text
                          text={"Create a new template"}
                          color="text-whiteGray"
                        />
                      </div>
                    </div>
                    {alltypeTemplates?.map((tempo, index) => (
                      <TemplateCard
                        tempo={tempo}
                        key={index}
                        selectedTemplateName={selectedTemplateName}
                        dispatch={dispatch}
                        setCTA={setCTA}
                        user={user?.uid}
                        selectedClient={selectedClient}
                        isClientView
                        clientId={cid}
                        setselectedView={setselectedView}
                        setShow={setShow}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="w-full h-full flex-center-center">
                    <CreateTempo
                      btnClick={createTemplateCTA}
                      selectedTemplateName={selectedTemplateName}
                    />
                  </div>
                )
              ) : (
                <TemplateSkeleton />
              )}
            </div>
          </div>
        </div>
      </div>
      <IntakeSubmissionModal
        show={show}
        handleClose={() => setShow(false)}
        fromSubmission={fromSubmission}
        viewLoader={viewLoader}
      />
    </div>
  );
};

export default Index;

import React, { useState } from "react";

import { Heading, InboxSkeleton } from "../../../utils";
import {
  ChatsIcon,
  SearchIcon,
  Plus,
  CrossIcon,
} from "../../../FeedLibraryIcon";

import UserChartCard from "./userChartCard";
import AllUserModal from "./allUserModal";
import { useSelector } from "react-redux";

const Index = ({
  setSearchTerm,
  activetab,
  searchTerm,
  userChats,
  setsearchNewUser,
  allUsers,
  activeChat,
  uid,
  fetchChatsAndMessagesForUser,
}) => {
  const [show, setShow] = useState(false);
  const { uiloader } = useSelector((state) => state.uiSettings);
  return (
    <div className="w-full h-full flex-column  gap-[28px] bg-secondary1 rounded-[20px] p-[20px] relative">
      <div className="w-full flex-center-between h-[46px]  gap-[16px]">
        <Heading
          text="Chats"
          fontweight="!font-[500]"
          fontSize="!text-[32px]"
          className=" leading-[32px]"
          color="text-textPrimary"
        />

        <span
          className="cursor-pointer chat-icon"
          onClick={() => setShow(true)}
        >
          <Plus />
        </span>

        <AllUserModal
          show={show}
          setShow={setShow}
          uid={uid}
          activeChat={activeChat}
          setsearchNewUser={setsearchNewUser}
          allUsers={allUsers}
          fetchChatsAndMessagesForUser={fetchChatsAndMessagesForUser}
          userChats={userChats}
        />
      </div>

      <div className="relative w-full ">
        <span className="absolute top-[12px] left-[12px]">
          {<SearchIcon />}
        </span>
        <input
          placeholder="Search Inbox"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={`w-full  py-[12px] !outline-none text-sm text-text3 px-[46px]  rounded-lg bg-background placeholder:text-sm `}
        />
        {searchTerm && (
          <span
            onClick={() => setSearchTerm("")}
            className="absolute top-[12px] right-[12px] cursor-pointer"
          >
            <CrossIcon />
          </span>
        )}
      </div>
      <div className="h-[800px] overflow-y-scroll ">
        {!uiloader ? (
          <div className="w-full pr-[12px] flex-column  gap-[16px]">
            {userChats?.length ? (
              userChats?.map((chat, i) => (
                <div className="" key={i}>
                  <UserChartCard
                    userChat={chat}
                    activeChat={activeChat}
                    activetab={activetab}
                    uid={uid}
                  />
                </div>
              ))
            ) : (
              <div className="flex-center-column gap-[20px] mt-[272px]">
                <ChatsIcon />
                <Heading
                  text="You don’t have an active chat yet."
                  fontweight="!font-[400]"
                  fontSize="!text-[16px]"
                  className="leading-[28px]"
                  color="text-text6"
                />
              </div>
            )}
          </div>
        ) : (
          <div className="flex-center-column gap-[28px] pr-[12px]">
            <InboxSkeleton />
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;

import React from 'react';

export const Notify3 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        opacity="0.2"
        d="M21 6V18C21 18.1989 20.921 18.3897 20.7803 18.5303C20.6397 18.671 20.4489 18.75 20.25 18.75H7.73438C7.5577 18.75 7.38672 18.8125 7.25156 18.9262L4.23281 21.5728C4.12357 21.6647 3.99038 21.7235 3.84887 21.7423C3.70737 21.7612 3.56343 21.7392 3.43397 21.6791C3.30451 21.6189 3.1949 21.523 3.11803 21.4028C3.04116 21.2825 3.00021 21.1428 3 21V6C3 5.80109 3.07902 5.61032 3.21967 5.46967C3.36032 5.32902 3.55109 5.25 3.75 5.25H20.25C20.4489 5.25 20.6397 5.32902 20.7803 5.46967C20.921 5.61032 21 5.80109 21 6Z"
        fill="#C0F121"
      />
      <path
        d="M20.25 4.5H3.75003C3.3522 4.5 2.97067 4.65804 2.68937 4.93934C2.40806 5.22064 2.25003 5.60218 2.25003 6V21C2.24833 21.2859 2.32916 21.5662 2.48281 21.8074C2.63646 22.0485 2.85641 22.2401 3.11628 22.3594C3.31486 22.4516 3.53109 22.4996 3.75003 22.5C4.10215 22.4992 4.4426 22.3736 4.71096 22.1456C4.71544 22.1427 4.71953 22.1392 4.72315 22.1353L7.7344 19.5H20.25C20.6479 19.5 21.0294 19.342 21.3107 19.0607C21.592 18.7794 21.75 18.3978 21.75 18V6C21.75 5.60218 21.592 5.22064 21.3107 4.93934C21.0294 4.65804 20.6479 4.5 20.25 4.5ZM20.25 18H7.7344C7.38117 17.9998 7.03922 18.1243 6.76878 18.3516L6.75753 18.3619L3.75003 21V6H20.25V18ZM8.25003 10.5C8.25003 10.3011 8.32904 10.1103 8.4697 9.96967C8.61035 9.82902 8.80111 9.75 9.00003 9.75H15C15.1989 9.75 15.3897 9.82902 15.5304 9.96967C15.671 10.1103 15.75 10.3011 15.75 10.5C15.75 10.6989 15.671 10.8897 15.5304 11.0303C15.3897 11.171 15.1989 11.25 15 11.25H9.00003C8.80111 11.25 8.61035 11.171 8.4697 11.0303C8.32904 10.8897 8.25003 10.6989 8.25003 10.5ZM8.25003 13.5C8.25003 13.3011 8.32904 13.1103 8.4697 12.9697C8.61035 12.829 8.80111 12.75 9.00003 12.75H15C15.1989 12.75 15.3897 12.829 15.5304 12.9697C15.671 13.1103 15.75 13.3011 15.75 13.5C15.75 13.6989 15.671 13.8897 15.5304 14.0303C15.3897 14.171 15.1989 14.25 15 14.25H9.00003C8.80111 14.25 8.61035 14.171 8.4697 14.0303C8.32904 13.8897 8.25003 13.6989 8.25003 13.5Z"
        fill="#C0F121"
      />
    </svg>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  query,
  collection,
  orderBy,
  getDocs,
  onSnapshot,
  where,
} from "firebase/firestore";
import { db } from "../../configs/firebase";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../configs/firebase";
import { MainHeading } from "../../components";
import ChatLeftSide from "./chatLeftSide";
import ChatRightSide from "./chatRightSide";
import {
  setUserChatDetails,
  setActiveChatReducer,
} from "../../store/reducers/inbox";
import { getallMyClientAction } from "../../store/actions/common";
import { setUILoaderReducer } from "../../store/reducers/uiSettings";

const Index = ({ searchQuery }) => {
  const { uid } = auth.currentUser;
  const { inbox, common } = useSelector((state) => state);
  const { activeChat } = inbox;
  const dispatch = useDispatch();
  const [userChats, setuserChats] = useState([]);
  const [allUsers, setallUsers] = useState([]);
  const [searchNewUser, setsearchNewUser] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    (async () => {
      getallMyClientAction(uid);
    })();
  }, []);

  useEffect(() => {
    dispatch(setUILoaderReducer(true));

    const chatQuery = query(
      collection(db, "Chats"),
      where("participants", "array-contains", uid)
    );

    const unsubscribe = onSnapshot(chatQuery, (snapshot) => {
      const chatsWithMessages = [];

      snapshot.forEach(async (chatDoc) => {
        const chatId = chatDoc.id;
        const chatData = chatDoc.data();

        const participants = chatDoc.data().participants;
        const receiverId = participants.find((id) => id !== uid);

        let receiverData;
        const q = query(
          collection(db, "users"),
          where("uid", "==", receiverId)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          receiverData = doc.data();
        });

        const messagesQuery = query(
          collection(db, "Chats", chatId, "messages"),
          orderBy("timestamp", "asc")
        );
        const unsubscribeMessages = onSnapshot(
          messagesQuery,
          (messagesSnapshot) => {
            const messages = messagesSnapshot.docs.map((messageDoc) => ({
              id: messageDoc.id,
              ...messageDoc.data(),
            }));

            const chatWithMessages = {
              id: chatId,
              createdAt: chatData.createdAt,
              participants: chatData.participants,
              messages: messages,
              reciever: receiverData,
            };

            const chatIndex = chatsWithMessages.findIndex(
              (chat) => chat.id === chatId
            );

            if (chatIndex !== -1) {
              chatsWithMessages[chatIndex] = chatWithMessages;
            } else {
              chatsWithMessages.push(chatWithMessages);
            }

            setTimeout(() => {
              dispatch(setUserChatDetails([...chatsWithMessages]));
              dispatch(setUILoaderReducer(false));
            }, 2000);
          }
        );
        return () => unsubscribeMessages();
      });

      setTimeout(() => {
        dispatch(setUserChatDetails([...chatsWithMessages]));
        dispatch(setUILoaderReducer(false));
      }, 1000);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (inbox?.chats?.length > 0) {
      setuserChats(inbox?.chats);
    } else {
      setuserChats([]);
      dispatch(setActiveChatReducer({}));
    }
    if (common?.allClients?.length > 0) {
      setallUsers(common?.allClients);
    }
  }, [inbox?.chats, common]);

  // new user search
  useEffect(() => {
    const query = searchNewUser || searchQuery;
    if (query) {
      const filteredItems = common?.allClients?.filter((item) =>
        item?.name.toLowerCase().includes(query.toLowerCase())
      );
      setallUsers(filteredItems);
    } else {
      setallUsers(common?.allClients);
    }
  }, [searchNewUser, searchQuery]);

  useEffect(() => {
    if (searchTerm) {
      const filteredItems = inbox?.chats?.filter((item) =>
        item?.reciever?.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setuserChats(filteredItems);
    } else {
      setuserChats(inbox?.chats);
    }
  }, [searchTerm]);

  return (
    <>
      <div className="get-started-feed">
        <div className="main-container get-started-tabs">
          <div className="pt-[40px] mb-[48px]">
            <MainHeading title="Inbox" />
          </div>
          <div className="w-full h-[900px] grid grid-cols-[max-content,1fr] gap-[20px]">
            <div className="w-[320px] lg:w-[440px] xl:w-[510px] h-full flex-shrink-0 ">
              <ChatLeftSide
                userChats={userChats}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
                uid={uid}
                activeChat={activeChat}
                setsearchNewUser={setsearchNewUser}
                allUsers={allUsers}
                dispatch={dispatch}
              />
            </div>

            <div className="w-full h-full bg-background rounded-[20px] ">
              <ChatRightSide
                activeChat={activeChat}
                common={common}
                userChats={userChats}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

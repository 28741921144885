import React from 'react';

export const SubtractIcon = ({ color }) => {
  return (
    <svg fill={color || '#ffffff'} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 459 459">
      <g>
        <path
          d="M459.313,229.648c0,22.201-17.992,40.199-40.205,40.199H40.181c-11.094,0-21.14-4.498-28.416-11.774
        C4.495,250.808,0,240.76,0,229.66c-0.006-22.204,17.992-40.199,40.202-40.193h378.936
        C441.333,189.472,459.308,207.456,459.313,229.648z"
        />
      </g>
    </svg>
  );
};

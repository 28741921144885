import React from "react";
import { Modal } from "rsuite";
import { Button, Heading, Text } from "../../utils";
import { CheckCircle, ErrorIcon } from "../../FeedLibraryIcon";

const SuccessModal = ({
  show,
  handleClose,
  successCTA,
  successMsg,
  okBtn,
  cancelBtn,
}) => {
  return (
    <div>
      <Modal
        open={show}
        onClose={handleClose}
        className="foodModal avatar-modal w-full !max-w-[600px]"
      >
        <Modal.Body className="!m-0 !p-0">
          <div className="flex-items-center-column gap-[48px] text-center">
            <div className="flex-items-center-column gap-[20px]">
              <span
                className={`w-[64px] h-[64px] rounded-xl ${
                  successMsg?.title === "Error" &&
                  "bg-activeBackground1 p-[12px]"
                } flex-center-center`}
              >
                {successMsg?.title === "Success" ? (
                  <CheckCircle />
                ) : (
                  <ErrorIcon />
                )}
              </span>
              <Heading
                text={successMsg?.title}
                fontweight="!font-[700]"
                fontSize="!text-[32px]"
                className=" leading-[40px]"
                color="text-textPrimary"
              />
            </div>
            <Text
              text={successMsg?.text}
              color="text-textPrimary"
              className=" !text-2xl !font-medium "
            />

            <div className="flex-start-center gap-[12px]  self-stretch w-full flex-[0_0_auto]">
              {okBtn && (
                <Button
                  text={okBtn}
                  color="text-primary1"
                  type="button"
                  cta={successCTA}
                  className="!w-max tracking-[0.1px] !px-[24px] !py-[10px]  !rounded-[8px] overflow-hidden !border !border-solid border-primary1   box-border"
                />
              )}
              {cancelBtn && (
                <Button
                  text={cancelBtn}
                  color="text-background"
                  bg="bg-primary1"
                  className="!w-max tracking-[0.1px] !px-[24px] !py-[10px] !rounded-[8px]   box-border"
                  cta={handleClose}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SuccessModal;

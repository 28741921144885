import React, { useState } from "react";
import { Modal } from "rsuite";
import { Button, Spinner, Text } from "../../utils";
import { updateCoachProfileContext } from "../../Services/commonContext";
import { CrossIcon } from "../../FeedLibraryIcon";
import { useSelector } from "react-redux";

const MealPlanModal = ({
  show,
  handleClose,
  successCTA,
  children,
  successText,
  centerBtn,
}) => {
  return (
    <Modal
      open={show}
      onClose={handleClose}
      className="foodModal avatar-modal w-full max-w-[540px]"
    >
      <Modal.Body className="!m-0 !p-0">
        <div className=" flex-items-center mb-[24px]">{children}</div>

        <div
          className={` gap-3  ${
            centerBtn ? "flex-center-center" : "flex-center-end"
          }`}
        >
          <Button
            text={"Go back"}
            rightmargin="mr-[10px]"
            className="!h-[44px] border !border-primary1 !px-[20px] !py-[7px] !font-medium !text-sm rounded-lg "
            bg="bg-secondary1 "
            color="text-primary1"
            cta={handleClose}
          />
          {successCTA && (
            <Button
              text={successText ? successText : "Yes"}
              rightmargin="mr-[10px]"
              className="!h-[44px] !px-[50px] !py-[7px] !font-medium !text-sm rounded-lg "
              bg="bg-primary1"
              color="text-background"
              cta={successCTA}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MealPlanModal;

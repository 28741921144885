import React from "react";

export const LinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.2503 10.0002C11.2503 12.6502 9.10866 14.7918 6.45866 14.7918C3.80866 14.7918 1.66699 12.6502 1.66699 10.0002C1.66699 7.35016 3.80866 5.2085 6.45866 5.2085"
        stroke="#C0F121"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33301 10C8.33301 7.24167 10.5747 5 13.333 5C16.0913 5 18.333 7.24167 18.333 10C18.333 12.7583 16.0913 15 13.333 15"
        stroke="#C0F121"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

import React, { useEffect, useState } from "react";
import FeedBreadcrumbs from "../../../components/common/breadcrumbs";
import { MainHeading } from "../../../components";
import {
  Button,
  FeedDropdown,
  FeedInput,
  Text,
  TextArea,
} from "../../../utils";
import { DeleteIcon, Plus } from "../../../FeedLibraryIcon";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { seteditFormReducer } from "../../../store/reducers/uiSettings";
import { toast } from "react-toastify";
import { generateUniqueId } from "../../../helper";

const food = [
  { id: 1, name: "Tablet" },
  { id: 2, name: "Capsule" },
  { id: 3, name: "Gram" },
  { id: 4, name: "Mg" },
  { id: 5, name: "Tsp" },
  { id: 6, name: "Tbsp" },
  { id: 7, name: "mL" },
  { id: 8, name: "Dropper" },
  { id: 9, name: "Iu" },
  { id: 10, name: "Scoop" },
  { id: 11, name: "Mcg" },
  { id: 12, name: "Serving" },
  { id: 13, name: "Unit" },
];
const timeSchedulerObj = [
  {
    id: generateUniqueId(),
    timestamp: "Time Stamp",
    count: "",
    unit: {},
  },
];
const SteppeTwo = ({
  closePlanHandler,
  formRef,
  setStepper,
  setplanScreenState,
}) => {
  const uiSettings = useSelector((state) => state.uiSettings);
  const { editForm } = uiSettings;
  const dispatch = useDispatch();
  const [labProducts, setLabProducts] = useState(editForm?.labProducts || []);
  const [supplementProducts, setsupplementProducts] = useState(
    editForm?.supplementProducts || []
  );
  const [topPanelHeader, settopPanelHeader] = useState(
    editForm?.tempUnits || []
  );
  const [tempSuppements, settempSuppements] = useState([]);
  const [step, setStep] = useState("");

  const handleAddTime = () => {
    if (supplementProducts?.[0]?.timeScheduler?.length > 7) {
      toast.error("Atleast 8 product can be added", {
        type: "error",
        autoClose: 5000,
      });
      return;
    }
    const tsID = generateUniqueId();
    const refactorData = supplementProducts?.map((supplement) => {
      const timeSchule = supplement.timeScheduler?.map((timer) => {
        return timer;
      });
      return {
        ...supplement,
        timeScheduler: [
          ...timeSchule,
          {
            ...timeSchedulerObj?.[0],
            tsId: tsID,
            id: generateUniqueId(),
          },
        ],
      };
    });
    settopPanelHeader([
      ...topPanelHeader,
      { id: generateUniqueId(), tsId: tsID, timestamp: "Time Stamp" },
    ]);
    setsupplementProducts(refactorData);
  };

  useEffect(() => {
    const labs = editForm?.supplementList?.filter(
      (product) =>
        product.category === "Lab Test Public" || product.category === "Test"
    );
    setLabProducts(labs);
    const supplements = editForm?.supplementList?.filter(
      (product) =>
        product.category === "Nutraceutical" ||
        product.category === "Nutraceuticals"
    );
    setsupplementProducts(
      supplements?.map((supplement) => {
        return {
          ...supplement,
          // timeScheduler: editForm?.supplementProducts ? editForm?.supplementProducts : timeSchedulerObj,
        };
      })
    );
  }, [editForm]);

  const handleUpdateScheduler = (suppObj, stamper, key, value) => {
    setsupplementProducts(
      supplementProducts?.map((supplement) => {
        if (supplement.id === suppObj.id) {
          return {
            ...supplement,
            timeScheduler: supplement.timeScheduler?.map((timestamper) => {
              if (timestamper?.id === stamper.id) {
                return {
                  ...timestamper,
                  [key]: value,
                };
              } else {
                return timestamper;
              }
            }),
          };
        } else {
          return supplement;
        }
      })
    );
  };
  const handleDeleteScheduler = (tsId) => {
    settopPanelHeader(topPanelHeader?.filter((f) => f.tsId !== tsId));
    const refactorData = supplementProducts?.map((supplement) => {
      return {
        ...supplement,
        timeScheduler: supplement.timeScheduler?.filter(
          (timer) => timer.tsId !== tsId
        ),
      };
    });
    setsupplementProducts(refactorData);
  };

  useEffect(() => {
    const withTimestamps = supplementProducts?.map((sup) => {
      return {
        ...sup,
        timeScheduler: sup.timeScheduler?.map((timer, index) => {
          const top = topPanelHeader?.[index];

          if (top) {
            return {
              ...timer,
              timestamp: top.timestamp,
            };
          }

          return timer;
        }),
      };
    });
    settempSuppements(withTimestamps);
  }, [topPanelHeader, supplementProducts]);

  return (
    <>
      <div className="">
        <FeedBreadcrumbs
          backClick={closePlanHandler}
          selectedTemplateName={{ name: "Supplements" }}
          formRef={formRef}
          cancelBtnClass="!border-none !p-2"
          submitBtnClass="!rounded-[8px]"
          marginBottom="!mb-0"
          disabled={true}
          crumbText="Supplemet Plan"
        />
        <MainHeading
          title="Create Supplement Plan Template"
          description={
            "Welcome to the Kaboodle Supplement Plan Builder. Please complete all steps and proceed using the buttons at the bottom of each screen."
          }
        />
      </div>
      <div className="mt-[24px] mb-[10px]">
        <Text
          text="Supplement schedule"
          color="text-textPrimary"
          fontSize={"text-[18px]"}
          className={"leading-[22px] !font-medium"}
        />
      </div>
      <Formik
        initialValues={{}}
        innerRef={formRef}
        // enableReinitialize
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const externalproduct = editForm?.supplementList?.filter(
            (f) => f.category === "external"
          );
          dispatch(
            seteditFormReducer({
              type: uiSettings?.formName || "create-supplement",
              data: {
                ...uiSettings?.editForm,
                ...values,
                supplementList: [
                  ...tempSuppements,
                  ...labProducts,
                  ...externalproduct,
                ],
                labProducts: labProducts,
                supplementProducts: tempSuppements,
                tempUnits: topPanelHeader,
              },
            })
          );
          setStepper([1, 2, 3]);
          setplanScreenState("frequencyStep");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            className={`w-full ${
              uiSettings?.formName === "view-template"
                ? "pointer-events-none"
                : "pointer-events-auto"
            }`}
          >
            <div
              className={`w-full max-h-[447px] flex-start-between  rounded-lg  bg-secondary1 ${
                supplementProducts?.length >= 5 && "overflow-y-scroll"
              }`}
            >
              {supplementProducts?.length ? (
                <div className="w-full grid grid-cols-[1fr,max-content]">
                  <div className="w-full flex-shrink-0 flex">
                    <div className="w-max flex flex-col">
                      <div className=" w-[295px]  p-[24px]  h-[107px] border-r border-b border-background flex items-center justify-start">
                        <Text
                          text="Product Name"
                          color="text-textPrimary"
                          fontSize={"text-[14px]"}
                          className={"leading-[24px] !font-medium"}
                        />
                      </div>
                      {supplementProducts.map((supplement, key) => (
                        <div
                          key={key}
                          className={`p-[24px] w-[295px] flex-shrink-0 ${"h-[79px]"} flex items-center justify-start border-b border-r border-background last:!mb-[37px]`}
                        >
                          <Text
                            text={supplement?.name + "," + supplement?.vendor}
                            color="text-textPrimary"
                            fontSize={"text-[14px]"}
                            className={"leading-[24px] !font-medium"}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="w-full !max-w-[1008px]">
                      <div className="flex flex-col w-full ">
                        <div className="w-max flex ">
                          {topPanelHeader?.map((timer) => (
                            <div className="w-[126px] p-[19px] group relative h-[107px] flex !items-end border-r border-b border-background">
                              <div
                                onClick={() =>
                                  handleDeleteScheduler(timer?.tsId)
                                }
                                className="cursor-pointer group-hover:block hidden absolute top-2 right-2"
                              >
                                <DeleteIcon />
                              </div>
                              <TextArea
                                value={timer.timestamp}
                                name="timestamp"
                                onChange={(e) => {
                                  setStep(e?.target?.value);
                                  settopPanelHeader(
                                    topPanelHeader?.map((head) => {
                                      if (head?.id === timer?.id) {
                                        return {
                                          ...head,
                                          timestamp: e.target.value,
                                        };
                                      } else {
                                        return head;
                                      }
                                    })
                                  );
                                }}
                                className={`${
                                  step?.length >= 23 && "h-full"
                                } text-textPrimary  time-step !bg-transparent  overflow-hidden  !text-[14px] !leading-[24px] !outline-none !font-normal`}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-col">
                          {supplementProducts?.map((supplement, key) => (
                            <div className="w-full flex-start-start">
                              {supplement?.timeScheduler?.map(
                                (schedule, index) => (
                                  <div
                                    key={index}
                                    className="w-[126px] flex-shrink-0 flex-column border-r border-background "
                                  >
                                    <div
                                      className={`px-[16px] py-2 w-full  !h-[79px] flex-center-start-column gap-1 last:border-b  border-background`}
                                    >
                                      <FeedInput
                                        type="number"
                                        placeholder="0"
                                        name="quantity"
                                        onChange={(e) =>
                                          handleUpdateScheduler(
                                            supplement,
                                            schedule,
                                            "count",
                                            e.target.value
                                          )
                                        }
                                        onBlur={handleBlur}
                                        value={schedule.count}
                                        setFieldValue={setFieldValue}
                                        className="w-full !h-[29px] font-normal !text-xs  !rounded-[5px] !px-2 !py-[7px] !border  !border-background !bg-secondary1 input-remove-arrow"
                                      />
                                      <FeedDropdown
                                        placeholder={"Most Recent"}
                                        value={schedule?.unit?.id}
                                        options={food}
                                        setFieldValueFull={(e) => {
                                          console.log(e);
                                          handleUpdateScheduler(
                                            supplement,
                                            schedule,
                                            "unit",
                                            e
                                          );
                                        }}
                                        name="filter"
                                        className="food-item-dropdown"
                                        IconSize={"12"}
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {uiSettings?.formName !== "view-template" && (
                    <div
                      onClick={handleAddTime}
                      className="w-[56px] h-full cursor-pointer rounded-[0px_8px_8px_0px]  p-[10px] flex-center-center bg-activeBackground2"
                    >
                      <Plus color="#C0F121" w="16" h="16" />
                    </div>
                  )}
                </div>
              ) : (
                <div className="w-full h-[447px] flex-center-center">
                  <Text
                    text={"There is no supplement product edit"}
                    className={"text-center"}
                  />
                </div>
              )}
            </div>
            <div className="mt-[24px] mb-[10px]">
              <Text
                text="Labs"
                color="text-textPrimary"
                fontSize={"text-[18px]"}
                className={"leading-[22px] !font-medium"}
              />
            </div>
            <div className="w-full h-auto flex-start-between rounded-lg  bg-secondary1">
              <div className="w-full flex-shrink-0 flex-column">
                <div className="flex w-full border-b border-background">
                  <div className="p-[24px] w-[354px] flex-shrink-0 h-[72px] flex-center-start border-r border-background ">
                    <Text
                      text="Product Name"
                      color="text-textPrimary"
                      fontSize={"text-[14px]"}
                      className={"leading-[24px] !font-medium"}
                    />
                  </div>
                  <div className="p-[19px] w-full h-[72px] flex-start-start  ">
                    <Text
                      text="Instructions"
                      color="text-textPrimary"
                      fontSize={"text-[14px]"}
                      className={"leading-[24px] !font-medium"}
                    />
                  </div>
                </div>
                {labProducts?.length ? (
                  labProducts?.map((lab) => {
                    return (
                      <div className="flex w-full border-b border-background">
                        <div className="p-[19px] w-[354px] flex-shrink-0 h-[72px] flex-start-start ">
                          <Text
                            text={lab.name + "," + lab.vendor}
                            color="text-textPrimary"
                            fontSize={"text-[14px]"}
                            className={"leading-[24px] !font-medium"}
                          />
                        </div>
                        <div
                          className={`px-[16px] py-2  w-full min-h-[97px] flex-start-start  border-l  last:border-b  border-background overflow-y-auto`}
                        >
                          <TextArea
                            text="Type Lab instructions here"
                            placeholder="Type Lab instructions here"
                            value={lab.description}
                            name="description"
                            onChange={(e) => {
                              setLabProducts(
                                labProducts?.map((labProd) => {
                                  if (lab.id === labProd.id) {
                                    return {
                                      ...labProd,
                                      description: e.target.value,
                                    };
                                  } else {
                                    return labProd;
                                  }
                                })
                              );
                            }}
                            className={
                              "!text-[12px] !font-normal !tracking-[0.5px] !border-none !bg-transparent"
                            }
                          />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex w-full border-b border-background">
                    <div className="p-[19px] w-[354px] h-[72px] flex-start-start border-r border-background ">
                      <Text text={"There is no lab product added"} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SteppeTwo;

import React from "react";

import { useAuth } from "../../context/AuthContext";
import Navlayout from "./navLayout";

const Index = ({
  children,
  searchQuery,
  setsearchQuery,
  userProfile,
  loading,
}) => {
  const { logout } = useAuth();

  return (
    <>
      <Navlayout
        children={children}
        logout={logout}
        searchQuery={searchQuery}
        setsearchQuery={setsearchQuery}
        userProfile={userProfile}
        loading={loading}
      />
    </>
  );
};

export default Index;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button, Stepper, Text } from "../../utils";
import SteppeOne from "./StepOne";
import SteppeTwo from "./StepTwo";
import SteppeThree from "./StepThree";
import SteppeFour from "./StepFour";
import { auth } from "../../configs/firebase";
import { getSupplementProducts } from "../../store/actions/supplementPlan";
import { getallMyClientAction } from "../../store/actions/common";

const NewSupplementsPlan = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const { uid } = auth.currentUser;
  const uiSettings = useSelector((state) => state.uiSettings);

  const [stepper, setStepper] = useState([1]);
  const [planScreenState, setplanScreenState] = useState("create");

  useEffect(() => {
    getallMyClientAction(uid);
    getSupplementProducts(uid);
  }, []);

  const closePlanHandler = () => {
    navigate(uiSettings?.editForm?.closer || "/template");
  };

  const backButtonHandler = () => {
    if (planScreenState === "schedulerStep") {
      setStepper([1]);
      setplanScreenState("create");
    } else if (planScreenState === "frequencyStep") {
      setStepper([1, 2]);
      setplanScreenState("schedulerStep");
    } else if (planScreenState === "step4Title") {
      setStepper([1, 2, 3]);
      setplanScreenState("frequencyStep");
    } else if (planScreenState === "create") {
      closePlanHandler();
    }
  };
  const nextButtonHandler = () => {
    if (planScreenState === "create") {
      formRef.current?.submitForm();
    } else if (planScreenState === "schedulerStep") {
      formRef.current?.submitForm();
    } else if (planScreenState === "frequencyStep") {
      formRef.current?.submitForm();
    } else if (planScreenState === "step4Title") {
      formRef.current?.submitForm();
    }
  };

  return (
    <div className="main-container !py-[26px]">
      <div className="mb-[16px]">
        <Stepper stepper={stepper} total={4} />
      </div>
      {planScreenState === "create" && (
        <SteppeOne
          formRef={formRef}
          closePlanHandler={closePlanHandler}
          setStepper={setStepper}
          setplanScreenState={setplanScreenState}
        />
      )}
      {planScreenState === "schedulerStep" && (
        <SteppeTwo
          formRef={formRef}
          closePlanHandler={closePlanHandler}
          setStepper={setStepper}
          setplanScreenState={setplanScreenState}
        />
      )}
      {planScreenState === "frequencyStep" && (
        <SteppeThree
          formRef={formRef}
          closePlanHandler={closePlanHandler}
          setStepper={setStepper}
          setplanScreenState={setplanScreenState}
        />
      )}
      {planScreenState === "step4Title" && (
        <SteppeFour formRef={formRef} closePlanHandler={closePlanHandler} />
      )}

      <div className="w-full flex-center-between py-[48px]">
        <Button
          text="Back"
          type="button"
          bg="bg-background border border-textPrimary"
          className="px-[30px] rounded-[12px] leading-6 text-[14px]"
          color="text-textPrimary"
          cta={backButtonHandler}
        />
        {stepper?.includes(4) && uiSettings?.formName === "view-template" ? (
          ""
        ) : (
          <>
            <Text
              text="Next: Review the plan"
              color="text-textPrimary"
              fontSize={"text-[15px]"}
              className={"leading-[21px]"}
            />

            <Button
              text={planScreenState === "step4Title" ? "Save & Exit" : "Next"}
              type="button"
              bg="bg-primary1"
              className="px-[40px]  rounded-[12px] leading-6 text-[16px]"
              color="text-background"
              cta={nextButtonHandler}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default NewSupplementsPlan;

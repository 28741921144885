import axios from 'axios';
import {errorHandling} from './error';
import {toast} from 'react-toastify';
import {addDoc, collection, doc, getDoc, setDoc, updateDoc} from 'firebase/firestore';
import {db} from '../configs/firebase';

const getPlanGenerationByAI = async (url, payload) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_OPEN_API_URL}/${url}`, payload, {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    errorHandling(error);
    throw error;
  }
};

const createAndUpdatePlanAction = async (url, payload) => {
  const toastId = toast.loading('Submitting...');
  let docRef;
  try {
    if (url === 'supplementPlan') {
      docRef = await addDoc(collection(db, url), payload);
    } else {
      docRef = doc(collection(db, url), payload.clientId);
      // Check if the document with the clientId already exists
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        await updateDoc(docRef, payload);
      } else {
        await setDoc(docRef, payload);
      }
    }
    if (docRef?.id) {
      toast.update(toastId, {
        render: 'Plan created successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeButton: true
      });
      return docRef;
    }
  } catch (error) {
    console.error('Error adding/updating document: ', error);
  }
};

const createPlanAction = async (url, payload) => {
  const toastId = toast.loading('Submitting...');
  let docRef;
  try {
    docRef = await addDoc(collection(db, url), payload);
    if (docRef?.id) {
      toast.update(toastId, {
        render: 'Plan created successfully',
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeButton: true
      });
      console.log('docRef', docRef);
      return docRef;
    }
  } catch (error) {
    console.error('Error adding/updating document: ', error);
  }
};

export {getPlanGenerationByAI, createPlanAction, createAndUpdatePlanAction};

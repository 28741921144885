/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { DropIcon } from "../../FeedLibraryIcon";
import "./style.scss";

export const FeedDropdown = (props) => {
  const {
    placeholder,
    disabled,
    value,
    className,
    options,
    setFieldValueFull,
    name,
    setFieldValue,
    toggleBtnClass,
    clearState,
    IconSize,
  } = props;
  const [selectLabel, setSelectLabel] = useState(
    value !== "" ? value : placeholder,
  );
  useEffect(() => {
    let label = placeholder;
    let selected;
    if (value !== "") {
      if (isNaN(value)) {
        selected = options?.find((ele) => ele.id === value);
      } else {
        selected = options?.find((ele) => ele.id === parseInt(value));
      }
      if (selected) {
        label = selected.name;
      }
      if (selected) {
        setSelectLabel(label);
      }
    } else {
      setSelectLabel(label);
    }
  }, [placeholder, value]);

  return (
    <Dropdown
      {...props}
      className={`feed-template-form-dropdwon ${className}`}
      disabled={disabled}
    >
      <Dropdown.Toggle
        className={`feed-custom-form-dropdown w-full ${toggleBtnClass}`}
        disabled={disabled}
      >
        <div className="w-full flex justify-between items-center">
          {selectLabel || "Select"}
          {
            <span>
              <DropIcon size={IconSize} />
            </span>
          }
          {props.crossicon && !disabled && (
            <span
              onClick={() => {
                if (!disabled) {
                  if (clearState) {
                    clearState();
                  }
                  setFieldValue(name, "");
                }
              }}
              className="absolute w-fit cursor-pointer top-[13px] right-[42px]"
            >
              {props.crossicon}
            </span>
          )}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className={`w-full ${
          options?.length > 6 && "h-[250px] overflow-y-scroll"
        }`}
      >
        {options?.length ? (
          options?.map((item, index) => (
            <div key={index}>
              <Dropdown.Item
                key={item.id}
                id={item.val}
                name={name}
                active={selectLabel === item.name}
                onClick={() => {
                  if (setFieldValue) {
                    setFieldValue(name, item.id);
                  }
                  if (setFieldValueFull) {
                    setFieldValueFull(item);
                  }
                  // if (onChange) {
                  //   onChange(item);
                  // }
                  setSelectLabel(item.name);
                }}
              >
                <div className="flex items-center">
                  {item.icon && <span className="mr-[12px]">{item.icon}</span>}

                  {item.name}
                </div>
              </Dropdown.Item>
            </div>
          ))
        ) : (
          <Dropdown.Item className="text-background hover:bg-background">
            No data Available
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

import axios from "axios";
import { getAllDocByCollectionContext } from "./commonContext";

const getFormSubmissiomContext = async (uid, collectionName) => {
  try {
    const res = await axios.post(
      "https://hipaa-api.jotform.com/form?apiKey=96f6e2d15e901cc91786cd28f102746e",
    );
    console.log("response", res);
  } catch (error) {
    console.error("Error:", error.message);
  }
};

const getAllFormByCoachContext = async (uid, cid, collectionName, type) => {
  const allForm = await getAllDocByCollectionContext(uid, collectionName);
  const filterForm = allForm?.filter(
    (f) => f.clientIds?.includes(cid) && f.templateType === type,
  );
  console.log("res", filterForm);
};

const getAllFormSubmissionContext = async (formId) => {
  try {
    const res = await axios.get(
      `https://hipaa-api.jotform.com/form/${formId}/submissions?apiKey=96f6e2d15e901cc91786cd28f102746e`,
    );
    console.log("response", res);
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
};
// const createUserContext = async (email, password) => {
//   try {
//     const functions = getFunctions();
//     const createUserFn = httpsCallable(functions, 'createUser');
//     const result = await createUserFn({email, password});
//     return result.data;
//   } catch (error) {
//     console.error('Error creating user:', error);
//   }
// };

export {
  getFormSubmissiomContext,
  getAllFormByCoachContext,
  getAllFormSubmissionContext,
};

import React from "react";

export const CheckCircle = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="12" fill="#C0F121" fillOpacity="0.2" />
      <path
        opacity="0.2"
        d="M47 32C47 34.9667 46.1203 37.8668 44.472 40.3336C42.8238 42.8003 40.4811 44.7229 37.7403 45.8582C34.9994 46.9935 31.9834 47.2906 29.0737 46.7118C26.1639 46.133 23.4912 44.7044 21.3934 42.6066C19.2956 40.5088 17.867 37.8361 17.2882 34.9264C16.7094 32.0166 17.0065 29.0006 18.1418 26.2597C19.2771 23.5189 21.1997 21.1762 23.6665 19.528C26.1332 17.8797 29.0333 17 32 17C35.9783 17 39.7936 18.5804 42.6066 21.3934C45.4197 24.2064 47 28.0218 47 32Z"
        fill="#C0F121"
      />
      <path
        d="M39.1344 27.3656C39.2506 27.4817 39.3428 27.6196 39.4057 27.7713C39.4686 27.9231 39.501 28.0857 39.501 28.25C39.501 28.4143 39.4686 28.5769 39.4057 28.7287C39.3428 28.8804 39.2506 29.0183 39.1344 29.1344L30.3844 37.8844C30.2683 38.0006 30.1304 38.0928 29.9787 38.1557C29.8269 38.2186 29.6643 38.251 29.5 38.251C29.3357 38.251 29.1731 38.2186 29.0213 38.1557C28.8696 38.0928 28.7317 38.0006 28.6156 37.8844L24.8656 34.1344C24.6311 33.8998 24.4993 33.5817 24.4993 33.25C24.4993 32.9183 24.6311 32.6002 24.8656 32.3656C25.1002 32.1311 25.4183 31.9993 25.75 31.9993C26.0817 31.9993 26.3998 32.1311 26.6344 32.3656L29.5 35.2328L37.3656 27.3656C37.4817 27.2494 37.6196 27.1572 37.7713 27.0943C37.9231 27.0314 38.0857 26.999 38.25 26.999C38.4143 26.999 38.5769 27.0314 38.7287 27.0943C38.8804 27.1572 39.0183 27.2494 39.1344 27.3656ZM48.25 32C48.25 35.2139 47.297 38.3557 45.5114 41.028C43.7258 43.7003 41.1879 45.7831 38.2186 47.013C35.2493 48.243 31.982 48.5648 28.8298 47.9378C25.6776 47.3107 22.7821 45.7631 20.5095 43.4905C18.2369 41.2179 16.6893 38.3224 16.0622 35.1702C15.4352 32.018 15.757 28.7507 16.987 25.7814C18.2169 22.8121 20.2997 20.2742 22.972 18.4886C25.6443 16.703 28.7861 15.75 32 15.75C36.3084 15.7545 40.439 17.4681 43.4855 20.5145C46.5319 23.561 48.2455 27.6916 48.25 32ZM45.75 32C45.75 29.2805 44.9436 26.6221 43.4327 24.3609C41.9218 22.0997 39.7744 20.3374 37.2619 19.2967C34.7494 18.256 31.9848 17.9837 29.3175 18.5142C26.6503 19.0447 24.2003 20.3543 22.2773 22.2773C20.3543 24.2003 19.0448 26.6503 18.5142 29.3175C17.9837 31.9847 18.256 34.7494 19.2967 37.2619C20.3374 39.7744 22.0997 41.9218 24.3609 43.4327C26.6221 44.9436 29.2805 45.75 32 45.75C35.6455 45.7459 39.1404 44.2959 41.7182 41.7182C44.2959 39.1404 45.7459 35.6455 45.75 32Z"
        fill="#C0F121"
      />
    </svg>
  );
};

import React from "react";
import Skeleton from "react-loading-skeleton";

export const PrePlanTableSkeleton = () => {
  return [1, 2, 3, 4].map((index) => (
    <tr
      key={index}
      className="h-[62px] cursor-pointer  border border-solid  !border-text4 "
    >
      <th scope="row" className="pl-[15px] pr-[10px] py-[20px]">
        <Skeleton height={8} width={80} />
      </th>
      <th scope="row" className=" pr-[15px] py-[20px]">
        <Skeleton height={8} width={80} />
      </th>
    </tr>
  ));
};

import { createContext, useContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from "../configs/firebase";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  console.log(loading);

  useEffect(() => {
    const isProtectedOrAuthRoute = false;

    if (isProtectedOrAuthRoute) {
      const proceed = onAuthStateChanged(auth, async (user) => {
        console.log("user", user);
        if (user && user.email) {
          setUser({
            ...user,
          });
          navigate("/");
        } else {
          setUser(null);
        }
        setLoading(false);
      });

      return () => proceed();
    } else {
      setLoading(false);
      setUser(null);
    }
  }, [location, navigate]);

  const signup = async (
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    actions,
  ) => {
    const { setSubmitting, setError } = actions;
    setSubmitting(true);
    try {
      setError("");
      if (password === confirmPassword) {
        await Cookies.set("firstName", firstName);
        await Cookies.set("lastName", lastName);
        await createUserWithEmailAndPassword(auth, email, password);
        setSubmitting(false);
        navigate("/login");
      } else throw new Error("Passwords do not match");
    } catch (err) {
      setSubmitting(false);
      setError(String(err.message));
    }
  };

  const login = async (email, password, actions) => {
    const { setSubmitting, setError } = actions;
    setSubmitting(true);
    try {
      setError("");
      const res = await signInWithEmailAndPassword(auth, email, password);
      return res;
    } catch (err) {
      setSubmitting(false);
      setError(String(err.message));
    }
  };

  const logout = async () => {
    try {
      setUser(null);
      await signOut(auth);
      navigate("/login");
      window.location.reload();
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  const getToken = async () => {
    /*
        Get JWT for user
    */
    try {
      const user = await auth.currentUser;
      if (user) {
        return await user.getIdToken(true);
      } else throw new Error("No Sure");
    } catch (e) {
      //TODO: message error
      return "";
    }
  };
  return (
    <AuthContext.Provider value={{ user, login, signup, logout, getToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

/* eslint-disable no-unused-vars */
import {
  query,
  collection,
  getDocs,
  doc,
  addDoc,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../configs/firebase";
import axios from "axios";
import { deletedocIDContext } from "./commonContext";

const createTemplateContext = async (formData, payload) => {
  try {
    const res = await axios.post(
      "https://hipaa-api.jotform.com/form?apiKey=96f6e2d15e901cc91786cd28f102746e",
      formData,
    );
    console.log("response", res);
    if (res.status === 200) {
      try {
        await addDoc(collection(db, "templates"), {
          ...payload,
          ...res?.data.content,
        });
      } catch (error) {
        console.error("Error:", error.message);
      }
      return { ...res.data, data: { ...payload, ...res?.data.content } };
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
};

const updateTemplateContext = async (formId, formData, payload) => {
  try {
    const res = await axios.put(
      `https://hipaa-api.jotform.com/form/${formId}/questions?apiKey=96f6e2d15e901cc91786cd28f102746e`,
      formData,
    );
    console.log("response", res);
    if (res.status === 200) {
      try {
        let userdata;
        const q = query(collection(db, "templates"), where("id", "==", formId));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (doc) => {
          userdata = doc.data();
          const docRef = doc.ref;
          await updateDoc(docRef, {
            ...payload,
          });
        });
        return res;
      } catch (error) {
        console.error("Error finding or updating user profile:", error);
      }
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
};

const assignTemplateFormContext = async (docId, url, payload) => {
  try {
    let docRef;
    docRef = doc(db, url, docId);
    await updateDoc(docRef, payload);
  } catch (error) {
    console.error("Error finding or updating template:", error);
  }
};

const getClientTYpeContext = async () => {
  try {
    const userFavoritesRef = collection(db, "clientType");
    const q = query(userFavoritesRef);
    const querySnapshot = await getDocs(q);
    const results = [];
    querySnapshot.forEach((doc) => {
      const ctype = doc.data();
      results.push(ctype);
    });
    return results;
  } catch (error) {
    console.error("Error searching for users:", error);
    // Handle the error, show an error message, etc.
  }
};

const deleteTemplateContext = async (docId, formId) => {
  try {
    const res = await axios.delete(
      `https://hipaa-api.jotform.com/form/${formId}?apiKey=96f6e2d15e901cc91786cd28f102746e`,
    );
    if (res.status === 200) {
      await deletedocIDContext("templates", docId);
      return res.data;
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
};

export {
  createTemplateContext,
  getClientTYpeContext,
  updateTemplateContext,
  deleteTemplateContext,
  assignTemplateFormContext,
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import AssignToClient from "../../../components/common/assignToClient";
import FeedBreadcrumbs from "../../../components/common/breadcrumbs";
import { MainHeading } from "../../../components";
import NoImage from "../../../assets/images/no-image.svg";
import { Button, FeedInput, Heading, LabelFeed, Text } from "../../../utils";
import { CrossIcon } from "../../../FeedLibraryIcon";
import {
  setbtnLoaderReducer,
  seteditFormReducer,
} from "../../../store/reducers/uiSettings";
import { useNavigate } from "react-router-dom";
import {
  addSupplementTemplateAction,
  updateSupplementTempalateAction,
} from "../../../store/actions/supplementPlan";
let cartItem = "";
const SteppeFour = ({ closePlanHandler, formRef }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uiSettings = useSelector((state) => state.uiSettings);
  const common = useSelector((state) => state.common);
  const { editForm } = uiSettings;
  const [selectedClient, setselectedClient] = useState(
    editForm?.selectedClient ? [editForm?.selectedClient] : []
  );
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    setselectedClient(
      common?.allClients?.filter((f) => editForm?.clientIds?.includes(f.uid))
    );
  }, [editForm?.clientIds]);

  useEffect(() => {
    if (editForm?.supplementList?.length) {
      cartItem = "";
      const filterArray = editForm?.supplementList?.filter(
        (f) => f.type !== "external"
      );
      // eslint-disable-next-line array-callback-return
      filterArray?.map((product) => {
        let variantID = product?.variantId.split("ProductVariant/")?.[1];
        variantID = variantID + ":1";
        if (!!cartItem?.length) {
          cartItem = cartItem.concat("," + variantID);
        } else {
          cartItem = variantID;
        }
      });
    }
  }, [editForm?.supplementList]);

  return (
    <>
      <div className="mb-[24px]">
        <FeedBreadcrumbs
          backClick={closePlanHandler}
          selectedTemplateName={{ name: "Supplements" }}
          formRef={formRef}
          cancelBtnClass="!border-none !p-2"
          submitBtnClass="!rounded-[8px]"
          marginBottom="!mb-0"
          disabled={false}
          crumbText="Supplemet Plan"
        />
        <MainHeading
          title="Create Supplement Plan Template"
          description={
            "Welcome to the Kaboodle Supplement Plan Builder. Please complete all steps and proceed using the buttons at the bottom of each screen."
          }
        />
      </div>
      <Formik
        initialValues={{
          name: uiSettings?.editForm?.name || "",
        }}
        innerRef={formRef}
        // enableReinitialize
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = "Required";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          dispatch(setbtnLoaderReducer(true));
          if (uiSettings?.formName === "edit-supplement") {
            await updateSupplementTempalateAction(editForm?.docId, {
              ...editForm,
              name: values.name,
              updatedAt: new Date().toLocaleString(),
            });
          } else {
            await addSupplementTemplateAction(`supplementPlan`, {
              ...editForm,
              name: values.name,
              clientIds: selectedClient
                ?.filter((f) => f.uid)
                ?.map((client) => client.uid),
              usedCount: selectedClient?.length,
              coachId: common?.user?.uid,
              createdAt: new Date().toLocaleString(),
              updatedAt: new Date().toLocaleString(),
            });
          }

          dispatch(setbtnLoaderReducer(false));
          setSubmitting(false);
          dispatch(seteditFormReducer({ type: "" }));
          navigate(editForm?.closer || "/template?type=supplement-plan");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            className={`w-full flex-column gap-6 ${
              uiSettings?.formName === "view-template"
                ? "pointer-events-none"
                : "pointer-events-auto"
            }`}
          >
            <div className="w-full flex-start-start-column autofill-fix">
              <LabelFeed
                text={
                  <>
                    Supplement Plan Name{" "}
                    <span className="text-primary1">*</span>
                  </>
                }
                className={"mb-[10px] !font-medium !text-lg"}
              />
              <FeedInput
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                setFieldValue={setFieldValue}
                type="text"
                placeholder="Add Name"
                name="name"
                crossicon={values?.name ? <CrossIcon /> : ""}
                className="w-full  !px-5 !bg-secondary1"
              />
              {errors.name && touched.name && (
                <div className="text-red text-[14px]  pt-[4px]">
                  {errors.name}
                </div>
              )}
            </div>

            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-[16px]">
              <div className="w-full flex-column gap-[16px]">
                <Heading
                  text={"Assign to client"}
                  color="text-textPrimary"
                  fontSize="text-[20px]"
                  fontweight="font-normal"
                  className="leading-[22px] "
                />

                <div className="w-full flex-column gap-6 px-[20px] py-6 rounded-lg bg-secondary1 ">
                  <div className="flex-column gap-[8px] w-full ">
                    <LabelFeed
                      text="Client Name"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[24px] tracking-[0.5px]"
                    />
                    <AssignToClient
                      heading
                      setselectedClient={setselectedClient}
                      selectedClient={selectedClient}
                      options={common?.allClients?.map((client) => {
                        return { ...client, id: client.uid, name: client.name };
                      })}
                      disabled={
                        uiSettings?.formName === "view-template" ? true : false
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex-column gap-[16px]">
                <Heading
                  text={`Supplement List (${uiSettings?.editForm?.supplementList?.length})`}
                  color="text-textPrimary"
                  fontSize="text-[20px]"
                  fontweight="font-normal"
                  className="leading-[22px] "
                />
                <div className="w-full py-6 px-4 bg-black rounded-lg">
                  <div
                    className={`h-[414px] flex-column gap-4 ${
                      uiSettings?.editForm?.supplementList?.length >= 6 &&
                      "overflow-y-scroll"
                    }`}
                  >
                    {uiSettings?.editForm?.supplementList?.map(
                      (item, index) => (
                        <div
                          key={index}
                          className="w-full flex-items-center gap-[16px]"
                        >
                          <Text
                            text={`${index + 1}.`}
                            color="text-textPrimary"
                            fontSize={"text-[16px]"}
                            className={"leading-[24px] tracking-[.5px]"}
                          />

                          <div
                            key={index}
                            className="w-[50px] h-[50px] rounded-lg p-[5px] flex-center-center bg-background  "
                          >
                            <img
                              src={
                                item?.images?.[0]?.src ||
                                item?.featureImage?.src ||
                                NoImage
                              }
                              alt="brand"
                              className="w-full h-full object-contain"
                            />
                          </div>

                          <Text
                            text={`${item?.name}, ${item?.vendor}`}
                            color="text-text6"
                            fontSize={"text-[18px]"}
                            className={"leading-[24px] tracking-[.5px]"}
                          />
                          {item.type === "external" && (
                            <Button
                              text={copied ? "Copied" : "Copy Link"}
                              type="button"
                              bg="bg-transparent border !border-background"
                              className="px-[12px] !py-[7px]  !rounded-[8px] !h-[44px] !font-medium leading-6 text-[14px]"
                              color="text-white"
                              cta={() => {
                                setCopied(true);
                                navigator.clipboard.writeText(item.productLink);
                              }}
                            />
                          )}
                        </div>
                      )
                    )}
                  </div>

                  <div className="w-full flex-center-end gap-[16px] pt-[24px]">
                    <Button
                      text="Buy Now"
                      type="button"
                      bg="bg-activeBackground"
                      className="px-[12px] !py-[7px] !rounded-[8px] !h-[44px] !font-medium leading-6 text-[14px]"
                      color="text-primary1"
                      cta={() =>
                        window.open(
                          `${process.env.REACT_APP_EXTERNAL_API_URL}/cart/${cartItem}`,
                          "rel=noopener noreferrer"
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SteppeFour;

import React from "react";
import { Drawer } from "rsuite";
import { Button, Text } from "../../../utils";
import { Cross } from "../../../FeedLibraryIcon";

const UserDrawer = ({
  isOpen,
  isClose,
  footerButton,
  children,
  heading,
  formRef,
}) => {
  return (
    <Drawer
      open={isOpen}
      className="!max-w-[693px] !w-full !bg-secondary2 !p-0  add-food-drawer"
    >
      <Drawer.Body className="!p-0 flex flex-col justify-between">
        <div className="w-full flex items-center justify-between px-[20px] py-[16px] border-b border-solid border-background9">
          <div className="flex items-center">
            {isOpen.includes("EditPermissions") && (
              <Text
                text={"Edit Permissions: "}
                color="text-textPrimary"
                className="!text-lg !font-normal m-0"
              />
            )}
            <Text
              text={heading}
              color="text-primary1"
              className="!text-lg !font-medium m-0"
            />
          </div>
          <Button cta={isClose} Icon={<Cross />} className="p-0 h-auto" />
        </div>

        {/*  */}

        {children}

        {/*  */}
        {footerButton && (
          <div className="w-full flex justify-between items-center px-[20px] py-[16px] border-t border-solid border-background9">
            <Button
              text="Cancel"
              type="button"
              bg=" border border-textPrimary"
              className="w-[102px] rounded-[8px]  !py-[7px] font-medium text-sm"
              color="text-textPrimary"
              cta={isClose}
            />

            <Button
              text="Save & Close"
              type="button"
              bg="bg-primary1"
              className="!px-[14px] !py-[7px]  rounded-[8px] font-medium text-sm "
              color="text-background"
              cta={() => {
                formRef.current?.submitForm();
              }}
            />
          </div>
        )}
      </Drawer.Body>
    </Drawer>
  );
};

export default UserDrawer;

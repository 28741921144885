import React from "react";

export const TextArea = (props) => {
  const {
    className,
    onChange,
    disabled,
    setFieldValue,
    name,
    placeholder,
    numberfield,
  } = props;

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      onChange(e);
      setFieldValue(name, value);
    }
  };

  return (
    <textarea
      {...props}
      onChange={numberfield ? handleInputChange : onChange}
      disabled={disabled}
      placeholder={placeholder}
      type="textarea"
      className={`font-normal text-textPrimary bg-background  text-[16px] not-italic leading-6 !outline-none tracking-[0.5px]  w-full ${className}`}
    />
  );
};

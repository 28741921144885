import React from "react";

const FeedButton = (props) => {
  const {
    text,
    Icon,
    color,
    bg,
    cta,
    border,
    disabled,
    className,
    rightmargin,
  } = props;

  return (
    <button
      {...props}
      onClick={() => {
        if (cta) {
          cta();
        }
      }}
      className={`${
        border ? `  fit-content solid border-1 ${border}` : "fit-content"
      }  ${
        disabled && "opacity-30"
      }  flex  h-[44px] fit-content items-center font-medium text-[14px] justify-center leading-5 !cursor-pointer  ${bg} ${color} ${className}`}
    >
      {Icon && <span className={`${Icon ? rightmargin : "mr-0"}`}>{Icon}</span>}
      {text}
    </button>
  );
};

export default FeedButton;

import React from "react";

import { auth } from "../../../configs/firebase";
import { Text } from "../../../utils";
import { formatChatDate } from "../../../helper";
import { ChecksIcon } from "../../../FeedLibraryIcon";
import ClienImage from "../../../assets/images/client-img.jpg";

const UserChats = ({ message, reciever, common }) => {
  const { uid } = auth.currentUser;

  return (
    <div className="w-full px-[20px] flex-column  gap-[20px]">
      {/* me */}
      {uid === message?.senderId ? (
        <div className="w-full flex-row-reverse-start gap-[16px] mb-[10px]">
          <div className="w-[36px] h-[36px] rounded-lg flex-start-center shadow-clientImgShadow overflow-hidden ">
            <img
              className="w-full h-full object-cover "
              src={common?.user?.avatar}
              alt="client"
            />
          </div>

          <div className="flex-column-items-end gap-[2px]">
            <div className="flex-reverse-items-center gap-[12px]">
              <span className="w-max max-w-[295px] px-[16px] py-[8px] rounded-[8px] text-right bg-background">
                <Text
                  text={message.text}
                  className="!text-white m-0 leading-[22px] "
                />
              </span>
              {/* <ChecksIcon /> */}
            </div>
            <Text
              text={
                message.timestamp?.seconds
                  ? formatChatDate(message.timestamp)
                  : "Just Now"
              }
              className="!text-text7 m-0 leading-[22px]"
            />
          </div>
        </div>
      ) : (
        <div className="w-full flex-start-start gap-[16px]">
          <div className="w-[36px] h-[36px] rounded-lg flex-start-center shadow-clientImgShadow overflow-hidden ">
            <img
              className="w-full h-full object-cover "
              src={reciever?.avatar || ClienImage}
              alt="client"
            />
          </div>

          <div className="flex-column  gap-[12px]">
            <div className="flex-items-center gap-[12px]">
              <span className="w-max max-w-[337px] px-[16px] text-left py-[8px] rounded-[8px] bg-primary1">
                <Text
                  text={message.text}
                  className="!text-background m-0 leading-[22px] text-left"
                />
              </span>
              <ChecksIcon />
            </div>

            <Text
              text={
                message?.timestamp
                  ? formatChatDate(message.timestamp)
                  : "Just Now"
              }
              className="!text-text7 m-0 leading-[22px]"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserChats;

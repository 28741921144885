/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  foodList: null,
  mealPlan: [],
  favFoods: [],
  foodListPagination: {
    total: 0,
    page: 1,
    pageSize: 20,
  },
};

export const mealPlan = createSlice({
  name: "mealPlan",
  initialState,
  reducers: {
    setFoodListReducer: (state, action) => {
      state.foodList = action.payload;
    },
    setAllFavFoodReducer: (state, action) => {
      state.favFoods = action.payload;
    },
    updateFoodItemPagination: (state, action) => {
      state.foodListPagination = action.payload;
    },
    addFoodItemReducer: (state, action) => {
      state.foodList = [action.payload, ...state.foodList];
    },
    addMealPlanReducer: (state, action) => {
      state.mealPlan = [action.payload, ...state.mealPlan];
    },
    setAllMealPlanReducer: (state, action) => {
      state.mealPlan = action.payload;
    },
    deleteMealPlanReducer: (state, action) => {
      state.mealPlan = state.mealPlan?.filter(
        (f) => f.docId !== action.payload
      );
    },
    addFavFoodItemReducer: (state, action) => {
      const exists = state.favFoods?.some(
        (food) => food.foodId === action.payload.foodId
      );
      if (exists) {
        state.favFoods = state.favFoods.filter(
          (food) => food.foodId !== action.payload.foodId
        );
      }
      if (!exists) {
        state.favFoods.push(action.payload);
      }
    },

    updateMealPlanReducer: (state, action) => {
      state.mealPlan = state.mealPlan?.map((meal) => {
        if (meal.docId === action.payload.docId) {
          return action.payload;
        } else {
          return meal;
        }
      });
    },
  },
});

export const {
  setFoodListReducer,
  updateFoodItemPagination,
  addFoodItemReducer,
  addMealPlanReducer,
  setAllMealPlanReducer,
  deleteMealPlanReducer,
  updateMealPlanReducer,
  addFavFoodItemReducer,
  setAllFavFoodReducer,
} = mealPlan.actions;

export default mealPlan.reducer;

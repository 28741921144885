import React from "react";

export const ComeraIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="30" height="30" rx="15" fill="#202020" />
      <rect x="0.5" y="0.5" width="30" height="30" rx="15" stroke="#202020" />
      <path
        d="M20.5 11.4348H18.7675L17.9156 10.2128C17.87 10.1474 17.8082 10.0937 17.7357 10.0566C17.6632 10.0195 17.5822 10 17.5 10H13.5C13.4178 10 13.3368 10.0195 13.2643 10.0566C13.1918 10.0937 13.13 10.1474 13.0844 10.2128L12.2319 11.4348H10.5C10.1022 11.4348 9.72064 11.5859 9.43934 11.855C9.15804 12.1241 9 12.489 9 12.8696V19.5652C9 19.9457 9.15804 20.3107 9.43934 20.5798C9.72064 20.8488 10.1022 21 10.5 21H20.5C20.8978 21 21.2794 20.8488 21.5607 20.5798C21.842 20.3107 22 19.9457 22 19.5652V12.8696C22 12.489 21.842 12.1241 21.5607 11.855C21.2794 11.5859 20.8978 11.4348 20.5 11.4348ZM17.75 15.9783C17.75 16.4039 17.618 16.82 17.3708 17.1739C17.1236 17.5279 16.7722 17.8037 16.361 17.9666C15.9499 18.1295 15.4975 18.1721 15.061 18.0891C14.6246 18.006 14.2237 17.8011 13.909 17.5001C13.5943 17.1991 13.38 16.8156 13.2932 16.3981C13.2064 15.9806 13.251 15.5479 13.4213 15.1547C13.5916 14.7614 13.88 14.4253 14.25 14.1888C14.62 13.9523 15.055 13.8261 15.5 13.8261C16.0967 13.8261 16.669 14.0528 17.091 14.4564C17.5129 14.8601 17.75 15.4075 17.75 15.9783Z"
        fill="#C0F121"
      />
    </svg>
  );
};

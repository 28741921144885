import React from 'react';

export const WorkoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_662_8037)">
        <path
          opacity="0.2"
          d="M12 8V24C12 24.2652 11.8946 24.5196 11.7071 24.7071C11.5196 24.8946 11.2652 25 11 25H8C7.73478 25 7.48043 24.8946 7.29289 24.7071C7.10536 24.5196 7 24.2652 7 24V8C7 7.73478 7.10536 7.48043 7.29289 7.29289C7.48043 7.10536 7.73478 7 8 7H11C11.2652 7 11.5196 7.10536 11.7071 7.29289C11.8946 7.48043 12 7.73478 12 8ZM24 7H21C20.7348 7 20.4804 7.10536 20.2929 7.29289C20.1054 7.48043 20 7.73478 20 8V24C20 24.2652 20.1054 24.5196 20.2929 24.7071C20.4804 24.8946 20.7348 25 21 25H24C24.2652 25 24.5196 24.8946 24.7071 24.7071C24.8946 24.5196 25 24.2652 25 24V8C25 7.73478 24.8946 7.48043 24.7071 7.29289C24.5196 7.10536 24.2652 7 24 7Z"
          fill="#C0F121"
        />
        <path
          d="M31 15H30V11C30 10.4696 29.7893 9.96086 29.4142 9.58579C29.0391 9.21071 28.5304 9 28 9H26V8C26 7.46957 25.7893 6.96086 25.4142 6.58579C25.0391 6.21071 24.5304 6 24 6H21C20.4696 6 19.9609 6.21071 19.5858 6.58579C19.2107 6.96086 19 7.46957 19 8V15H13V8C13 7.46957 12.7893 6.96086 12.4142 6.58579C12.0391 6.21071 11.5304 6 11 6H8C7.46957 6 6.96086 6.21071 6.58579 6.58579C6.21071 6.96086 6 7.46957 6 8V9H4C3.46957 9 2.96086 9.21071 2.58579 9.58579C2.21071 9.96086 2 10.4696 2 11V15H1C0.734784 15 0.48043 15.1054 0.292893 15.2929C0.105357 15.4804 0 15.7348 0 16C0 16.2652 0.105357 16.5196 0.292893 16.7071C0.48043 16.8946 0.734784 17 1 17H2V21C2 21.5304 2.21071 22.0391 2.58579 22.4142C2.96086 22.7893 3.46957 23 4 23H6V24C6 24.5304 6.21071 25.0391 6.58579 25.4142C6.96086 25.7893 7.46957 26 8 26H11C11.5304 26 12.0391 25.7893 12.4142 25.4142C12.7893 25.0391 13 24.5304 13 24V17H19V24C19 24.5304 19.2107 25.0391 19.5858 25.4142C19.9609 25.7893 20.4696 26 21 26H24C24.5304 26 25.0391 25.7893 25.4142 25.4142C25.7893 25.0391 26 24.5304 26 24V23H28C28.5304 23 29.0391 22.7893 29.4142 22.4142C29.7893 22.0391 30 21.5304 30 21V17H31C31.2652 17 31.5196 16.8946 31.7071 16.7071C31.8946 16.5196 32 16.2652 32 16C32 15.7348 31.8946 15.4804 31.7071 15.2929C31.5196 15.1054 31.2652 15 31 15ZM4 21V11H6V21H4ZM11 24H8V8H11V24ZM24 24H21V8H24V21.9788C24 21.9862 24 21.9925 24 22C24 22.0075 24 22.015 24 22.0212V24ZM28 21H26V11H28V21Z"
          fill="#C0F121"
        />
      </g>
      <defs>
        <clipPath id="clip0_662_8037">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from 'react';

export const Info = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Info">
        <path
          id="Vector"
          opacity="0.2"
          d="M19.25 11C19.25 12.6317 18.7661 14.2267 17.8596 15.5835C16.9531 16.9402 15.6646 17.9976 14.1571 18.622C12.6497 19.2464 10.9909 19.4098 9.39051 19.0915C7.79017 18.7731 6.32016 17.9874 5.16637 16.8336C4.01259 15.6798 3.22685 14.2098 2.90853 12.6095C2.5902 11.0092 2.75357 9.35035 3.378 7.84286C4.00242 6.33537 5.05984 5.0469 6.41655 4.14038C7.77326 3.23385 9.36831 2.75 11 2.75C13.188 2.75 15.2865 3.61919 16.8336 5.16637C18.3808 6.71354 19.25 8.81196 19.25 11Z"
          fill="#C0F121"
        />
        <path
          id="Vector_2"
          d="M12.375 15.125C12.375 15.3073 12.3026 15.4822 12.1736 15.6111C12.0447 15.7401 11.8698 15.8125 11.6875 15.8125C11.3228 15.8125 10.9731 15.6676 10.7152 15.4098C10.4574 15.1519 10.3125 14.8022 10.3125 14.4375V11C10.1302 11 9.9553 10.9276 9.82637 10.7986C9.69744 10.6697 9.625 10.4948 9.625 10.3125C9.625 10.1302 9.69744 9.9553 9.82637 9.82636C9.9553 9.69743 10.1302 9.625 10.3125 9.625C10.6772 9.625 11.0269 9.76987 11.2848 10.0277C11.5426 10.2856 11.6875 10.6353 11.6875 11V14.4375C11.8698 14.4375 12.0447 14.5099 12.1736 14.6389C12.3026 14.7678 12.375 14.9427 12.375 15.125ZM19.9375 11C19.9375 12.7677 19.4133 14.4956 18.4313 15.9654C17.4492 17.4352 16.0534 18.5807 14.4202 19.2572C12.7871 19.9336 10.9901 20.1106 9.25638 19.7658C7.52268 19.4209 5.93017 18.5697 4.68024 17.3198C3.43031 16.0698 2.57909 14.4773 2.23424 12.7436C1.88938 11.0099 2.06637 9.21288 2.74283 7.57977C3.41929 5.94665 4.56483 4.55081 6.0346 3.56874C7.50436 2.58668 9.23233 2.0625 11 2.0625C13.3696 2.065 15.6414 3.00743 17.317 4.683C18.9926 6.35856 19.935 8.6304 19.9375 11ZM18.5625 11C18.5625 9.50428 18.119 8.04215 17.288 6.7985C16.457 5.55485 15.2759 4.58555 13.894 4.01316C12.5122 3.44077 10.9916 3.29101 9.52463 3.58281C8.05765 3.87461 6.71014 4.59487 5.65251 5.65251C4.59487 6.71014 3.87462 8.05765 3.58282 9.52463C3.29101 10.9916 3.44078 12.5122 4.01316 13.894C4.58555 15.2759 5.55486 16.457 6.7985 17.288C8.04215 18.119 9.50428 18.5625 11 18.5625C13.005 18.5602 14.9272 17.7627 16.345 16.345C17.7627 14.9272 18.5602 13.005 18.5625 11ZM10.6563 8.25C10.8602 8.25 11.0596 8.18952 11.2292 8.0762C11.3988 7.96289 11.531 7.80183 11.609 7.61339C11.6871 7.42496 11.7075 7.21761 11.6677 7.01756C11.6279 6.81752 11.5297 6.63377 11.3855 6.48955C11.2412 6.34532 11.0575 6.24711 10.8574 6.20731C10.6574 6.16752 10.45 6.18795 10.2616 6.266C10.0732 6.34405 9.91212 6.47623 9.7988 6.64582C9.68549 6.81541 9.625 7.01479 9.625 7.21875C9.625 7.49225 9.73365 7.75456 9.92705 7.94795C10.1204 8.14135 10.3827 8.25 10.6563 8.25Z"
          fill="#C0F121"
        />
      </g>
    </svg>
  );
};

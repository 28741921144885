import React from "react";
import Multiselect from "multiselect-react-dropdown";
import { Text, LabelFeed } from "../../utils";
import { CrossIcon, DropIcon } from "../../FeedLibraryIcon";

const AssignToClient = ({
  options,
  setselectedClient,
  error,
  selectedClient,
  disabled,
  heading,
  formTitle,
}) => {
  return (
    <div className={`secondary-grid ${heading && "!p-0"}`}>
      <div className="flex flex-col">
        {!heading && (
          <Text text="Do you have a client already?" className={"mb-[32px]"} />
        )}
        <div className="">
          {!heading && (
            <div className="mb-[20px]">
              <LabelFeed
                text={`Select clients to assign this ${
                  formTitle || "template"
                }`}
                color="text-textPrimary"
              />
            </div>
          )}
          <div className="w-full mb-[16px]">
            <Multiselect
              options={options}
              onSelect={(e) => {
                setselectedClient([...e]);
              }}
              optionValueDecorator={(label, option) => {
                return (
                  <div className="flex-items-center">
                    <img
                      src={option.avatar}
                      alt={label}
                      className="w-[34px] h-[34px] rounded"
                    />
                    <span className="ml-3">{option.name}</span>
                  </div>
                );
              }}
              selectedValues={selectedClient}
              onRemove={(e) => {
                setselectedClient([...e]);
              }}
              displayValue="name"
              hideSelectedList
              showCheckbox={false}
              placeholder={"Search Client"}
              customArrow={<DropIcon />}
              disable={disabled}
              caseSensitiveSearch={false}
              className="f4f-mutliselect-droddown"
            />
            {error && (
              <div className="text-red text-[14px] pt-2 pl-1">{error}</div>
            )}
          </div>

          <div className="flex items-start flex-wrap gap-[16px]">
            {selectedClient?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`w-fit flex-items-center p-[10px] bg-activeBackground rounded ${
                    disabled ? "pointer-events-none" : "pointer-events-auto"
                  }`}
                >
                  <Text
                    text={item.name}
                    color={"text-primary1"}
                    className={"mr-1 "}
                  />
                  <span
                    className="cursor-pointer"
                    onClick={() =>
                      setselectedClient(
                        selectedClient?.filter((f) => f.id !== item.id),
                      )
                    }
                  >
                    <CrossIcon />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignToClient;

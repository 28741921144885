import React from 'react';

export const FoodNotFavIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.6895 6.91313C17.6138 6.67983 17.4711 6.47396 17.2793 6.32115C17.0874 6.16834 16.8549 6.07535 16.6106 6.05375L11.9707 5.65375L10.152 1.32875C10.0573 1.10181 9.89756 0.907959 9.69292 0.771608C9.48828 0.635256 9.24788 0.5625 9.00197 0.5625C8.75607 0.5625 8.51566 0.635256 8.31102 0.771608C8.10638 0.907959 7.94666 1.10181 7.85197 1.32875L6.03869 5.65375L1.39338 6.05609C1.14809 6.07671 0.914352 6.16928 0.721469 6.3222C0.528586 6.47512 0.385146 6.68159 0.309136 6.91571C0.233126 7.14983 0.227931 7.40118 0.294202 7.63824C0.360472 7.8753 0.495259 8.08752 0.681658 8.24828L4.20588 11.328L3.14963 15.9014C3.09377 16.1407 3.10971 16.3912 3.19545 16.6215C3.28118 16.8519 3.43291 17.0518 3.63165 17.1964C3.8304 17.3409 4.06736 17.4237 4.31289 17.4343C4.55842 17.4449 4.80164 17.3829 5.01213 17.2561L8.9965 14.8342L12.9895 17.2561C13.2 17.3829 13.4432 17.4449 13.6887 17.4343C13.9342 17.4237 14.1712 17.3409 14.3699 17.1964C14.5687 17.0518 14.7204 16.8519 14.8062 16.6215C14.8919 16.3912 14.9078 16.1407 14.852 15.9014L13.7965 11.3233L17.3199 8.24828C17.5063 8.08696 17.6408 7.87414 17.7066 7.63658C17.7723 7.39901 17.7664 7.14731 17.6895 6.91313ZM16.5004 7.30375L12.977 10.3787C12.8055 10.5279 12.6779 10.721 12.608 10.9373C12.5381 11.1535 12.5284 11.3848 12.5801 11.6061L13.6387 16.1873L9.64885 13.7655C9.45419 13.6469 9.23067 13.5842 9.00275 13.5842C8.77484 13.5842 8.55132 13.6469 8.35666 13.7655L4.37228 16.1873L5.42385 11.6092C5.47553 11.3879 5.46588 11.1567 5.39595 10.9404C5.32602 10.7241 5.19845 10.531 5.02697 10.3819L1.50197 7.30844C1.50168 7.3061 1.50168 7.30374 1.50197 7.30141L6.14572 6.89984C6.37244 6.87986 6.5894 6.79835 6.7732 6.66412C6.957 6.52988 7.10066 6.34801 7.18869 6.13813L9.00197 1.81859L10.8145 6.13813C10.9025 6.34801 11.0462 6.52988 11.23 6.66412C11.4138 6.79835 11.6307 6.87986 11.8574 6.89984L16.502 7.30141C16.502 7.30141 16.502 7.30609 16.502 7.30688L16.5004 7.30375Z"
        fill="#7D7D7D"
      />
    </svg>
  );
};

import React from "react";
import { Button, Text } from "../../utils";
import { FileIcon } from "../../FeedLibraryIcon";
export const CreateTempo = ({ btnClick, selectedTemplateName }) => {
  return (
    <div className="flex-items-center-column gap-[20px] ">
      <span>
        <FileIcon />
      </span>
      <Text
        text="You Don't Have Any Templates"
        className={"leading-5"}
        color={"text-textPrimary"}
      />
      <Button
        text={selectedTemplateName?.clientbtnText}
        bg="bg-primary1"
        className="px-[24px] py-[14px] font-normal rounded-[12px]"
        color="text-background"
        cta={btnClick}
      />
    </div>
  );
};

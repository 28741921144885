import React from 'react';

export const RightArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M12.773 9.39798L7.14796 15.023C7.0957 15.0752 7.03366 15.1167 6.96537 15.145C6.89709 15.1733 6.8239 15.1878 6.74999 15.1878C6.67608 15.1878 6.6029 15.1733 6.53461 15.145C6.46633 15.1167 6.40429 15.0752 6.35202 15.023C6.29976 14.9707 6.2583 14.9087 6.23002 14.8404C6.20174 14.7721 6.18718 14.6989 6.18718 14.625C6.18718 14.5511 6.20174 14.4779 6.23002 14.4096C6.2583 14.3413 6.29976 14.2793 6.35202 14.227L11.5798 9.00001L6.35202 3.77298C6.24648 3.66743 6.18718 3.52427 6.18718 3.37501C6.18718 3.22574 6.24648 3.08259 6.35202 2.97704C6.45757 2.87149 6.60073 2.81219 6.74999 2.81219C6.89926 2.81219 7.04241 2.87149 7.14796 2.97704L12.773 8.60204C12.8253 8.65428 12.8668 8.71632 12.8951 8.7846C12.9234 8.85289 12.9379 8.92609 12.9379 9.00001C12.9379 9.07393 12.9234 9.14713 12.8951 9.21541C12.8668 9.2837 12.8253 9.34574 12.773 9.39798Z"
        fill="#C0F121"
      />
    </svg>
  );
};

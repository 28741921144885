import store from "../store";
import {
  getAllUserClientContext,
  getAllDocByCollectionContext,
  deletedocIDContext,
  createDocContext,
  updateDocCollectionContext,
} from "../../Services/commonContext";

import {
  setUserDetails,
  setClientTypeReducer,
  addClientTypeReducer,
  deleteCLientTypeReducer,
  updateClientTypeReducer,
  setAllMyClientReducer,
} from "../reducers/common";
const setUserDetailsAction = async (data) => {
  if (data?.uid) {
    store.dispatch(setUserDetails(data));
  }
};

const getallMyClientAction = async (uid) => {
  const result = await getAllUserClientContext(uid);
  const filterddata = result?.map((client) => {
    return {
      ...client,
      id: client.docId,
    };
  });
  if (!!result?.length) {
    store.dispatch(setAllMyClientReducer(filterddata));
  }
  return result;
};
const createCLientTypeAction = async (data) => {
  const result = await createDocContext("clientType", data);
  if (result?.id) {
    store.dispatch(addClientTypeReducer({ ...data, docId: result?.id }));
    return result;
  }
};
const updateClientTypeAction = async (data) => {
  const result = await updateDocCollectionContext(
    "clientType",
    data?.docId,
    data
  );
  if (!!result) {
    store.dispatch(updateClientTypeReducer(data));
    return result;
  }
};
const deleteClientTypeAction = async (docId) => {
  await deletedocIDContext("clientType", docId);
  store.dispatch(deleteCLientTypeReducer(docId));
};
const getCLientTypeAction = async (uid, url) => {
  const result = await getAllDocByCollectionContext(uid, url);
  if (result?.length) {
    store.dispatch(setClientTypeReducer(result));
    return result;
  }
};

export {
  setUserDetailsAction,
  createCLientTypeAction,
  getCLientTypeAction,
  deleteClientTypeAction,
  updateClientTypeAction,
  getallMyClientAction,
};

import React, { useRef } from "react";
import { Loader, Modal } from "rsuite";

import { Formik } from "formik";
import { Button, FeedInput, Heading, LabelFeed, Text } from "../../../utils";
import { CrossIcon } from "../../../FeedLibraryIcon";
import { setbtnLoaderReducer } from "../../../store/reducers/uiSettings";
import { useDispatch, useSelector } from "react-redux";
import { addCustomSupplementAction } from "../../../store/actions/supplementPlan";
import { generateUniqueId } from "../../../helper";
const timeSchedulerObj = [
  {
    id: generateUniqueId(),
    tsId: generateUniqueId(),
    timestamp: "Time Stamp",
    count: "",
    unit: {},
  },
];
const CustomSupModal = (props) => {
  const { open, setOpen, user, setselectedProduct, selectedProduct } = props;
  const uiSettings = useSelector((state) => state.uiSettings);
  const formRef = useRef(null);
  const dispatch = useDispatch();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className="w-full max-w-[926px] foodModal"
    >
      <Modal.Body className="w-full !p-0 !mt-0 ">
        <Heading
          text={"Add Custom Supplement"}
          color="text-textPrimary"
          fontSize="text-[22px]"
          fontweight="font-bold"
          className="leading-6 mb-[16px]"
        />
        <Text
          text={
            "Enter foods exactly as the label appears.\nBE SURE TO ADD ALL THE SERVING SIZES CONVERSIONS shown on the label.\nSpecifically enter the serving size in grams (or flud ounces/ml if it’s a liquie or deosn’t show grams).\nA saved food will be added into your database and will be sent to the admn for approal to be added.\n"
          }
          color="text-whiteGray"
          className="font-normal "
        />
        {/*  */}
        <div className=" mt-6 mb-9">
          <Heading
            text={"Product Information"}
            color="text-textPrimary"
            fontSize="text-[20px]"
            fontweight="font-medium"
            className="leading-[22px] mb-[24px] "
          />

          <Formik
            initialValues={{
              title: "",
              productPrice: "",
              productLink: "",
            }}
            innerRef={formRef}
            enableReinitialize
            validate={(values) => {
              const errors = {};
              if (!values.title) {
                errors.title = "Required";
              }
              if (!values.productPrice) {
                errors.productPrice = "Required";
              }
              if (!values.productLink) {
                errors.productLink = "Required";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const payload = {
                variantId: generateUniqueId(),
                id: generateUniqueId(),
                name: values.title,
                productLink: values.productLink,
                featureImage: { src: "" },
                price: { amount: values.productPrice, currencyCode: "USD" },
                availableForSale: true,
                timeScheduler: timeSchedulerObj,
                category: "Nutraceutical",
                type: "external",
                vendor: "Custom Product",
                coachId: user?.uid,
              };
              dispatch(setbtnLoaderReducer(true));
              await addCustomSupplementAction(`customSupplement`, payload);
              setselectedProduct([...selectedProduct, payload]);
              dispatch(setbtnLoaderReducer(false));
              setOpen(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className="w-full">
                <div className="w-full grid grid-cols-2 gap-x-6 gap-y-[16px]">
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text={
                        <>
                          Product Name <span className="text-primary1"> *</span>
                        </>
                      }
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      crossicon={values?.title ? <CrossIcon /> : ""}
                      placeholder="Supplement Name"
                      type="text"
                      setFieldValue={setFieldValue}
                      className="!text-sm !rounded-[4px]"
                    />
                    {errors.title && touched.title && (
                      <LabelFeed
                        text={errors.title}
                        color="text-red"
                        className="!leading-[22px] !text-[14px]"
                      />
                    )}
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text={
                        <>
                          Product Price{" "}
                          <span className="text-primary1"> *</span>
                        </>
                      }
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="productPrice"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.productPrice}
                      crossicon={values?.productPrice ? <CrossIcon /> : ""}
                      placeholder="0"
                      className="!text-sm !rounded-[4px]"
                    />
                    {errors.productPrice && touched.productPrice && (
                      <LabelFeed
                        text={errors.productPrice}
                        color="text-red"
                        className="!leading-[22px] !text-[14px]"
                      />
                    )}
                  </div>

                  <div className="flex flex-col gap-[8px] w-full col-span-2">
                    <LabelFeed
                      text={
                        <>
                          Product Link <span className="text-primary1"> *</span>
                        </>
                      }
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="productLink"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.productLink}
                      crossicon={values?.productLink ? <CrossIcon /> : ""}
                      placeholder="Past link here"
                      className="!text-sm !rounded-[4px]"
                    />
                    {errors.productLink && touched.productLink && (
                      <LabelFeed
                        text={errors.productLink}
                        color="text-red"
                        className="!leading-[22px] !text-[14px]"
                      />
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
      <Modal.Footer className="w-full flex justify-between">
        <Button
          text="Cancel"
          type="button"
          bg=" border border-textPrimary"
          className="w-[102px] rounded-[8px] !py-[7px] font-medium text-sm"
          color="text-textPrimary"
          cta={() => setOpen(false)}
        />

        <Button
          text={
            uiSettings?.btnloader ? (
              <Loader size="sm" content="Add Supplement" />
            ) : (
              "Add Supplement"
            )
          }
          type="submit"
          bg="bg-primary1"
          className="!px-[14px] !py-[7px] rounded-[8px] font-medium text-sm "
          color="text-background"
          cta={() => {
            formRef.current?.submitForm();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default CustomSupModal;

import React, { useEffect, useState } from "react";
import { Animation, Popover, Whisper } from "rsuite";
import { useDispatch, useSelector } from "react-redux";

import { Heading, Text } from "../../../../utils";
import { ArrowDown, ArrowUp } from "../../../../FeedLibraryIcon";
import ClientNotes from "./clientNotes";
import Meals from "./meals";
import FeedBreadcrumbs from "../../../../components/common/breadcrumbs";
import { MainHeading } from "../../../../components";

function calculateTotalsAcrossMeals(data, nutrients) {
  return nutrients.reduce((acc, nutrient) => {
    acc[`${nutrient}`] = data
      .reduce((total, meal) => {
        return (
          total +
          meal.foodList.reduce((sum, food) => sum + (food[nutrient] || 0), 0)
        );
      }, 0)
      .toFixed(2);
    return acc;
  }, {});
}

const MealPlanSummary = ({ setMealsData, mealsData, closePlanHandler }) => {
  const dispatch = useDispatch();
  const uiSettings = useSelector((state) => state.uiSettings);
  const { editForm } = uiSettings;
  const [openNutrition, setOpenNutrition] = useState(true);
  const [nutritionLevel, setnutritionLevel] = useState(
    editForm?.nutritionLevel || {}
  );

  useEffect(() => {
    const totals = calculateTotalsAcrossMeals(editForm?.mealList, [
      "protein",
      "carbs",
      "fiber",
      "netCarbs",
      "fat",
      "sodium",
      "potassium",
      "calories",
    ]);
    if (totals && uiSettings?.formName !== "view-template") {
      console.log("totals", totals);
      const totalProtien = parseInt(totals.protein);
      const totalCarbs = parseInt(totals.carbs) + parseInt(totals.fiber);
      const totalFat = parseInt(totals.fat);
      // const totalFiber = parseInt(totals.fiber);
      // const totalnetCarbs = parseInt(totals.netCarbs);
      // const totalPostassium = parseInt(totals.potassium);
      // const totalSodium = parseInt(totals.sodium);
      totals["totalCalories"] = totals.calories;
      const totalMealMacros = (totalProtien + totalCarbs + totalFat).toFixed(2);
      totals["totalMealCarbPerc"] = totalCarbs
        ? ((totalCarbs / totalMealMacros) * 100).toFixed(2)
        : 0;
      totals["totalMealFatPerc"] = totalFat
        ? ((totalFat / totalMealMacros) * 100).toFixed(2)
        : 0;
      totals["totalMealProtienPerc"] = totalProtien
        ? ((totalProtien / totalMealMacros) * 100).toFixed(2)
        : 0;
      setnutritionLevel(totals);
      // const totalProtien = totals.protein * 4;
      // const totalCarbs = totals.carbs * 4;
      // const totalFat = totals.fat * 9;
      // const totalFiber = totals.fiber * 2;
      // const totalnetCarbs = totals.netCarbs * 4;
      // const totalPostassium = totals.potassium * 2;
      // const totalSodium = totals.sodium * 0.01;
      // const totalCalories = (totalProtien + totalCarbs + totalFiber + totalnetCarbs + totalPostassium + totalFat + totalSodium).toFixed(2);
      // totals['totalCalories'] = totalCalories;
      // const totalMealMacros = totalProtien + totalCarbs + totalFat + totalFiber + totalnetCarbs + totalPostassium + totalSodium;
      // totals['totalMealCarbPerc'] = totalCarbs ? ((totalCarbs / totalMealMacros) * 100).toFixed(2) : 0;
      // totals['totalMealFatPerc'] = totalFat ? ((totalFat / totalMealMacros) * 100).toFixed(2) : 0;
      // totals['totalMealProtienPerc'] = totalProtien ? ((totalProtien / totalMealMacros) * 100).toFixed(2) : 0;

      // totals['totalMealSodiumPerc'] = totalSodium ? ((totalSodium / totalMealMacros) * 100).toFixed(2) : 0;
      // totals['totalMealPotPerc'] = totalPostassium ? ((totalPostassium / totalMealMacros) * 100).toFixed(2) : 0;
      // totals['totalMealnetCarbPerc'] = totalnetCarbs ? ((totalnetCarbs / totalMealMacros) * 100).toFixed(2) : 0;
      // totals['totalMealFiberPerc'] = totalFiber ? ((totalFiber / totalMealMacros) * 100).toFixed(2) : 0;
      // setnutritionLevel(totals);
    }
  }, [editForm?.mealList]);
  return (
    <div>
      <div>
        <FeedBreadcrumbs
          backClick={closePlanHandler}
          selectedTemplateName={{ name: "Meal plan" }}
          formRef={() => {}}
          cancelBtnClass="!border-none !p-2"
          submitBtnClass="!rounded-[8px]"
          marginBottom="!mb-0"
          disabled
          crumbText="Meal Plan"
        />
        <MainHeading title="Meal Plan Summary" description={"Create a new melplan"} />
      </div>
      <div className="w-full  flex-center-between gap-4 mt-[20px] mb-4">
        <div className="w-max min-w-[214px] min-h-[150px] text-center px-[24px] py-8 rounded-[20px] bg-secondary1">
          <Heading
            text={
              Math.ceil(editForm?.macroTargets?.totalTargetCalories) || "0000"
            }
            fontSize="text-[40px]"
            fontweight="!font-[700]"
            color="text-textPrimary"
            className="leading-[48px] mb-[10px] truncate"
          />
          <Text
            text="Daily Calories"
            color="text-whiteGray"
            className={"!text-base tracking-[0.5px]"}
          />
        </div>

        <div className="w-full px-[24px] min-h-[150px] py-8 rounded-[20px] bg-secondary1 ">
          <div className="w-full flex-items-center  rounded-lg overflow-hidden mb-6">
            <Whisper
              followCursor
              placement="top"
              speaker={
                <Popover className={`!bg-purpalBg`}>
                  <Text
                    text={`Protein (${
                      editForm?.macroTargets?.unit === "gram"
                        ? editForm?.nutritionLevel?.protein + "g"
                        : editForm?.nutritionLevel?.totalMealProtienPerc + "%"
                    })`}
                    color="text-secondary1"
                    className={" !whitespace-nowrap"}
                  />
                </Popover>
              }
            >
              <div
                className={` h-[48px] relative bg-purpalLight `}
                style={{
                  width: `${editForm?.macroTargets?.proteins}%`,
                }}
              >
                <div
                  className="h-full  bg-purpalBg text-left"
                  style={{
                    width: `${
                      editForm?.macroTargets?.unit === "gram"
                        ? editForm?.nutritionLevel?.protein
                        : editForm?.nutritionLevel?.totalMealProtienPerc
                    }%`,
                  }}
                >
                  {editForm?.macroTargets?.proteins >= 18 && (
                    <Text
                      text={`Protein (${
                        editForm?.macroTargets?.unit === "gram"
                          ? editForm?.macroTargets?.proteins + "g"
                          : editForm?.macroTargets?.proteins + "%"
                      })`}
                      color="text-white"
                      className={
                        "!text-base !whitespace-nowrap absolute left-[24px] top-[13px] "
                      }
                    />
                  )}
                </div>
              </div>
            </Whisper>
            <Whisper
              followCursor
              placement="top"
              speaker={
                <Popover className={`!bg-successColor2 `}>
                  <Text
                    text={`Carbs (${
                      editForm?.macroTargets?.unit === "gram"
                        ? editForm?.nutritionLevel?.carbs + "g"
                        : editForm?.nutritionLevel?.totalMealCarbPerc + "%"
                    }`}
                    color="text-secondary1"
                    className={" !whitespace-nowrap"}
                  />
                </Popover>
              }
            >
              <div
                className={` h-[48px] relative overflow-hidden  bg-successLight`}
                style={{
                  width: `${editForm?.macroTargets?.carbohydrates}%`,
                }}
              >
                <div
                  className="h-full text-left  bg-successColor2"
                  style={{
                    width: `${
                      editForm?.macroTargets?.unit === "gram"
                        ? editForm?.nutritionLevel?.carbs
                        : editForm?.nutritionLevel?.totalMealCarbPerc
                    }%`,
                  }}
                >
                  {editForm?.macroTargets?.carbohydrates >= 18 && (
                    <Text
                      text={`Carbs (${
                        editForm?.macroTargets?.unit === "gram"
                          ? editForm?.macroTargets?.carbohydrates + "g"
                          : editForm?.macroTargets?.carbohydrates + "%"
                      })`}
                      color="text-white"
                      className={
                        "!text-base !whitespace-nowrap absolute left-[24px] top-[13px]"
                      }
                    />
                  )}
                </div>
              </div>
            </Whisper>

            <Whisper
              followCursor
              placement="top"
              speaker={
                <Popover className={`!bg-pinkBg`}>
                  <Text
                    text={`Fat (${
                      editForm?.macroTargets?.unit === "gram"
                        ? editForm?.nutritionLevel?.fat + "g"
                        : editForm?.nutritionLevel?.totalMealFatPerc + "%"
                    })`}
                    color="text-secondary1"
                    className={" !whitespace-nowrap"}
                  />
                </Popover>
              }
            >
              <div
                className={` h-[48px] relative !bg-pinkLight`}
                style={{
                  width: `${editForm?.macroTargets?.fat}%`,
                }}
              >
                <div
                  className={`bg-pinkBg text-left  h-full `}
                  style={{
                    width: `${
                      editForm?.macroTargets?.unit === "gram"
                        ? editForm?.nutritionLevel?.fat
                        : editForm?.nutritionLevel?.totalMealFatPerc
                    }%`,
                  }}
                >
                  {editForm?.macroTargets?.fat >= 18 && (
                    <Text
                      text={`Fat (${
                        editForm?.macroTargets?.unit === "gram"
                          ? editForm?.macroTargets?.fat + "g"
                          : editForm?.macroTargets?.fat + "%"
                      })`}
                      color="text-white"
                      className={
                        "!text-base !whitespace-nowrap absolute left-[24px] top-[13px]"
                      }
                    />
                  )}
                </div>
              </div>
            </Whisper>
          </div>
          <div
            onClick={() => {
              setOpenNutrition(!openNutrition);
            }}
            className="w-max cursor-pointer flex-items-center gap-[10px] ml-auto text-right"
          >
            <Text
              text={`${openNutrition ? "Close" : "Open"} Nutrition Summary`}
              className={"!text-base "}
            />
            {openNutrition ? <ArrowUp /> : <ArrowDown />}
          </div>
        </div>
      </div>
      <div className="w-full relative  flex-items-start gap-4 ">
        <Meals
          setMealsData={setMealsData}
          mealsData={mealsData}
          nutritionLevel={nutritionLevel}
        />
        {openNutrition && (
          <div className="w-[364px] sticky top-[10px] flex-shrink-0 flex-column gap-4">
            <Animation.Collapse in={openNutrition} dimension="height">
              <div className="w-full px-[20px] py-6 rounded-[20px] bg-background7 ">
                <Heading
                  text="Nutrition Levels"
                  fontSize="text-[18px]"
                  fontweight="!font-[400]"
                  color="text-white"
                  className="leading-[44px] mb-6"
                />

                {[
                  "protein",
                  "sodium",
                  "potassium",
                  "netCarbs",
                  "fiber",
                  "fat",
                  "carbs",
                ].map((name, index) => (
                  <div
                    key={index}
                    className="w-full grid grid-cols-2 gap-2 mb-4"
                  >
                    <Text
                      text={name}
                      fontSize="16px"
                      className="text-left capitalize"
                    />
                    <Text
                      text={nutritionLevel[name] + "g"}
                      color="text-text6"
                      className="text-right"
                    />
                  </div>
                ))}

                <div className="w-full grid grid-cols-2 gap-1">
                  <Text
                    text="Total Target Calories"
                    fontSize="16px"
                    className="text-left"
                  />
                  <Text
                    text={`${
                      editForm?.nutritionLevel?.totalCalories || "000.00"
                    } cal`}
                    color="text-primary1"
                    className="text-right !font-medium"
                  />
                </div>
              </div>
            </Animation.Collapse>

            {editForm?.isClientView && false && (
              <ClientNotes editForm={editForm} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MealPlanSummary;

/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chats: null,
  activeChat: {},
  readChats: null,
  unReadChats: null,
};

export const inbox = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    setUserChatDetails: (state, action) => {
      state.chats = action.payload;
    },
    setActiveChatReducer: (state, action) => {
      state.activeChat = action.payload;
    },
    deleteChatReducer: (state, action) => {
      state.chats = state.chats?.filter((chat) => chat.id !== action.payload);
    },
    setActiveReadChatStatus: (state, action) => {
      state.chats = state.chats?.map((chat) => {
        if (chat.id === action.payload?.chatId) {
          return {
            ...chat,
            messages: chat.messages?.map((message) => {
              if (message?.senderId !== action.payload?.uid) {
                return { ...message, isRead: true };
              } else {
                return { ...message, isRead: false };
              }
            }),
          };
        } else {
          return chat;
        }
      });
    },
  },
});

export const { setUserChatDetails, setActiveChatReducer, setActiveReadChatStatus, deleteChatReducer } = inbox.actions;

export default inbox.reducer;

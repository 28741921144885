import React from "react";

export const NewAI = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="113"
      height="114"
      viewBox="0 0 113 114"
      fill="none"
    >
      <rect
        y="0.5"
        width="113"
        height="113"
        rx="10"
        fill="#C0F121"
        fill-opacity="0.2"
      />
      <path
        d="M75.0045 42.3866L77.3584 41.8158C78.2139 41.6083 78.2139 40.3917 77.3584 40.1842L75.0045 39.6134C74.6991 39.5394 74.4606 39.3009 74.3866 38.9955L73.8158 36.6416C73.6213 35.8396 72.5398 35.7895 72.2345 36.4912C72.2141 36.538 72.1972 36.5881 72.1842 36.6416L71.6134 38.9955C71.544 39.2818 71.3301 39.5093 71.0519 39.5977C71.0334 39.6035 71.0146 39.6088 70.9955 39.6134L68.6416 40.1842C67.8396 40.3787 67.7895 41.4602 68.4912 41.7655C68.538 41.7859 68.5881 41.8028 68.6416 41.8158L70.9955 42.3866C71.2627 42.4514 71.4787 42.642 71.5782 42.8933C71.5924 42.9292 71.6042 42.9663 71.6134 43.0045L72.1842 45.3584C72.3917 46.2139 73.6083 46.2139 73.8158 45.3584L74.3866 43.0045C74.4606 42.6991 74.6991 42.4606 75.0045 42.3866Z"
        fill="#C0F121"
        fill-opacity="0.7"
      />
      <path
        d="M66.6036 29.1092L68.4867 28.6526C69.1711 28.4867 69.1711 27.5133 68.4867 27.3474L66.6036 26.8908C66.3593 26.8315 66.1685 26.6407 66.1092 26.3964L65.6526 24.5133C65.497 23.8717 64.6318 23.8316 64.3876 24.393C64.3713 24.4304 64.3578 24.4705 64.3474 24.5133L63.8908 26.3964C63.8352 26.6255 63.6641 26.8074 63.4416 26.8781C63.4267 26.8828 63.4117 26.8871 63.3964 26.8908L61.5133 27.3474C60.8717 27.503 60.8316 28.3682 61.393 28.6124C61.4304 28.6287 61.4705 28.6422 61.5133 28.6526L63.3964 29.1092C63.6102 29.1611 63.783 29.3136 63.8625 29.5146C63.8739 29.5434 63.8833 29.5731 63.8908 29.6036L64.3474 31.4867C64.5133 32.1711 65.4867 32.1711 65.6526 31.4867L66.1092 29.6036C66.1685 29.3593 66.3593 29.1685 66.6036 29.1092Z"
        fill="#C0F121"
        fill-opacity="0.4"
      />
      <path
        d="M63.3176 65.9076L72.4977 63.6815C75.8341 62.8725 75.8341 58.1275 72.4977 57.3185L63.3176 55.0924C62.1264 54.8036 61.1964 53.8736 60.9076 52.6824L58.6815 43.5022C57.9231 40.3744 53.7052 40.1789 52.5144 42.9158C52.435 43.0982 52.369 43.2937 52.3185 43.5022L50.0924 52.6824C49.8216 53.7991 48.9873 54.6863 47.9026 55.0309C47.8303 55.0538 47.7568 55.0744 47.6824 55.0924L38.5022 57.3185C35.3744 58.0769 35.179 62.2948 37.9158 63.4856C38.0982 63.565 38.2937 63.631 38.5023 63.6815L47.6824 65.9076C48.7247 66.1603 49.567 66.904 49.9548 67.8839C50.0102 68.0239 50.0563 68.1687 50.0924 68.3176L52.3185 77.4978C53.1275 80.8341 57.8725 80.8341 58.6815 77.4977L60.9076 68.3176C61.1964 67.1265 62.1264 66.1964 63.3176 65.9076Z"
        fill="#C0F121"
      />
    </svg>
  );
};

import React from 'react';

export const Paragraph = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M3 6C3 5.80109 3.07902 5.61032 3.21967 5.46967C3.36032 5.32902 3.55109 5.25 3.75 5.25H20.25C20.4489 5.25 20.6397 5.32902 20.7803 5.46967C20.921 5.61032 21 5.80109 21 6C21 6.19891 20.921 6.38968 20.7803 6.53033C20.6397 6.67098 20.4489 6.75 20.25 6.75H3.75C3.55109 6.75 3.36032 6.67098 3.21967 6.53033C3.07902 6.38968 3 6.19891 3 6ZM20.25 9H3.75C3.55109 9 3.36032 9.07902 3.21967 9.21967C3.07902 9.36032 3 9.55109 3 9.75C3 9.94891 3.07902 10.1397 3.21967 10.2803C3.36032 10.421 3.55109 10.5 3.75 10.5H20.25C20.4489 10.5 20.6397 10.421 20.7803 10.2803C20.921 10.1397 21 9.94891 21 9.75C21 9.55109 20.921 9.36032 20.7803 9.21967C20.6397 9.07902 20.4489 9 20.25 9ZM20.25 12.75H3.75C3.55109 12.75 3.36032 12.829 3.21967 12.9697C3.07902 13.1103 3 13.3011 3 13.5C3 13.6989 3.07902 13.8897 3.21967 14.0303C3.36032 14.171 3.55109 14.25 3.75 14.25H20.25C20.4489 14.25 20.6397 14.171 20.7803 14.0303C20.921 13.8897 21 13.6989 21 13.5C21 13.3011 20.921 13.1103 20.7803 12.9697C20.6397 12.829 20.4489 12.75 20.25 12.75ZM20.25 16.5H3.75C3.55109 16.5 3.36032 16.579 3.21967 16.7197C3.07902 16.8603 3 17.0511 3 17.25C3 17.4489 3.07902 17.6397 3.21967 17.7803C3.36032 17.921 3.55109 18 3.75 18H20.25C20.4489 18 20.6397 17.921 20.7803 17.7803C20.921 17.6397 21 17.4489 21 17.25C21 17.0511 20.921 16.8603 20.7803 16.7197C20.6397 16.579 20.4489 16.5 20.25 16.5Z"
        fill="#C0F121"
      />
    </svg>
  );
};

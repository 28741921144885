import React from "react";
import { Info } from "../../FeedLibraryIcon";
import { Text } from "../../utils";

const Index = ({ text }) => {
  return (
    <div className="relative tooltip-box">
      <div
        id="tooltip-disabled"
        className="absolute top-[40px] left-[-40px] z-10"
      >
        <div className="!w-full sm:!w-[564px] !rounded-[20px]  !px-[29px] !py-[28px] tooltip-content-box">
          <Text
            text={text}
            className="!leading-6"
            fontSize="!text-[16px]"
            color="text-textPrimary"
          />
        </div>
      </div>
      <span className="cursor-pointer tooltip-btn">
        <Info />
      </span>
    </div>
  );
};
export default Index;

import {
  configureStore,
  createSerializableStateInvariantMiddleware,
} from "@reduxjs/toolkit";
import uiSettings from "./reducers/uiSettings";
import supplementPlan from "./reducers/supplementPlan";
import common from "./reducers/common";
import workoutPlan from "./reducers/workoutPlan";
import mealPlan from "./reducers/mealPlan";
import inbox from "./reducers/inbox";
import template from "./reducers/template";
import myClient from "./reducers/myClients";
import permissions from "./reducers/permissions";

const middleware = [
  // Add the serializable state invariant middleware
  createSerializableStateInvariantMiddleware({
    // Optionally, define additional ignored paths if needed
    ignoredPaths: ["inbox.chats.0.createdAt"],
    // Optionally, define a custom serialization function for specific types
    isSerializable: (value) => typeof value !== "function",
  }),
  // Add other middleware as needed
  // For example: thunk middleware, logger middleware, etc.
];

const store = configureStore({
  reducer: {
    uiSettings,
    common,
    supplementPlan,
    workoutPlan,
    inbox,
    template,
    myClient,
    mealPlan,
    permissions,
  },
  middleware,
});

export default store;

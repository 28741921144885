import React, { useEffect, useState } from "react";
import { Panel } from "rsuite";

import MealForm from "./component/mealForm";
import MealDropdown from "./component/mealDropdown";
import {
  ArrowDown,
  ArrowUp,
  CopyIcon,
  DeleteIcon,
  FavIcon,
  FoodFavIcon,
  Plus,
} from "../../../../../FeedLibraryIcon";
import { Button, ToggleButton, Text, Heading } from "../../../../../utils";
import MealsModal from "./component/mealsModal";
import { FoodListTable } from "./component/foodListTable";
import { FoodListColumn, mealList } from "../../../../../schema/mealPlan";
import { useDispatch, useSelector } from "react-redux";
import { seteditFormReducer } from "../../../../../store/reducers/uiSettings";
import { generateUniqueId } from "../../../../../helper";

const Meals = (props) => {
  const { mealsData, setMealsData, nutritionLevel } = props;
  const dispatch = useDispatch();
  const [check, setCheck] = useState();
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [showAccordion, setShowAccordion] = useState(0);
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedMealFoods, setselectedMealFoods] = useState([]);
  const [selectedMeal, setselectedMeal] = useState([]);
  const [dataChange, setdataChange] = useState([]);
  const [selectedFoodData, setselectedFoodData] = useState([]);
  const uiSettings = useSelector((state) => state.uiSettings);
  const { editForm } = uiSettings;

  useEffect(() => {
    if (uiSettings?.formName !== "view-template") {
      dispatch(
        seteditFormReducer({
          type: uiSettings?.formName || "create-meal",
          data: {
            ...editForm,
            nutritionLevel: nutritionLevel,
            mealList: mealsData,
          },
        }),
      );
    }
    setselectedMeal(mealsData?.[0]);
  }, [mealsData, nutritionLevel]);

  useEffect(() => {
    // console.log("In useEffect[dataChange] wala...");
    // console.log("dataChange ===> ", dataChange);
    // console.log("mealsData ===> ", mealsData);
    // console.log("selectedMeal ===> ", selectedMeal);

    if (dataChange?.docId) {
      setMealsData(
        mealsData?.map((meal) => {
          // if (meal.id === selectedMeal.id) {
            return {
              ...meal,
              foodList: meal?.foodList?.map((food) => {
                if (food.docId === dataChange.docId) {
                  return dataChange;
                } else {
                  return food;
                }
              }),
            };
          // } else {
          //   return meal;
          // }
        }),
      );
    }
  }, [dataChange]);

  const addNewMeal = () => {
    const totalMeal = mealsData?.length + 1;
    setShowAccordion(totalMeal - 1);
    setMealsData([
      ...mealsData,
      { ...mealList?.[0], id: totalMeal, title: "Meal " + totalMeal },
    ]);
  };

  const deleteMeal = (mealId) => {
    setMealsData(mealsData?.filter((f) => f.id !== mealId));
  };

  const duplicateMeal = (item) => {
    const totalMeal = mealsData?.length + 1;
    setShowAccordion(totalMeal - 1);
    const copyNumbers = mealsData
      .filter((meal) => meal.title.startsWith(item.title + " Copy"))
      .map((meal) => parseInt(meal.title.match(/\((\d+)\)/)?.[1] || 0, 10));
    const highestCopyNumber = Math.max(...copyNumbers, 0);
    const newCopyNumber = highestCopyNumber + 1;
    setMealsData([
      ...mealsData,
      {
        ...item,
        id: totalMeal,
        title: `${item?.title} Copy${
          newCopyNumber > 0 ? `(${newCopyNumber})` : ""
        }`,
      },
    ]);
  };

  const deleteFoodUnderMeal = (selectedItem) => {
    setMealsData(
      mealsData?.map((mealItem) => {
        if (mealItem.id === selectedItem.id) {
          return {
            ...mealItem,
            foodList: mealItem.foodList?.filter(
              (obj1) =>
                !selectedMealFoods.some((obj2) => obj2.docId === obj1.docId),
            ),
          };
        } else {
          return mealItem;
        }
      }),
    );
  };
  
  const copyFoodUnderMeal = (selectedItem) => {
    setMealsData(
      mealsData?.map((mealItem) => {
        if (mealItem.id === selectedItem.id) {
          return {
            ...mealItem,
            foodList: [
              ...mealItem.foodList,
              ...selectedMealFoods?.map((food) => {
                return {
                  ...food,
                  docId: generateUniqueId(),
                  id: generateUniqueId(),
                };
              }),
            ],
          };
        } else {
          return mealItem;
        }
      }),
    );
  };

  const addFavoriteFood = (meal) => {
    setMealsData(
      mealsData?.map((item) => {
        if (item.id === meal.id) {
          return {
            ...item,
            favorite: !item.favorite,
          };
        } else {
          return meal;
        }
      }),
    );
  };

  return (
    <div className={`w-full  `}>
      {mealsData?.map((meal, index) => (
        <Panel
          key={index}
          expanded={showAccordion === index ? true : false}
          collapsible
          className="bg-secondary1  !rounded-[20px] !overflow-visible pnal-accordion mb-[16px]"
          header={
            <div className="w-full">
              <div className="w-full flex items-center gap-4 !mb-6">
                <div className="w-full flex items-center justify-between !p-0 ">
                  <Heading
                    text={meal.title}
                    color="text-textPrimary"
                    fontSize="text-[22px]"
                    fontweight="font-medium"
                    className="leading-[40px] "
                  />
                  <div eventKey="0" className="flex items-center gap-[10px]">
                    {meal.favorite && (
                      <span
                        className="mr-3"
                        onClick={() => addFavoriteFood(meal)}
                      >
                        <FavIcon />
                      </span>
                    )}

                    <Button
                      text={`Show ${showAccordion === index ? "less" : "more"}`}
                      color="text-textPrimary"
                      className="!w-max !h-auto !p-0 !text-base !leading-[22px]  !border-none"
                      cta={() => {
                        setselectedMeal(meal);
                        setShowAccordion(
                          showAccordion === index ? false : index,
                        );
                      }}
                    />
                    {showAccordion === index ? <ArrowUp /> : <ArrowDown />}
                  </div>
                </div>
                {uiSettings?.formName !== "view-template" && (
                  <MealDropdown
                    meal={meal}
                    deleteMeal={deleteMeal}
                    duplicateMeal={duplicateMeal}
                    addFavoriteFood={addFavoriteFood}
                  />
                )}
              </div>
              <MealForm
                meal={meal}
                showAccordion={showAccordion}
                index={index}
                setMealsData={setMealsData}
                mealsData={mealsData}
              />
            </div>
          }
        >
          {uiSettings?.formName !== "view-template" && (
            <Button
              text="+ Add Food Item"
              type="button"
              bg="!bg-primary1"
              className="!px-[14px] !py-[7px] !w-max flex  !justify-center rounded-lg !text-sm !mt-6"
              color="text-background"
              cta={() => {
                setselectedMeal(meal);
                setShowSidebar(true);
              }}
            />
          )}
          <div
            className={`!p-0 !pt-6 ${
              uiSettings?.formName === "view-template"
                ? "pointer-events-none"
                : "pointer-events-auto"
            }`}
          >
            <Text text="Food List" className={"tracking-[0.5px] mb-2"} />
            <div className="flex justify-between items-center">
              <div className="w-max flex items-center  gap-4 mb-4">
                <Text text="See Full Nutrition Details" color={"text-white"} />
                <div className="">
                  <ToggleButton
                    checked={check}
                    onChange={setCheck}
                    className="custom-check"
                  />
                </div>
              </div>
              <div className="flex items-center gap-4">
                <span
                  className="!cursor-pointer"
                  onClick={() => deleteFoodUnderMeal(meal)}
                >
                  <DeleteIcon color={!selectedMealFoods?.length && "#7D7D7D"} />
                </span>
                <span
                  className="!cursor-pointer"
                  onClick={() => {
                    if (selectedMealFoods?.length) copyFoodUnderMeal(meal);
                  }}
                >
                  <CopyIcon color={!selectedMealFoods?.length && "#7D7D7D"} />
                </span>
              </div>
            </div>

            <FoodListTable
              tableColumns={
                check ? FoodListColumn : FoodListColumn?.slice(0, 7)
              }
              tabledata={meal?.foodList?.map((food) => {
                return {
                  ...food,
                  label: food?.notes,
                };
              })}
              setSelectedData={setselectedMealFoods}
              setCheckedKeys={setCheckedKeys}
              checkedKeys={checkedKeys}
              selectedData={selectedMealFoods}
              setdataChange={setdataChange}
              setselectedMeal={setselectedMeal}
              isMealView
            />
          </div>
        </Panel>
      ))}

      <Button
        Icon={<Plus w="16" h="16" />}
        text={"Add Meal"}
        className="w-full !h-[88px] flex gap-[8px]  !px-[20px] !py-[24px] font-normal rounded-[20px]  text-[18px] leading-10"
        bg="bg-background7"
        color="text-whiteGray"
        type="button"
        cta={addNewMeal}
      />

      <MealsModal
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        selectedMeal={selectedMeal}
        setMealsData={setMealsData}
        mealsData={mealsData}
        setSelectedData={setselectedFoodData}
        selectedData={selectedFoodData}
      />
    </div>
  );
};

export default Meals;

import {
  query,
  collection,
  getDocs,
  addDoc,
  where,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../configs/firebase";
import {
  algoliaClient,
  ALGOLIA_FOOD_ITEMS_INDEX_ALPHA_REPLICA,
} from "../configs/algolia";

// const getTotalFoodItemContext = async () => {
//   try {
//     const coll = collection(db, "foodItems");
//     const snapshot = await getCountFromServer(coll);
//     const totalCount = snapshot.data().count;
//     console.log("count: ", totalCount);
//     return totalCount;
//   } catch (error) {
//     console.error("Error searching of total foods:", error);
//   }
// };

const getTotalFoodItemContext = async (searchString) => {
  try {
    const algoliaFoodItemsIndexAlphaReplica = algoliaClient.initIndex(
      ALGOLIA_FOOD_ITEMS_INDEX_ALPHA_REPLICA
    );

    // Searching an empty index returns some metadata about the index, including the number of entries
    const data = await algoliaFoodItemsIndexAlphaReplica.search(searchString);

    console.log("count: ", data.nbHits);

    return data.nbHits;
  } catch (error) {
    console.error("Error searching of total foods:", error);
  }
};

// const getFoodListItemContext = async (pageNumber, pageSize, searchString) => {
//   try {
//     let offset = 1;
//     if (pageNumber !== 1 && !searchString) {
//       offset = (pageNumber - 1) * pageSize;
//     }

//     const userFoodRef = collection(db, "foodItems");
//     let q = userFoodRef;
//     if (searchString) {
//       const capitalizeQueryString =
//         searchString.charAt(0).toUpperCase() + searchString.slice(1);
//       console.log("searchString", capitalizeQueryString);
//       q = query(
//         q,
//         where("name", ">=", capitalizeQueryString),
//         where("name", "<=", capitalizeQueryString + "\uf8ff")
//       );
//     }
//     console.log("offset", offset);
//     q = query(q, orderBy("id"), startAfter(offset), limit(pageSize));

//     const querySnapshot = await getDocs(q);

//     const data = querySnapshot.docs.map((doc) => ({
//       docId: doc.id,
//       ...doc.data(),
//     }));
//     console.log("data", data);
//     return data;
//   } catch (error) {
//     console.error("Error searching for foods:", error);
//     throw error;
//   }
// };

const getFoodListItemContext = async (pageNumber, pageSize, searchString) => {
  try {
    const algoliaFoodItemsIndexAlphaReplica = algoliaClient.initIndex(
      ALGOLIA_FOOD_ITEMS_INDEX_ALPHA_REPLICA
    );

    await algoliaFoodItemsIndexAlphaReplica.setSettings({
      customRanking: ["asc(id)"],
      ranking: ["custom", "typo", "words", "filters", "attribute", "exact"],
    });

    let offset = 0;
    if (pageNumber !== 0) {
      offset = (pageNumber - 1) * pageSize;
    }
    console.log("pageNumber", pageNumber);
    console.log("offset", offset);
    console.log("pageSize", pageSize);

    let data = await algoliaFoodItemsIndexAlphaReplica.search(searchString, {
      page: pageNumber - 1,
      hitsPerPage: pageSize,
      // offset,
    });

    data = data.hits.map((hit) => ({ docId: hit.objectID, ...hit }));

    console.log("data", data);

    return data;
  } catch (error) {
    console.error("Error searching for foods:", error);
    throw error;
  }
};

const createOrReplaceDocContext = async (collectionName, payload) => {
  const { foodId, coachId } = payload;
  const collectionRef = collection(db, collectionName);
  const q = query(
    collectionRef,
    where("foodId", "==", foodId),
    where("coachId", "==", coachId)
  );
  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const deletions = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deletions);
      console.log(`Deleted documents with foodId ${foodId}`);
      return { status: "deleted", foodId };
    } else {
      const docRef = await addDoc(collectionRef, payload);
      console.log(`New document created with ID ${docRef.id}`);
      return { status: "created", id: docRef.id };
    }
  } catch (error) {
    console.error("Error processing document:", error);
    return { status: "error", error };
  }
};

export {
  getFoodListItemContext,
  getTotalFoodItemContext,
  createOrReplaceDocContext,
};

/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  allworkout: [],
};

export const workout = createSlice({
  name: "workout",
  initialState,
  reducers: {
    setAllworkoutReducer: (state, action) => {
      state.allworkout = action.payload;
    },
    deleteWorkoutReducer: (state, action) => {
      state.allworkout = state.allworkout?.filter(
        (f) => f?.docId !== action.payload
      );
    },
    addWorkoutPlanReducer: (state, action) => {
      state.allworkout = [action.payload, ...state.allworkout];
    },
    updateWorkoutPlanReducer: (state, action) => {
      state.allworkout = state?.allworkout?.map((temp) => {
        if (temp?.docId === action.payload?.docId) {
          return action.payload;
        } else {
          return temp;
        }
      });
    },
  },
});

export const {
  setAllworkoutReducer,
  deleteWorkoutReducer,
  addWorkoutPlanReducer,
  updateWorkoutPlanReducer,
} = workout.actions;

export default workout.reducer;

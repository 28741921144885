/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openModel: false,
  btnloader: false,
  uiloader: false,
  formName: '',
  templateName: {},
  editForm: {},
  existingPlan: null,
  existingWorkout: null,
};

export const uiSettings = createSlice({
  name: 'ui-settings',
  initialState,
  reducers: {
    setFormModal: (state, action) => {
      state.openModel = action.payload;
    },
    setExistingPlanReducer: (state, action) => {
      state.existingPlan = action.payload;
    },
    setExistingWorkoutReducer: (state, action) => {
      state.existingWorkout = action.payload;
    },
    setbtnLoaderReducer: (state, action) => {
      state.btnloader = action.payload;
    },
    setUILoaderReducer: (state, action) => {
      state.uiloader = action.payload;
    },
    seteditFormReducer: (state, action) => {
      state.editForm = action.payload?.data || '';
      state.formName = action.payload?.type || '';
    },
    setTemplateNameReducer: (state, action) => {
      state.templateName = action.payload;
    },
  },
});

export const { setExistingPlanReducer, setExistingWorkoutReducer, setbtnLoaderReducer, setFormModal, setTemplateNameReducer, setUILoaderReducer, seteditFormReducer } =
  uiSettings.actions;

export default uiSettings.reducer;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";

import FeedBreadcrumbs from "../../../components/common/breadcrumbs";
import { MainHeading } from "../../../components";
import { FeedInput, Heading, LabelFeed, Text, TextArea } from "../../../utils";
import {
  CheckRadioIcon,
  CrossIcon,
  NewAI,
  ScratchIcon,
  UnCheckRadioIcon,
} from "../../../FeedLibraryIcon";
import { seteditFormReducer } from "../../../store/reducers/uiSettings";

const SteppeOne = ({
  closePlanHandler,
  formRef,
  setStepper,
  setplanScreenState,
  selectedPlan,
  setSelectedPlan,
}) => {
  const { uiSettings } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <>
      <div className="">
        <FeedBreadcrumbs
          backClick={closePlanHandler}
          selectedTemplateName={{ name: "Workouts" }}
          formRef={formRef}
          cancelBtnClass="!border-none !p-2"
          submitBtnClass="!rounded-[8px]"
          marginBottom="!mb-0"
          disabled={true}
          crumbText="Workout Plan"
        />
        <MainHeading
          title="Create Workout Plan Template"
          description={
            "Welcome to the Kaboodle Workout Plan Builder. Please complete all steps and proceed using the buttons at the bottom of each screen."
          }
        />
      </div>

      <div className="mt-6 w-full  ">
        <Formik
          initialValues={{
            name: uiSettings?.editForm?.name || "",
            description: uiSettings?.editForm?.description || "",
          }}
          // enableReinitialize
          innerRef={formRef}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            dispatch(
              seteditFormReducer({
                type: uiSettings?.formName || "create-meal",
                data: {
                  ...uiSettings.editForm,
                  ...values,
                  method: selectedPlan,
                },
              }),
            );
            setStepper([1, 2]);
            if (selectedPlan === "AI") {
              setplanScreenState("fillQuestions");
            } else {
              setplanScreenState("addMovements");
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <>
              <form
                onSubmit={handleSubmit}
                className={`flex-column w-full ${
                  uiSettings?.formName === "view-template"
                    ? "pointer-events-none"
                    : "pointer-events-auto"
                }`}
              >
                <div className="w-full grid grid-cols-2 gap-[24px]">
                  <div className="w-full h-full">
                    <div className="w-full flex-items-start-column mb-6 autofill-fix">
                      <LabelFeed
                        text={
                          <>
                            Workout Plan Name{" "}
                            <span className="text-primary1">*</span>
                          </>
                        }
                        className={"mb-[10px] !font-medium !text-lg"}
                      />
                      <FeedInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        setFieldValue={setFieldValue}
                        type="text"
                        placeholder="Add Name"
                        name="name"
                        crossicon={values?.name ? <CrossIcon /> : ""}
                        className="w-full  !px-5 !bg-secondary1"
                      />
                      {errors.name && touched.name && (
                        <div className="text-red text-[14px]  pt-[4px]">
                          {errors.name}
                        </div>
                      )}
                    </div>

                    <div className="w-full h-full flex-items-start-column ">
                      <LabelFeed
                        text="Description"
                        className={"mb-[12px] !font-medium !text-lg"}
                      />
                      <div className="w-full  min-h-[356px] px-[12px] py-6 rounded-[20px] bg-secondary1">
                        <TextArea
                          placeholder="Add Instruction"
                          rows={5}
                          name={"description"}
                          setFieldValue={setFieldValue}
                          onChange={handleChange}
                          value={values.description}
                          onBlur={handleBlur}
                          className="leading-[24px] h-full !p-0 text-[16px] font-normal text-text6 !outline-none !border-none !bg-transparent"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <LabelFeed
                      text={
                        <>
                          Select Plan Building Method{" "}
                          <span className="text-primary1">*</span>
                        </>
                      }
                      className={"mb-[10px] !font-medium !text-lg"}
                    />
                    <div className="w-full flex flex-col gap-[16px]">
                      <div
                        className={`relative w-full min-h-[222px] cursor-pointer flex-center-center bg-secondary1 p-[36px] rounded-[8px] ${
                          selectedPlan === "AI" && "border !border-background8"
                        } `}
                        onClick={() => {
                          setSelectedPlan("AI");
                        }}
                      >
                        <div className="absolute top-[16px] right-[16px] text-white">
                          <span>
                            {selectedPlan === "AI" ? (
                              <CheckRadioIcon />
                            ) : (
                              <UnCheckRadioIcon />
                            )}
                          </span>
                        </div>
                        <div className="flex flex-center-start gap-[24px]">
                          <NewAI />
                          <div className="">
                            <Heading
                              text={"A.I. Assisted (Beta)"}
                              fontSize="!text-[24px]"
                              color="text-white"
                              fontweight="!font-normal"
                              className="!leading-[24px] mb-[16px]"
                            />
                            <div className="w-[443px]">
                              <Text
                                text={`Leveraging our proprietary Kaboodle A.I. you can have the plan drafted for you and then make tweaks before saving it as a template or applying to a client.`}
                                color="text-text8"
                                fontSize={"!text-[16px]"}
                                className={"!font-normal !leading-[24px]"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`relative w-full cursor-pointer min-h-[222px] flex items-center justify-center bg-secondary1 p-[36px] rounded-[8px] ${
                          selectedPlan === "Scratch" &&
                          "border !border-background8"
                        } `}
                        onClick={() => {
                          setSelectedPlan("Scratch");
                        }}
                      >
                        <div className="absolute top-[16px] right-[16px] text-white">
                          <span>
                            {selectedPlan === "Scratch" ? (
                              <CheckRadioIcon />
                            ) : (
                              <UnCheckRadioIcon />
                            )}
                          </span>
                        </div>
                        <div className="w-full flex items-center justify-start gap-[24px]">
                          <ScratchIcon />

                          <div className="">
                            <Heading
                              text={"Start From Scratch"}
                              fontSize="!text-[24px]"
                              color="text-white"
                              fontweight="!font-normal"
                              className="!leading-[24px] mb-[16px]"
                            />
                            <div className="w-[443px]">
                              <Text
                                text={`If you'd rather make the plan from scratch this option is for you. In this state you'll be writing in the workouts and optionally providing video links to guide your clients.`}
                                color="text-text8"
                                fontSize={"!text-[16px]"}
                                className={"!font-normal !leading-[24px]"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SteppeOne;

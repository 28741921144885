/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedClient: null,
  clientMealPlan: null,
  clientWorkout: null,
  clientSupplement: null,
  clientCheckIn: null,
  clientIntake: null,
};

export const myClient = createSlice({
  name: "myClient",
  initialState,
  reducers: {
    setSelectedClientReducder: (state, action) => {
      state.selectedClient = action.payload;
    },
    setSelectedClientMealPlan: (state, action) => {
      state.clientMealPlan = action.payload;
    },
    setSelectedClientWorkout: (state, action) => {
      state.clientWorkout = action.payload;
    },
    setSelectedClientSupplement: (state, action) => {
      state.clientSupplement = action.payload;
    },
    addClientSupplement: (state, action) => {
      state.clientSupplement = [action.payload, ...state.clientSupplement];
    },
    addClientIntakeReducer: (state, action) => {
      state.clientIntake = [...state.clientIntake, action.payload];
    },
    addClientCheckInReducer: (state, action) => {
      state.clientCheckIn = [...state.clientCheckIn, action.payload];
    },
    setClientCheckinForm: (state, action) => {
      state.clientCheckIn = action.payload;
    },
    setClientIntakeForm: (state, action) => {
      state.clientIntake = action.payload;
    },
  },
});

export const {
  setSelectedClientReducder,
  setSelectedClientMealPlan,
  setSelectedClientWorkout,
  setSelectedClientSupplement,
  setClientIntakeForm,
  setClientCheckinForm,
  addClientSupplement,
  addClientIntakeReducer,
  addClientCheckInReducer,
} = myClient.actions;

export default myClient.reducer;

import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Heading, Text } from "../../utils";
import {
  DeleteIcon,
  DotsThreeVerticalIcon,
  PencilIcon,
  DownloadIcon,
  PermissionsIcon,
} from "../../FeedLibraryIcon";
import { seteditFormReducer } from "../../store/reducers/uiSettings";
import { useNavigate } from "react-router-dom";
import { deleteClientAction } from "../../store/actions/myClient";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../configs/firebase";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import UserDrawer from "../../pages/userManagement/component/userDrawer";
import PermissionsFormik from "../../pages/userManagement/component/permissionsFormik";

const ClientStatus = ({ client }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openPermissionsModal, setOpenPermissionsModal] = useState("");

  return (
    <div className="self-stretch rounded-xl bg-secondary1  p-[20px] flex-center-start gap-[10px] text-[24px]">
      <div className="flex-1  flex-center-start">
        <div className=" flex-start-start gap-[20px]">
          <div className="w-[58px] h-[58px] rounded-lg flex-start-center shadow-clientImgShadow overflow-hidden ">
            <img
              className="w-full h-full object-cover "
              src={client?.avatar}
              alt="client"
            />
          </div>
          <div className="flex-start-start-column gap-[12px]">
            <div className=" flex-center-start gap-[20px]">
              <Heading
                text={client?.name}
                fontSize="text-[24px]"
                fontweight="!font-[400]"
                color="text-textPrimary"
                className="leading-[24px]"
              />
              <div className="w-max h-[30px]  flex-center-start px-2 py-1.5 rounded-md bg-activeBackground">
                <Text
                  text={client?.clientType?.name || "N/A"}
                  className=" leading-normal tracking-[-0.056px]"
                />
              </div>
            </div>

            <Text
              text="N/A"
              color="text-text3"
              fontSize={"text-[16px]"}
              className="leading-[16px] tracking-[0.5px]"
            />
          </div>
        </div>
      </div>

      <Dropdown className="navbar-dropdown">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="nav-link nav-dropdown flex-items-baseline gap-2"
        >
          <DotsThreeVerticalIcon w="24px" h="24px" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            className="nav-link flex-items-center gap-[14px]"
            onClick={() => {
              dispatch(seteditFormReducer({ type: "edit-client" }));
              navigate("/my-clients");
            }}
          >
            <PencilIcon />
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            className="nav-link flex-items-center gap-[14px]"
            onClick={async () => {
              await sendPasswordResetEmail(auth, client.email)
                .then(async function () {
                  Swal.fire({
                    title: "Resend Invite?",
                    text: "Are you sure, you want to send invite again.",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    customClass: {
                      confirmButton: "!bg-primary1 !text-background",
                    },
                    background: "#030303",
                    showLoaderOnConfirm: true,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      toast("Invite has been sent successfully");
                    }
                  });
                })
                .catch(function (error) {
                  console.error(error.message);
                });
            }}
          >
            <DownloadIcon />
            Resend Invite
          </Dropdown.Item>
          <Dropdown.Item
            className="nav-link flex-items-center gap-[14px]"
            onClick={() => {
              setOpenPermissionsModal("EditPermissions");
            }}
          >
            <PermissionsIcon color="#C0F121" />
            Edit Permissions
          </Dropdown.Item>
          <Dropdown.Item
            className="nav-link flex-items-center gap-[14px]"
            onClick={() =>
              deleteClientAction(client?.docId, navigate, () => {})
            }
          >
            <DeleteIcon />
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <UserDrawer
        heading={client?.name}
        isOpen={openPermissionsModal}
        footerButton={true}
        isClose={() => setOpenPermissionsModal("")}
      >
        <PermissionsFormik />
      </UserDrawer>
    </div>
  );
};

export default ClientStatus;

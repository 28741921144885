import { Formik } from "formik";
import React, { useState } from "react";
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FeedDropdown,
  FeedInput,
  FeedLink,
  Heading,
  LabelFeed,
  Spinner,
  Text,
} from "../../utils";

import axios from "axios";

import { auth } from "../../configs/firebase";
import SuccessModal from "../model/successModal";
import {
  createDocContext,
  updateDocCollectionContext,
} from "../../Services/commonContext";
import { CrossIcon } from "../../FeedLibraryIcon";
import { generateUniqueId } from "../../helper";
import { getallMyClientAction } from "../../store/actions/common";
import { seteditFormReducer } from "../../store/reducers/uiSettings";
import {
  deleteClientAction,
  updateClientdetailAction,
} from "../../store/actions/myClient";

const CreateClientForm = ({ setAddClient, clientUser, pendingClient }) => {
  const { common, uiSettings } = useSelector((state) => state);
  const { uid, displayName } = auth.currentUser;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [submitType, setSubmitType] = useState("invite");
  const [successMsg, setSuccessMsg] = useState({});

  return (
    <>
      <div className="flex-items-center gap-2 mb-[16px]">
        <FeedLink
          onClick={() => setAddClient(false)}
          text="Clients"
          className="leading-[22px] text-text3 hover:text-text3"
        />
        <span className="text-white">/</span>
        <FeedLink
          path="#"
          text="Invite Client"
          className="leading-[22px] hover:text-primary1"
        />
      </div>
      <div className="w-full flex-center-between gap-[16px] mb-[48px]">
        <Heading
          text="My Clients"
          fontSize="text-[40px]"
          fontweight="!font-[700]"
          color="text-textPrimary"
          className="leading-[48px]"
        />

        <Button
          text={"Close"}
          cta={() => setAddClient(false)}
          className="px-[24px] py-[7px] !font-normal rounded-xl "
          bg="bg-primary1"
          color="text-background"
        />
      </div>

      <div>
        <Formik
          initialValues={{
            firstName: pendingClient?.firstName || "",
            lastName: pendingClient?.lastName || "",
            email: pendingClient?.email || "",
            phoneNumber: pendingClient?.phoneNumber || "",
            gender: pendingClient?.gender || "",
            clientType:
              common?.clientType?.filter(
                (f) => f.id === pendingClient?.clientType?.id,
              )?.[0]?.id || "",
          }}
          enableReinitialize
          validate={(values) => {
            const errors = {};

            if (!values.firstName) {
              errors.firstName = "Required";
            }
            if (!values.lastName) {
              errors.lastName = "Required";
            }

            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const clientdetail = common?.clientType?.filter(
              (f) => f.id === values.clientType,
            )?.[0];
            if (uiSettings?.formName === "edit-client") {
              await updateClientdetailAction({
                ...pendingClient,
                ...values,
                name: values?.firstName + " " + values.lastName,
                clientType: clientdetail || "N/A",
              });
              toast("Client is updated successfully");
              setAddClient(false);
              dispatch(seteditFormReducer({}));
              return;
            }
            if (
              clientUser?.find(
                (client) =>
                  client.email === values.email && client.status === "Active",
              )
            ) {
              setShow(true);
              setSuccessMsg({
                title: "Error",
                text: "Email address is already in use. Please use a different email address.",
              });
              setSubmitType("");
              return;
            }
            const randomPassword = generateUniqueId();

            const payload = {
              ...values,
              name: values?.firstName + " " + values.lastName,
              userType: "client",
              clientType: clientdetail || "N/A",
              coachId: uid,
              avatar: "https://api.dicebear.com/7.x/thumbs/svg?seed=Callie",
              photoURL: "https://api.dicebear.com/7.x/thumbs/svg?seed=Callie",
            };
            if (submitType === "invite") {
              await createUserWithEmailAndPassword(
                auth,
                values.email,
                randomPassword,
              )
                .then(async function (client) {
                  console.log("hello client: " + JSON.stringify(client) + "\n\n");
                  await axios
                    .post(
                      "https://f4f-server-235d31f8cf51.herokuapp.com/send-email/new-client",
                      {
                        clientEmail: client.user.email,
                        clientFirstName: values?.firstName,
                        coachFirstName: "",
                      },
                    )
                    .then(async function () {
                      let result;
                      if (pendingClient?.docId) {
                        console.log("Yolo 1: Calling updateDocCollectionContext");
                        result = await updateDocCollectionContext(
                          "users",
                          pendingClient?.docId,
                          {
                            ...payload,
                            uid: client?.user?.uid,
                            status: "Active",
                          },
                        );
                      } else {
                        console.log("Yolo 2: Calling createDocContext");
                        result = await createDocContext("users", {
                          ...payload,
                          uid: client?.user?.uid,
                          status: "Active",
                        });
                      }
                      console.log("result ===> ", result)

                      // if (result || result?.id) {
                      if (true) {
                        setShow(true);
                        setSuccessMsg({
                          title: "Success",
                          text: "Account Created Successfully. Email has been sent to the client. Client will need to set a password using the link provided in their email.",
                        });
                        getallMyClientAction(uid);
                        setSubmitting(false);
                      }
                    })
                    .catch(function (error) {
                      console.error(error.message);
                    });
                })
                .catch(function (error) {
                  const errorCode = error.code;
                  if (errorCode === "auth/email-already-in-use") {
                    setSuccessMsg({
                      title: "Error",
                      text: "Email address is already in use. Please use a different email address.",
                    });
                    setSubmitting(false);
                    setShow(true);
                  } else {
                    console.error(error.message);
                  }
                });
            } else {
              // for pending client
              if (
                clientUser?.find(
                  (client) =>
                    client.email === values.email &&
                    client.status === "Pending",
                )
              ) {
                setShow(true);
                setSuccessMsg({
                  title: "Error",
                  text: "Email address is already in use. Please use a different email address.",
                });
                setSubmitType("");
                return;
              }
              const res = await createDocContext("users", {
                ...payload,
                status: "Pending",
              });
              if (res?.id) {
                setShow(true);
                setSuccessMsg({
                  title: "Success",
                  text: "Account Created Successfully but not active yet. You can send an email by editing the information",
                });
                getallMyClientAction(uid);
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="w-full flex-column gap-[24px] p-[20px] rounded-[20px]  !bg-secondary1 mb-[32px]">
                <div className="w-full flex-center-between gap-[16px]">
                  <Text
                    text="Basic Client Information"
                    fontSize="!text-[20px]"
                    color="text-white"
                    className={"leading-[24px]  "}
                  />
                </div>

                <div className="w-full flex-column gap-[16px]">
                  <div className="w-full flex gap-[16px]">
                    <div className="w-full flex-column gap-[16px]">
                      <LabelFeed
                        text={
                          <>
                            First Name <span className="text-primary1">*</span>
                          </>
                        }
                        color="text-text1"
                        className="!leading-[22px]"
                      />
                      <FeedInput
                        type="text"
                        placeholder="Enter First Name Here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        setFieldValue={setFieldValue}
                        name="firstName"
                        crossicon={values.firstName ? <CrossIcon /> : ""}
                        className="w-full !h-[48px] !text-sm placeholder:!text-text2 "
                      />
                      {errors.firstName && touched.firstName && (
                        <LabelFeed
                          text={errors.firstName}
                          color="text-red"
                          className="!leading-[22px] !text-[14px] mt-[-10px]"
                        />
                      )}
                    </div>
                    <div className="w-full flex-column gap-[16px]">
                      <LabelFeed
                        text={
                          <>
                            Last Name <span className="text-primary1">*</span>
                          </>
                        }
                        color="text-text1"
                        className="!leading-[22px]"
                      />
                      <FeedInput
                        type="text"
                        placeholder="Enter Last Name Here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        setFieldValue={setFieldValue}
                        name="lastName"
                        crossicon={values.lastName ? <CrossIcon /> : ""}
                        className="w-full !h-[48px] !text-sm placeholder:!text-text2 "
                      />
                      {errors.lastName && touched.lastName && (
                        <LabelFeed
                          text={errors.lastName}
                          color="text-red"
                          className="!leading-[22px] !text-[14px] mt-[-10px]"
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full flex  gap-[16px] ">
                    <div className="w-full flex-column gap-[16px]">
                      <LabelFeed
                        text={
                          <>
                            Email Address{" "}
                            <span className="text-primary1"> *</span>
                          </>
                        }
                        color="text-text1"
                        className="!leading-[22px]"
                      />
                      <FeedInput
                        type="email"
                        placeholder="Enter Your Email Address Here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        setFieldValue={setFieldValue}
                        name="email"
                        crossicon={values.email ? <CrossIcon /> : ""}
                        className="w-full !h-[48px] !text-sm placeholder:!text-text2  "
                      />

                      {errors.email && touched.email && (
                        <LabelFeed
                          text={errors.email}
                          color="text-red"
                          className="!leading-[22px] !text-[14px] mt-[-10px]"
                        />
                      )}
                    </div>
                  </div>

                  <div className="w-full flex gap-[16px]">
                    <div className="w-full flex-column gap-[16px]">
                      <LabelFeed
                        text="Phone Number"
                        color="text-text1"
                        className="!leading-[22px]"
                      />
                      <FeedInput
                        type="text"
                        placeholder="Enter Phone Number Here"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        setFieldValue={setFieldValue}
                        name="phoneNumber"
                        crossicon={values.phoneNumber ? <CrossIcon /> : ""}
                        className="w-full !h-[48px] !text-sm placeholder:!text-text2 "
                      />
                    </div>
                    <div className="w-full flex-column gap-[16px]">
                      <LabelFeed
                        text="Gender"
                        color="text-text1"
                        className="!leading-[22px]"
                      />
                      <FeedDropdown
                        options={[
                          {
                            id: 1,
                            val: "male",
                            name: "Male",
                          },
                          {
                            id: 2,
                            val: "female",
                            name: "Female",
                          },
                          {
                            id: 3,
                            val: "other",
                            name: "Other",
                          },
                        ]}
                        placeholder="Select Gender"
                        value={values.gender}
                        setFieldValue={setFieldValue}
                        name="gender"
                      />
                    </div>
                  </div>

                  <Text
                    text="Internal Use Only"
                    fontSize="!text-[20px]"
                    color="text-white"
                    className={"leading-[24px] my-[16px] "}
                  />

                  <div className="w-full flex gap-[16px]">
                    <div className="w-full flex-column gap-[16px]">
                      <LabelFeed
                        text="Client Type"
                        color="text-text1"
                        className="!leading-[22px]"
                      />
                      <FeedDropdown
                        options={common?.clientType}
                        value={values.clientType}
                        setFieldValue={setFieldValue}
                        name="clientType"
                        placeholder="Select Client Type"
                      />
                    </div>
                    <div className="w-full flex-column gap-[16px]"></div>
                  </div>
                </div>
                <div className="flex-start-end gap-[12px] self-stretch w-full flex-[0_0_auto] mt-8">
                  {!pendingClient?.status && (
                    <Button
                      text={
                        isSubmitting && submitType === "not-invite" ? (
                          <Spinner />
                        ) : (
                          "Save & Do Not Invite"
                        )
                      }
                      type="submit"
                      color="text-white"
                      className="!w-[251.41px] tracking-[0.1px] !px-[24px] !py-[10px]  !rounded-[8px] overflow-hidden !border !border-solid border-white  box-border"
                      cta={() => setSubmitType("not-invite")}
                    />
                  )}
                  {pendingClient?.status && (
                    <Button
                      text={"Delete"}
                      type="button"
                      bg={"bg-background"}
                      className={`px-[20px] rounded-[12px] leading-6 text-[14px] border border-textPrimary`}
                      color={`text-red`}
                      cta={() => {
                        deleteClientAction(
                          pendingClient?.docId,
                          navigate,
                          setAddClient,
                        );
                      }}
                    />
                  )}
                  <Button
                    text={
                      submitType === "invite" && isSubmitting ? (
                        <Spinner />
                      ) : uiSettings?.formName === "edit-client" ? (
                        "Save"
                      ) : (
                        "Save & Invite"
                      )
                    }
                    type="submit"
                    color="text-background"
                    bg="bg-primary1"
                    className="!w-[143.3px] tracking-[0.1px] !px-[24px] !py-[10px] !rounded-[8px]  box-border whitespace-nowrap"
                    cta={() => setSubmitType("invite")}
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <SuccessModal
        show={show}
        successMsg={successMsg}
        handleClose={() => {
          setAddClient(false);
          setShow(false);
        }}
        successCTA={() => {
          setAddClient(true);
          setShow(false);
        }}
        okBtn={pendingClient?.docId ? "Cancel" : "Add Another Client"}
        cancelBtn={"Back to Client Page"}
      />
    </>
  );
};

export default CreateClientForm;

import { toast } from "react-toastify";

export const errorHandling = (error) => {
  console.log(error);

  try {
    if (error.code || error.Details) {
      toast(error.message, {
        type: "error",
        autoClose: 5000,
      });
    } else {
    }
  } catch (e) {
    toast("Something Went Wrong", {
      type: "error",
      autoClose: 5000,
    });
  }
  window.scrollTo(0, 0);
};

import React from "react";

export const DotsThreeVerticalIconGreen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <rect
        opacity="0.2"
        x="0.000244141"
        width="32"
        height="32"
        rx="5"
        fill="#C0F121"
      />
      <circle cx="16.0055" cy="22.6761" r="1.33333" fill="#C0F121" />
      <circle cx="16.0055" cy="15.9906" r="1.33333" fill="#C0F121" />
      <circle cx="16.0055" cy="9.33333" r="1.33333" fill="#C0F121" />
    </svg>
  );
};

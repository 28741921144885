import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'kaboodle-a5fd0.firebaseapp.com',
  databaseURL: 'https://kaboodle-a5fd0-default-rtdb.firebaseio.com',
  projectId: 'kaboodle-a5fd0',
  storageBucket: 'kaboodle-a5fd0.appspot.com',
  messagingSenderId: '475129873364',
  appId: '1:475129873364:web:563d6342ce3b40b1e68c41',
  measurementId: 'G-LWTQKTG9KH',
  // type: 'service_account',
  // project_id: 'kaboodle-a5fd0',
  // private_key_id: 'de71045ec7d8bd6d3b6fc848e755a3aa8b2ab413',
  // apiKey: 'AIzaSyDWPwShWslW7fRkoboK6DaLleut2Skepo0',
  // private_key:
  //   'MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC3Mh/TFuLDcUdP\n6MCQXBc7pptKAcvCgyEINwyX9tfL2lOeJmE8i+u+fn0U8luTP0vHfmwWWa6shnrU\nErAjVXk8C9CTqpgGVvo3agLGX3ZTtI0ZcK7R4xrw9YwTNzrtsZGDnZozElDZ9tXZ\n6y8oQjB7k5ePnys687QpZXpdzuNOuuTiZmqAeUNAR1I/BaUDoj3uA7y3QOSp2HLT\ne8tbVixwKQxfI2AO5oX4NxE4OJzNAer4tlR1mZoFsqg6iHfKA/oaKiZC9XON2cGU\nYRS0OJd1+fv1bfutaK86MtJemPkho+n2uCv10O7huB/4OH7iYtuPqNHnqy6yq0iF\nC5OG/TLPAgMBAAECggEAHEWOB8OmZ0caHEjtPtoXc8s4d+ljCre151CnjuQ3VWxy\nLxv5MaDeVt2qbAsi+sxv42+tOp3IiTOPzR69NBbCyPOB0ugm4gOWResEVpzw4+Yf\nSGfNcvK7e6hJX8XMQFaBAHDYFT2kYJ7pTeIvuuTMGqYiY7XKtCRcD29rvQJqrgGC\nxKZOJGsCtWpEqRte2+9Mc9hbsQYur1iOUlKA3w8qFX8GjRCShx/OhPleRE3VviE4\n6bS9Isa1r9mom/qhzfRgUfMSCKKlfJGgpOJUXyUW7Rqz3kBCzywskUgsIjM03Qz5\np1LP+IOGq0cS3bzHj2YkmLA+gfHhIBgOHD2XpJRsIQKBgQDoEgf/loEzSd4pj5x1\nTsMcp/Npy0EqaxKEskzizna+sCoqDkdkFQpJ2qkvOK6O9rWrCps8SC8/jlSivIzh\nLCuNzermAATAnYH2M3HmEoCP/GRzBFOPbuIwFqSUZFg4KXBpaq+4SOCQfdt3FZC7\nVn47wKVX6GBDqzPigJ+6tpJY2QKBgQDKFfK5AbuKGtWkUndpiRQsR9Cm67+/XrxF\nBuOaGIpacikASEaOSKvzGPNMuDWDO4TrxXbnL8xy5yjBKQ6G26bBSRLBAMJGUmwD\nTdMn6YQJpZW2enCbVn7o52WbURaB4wt+Ah/bhSl99VvSH1x0axjRCy7UgZYQ5VES\nYXlD05rf5wKBgDcysrxUK/7q/vCbu/ca+MWEHR14vtM8DHeLgUYhHZVViXHfW5Ou\nfXf7jmow7TQL8kHwj+PSt4pCbFcYydl1DBb6d3ilYHv/+r14lwrGM9YLjkjdKpLf\nKOWPPS4ef+07zgUE3y7SBrfXd6/nbTlh5TyJIcq69sqhmgGx3kwmyMBhAoGAUbLD\n2RWhOvkUTAVm70yS0kAKU6FVeMmU9ZcOZtrq/BvRBaMymw04LuR0YBzMzYw/dY30\nPwys6/sWF9lAXTbp2JRxN4PWBtYFguRwrF8UmU3PE+G2KqayUfhStJL4tXQTzlKP\njJDuMFUEVROGri4p70FFInZtM9FxkCvF+azPnRsCgYB8/FZg5ps2t/4SDivQswJ0\nt2XcWjgYRUucedYfjjae4IwSfyWbU8m4A1fysfhZkAbW3CQ7P+kgzYb5Xi1/dXGP\nTNCn6kDVIepzyXReFlnaq4p4+OyhJgVPq1bTjTTmhoYi67e6l2jjCurpNqK3mr3+\ngmQlyderlH93S6AdGgZJpg==',
  // client_email: 'firebase-adminsdk-mto8u@kaboodle-a5fd0.iam.gserviceaccount.com',
  // client_id: '103304531555274805345',
  // auth_uri: 'https://accounts.google.com/o/oauth2/auth',
  // token_uri: 'https://oauth2.googleapis.com/token',
  // auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
  // client_x509_cert_url: 'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-mto8u%40kaboodle-a5fd0.iam.gserviceaccount.com',
  // universe_domain: 'googleapis.com',
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth();

export { app, auth };
export const db = getFirestore(app);
export const firestore = getFirestore();

import {
  createDocContext,
  deletedocIDContext,
  getAllDocByCollectionContext,
  updateDocCollectionContext,
} from "../../Services/commonContext";
import {
  createOrReplaceDocContext,
  getFoodListItemContext,
  getTotalFoodItemContext,
} from "../../Services/mealPlan";
import { sortedData } from "../../helper";
import {
  addFoodItemReducer,
  addMealPlanReducer,
  setFoodListReducer,
  setAllMealPlanReducer,
  deleteMealPlanReducer,
  updateMealPlanReducer,
  updateFoodItemPagination,
  addFavFoodItemReducer,
  setAllFavFoodReducer,
} from "../reducers/mealPlan";

import store from "../store";

const createMealPlanAction = async (url, payload) => {
  const result = await createDocContext(url, payload);
  if (result?.id) {
    store.dispatch(addMealPlanReducer(payload));
    return result;
  }
};
const getFoodListItemAction = async (page, limit, searchString) => {
  const total = await getTotalFoodItemContext(searchString);
  const result = await getFoodListItemContext(page, limit, searchString);
  store.dispatch(setFoodListReducer(result));
  if (total) {
    store.dispatch(updateFoodItemPagination({ total: total }));
  }
};
const getFavFoodItemAction = async (uid, url) => {
  const result = await getAllDocByCollectionContext(uid, url);
  store.dispatch(setAllFavFoodReducer(result));
  return result;
};

const getMealPlanTemplateAction = async (uid, url) => {
  const result = await getAllDocByCollectionContext(uid, url);
  if (result?.length) {
    store.dispatch(setAllMealPlanReducer(sortedData(result)));
    return result;
  }
};

const addFoodItemList = async (url, payload) => {
  const result = await createDocContext(url, payload);
  if (result) {
    store.dispatch(addFoodItemReducer(payload));
    return result;
  }
};
// const fooditemDBinsertAction = async (foodData) => {
//   console.log(
//     "data",
//     foodData?.filter((f) => f.approved),
//   );

//   foodData
//     ?.filter((f) => f.approved)
//     ?.map(async (item, index) => {
//       await createDocContext("foodItems", {
//         ...item,
//         instaCartId: null,
//         coachId: "all",
//         clientId: "all",
//         companyId: "all",
//         barCodeId: null,
//       });
//       console.log(index);
//     });
// };
const AddFavoriteToFoodItemAction = async (data) => {
  const result = await createOrReplaceDocContext("favFoods", data);
  if (result) {
    store.dispatch(addFavFoodItemReducer(data));
    return result;
  }
};
const updateMealPlanAction = async (url, data) => {
  const result = await updateDocCollectionContext(url, data?.docId, data);
  if (result) {
    store.dispatch(updateMealPlanReducer(data));
    return result;
  }
};

const deleteMealPlanAction = async (docId) => {
  const result = await deletedocIDContext("mealplan", docId);
  if (result) {
    store.dispatch(deleteMealPlanReducer(docId));
    return result;
  }
};

export {
  getFoodListItemAction,
  addFoodItemList,
  AddFavoriteToFoodItemAction,
  // fooditemDBinsertAction,
  getFavFoodItemAction,
  createMealPlanAction,
  getMealPlanTemplateAction,
  deleteMealPlanAction,
  updateMealPlanAction,
};

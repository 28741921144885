import React, { useEffect, useState } from "react";
import { Dropdown } from "rsuite";
import { Drawer } from "rsuite";
import { FeedSearch, MainHeading } from "../../../../../../components";
import { Button, Text } from "../../../../../../utils";
import { Cross, MealPlanIcon } from "../../../../../../FeedLibraryIcon";
import AddFoodModal from "./addFoodModal";
import { FoodListTable } from "./foodListTable";
import { FoodTypeListColumn } from "../../../../../../schema/mealPlan";
import { useSelector } from "react-redux";
import {
  AddFavoriteToFoodItemAction,
  getFavFoodItemAction,
} from "../../../../../../store/actions/mealPlan";

const tabsData = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "favorite",
    name: "Favorite",
  },
];

const MealsModal = ({
  setShowSidebar,
  showSidebar,
  selectedMeal,
  setMealsData,
  mealsData,
  setSelectedData,
  selectedData,
}) => {
  const { foodList, favFoods } = useSelector((state) => state.mealPlan);
  const { user } = useSelector((state) => state.common);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectType, setSelectType] = useState("All");
  const [selectTab, setSelectTab] = useState("all");
  const [open, setOpen] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);
  // const [selectedData, setSelectedData] = useState([]);
  const [mealListItem, setmealListItem] = useState([]);
  const [foodFilter, setfoodFilter] = useState([]);

  /* map table data */
  const foodDatahandler = () => {
    let filterFoods = [];
    if (selectTab === "favorite") {
      filterFoods = foodList?.filter((f) =>
        favFoods?.some((fav) => fav.foodId === f.id)
      );
    } else if (selectType?.id) {
      filterFoods = foodList?.filter((f) => f.food_type === selectType?.id);
    } else if (selectType === "All") {
      filterFoods = foodList;
    }
    filterFoods = filterFoods?.filter((food) => !!food?.notes);
    setmealListItem(
      filterFoods?.map((item, counter) => {
        return {
          ...item,
          name: item.name || "Food" + counter,
          label: item?.notes,
        };
      })
    );
  };

  /*favorite item handler*/
  const favouriteItemClick = (rowData) => {
    AddFavoriteToFoodItemAction({
      coachId: user?.uid,
      foodId: rowData?.id,
    });
  };
  useEffect(() => {
    setSearchQuery("");
    setSelectedData([]);
    setCheckedKeys([]);
    getFavFoodItemAction(user?.uid, "favFoods");
  }, [showSidebar]);

  useEffect(() => {
    foodDatahandler();
  }, [foodList, selectTab, selectType]);
  // move filter type to all
  useEffect(() => {
    setSelectType("All");
  }, [foodList]);

  useEffect(() => {
    const originalArray = foodList?.map((fo) => fo.food_type);
    const filteredArray = Array.from(
      new Set(originalArray?.filter((item) => item !== "N/A"))
    );
    setfoodFilter(
      filteredArray?.map((f) => {
        return {
          id: f,
          name: f,
        };
      })
    );
  }, [foodList]);

  return (
    <>
      <Drawer
        open={showSidebar}
        className="!max-w-[1243px] !w-full !bg-secondary2 !p-5  add-food-drawer"
      >
        <Drawer.Body className="!p-0 flex flex-col justify-between">
          <div className="w-full !mb-6">
            <MainHeading
              titleClasses="!text-[22px]"
              secbtnTextColor="!text-primary1"
              descriptionClasses="!text-sm !text-whiteGray !tracking-[0.1px]"
              title={"Add a Food Item"}
              description={
                "Search for specific foods or general food types in the search field.\nYou can also sort foods based on macro content using the dropdowns below."
              }
              secondaryBtnText={"Add a Food Item"}
              secCTA={async () => {
                setOpen(true);
              }}
            />
          </div>
          <div className="mb-[24px]">
            <div className=" w-full flex items-center justify-between gap-2">
              <div className="w-[344px] flex items-center gap-[7px] rounded-lg  p-1 bg-background2">
                {tabsData.map((item, index) => (
                  <Button
                    key={index}
                    text={item.name}
                    type="button"
                    bg={
                      selectTab === item.id
                        ? "bg-background5"
                        : "bg-background2"
                    }
                    className=" w-full !px-[8px] !py-[6px] rounded-[4px] font-normal text-sm "
                    color={
                      selectTab === item.id ? "text-white" : "text-whiteGray"
                    }
                    cta={() => {
                      setSelectTab(item.id);
                    }}
                  />
                ))}
              </div>

              <FeedSearch
                handlesearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                placeholder={"Search for food"}
                className="!text-sm !font-normal bg-background2 !w-[344px] !h-[44px]"
              />
            </div>

            <div className="w-full flex items-center justify-between gap-3">
              <div className="w-full flex items-center gap-[12px] mt-6 mb-4">
                <Text
                  text="Food Type"
                  color="text-textPrimary"
                  className="text-base"
                />
                <Button
                  text={"All"}
                  type="button"
                  bg={selectType === "All" ? "bg-primary1" : "bg-transparent "}
                  className={`w-[77px] px-[20px] !py-[10px] rounded-lg  border-solid !text-sm font-normal border ${
                    selectType === "All"
                      ? "!border-primary1"
                      : "!border-background"
                  }`}
                  color={`${
                    selectType === "All" ? " text-background" : "text-whiteGray"
                  }`}
                  cta={() => setSelectType("All")}
                />

                {foodFilter?.slice(0, 4)?.map((item, index) => (
                  <Button
                    key={index}
                    text={item.name}
                    type="button"
                    bg={
                      selectType?.id === item?.id
                        ? "bg-primary1"
                        : "bg-transparent"
                    }
                    className={`px-[20px] !py-[10px] rounded-lg border border-solid !text-sm font-normal  ${
                      selectType?.id === item?.id
                        ? "!border-primary1"
                        : "!border-background"
                    }`}
                    color={`${
                      selectType?.id === item?.id
                        ? "text-background"
                        : "text-whiteGray"
                    }`}
                    cta={() => setSelectType(item)}
                  />
                ))}
              </div>
              <Button
                text="Add to meal"
                type="button"
                bg="bg-primary1"
                className="!px-[14px] !py-[7px] w-[200px]   rounded-[8px] font-medium text-sm "
                color="text-background"
                cta={() => {
                  setMealsData(
                    mealsData?.map((meal) => {
                      if (meal.id === selectedMeal.id) {
                        return {
                          ...meal,
                          foodList: [...meal.foodList, ...selectedData],
                        };
                      } else {
                        return meal;
                      }
                    })
                  );
                  setShowSidebar(false);
                }}
              />
              <Dropdown
                title={`${selectedData.length || 0} Selected Food`}
                icon={<MealPlanIcon w="24" h="24" color="#7D7D7D" />}
                placement="bottomStart"
                className={`food-dropdown-r ${selectedData.length && "active"}`}
                noCaret
                disabled={selectedData.length > 0 ? false : true}
              >
                {selectedData?.map((row, index) => (
                  <Dropdown.Item key={index}>
                    <Text
                      text={row?.name}
                      color="text-textPrimary"
                      className="!text-xs leading-6 tracking-[.5px]"
                    />
                    <div className="w-full flex items-center justify-end gap-[16px]">
                      {[
                        [row?.calories, "calories"],
                        [row?.protein, "Proteins"],
                        [row?.carbs, "Carbs"],
                        [row?.fat, "Fats"],
                      ].map(([number, name], index) => (
                        <div key={index} className="flex items-center gap-1">
                          <Text
                            text={number}
                            color="text-primary1"
                            className="!text-xs tracking-[.5px]"
                          />
                          <Text
                            text={name}
                            color="text-whiteGray"
                            className="!text-xs tracking-[.5px]"
                          />
                        </div>
                      ))}
                    </div>
                    <span
                      onClick={() => {
                        setSelectedData(
                          selectedData?.filter((f) => f.id !== row.id)
                        );
                        setCheckedKeys(
                          checkedKeys?.filter((f) => f !== row.id)
                        );
                      }}
                    >
                      <Cross size="20" />
                    </span>
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </div>
            <FoodListTable
              tableColumns={FoodTypeListColumn}
              tabledata={mealListItem}
              setCheckedKeys={setCheckedKeys}
              checkedKeys={checkedKeys}
              setSelectedData={setSelectedData}
              showPagination
              favouriteItemClick={favouriteItemClick}
              searchQuery={searchQuery}
              selectedData={selectedData}
            />
          </div>

          <div className="w-full flex justify-between items-center ">
            <Button
              text="Cancel"
              type="button"
              bg=" border border-textPrimary"
              className="w-[102px] rounded-[8px] !py-[7px] font-medium text-sm"
              color="text-textPrimary"
              cta={() => setShowSidebar(false)}
            />

            <Button
              text="Add to meal"
              type="button"
              bg="bg-primary1"
              className="!px-[14px] !py-[7px] rounded-[8px] font-medium text-sm "
              color="text-background"
              cta={() => {
                setMealsData(
                  mealsData?.map((meal) => {
                    if (meal.id === selectedMeal.id) {
                      return {
                        ...meal,
                        foodList: [...meal.foodList, ...selectedData],
                      };
                    } else {
                      return meal;
                    }
                  })
                );
                setShowSidebar(false);
              }}
            />
          </div>
        </Drawer.Body>
      </Drawer>

      <AddFoodModal open={open} setOpen={setOpen} />
    </>
  );
};

export default MealsModal;

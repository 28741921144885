import React from "react";
import { Loader } from "rsuite";
import { Text, Button } from "../../utils";
import { useSelector } from "react-redux";
const FeedBreadcrumbs = ({
  backClick,
  selectedTemplateName,
  formRef,
  submitBtnClass,
  cancelBtnClass,
  marginBottom,
  disabled = false,
  crumbText,
}) => {
  const { uiSettings } = useSelector((state) => state);
  return (
    <div
      className={`flex-center-between ${
        marginBottom ? marginBottom : "mb-[16px]"
      }`}
    >
      <div className="flex-center-center ">
        <div onClick={() => backClick()} className="cursor-pointer">
          <Text text={crumbText} color="text-text3" className={"capitalize"} />
        </div>
        <span className="text-text3 mx-[8px]">/</span>
        <Text text="Create a New Template" color="text-primary1" />
      </div>
      <div className="flex gap-[24px]">
        <Button
          text={uiSettings?.formName === "view-template" ? "Close" : "Cancel"}
          className={`bg-background border border-[#E6E6E6] rounded-full p-[20px] leading-6 text-[16px] ${cancelBtnClass}`}
          color="text-textPrimary"
          cta={() => backClick()}
        />
        {uiSettings?.formName !== "view-template" && (
          <Button
            type="button"
            text={
              uiSettings?.btnloader ? (
                <Loader size="sm" content="Save & Exit" />
              ) : (
                "Save & Exit"
              )
            }
            bg="bg-primary1"
            className={`px-[20px] rounded-full leading-6 text-[16px] ${submitBtnClass}`}
            color="text-background"
            disabled={disabled}
            cta={() => {
              formRef.current?.submitForm();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FeedBreadcrumbs;

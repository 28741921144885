import React, { useEffect, useState } from "react";
import { Table, Checkbox, Pagination, Popover, Tooltip, Whisper } from "rsuite";
import { FoodFavIcon, FoodNotFavIcon } from "../../../../../../FeedLibraryIcon";
import {
  generateUniqueId,
  removeDuplicateArrayItem,
} from "../../../../../../helper";
import { getFoodListItemAction } from "../../../../../../store/actions/mealPlan";
import { useSelector } from "react-redux";
const { Column, HeaderCell, Cell } = Table;

export const FoodListTable = (props) => {
  const {
    tableColumns,
    tabledata,
    setSelectedData,
    showPagination,
    setCheckedKeys,
    checkedKeys,
    favouriteItemClick,
    searchQuery,
    isMealView,
    setdataChange,
    selectedData,
    setselectedMeal
  } = props;
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [loader, setloader] = useState(false);
  const [data, setData] = useState(tabledata);
  const { foodListPagination, favFoods } = useSelector(
    (state) => state.mealPlan
  );
  let checked = false;
  let indeterminate = false;

  useEffect(() => {
    (async () => {
      if (showPagination) {
        setloader(true);
        await getFoodListItemAction(page, limit, searchQuery.trim());
        setloader(false);
      }
    })();
  }, [page, limit, showPagination, searchQuery]);

  useEffect(() => {
    setData(tabledata);
  }, [tabledata]);

  if (checkedKeys?.length === data?.length) {
    checked = true;
  } else if (checkedKeys?.length === 0) {
    checked = false;
  } else if (checkedKeys?.length > 0 && checkedKeys?.length < data?.length) {
    indeterminate = true;
  }
  // SELECT DATA ROW FORM THE TABLE
  const handleCheckAll = (value, checked) => {
    const keys = checked ? data.map((item) => item.id) : [];
    setCheckedKeys(keys);
    const alldata = removeDuplicateArrayItem([...data, ...selectedData]);
    const selectedRowsData = alldata.filter((row) => keys.includes(row.id));

    setSelectedData(
      selectedRowsData?.map((row) => {
        return {
          ...row,
          uuid: generateUniqueId(),
          total_serving: "",
        };
      })
    );
  };

  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);
    setCheckedKeys(keys);
    const alldata = removeDuplicateArrayItem([...data, ...selectedData]);
    const selectedRowsData = alldata.filter((row) => keys.includes(row.id));
    setSelectedData(selectedRowsData);
  };

  //total_serving

  // FOR PAGINATION
  // const paginationData = data.filter((v, i) => {
  //   const start = limit * (page - 1);
  //   const end = start + limit;
  //   return i >= start && i < end;
  // });
  /* HANDLE CHANGE UPDATE ROW*/
  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], data);
    nextData.find((item) => item.id === id)[key] = value;
    if (key === "total_serving" && value !== 0 && value) {
      nextData.find((item) => item.id === id)["protein"] = value
        ? tabledata.find((item) => item.id === id)?.protein * value
        : tabledata.find((item) => item.id === id)?.protein;
      nextData.find((item) => item.id === id)["carbs"] = value
        ? tabledata.find((item) => item.id === id)?.carbs * value
        : tabledata.find((item) => item.id === id)?.carbs;
      nextData.find((item) => item.id === id)["fat"] = value
        ? tabledata.find((item) => item.id === id)?.fat * value
        : tabledata.find((item) => item.id === id)?.fat;
      nextData.find((item) => item.id === id)["calories"] = value
        ? tabledata.find((item) => item.id === id)?.calories * value
        : tabledata.find((item) => item.id === id)?.calories;
      nextData.find((item) => item.id === id)["sodium"] = value
        ? tabledata.find((item) => item.id === id)?.sodium * value
        : tabledata.find((item) => item.id === id)?.sodium;
      nextData.find((item) => item.id === id)["potassium"] = value
        ? tabledata.find((item) => item.id === id)?.potassium * value
        : tabledata.find((item) => item.id === id)?.potassium;
      nextData.find((item) => item.id === id)["fiber"] = value
        ? tabledata.find((item) => item.id === id)?.fiber * value
        : tabledata.find((item) => item.id === id)?.fiber;
    }

    setData(nextData);
  };

  const handleEditState = (rowData) => {
    // console.log("rowData ===> ", rowData);

    const id = rowData.id
    const nextData = [...data];
    const activeItemIndex = nextData.findIndex((item) => item.id === id);
    // console.log("activeItemIndex ===> ", activeItemIndex);

    if (activeItemIndex !== -1) {
      const activeItem = { ...nextData[activeItemIndex] };
      // console.log("activeItem ===> ", activeItem);

      activeItem.status = activeItem.status ? null : "EDIT";
      // setselectedMeal

      if (!activeItem.status) {
        setdataChange(activeItem);
      }

      nextData[activeItemIndex] = activeItem;

      // if (isMealView) {
      //   setdataChange(nextData);
      // }
      // console.log("Now, nextData ===> ", nextData);
      setData(nextData);
    } else {
      console.error("Item not found with id:", id);
    }
  };

  const handleChangeLimit = (dataKey) => {
    console.log(dataKey);
    setPage(1);
    setLimit(dataKey);
  };

  return (
    <>
      <Table
        height={400}
        data={data}
        hover={true}
        rowKey="id"
        bordered
        cellBordered
        checkboxColumn
        // wordWrap="break-word"
        loading={isMealView ? false : loader}
        rowSelection={{
          checkedKeys,
          onChange: (keys) => {
            setCheckedKeys(keys);
          },
        }}
      >
        <Column width={50} align="center">
          <HeaderCell style={{ padding: 0 }}>
            <div style={{ lineHeight: "40px" }}>
              <Checkbox
                inline
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleCheckAll}
              />
            </div>
          </HeaderCell>
          <CheckCell
            dataKey="id"
            checkedKeys={checkedKeys}
            onChange={handleCheck}
          />
        </Column>
        {tableColumns?.map((column) => (
          <Column
            width={column.width}
            flexGrow={column?.flexGrow}
            fixed
            key={column.id}
          >
            <HeaderCell>
              <Whisper
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={<Tooltip>{column?.label}</Tooltip>}
              >
                <div className="header-cell whitespace-nowrap overflow-hidden overflow-ellipsis">
                  {column?.label}
                </div>
              </Whisper>
            </HeaderCell>
            {column?.id === "favorite" ? (
              <Cell dataKey={column.id}>
                {(rowData) => (
                  <div
                    className="cursor-pointer"
                    onClick={() => favouriteItemClick(rowData)}
                  >
                    {favFoods?.find((f) => f.foodId === rowData?.id) ? (
                      <FoodFavIcon />
                    ) : (
                      <FoodNotFavIcon />
                    )}
                  </div>
                )}
              </Cell>
            ) : column?.id === "name" ? (
              <NameCell
                dataKey={column.id}
                className="text-white text-sm !w-full"
                style={{ color: "#fff" }}
              />
            ) : column?.id === "total_serving" || column?.id === "note" ? (
              <EditableCell
                dataKey={column.id}
                className="text-white text-sm !w-full"
                style={{ color: "#fff" }}
                onChange={handleChange}
              />
            ) : (
              <Cell
                dataKey={column.id}
                className="text-white text-sm !w-full"
                style={{ color: "#fff" }}
              />
            )}
          </Column>
        ))}
        {isMealView && (
          <Column flexGrow={1}>
            <HeaderCell>...</HeaderCell>
            <ActionCell dataKey={"id"} onClick={handleEditState} />
          </Column>
        )}
      </Table>
      {showPagination && data?.length > 0 && (
        <div className="mt-[24px]">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="sm"
            layout={["-", "limit", "|", "pager", "skip"]}
            total={foodListPagination?.total}
            limitOptions={[10, 20, 60]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )}
    </>
  );
};

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: "46px" }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys?.some((item) => item === rowData[dataKey])}
      />
    </div>
  </Cell>
);

const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
  const editing = rowData?.status === "EDIT";
  return (
    <Cell {...props} className={editing ? "table-content-editing" : ""}>
      {editing ? (
        <input
          className={`rs-input !outline-none mt-[-4px]`}
          defaultValue={rowData[dataKey]}
          onChange={(event) => {
            onChange && onChange(rowData.id, dataKey, event.target.value);
          }}
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
};

const ActionCell = ({ rowData, dataKey, onClick, ...props }) => {
  return (
    <Cell {...props} style={{ padding: "6px" }}>
      <div
        className="text-primary1 font-normal text-center pt-[8px]"
        onClick={() => {
          onClick(rowData);
        }}
      >
        {rowData.status === "EDIT" ? "Save" : "Edit"}
      </div>
    </Cell>
  );
};

const NameCell = ({ rowData, dataKey, ...props }) => {
  const speaker = (
    <Popover>
      <p className="text-white">{rowData.name}</p>
    </Popover>
  );

  return (
    <Cell {...props}>
      <Whisper followCursor placement="top" speaker={speaker}>
        <p>{rowData[dataKey]}</p>
      </Whisper>
    </Cell>
  );
};

import { Formik } from 'formik';
import React from 'react';
import { FeedInput, LabelFeed, TextArea } from '../../../../../../utils';
import { CrossIcon } from '../../../../../../FeedLibraryIcon';
import { useSelector } from 'react-redux';

const MealForm = ({ meal, showAccordion, index, setMealsData, mealsData }) => {
  const uiSettings = useSelector((state) => state.uiSettings);
  const onChangeHandler = (key, value) => {
    setMealsData(
      mealsData?.map((item) => {
        if (item.id === meal.id) {
          return {
            ...item,
            [key]: value,
          };
        } else {
          return item;
        }
      }),
    );
  };
  return (
    <Formik
      initialValues={{
        mealName: meal?.mealName || '',
        mealNote: meal?.mealNote || '',
      }}
      enableReinitialize
      validate={(values) => {
        const errors = {};
        if (!values.mealName) {
          errors.mealName = 'Required';
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {}}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-[8px] w-full ">
            <LabelFeed text="Meal Name" fontSize={'!text-[14px]'} color="text-textPrimary" className="leading-[24px] tracking-[0.5px]" />
            <FeedInput
              name="mealName"
              onChange={(e) => onChangeHandler('mealName', e.target.value)}
              onBlur={handleBlur}
              value={values.mealName}
              disabled={uiSettings?.formName === 'view-template' ? true : false}
              // crossicon={values?.mealName ? <CrossIcon /> : ''}
              placeholder="Name"
              className="!text-sm"
            />
          </div>
          {showAccordion === index && (
            <div className="flex flex-col gap-[8px] w-full mt-[24px]">
              <LabelFeed text="Note for this Meal" fontSize={'text-[14px]'} color="text-textPrimary" className="leading-[24px] tracking-[0.5px]" />
              <TextArea
                placeholder="Add Note for this meal here"
                rows={4}
                name={'mealNote'}
                setFieldValue={setFieldValue}
                onChange={(e) => onChangeHandler('mealNote', e.target.value)}
                value={values.mealNote}
                onBlur={handleBlur}
                disabled={uiSettings?.formName === 'view-template' ? true : false}
                className="py-[11px] px-[12px]  rounded-[8px]  tracking-[0.5px] !text-sm"
              />
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default MealForm;

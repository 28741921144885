import React from "react";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { auth } from "../../configs/firebase";
import {
  FeedInput,
  LabelFeed,
  Button,
  Heading,
  FeedLink,
  Spinner,
} from "../../utils";
import { Envelope } from "../../FeedLibraryIcon";
import { sendPasswordResetEmail } from "firebase/auth";

const ForgetPassword = () => {
  return (
    <div className=" h-[100vh] flex-center-center">
      <div className="w-[550px] rounded-[20px] flex-items-center-column bg-[#0E0E0E]  px-[20px] py-[28px]">
        <div className="mb-[48px]">
          <Heading text="Reset Your Password" className="" />
        </div>

        <Formik
          initialValues={{ email: "" }}
          enableReinitialize
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            // firebase auth login
            try {
              await sendPasswordResetEmail(auth, values.email)
                .then(async function (res) {
                  Swal.fire({
                    icon: "success",
                    title: "Email has been sent.",
                    text: "A password reset request has been sent. Please check your email for details on how to reset your password.",
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    customClass: {
                      confirmButton: "!bg-primary1 !text-background",
                    },
                    background: "#030303",
                    showLoaderOnConfirm: true,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                    }
                  });
                  setSubmitting(false);
                })
                .catch(function (error) {
                  console.error(error.message);
                });
            } catch (error) {
              console.error("email sending error:", error.message);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <>
              <form onSubmit={handleSubmit} className="flex-column  w-full">
                <div className="w-full flex-items-start-column mb-[20px]">
                  <LabelFeed text="Email Address" className={"mb-[12px]"} />
                  <FeedInput
                    className="w-full"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    setFieldValue={setFieldValue}
                    type="text"
                    icon={<Envelope />}
                    placeholder="Enter Email Address"
                    name="email"
                  />
                  {errors.email && touched.email && (
                    <div className="text-red text-[14px]  pt-[4px]">
                      {errors.email}
                    </div>
                  )}
                </div>
                <Button
                  type="submit"
                  bg="bg-primary1"
                  text={isSubmitting ? <Spinner /> : "Submit"}
                  className=" w-full rounded-lg px-[24px] py-[12px] mb-[32px]"
                  color="text-secondary1"
                />
                <div className=" my-[32px] text-center">
                  <FeedLink path="/login" text={" Back to Login"} />
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgetPassword;

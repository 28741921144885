import React from 'react';

export const DownloadIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M21 14.2501V19.5001C21 19.8979 20.842 20.2795 20.5607 20.5608C20.2794 20.8421 19.8978 21.0001 19.5 21.0001H4.5C4.10218 21.0001 3.72064 20.8421 3.43934 20.5608C3.15804 20.2795 3 19.8979 3 19.5001V14.2501C3 14.0512 3.07902 13.8604 3.21967 13.7198C3.36032 13.5791 3.55109 13.5001 3.75 13.5001C3.94891 13.5001 4.13968 13.5791 4.28033 13.7198C4.42098 13.8604 4.5 14.0512 4.5 14.2501V19.5001H19.5V14.2501C19.5 14.0512 19.579 13.8604 19.7197 13.7198C19.8603 13.5791 20.0511 13.5001 20.25 13.5001C20.4489 13.5001 20.6397 13.5791 20.7803 13.7198C20.921 13.8604 21 14.0512 21 14.2501ZM8.78063 8.03073L11.25 5.56041V14.2501C11.25 14.449 11.329 14.6398 11.4697 14.7804C11.6103 14.9211 11.8011 15.0001 12 15.0001C12.1989 15.0001 12.3897 14.9211 12.5303 14.7804C12.671 14.6398 12.75 14.449 12.75 14.2501V5.56041L15.2194 8.03073C15.3601 8.17146 15.551 8.25052 15.75 8.25052C15.949 8.25052 16.1399 8.17146 16.2806 8.03073C16.4214 7.89 16.5004 7.69912 16.5004 7.5001C16.5004 7.30108 16.4214 7.11021 16.2806 6.96948L12.5306 3.21948C12.461 3.14974 12.3783 3.09443 12.2872 3.05668C12.1962 3.01894 12.0986 2.99951 12 2.99951C11.9014 2.99951 11.8038 3.01894 11.7128 3.05668C11.6217 3.09443 11.539 3.14974 11.4694 3.21948L7.71937 6.96948C7.57864 7.11021 7.49958 7.30108 7.49958 7.5001C7.49958 7.69912 7.57864 7.89 7.71937 8.03073C7.86011 8.17146 8.05098 8.25052 8.25 8.25052C8.44902 8.25052 8.63989 8.17146 8.78063 8.03073Z"
        fill="#C0F121"
      />
    </svg>
  );
};

import React, { useEffect, useState } from "react";
import { Animation } from "rsuite";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { customDateFormat } from "../../../helper";
import FeedBreadcrumbs from "../../../components/common/breadcrumbs";
import { MainHeading } from "../../../components";

import {
  Button,
  DatePicker,
  FeedCheckbox,
  FeedInput,
  LabelFeed,
  TextArea,
} from "../../../utils";
import { CrossIcon } from "../../../FeedLibraryIcon";
import { seteditFormReducer } from "../../../store/reducers/uiSettings";

const weeks = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
];

const SteppeThree = ({
  closePlanHandler,
  formRef,
  setStepper,
  setplanScreenState,
}) => {
  const initiallySelectedDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const dispatch = useDispatch();
  const { uiSettings } = useSelector((state) => state);
  const [selectedDays, setSelectedDays] = useState(initiallySelectedDays);
  const [startDate, setstartDate] = useState(
    uiSettings?.editForm?.start_date || "yyyy-mm-dd",
  );
  const [show, setShow] = useState(true);

  const handleCheckboxChange = (value) => {
    const currentIndex = selectedDays.indexOf(value);
    const newSelectedDays = [...selectedDays];
    if (currentIndex === -1) {
      newSelectedDays.push(value);
    } else {
      newSelectedDays.splice(currentIndex, 1);
    }
    setSelectedDays(newSelectedDays);
  };

  useEffect(() => {
    if (uiSettings?.editForm?.start_date) {
      setstartDate(uiSettings?.editForm?.start_date);
    }
    if (uiSettings?.editForm?.days) {
      setSelectedDays(uiSettings?.editForm?.days);
    }
  }, [uiSettings?.editForm?.days, uiSettings?.editForm?.start_date]);

  return (
    <>
      <div className="mb-[24px]">
        <FeedBreadcrumbs
          backClick={closePlanHandler}
          selectedTemplateName={{ name: "Supplements" }}
          formRef={formRef}
          cancelBtnClass="!border-none !p-2"
          submitBtnClass="!rounded-[8px]"
          marginBottom="!mb-0"
          disabled={true}
          crumbText="Supplemet Plan"
        />
        <MainHeading
          title="Create Supplement Plan Template"
          description={
            "Welcome to the Kaboodle Supplement Plan Builder. Please complete all steps and proceed using the buttons at the bottom of each screen."
          }
        />
      </div>
      <Formik
        initialValues={{
          name: uiSettings?.editForm?.name || "",
          days: selectedDays,
          start_date: startDate,
          clientNote: uiSettings?.editForm?.clientNote || "",
        }}
        innerRef={formRef}
        enableReinitialize
        validate={(values) => {
          const errors = {};

          if (!values.days) {
            errors.days = "Required";
          }
          if (!values.name) {
            errors.name = "Required";
          }
          if (
            startDate === "yyyy-mm-dd" ||
            startDate === "" ||
            startDate === undefined ||
            startDate === null
          ) {
            errors.start_date = "Required";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          dispatch(
            seteditFormReducer({
              type: uiSettings?.formName,
              data: {
                ...uiSettings.editForm,
                ...values,
                start_date: customDateFormat(startDate),
              },
            }),
          );
          setStepper([1, 2, 3, 4]);
          setplanScreenState("step4Title");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form
            onSubmit={handleSubmit}
            className={`w-full flex-column gap-6 ${
              uiSettings?.formName === "view-template"
                ? "pointer-events-none"
                : "pointer-events-auto"
            }`}
          >
            <div className="w-full flex-items-start-column autofill-fix">
              <LabelFeed
                text={
                  <>
                    Supplement Plan Name{" "}
                    <span className="text-primary1">*</span>
                  </>
                }
                className={"mb-[10px] !font-medium !text-lg"}
              />
              <FeedInput
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                setFieldValue={setFieldValue}
                type="text"
                placeholder="Add Name"
                name="name"
                crossicon={values?.name ? <CrossIcon /> : ""}
                className="w-full  !px-5 !bg-secondary1"
              />
              {errors.name && touched.name && (
                <div className="text-red text-[14px]  pt-[4px]">
                  {errors.name}
                </div>
              )}
            </div>

            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-[16px]">
              <div className="w-full flex-column gap-4">
                <div className="w-full flex-column gap-6 px-[20px] py-6 rounded-[20px] bg-secondary1 ">
                  <div className="flex-column gap-[8px] w-full f4f-date-wrapper">
                    <LabelFeed
                      text={
                        <>
                          Start Date <span className="text-primary1">*</span>
                        </>
                      }
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[24px] tracking-[0.5px]"
                    />

                    <DatePicker
                      value={startDate}
                      placeholder="Select start date"
                      onChange={(e) => setstartDate(e)}
                    />
                    {errors.start_date && (
                      <LabelFeed
                        text="Required"
                        color="text-red"
                        className="!leading-[22px]"
                      />
                    )}
                  </div>
                  <div className="flex-column gap-[8px] w-full ">
                    <LabelFeed
                      text={
                        <>
                          Frequency <span className="text-primary1">*</span>
                        </>
                      }
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[24px] tracking-[0.5px]"
                    />
                    <div className="w-full rounded-lg bg-background">
                      <div className="w-full flex-center-between gap-3 pt-[11px] px-[12px] pb-[12px] ">
                        <LabelFeed
                          text={`${selectedDays.length} Days / Week`}
                          fontSize={"!text-[14px]"}
                          color="text-textPrimary"
                          className="leading-[24px] tracking-[0.5px]"
                        />
                        <Button
                          text={show ? "Save" : "Change"}
                          color="text-primary1"
                          className="!w-max !h-auto !p-0 !text-sm !leading-6 tracking-[0.5px] !border-none"
                          cta={() => setShow(!show)}
                        />
                      </div>
                      <Animation.Collapse in={show} dimension="height">
                        <div className="w-full pt-[12px] px-[12px] pb-[11px] border-t border-solid border-background2">
                          <LabelFeed
                            text="Select Days"
                            fontSize={"!text-[14px]"}
                            color="text-textPrimary"
                            className="leading-[24px] tracking-[0.5px] mb-[10px]"
                          />
                          <div
                            className="w-full grid grid-cols-4 gap-x-2 gap-y-[10px] "
                            role="group"
                            aria-labelledby="checkbox-group"
                          >
                            {weeks.map((day, index) => (
                              <div
                                key={index}
                                className="flex-items-center px-[10px] py-2"
                              >
                                <FeedCheckbox
                                  name="tags"
                                  text={day.label}
                                  value={day.value}
                                  checked={selectedDays.includes(day.value)}
                                  onChange={() =>
                                    handleCheckboxChange(day.value)
                                  }
                                />
                              </div>
                            ))}
                          </div>
                          {errors.days && touched.days && (
                            <LabelFeed
                              text={errors.days}
                              color="text-red"
                              className="!leading-[22px] !text-[14px]"
                            />
                          )}
                        </div>
                      </Animation.Collapse>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full px-[20px] py-6 rounded-[20px] bg-secondary1">
                <TextArea
                  placeholder={"Add clientNote ( optional )"}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  name="clientNote"
                  value={values.clientNote}
                  color="text-text6"
                  className="leading-[24px] h-full p-[8px] text-[16px] font-normal text-text6 !outline-none !border-none !bg-transparent"
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SteppeThree;

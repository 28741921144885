/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  getCLientTypeAction,
  getallMyClientAction,
} from "../../store/actions/common";
import MyClients from "../../components/myClients";
import { auth } from "../../configs/firebase";

const Index = ({ searchQuery, setsearchQuery }) => {
  const { uid } = auth.currentUser;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await getallMyClientAction(uid);
      await getCLientTypeAction(uid, "clientType");
      setLoading(false);
    })();
  }, []);
  
  return (
    <div className="main-container !py-[40px]">
      <MyClients
        searchQuery={searchQuery}
        setsearchQuery={setsearchQuery}
        uid={uid}
        loading={loading}
      />
    </div>
  );
};

export default Index;

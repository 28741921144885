import React from "react";
import PeraText from "../ParaText";

export const FeedCheckbox = (props) => {
  const { text, className, checked } = props;
  return (
    <label className={`custom-checkbox  rounded-[4px]   ${className}`}>
      <input {...props} type="checkbox" checked={checked} />
      <span className="checkmark"></span>
      <PeraText
        text={text}
        className={`w-full leading-5  tracking-[0.5px]`}
        color="text-textPrimary"
        fontSize="text-[14px] ml-[4px] "
      />
    </label>
  );
};

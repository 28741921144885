import {
  deletedocIDContext,
  getAllDocByCollectionContext,
  updatePlanContext,
} from "../../Services/commonContext";
import {
  createPlanAction,
  getPlanGenerationByAI,
} from "../../Services/workoutPlan";
import { sortedData } from "../../helper";
import {
  setAllworkoutReducer,
  deleteWorkoutReducer,
  addWorkoutPlanReducer,
  updateWorkoutPlanReducer,
} from "../reducers/workoutPlan";
import store from "../store";

const getPlanGenerationByAIAction = async (url, payload) => {
  const result = await getPlanGenerationByAI(url, payload);
  console.log("response ===> ", result);
  if (result?.status === 200) {
    return result;
  } else {
    return undefined;
  }
};
const getWorkoutTemplateAction = async (uid) => {
  const result = await getAllDocByCollectionContext(uid, "workoutplan");
  if (!!result?.length) {
    store.dispatch(setAllworkoutReducer(sortedData(result)));
    return result;
  }
};
const addWorkoutTemplateAction = async (url, payload) => {
  const result = await createPlanAction(url, payload);
  if (result?.id) {
    store.dispatch(addWorkoutPlanReducer(payload));
    return result;
  }
};
const updateWorkoutTemplateAction = async (url, id, payload) => {
  const result = await updatePlanContext(url, id, payload);
  store.dispatch(updateWorkoutPlanReducer(payload));
  return result;
};

const deleteWorkoutTemplateAction = async (docId) => {
  await deletedocIDContext("workoutplan", docId);
  store.dispatch(deleteWorkoutReducer(docId));
};

export {
  getPlanGenerationByAIAction,
  getWorkoutTemplateAction,
  deleteWorkoutTemplateAction,
  addWorkoutTemplateAction,
  updateWorkoutTemplateAction,
};

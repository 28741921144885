import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { toast } from "react-toastify";
import FeedBreadcrumbs from "../../../components/common/breadcrumbs";
import { MainHeading } from "../../../components";
import {
  FeedCheckbox,
  FeedInput,
  LabelFeed,
  Text,
  TextArea,
} from "../../../utils";
import { CrossIcon } from "../../../FeedLibraryIcon";
import { seteditFormReducer } from "../../../store/reducers/uiSettings";
import { guidedWorkoutQuestions } from "../../../schema";
import { generateUniqueId, workoutSinlgeChecked } from "../../../helper";
import InfoToolTip from "../../../components/infoToolTip";
import { getPlanGenerationByAIAction } from "../../../store/actions/workoutPlan";
import Swal from "sweetalert2";
const movementObj = {
  order: "B1",
  excerciseName: "Excersise Name",
  sets: 1,
  repititions: 1,
  rest: "1min",
  temp1: 1,
  temp2: 2,
  temp3: 3,
  temp4: 4,
  note: "",
  videoUrl: "",
};
const SteppeTwo = ({
  closePlanHandler,
  formRef,
  setStepper,
  setplanScreenState,
  setaiLoader,
  aiLoader,
}) => {
  const uiSettings = useSelector((state) => state.uiSettings);
  const { editForm } = uiSettings;
  const [guidedquestions, setguidedquestions] = useState([]);
  const dispatch = useDispatch();

  const submitFormik = async (values) => {
    try {
      const checkedAnswer = {};
      let url = "";
      let payload;

      if (!!guidedquestions?.length) {
        guidedquestions.forEach((question) => {
          const { key, questionsOption } = question;
          const checkedOptions = questionsOption
            .filter((option) => option.checked)
            .map((option) => option.value)
            .join(", "); // Join the array elements with commas and space
          checkedAnswer[key] = checkedOptions;
        });
        url = "workout/create";
        payload = {
          requester: "5eb12e197e06a76ccdefc121",
          response: " ",
          age: checkedAnswer?.age || "Middle age",
          gender: checkedAnswer?.gender || "Non specific",
          goal: checkedAnswer?.fitnessGoal || "General Fitness",
          targetDays: checkedAnswer?.commitDays || "3",
          equipment: checkedAnswer?.equipment || "None",
          fitnessLevel: checkedAnswer?.fitnessLevel || "None",
          intensity: "Low",
          fitnessGoal: checkedAnswer?.fitnessGoal || "None",
          exerciseType: checkedAnswer?.exerciseType || "Cardio",
          commitDays: checkedAnswer?.commitDays || "3",
          healthIssues: checkedAnswer?.healthIssues || "None",
          timeFrameForOutput: checkedAnswer?.timeFrameForOutput || "1 month",
          programType: checkedAnswer?.programType || "None",
          // regenerate: 1
        };

        // Display loading message
        const loadingSwal = Swal.fire({
          title: " Please wait...",
          html: "<p>AI Assistant generating workout plan,</p>",
          background: "#030303",
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
          allowOutsideClick: false,
        });

        setaiLoader(true);
        const response = await getPlanGenerationByAIAction(url, payload);
        loadingSwal.close();

        if (response?.status === 200) {
          const allExcersises = response.data.message;
          toast("Workout Plan Created Successfully");
          const allExcs =
            allExcersises?.exerciselist || allExcersises?.exerciseList;
          console.log("allExcs", allExcs);
          dispatch(
            seteditFormReducer({
              type: uiSettings?.formName || "create-meal",
              data: {
                ...uiSettings.editForm,
                ...values,
                allQuestions: guidedquestions,
                movements: allExcs,
              },
            }),
          );
          setStepper([1, 2, 3]);
          setplanScreenState("addMovements");

          setaiLoader(false);
        } else {
          await Swal.fire({
            title: "Error",
            text: "An error occurred while processing your request",
            icon: "error",
            background: "#030303",
          });

          setaiLoader(false);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      await Swal.fire({
        title: "Error",
        text: "An error occurred while processing your request",
        icon: "error",
        background: "#030303",
      });

      setaiLoader(false);
    }
  };

  useEffect(() => {
    if (editForm?.allQuestions?.length) {
      setguidedquestions(editForm?.allQuestions);
    } else {
      setguidedquestions(
        guidedWorkoutQuestions?.map((question) => {
          return {
            ...question,
            questionsOption: question?.questionsOption?.map((q, key) => {
              return {
                ...q,
                checked: key === 0 ? true : false,
              };
            }),
          };
        }),
      );
    }
  }, [editForm?.allQuestions]);

  return (
    <>
      <div className="">
        <FeedBreadcrumbs
          backClick={closePlanHandler}
          selectedTemplateName={{ name: "Workouts" }}
          formRef={formRef}
          cancelBtnClass="!border-none !p-2"
          submitBtnClass="!rounded-[8px]"
          marginBottom="!mb-0"
          disabled={true}
          crumbText="Workout Plan"
        />
        <MainHeading
          title="Fill Questions"
          description={
            "Welcome to the Kaboodle Workout Plan Builder. Please complete all steps and proceed using the buttons at the bottom of each screen."
          }
        />
      </div>

      <div className="mt-6 w-full  ">
        <Formik
          initialValues={{
            name: uiSettings?.editForm?.name || "",
            description: uiSettings?.editForm?.description || "",
          }}
          // enableReinitialize
          innerRef={formRef}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (uiSettings?.formName === "view-template") {
              setStepper([1, 2, 3]);
              setplanScreenState("addMovements");
            } else {
              submitFormik();
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <>
              <form
                onSubmit={handleSubmit}
                className={`flex-column w-full ${
                  uiSettings?.formName === "view-template"
                    ? "pointer-events-none"
                    : "pointer-events-auto"
                }`}
              >
                <div className="w-full h-full mb-6">
                  <div className="w-full flex-items-start-column mb-6 autofill-fix">
                    <LabelFeed
                      text={
                        <>
                          Workout Plan Name{" "}
                          <span className="text-primary1">*</span>
                        </>
                      }
                      className={"mb-[10px] !font-medium !text-lg"}
                    />
                    <FeedInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      setFieldValue={setFieldValue}
                      type="text"
                      placeholder="Add Name"
                      name="name"
                      crossicon={values?.name ? <CrossIcon /> : ""}
                      className="w-full  !px-5 !bg-secondary1"
                    />
                    {errors.name && touched.name && (
                      <div className="text-red text-[14px]  pt-[4px]">
                        {errors.name}
                      </div>
                    )}
                  </div>

                  <div className="w-full h-full flex-items-start-column ">
                    <LabelFeed
                      text="Description"
                      className={"mb-[12px] !font-medium !text-lg"}
                    />
                    <div className="w-full  min-h-[114px] px-[12px] py-6 rounded-[20px] bg-secondary1">
                      <TextArea
                        placeholder="Add Instruction"
                        rows={5}
                        name={"description"}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        value={values.description}
                        onBlur={handleBlur}
                        className="leading-[24px] h-full !p-0 text-[16px] font-normal text-text6 !outline-none !border-none !bg-transparent"
                      />
                    </div>
                  </div>
                </div>
                {/* d */}
                <div className="flex-items-center gap-[16px]">
                  <Text
                    text="Fill out the following questions."
                    fontSize={"!text-[20px]"}
                    color="text-white"
                  />
                  <InfoToolTip text="Clicking all of the buttons will create a very generic workout plan. To make it more precise, try to limit the number of boxes checked. This feature is still in BETA and your feedback is greatly appreciated to improve its outcomes." />
                </div>

                <div className="grid grid-cols-2  gap-y-[24px] gap-x-[20px] p-[20px] rounded-lg bg-secondary1 mt-[10px] w-full">
                  {guidedquestions?.map((quest, index) => (
                    <div
                      key={index}
                      className={`flex-column gap-[16px] ${
                        quest?.key === "fitnessLevel" ||
                        quest?.key === "exerciseType" ||
                        quest?.key === "healthIssues" ||
                        quest?.key === "equipment"
                          ? "col-span-2"
                          : ""
                      } `}
                    >
                      <div className="flex">
                        <span className="text-primary1 text-[16px] leading-6 font-normal">
                          Question:{index < 9 ? "0" : ""}
                          {index + 1}&nbsp;{" "}
                        </span>
                        <LabelFeed text={quest.questionsName} />
                      </div>

                      <div
                        className={`w-full  h-full  rounded-[8px] py-[11px] px-[12px] bg-[#202020] `}
                      >
                        <div
                          className={`grid ${
                            quest?.key === "fitnessLevel" ||
                            quest?.key === "exerciseType" ||
                            quest?.key === "healthIssues" ||
                            quest?.key === "equipment"
                              ? "grid-cols-3"
                              : "grid-cols-2"
                          } gap-[20px]`}
                        >
                          {quest?.questionsOption?.map((item, index) => (
                            <FeedCheckbox
                              key={index}
                              id={item.id}
                              text={
                                quest.key === "age" ? item.label : item.value
                              }
                              name={item.value}
                              value={item.value}
                              checked={item.checked}
                              onChange={(e) => {
                                setguidedquestions(
                                  guidedquestions?.map((question) => {
                                    if (question.key === quest.key) {
                                      return {
                                        ...question,
                                        questionsOption:
                                          question?.questionsOption?.map(
                                            (q) => {
                                              if (q.value === item.value) {
                                                return {
                                                  ...q,
                                                  checked: e.target.checked,
                                                };
                                              } else {
                                                if (
                                                  workoutSinlgeChecked?.includes(
                                                    question.key,
                                                  )
                                                ) {
                                                  return {
                                                    ...q,
                                                    checked: false,
                                                  };
                                                } else {
                                                  return { ...q };
                                                }
                                              }
                                            },
                                          ),
                                      };
                                    } else {
                                      return {
                                        ...question,
                                        questionsOption:
                                          question?.questionsOption?.map(
                                            (q) => {
                                              return { ...q };
                                            },
                                          ),
                                      };
                                    }
                                  }),
                                );
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SteppeTwo;

/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  allTypeUsers: [],
  totalEntity: {},
  role: {},
};

export const permissions = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setAllUserDetailReducer: (state, action) => {
      state.allTypeUsers = action.payload;
    },
    setTotalEnityCount: (state, action) => {
      state.totalEntity = action.payload;
    },
    deleteUserActionReducer: (state, action) => {
      state.allTypeUsers = state.allTypeUsers?.filter(
        (user) => user.docId !== action.payload
      );
    },
    addUserDetailReducer: (state, action) => {
      state.allTypeUsers = [...state.allTypeUsers, action.payload];
    },
    updateUserDetailsReducer: (state, action) => {
      state.allTypeUsers = state.allTypeUsers?.map((user) => {
        if (user.docId === action.payload?.docId) {
          return action.payload;
        } else {
          return user;
        }
      });
    },
    setRolePermissions: (state, action) => {
      state.role = action.payload;
    },
  },
});

export const {
  setAllUserDetailReducer,
  setTotalEnityCount,
  deleteUserActionReducer,
  addUserDetailReducer,
  updateUserDetailsReducer,
  setRolePermissions,
} = permissions.actions;

export default permissions.reducer;

import React from 'react';

const LabelFeed = ({ text, className, fontSize, color, fontweight }) => {
  return (
    <label className={`flex  items-center  `}>
      <span className={`${fontSize ? fontSize : 'text-[16px]'} ${fontweight ? fontweight : 'font-normal'} ${color ? color : 'text-text1'} not-italic leading-6 ${className}`}>
        {text}
      </span>
    </label>
  );
};

export default LabelFeed;

import React from "react";

export const EyeOn = () => (
  <svg
    fill="#000000"
    height="20"
    width="20"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 612 612"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M609.608,315.426c3.19-5.874,3.19-12.979,0-18.853c-58.464-107.643-172.5-180.72-303.607-180.72
          S60.857,188.931,2.393,296.573c-3.19,5.874-3.19,12.979,0,18.853C60.858,423.069,174.892,496.147,306,496.147
          S551.143,423.069,609.608,315.426z M306,451.855c-80.554,0-145.855-65.302-145.855-145.855S225.446,160.144,306,160.144
          S451.856,225.446,451.856,306S386.554,451.855,306,451.855z"
        />
        <path
          d="M306,231.67c-6.136,0-12.095,0.749-17.798,2.15c5.841,6.76,9.383,15.563,9.383,25.198c0,21.3-17.267,38.568-38.568,38.568
          c-9.635,0-18.438-3.541-25.198-9.383c-1.401,5.703-2.15,11.662-2.15,17.798c0,41.052,33.279,74.33,74.33,74.33
          s74.33-33.279,74.33-74.33S347.052,231.67,306,231.67z"
        />
      </g>
    </g>
  </svg>
);

import React from "react";
import Skeleton from "react-loading-skeleton";

export const ClientStatusSkeleton = () => {
  return (
    <div className="self-stretch rounded-xl bg-secondary1 flex-center-start p-[20px]  gap-[10px] text-[24px]">
      <div className="flex-1  flex-center-start">
        <div className="flex flex-start-start  gap-[20px]">
          <Skeleton height={58} width={58} />
          <div className="flex-start-start-column ">
            <div className=" flex-center-start gap-[20px]">
              <Skeleton height={8} width={200} />{" "}
              <Skeleton height={30} width={97} />{" "}
            </div>
            <Skeleton height={8} width={150} className="!h-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { Modal } from "rsuite";
import { Button, Heading, PrePlanTableSkeleton, Text } from "../../../utils";
import SimpleView from "./simpleView";

const IntakeSubmissionModal = ({
  handleClose,
  show,
  viewLoader,
  fromSubmission,
}) => {
  const [showView, setShowView] = useState(false);
  const [formAnswers, setFormAnswers] = useState([]);
  return (
    <Modal
      open={show}
      onClose={handleClose}
      className=" !rounded-[20px] !w-[600px] foodModal modal-black"
    >
      <Modal.Body className="!w-full !max-w-full !p-0 !m-0">
        <div className="flex-column gap-[32px] p-1">
          <div className="flex-column gap-3">
            <Heading
              text="Pre Plan Intake Submission"
              fontweight="!font-[500]"
              fontSize="!text-[22px]"
              color="text-textPrimary"
              className="leading-[20px] "
            />
            <Text
              text="This is where you can view all the submissions by Client Name"
              className="leading-[20px] font-medium tracking-[0.1px]"
            />
          </div>

          {showView ? (
            <SimpleView formAnswers={formAnswers} />
          ) : (
            <div>
              <table className="w-full text-sm text-left rounded-xl text-textPrimary overflow-hidden ">
                <thead className="h-[58px] w-full  bg-background  ">
                  <tr>
                    <th
                      scope="col"
                      className="max-w-[280px] pl-[15px] pr-[10px] py-[20px]"
                    >
                      <Text text="Created On" className="!font-medium" />
                    </th>
                    <th scope="col" className=" pr-[15px] py-[20px]">
                      <Text text="Action" className="!font-medium" />
                    </th>
                  </tr>
                </thead>
                <tbody
                  className={`w-full ${
                    !fromSubmission?.length && !viewLoader && "text-center"
                  }`}
                >
                  {!viewLoader ? (
                    fromSubmission?.length ? (
                      fromSubmission?.map((item, index) => (
                        <tr
                          key={index}
                          className="h-[62px] cursor-pointer  border border-solid  !border-text4 "
                        >
                          <th
                            scope="row"
                            className="pl-[15px] pr-[10px] py-[20px]"
                          >
                            <Text text={item?.created_at} />
                          </th>
                          <th scope="row" className=" pr-[15px] py-[20px]">
                            <div className="!w-[84px]">
                              <Button
                                text={"View"}
                                className="!h-auto !p-0 !font-normal  !underline"
                                color="text-primary1"
                                cta={() => {
                                  setFormAnswers(Object.values(item?.answers));
                                  setShowView(true);
                                }}
                              />
                            </div>
                          </th>
                        </tr>
                      ))
                    ) : (
                      <tr className="h-[62px]">
                        <th
                          scope="row"
                          colSpan={2}
                          className="pl-[15px] pr-[10px] py-[20px]"
                        >
                          <Text
                            text={"No Submission found for this Client"}
                            color={"text-primary1"}
                          />
                        </th>
                      </tr>
                    )
                  ) : (
                    <PrePlanTableSkeleton />
                  )}
                </tbody>
              </table>
            </div>
          )}

          <div className="flex-start-end gap-[12px]  self-stretch w-full flex-[0_0_auto]">
            {showView && (
              <Button
                text="Back"
                color="text-primary1"
                className="!w-max tracking-[0.1px] !px-[24px] !py-[10px]  !rounded-[8px] overflow-hidden !border !border-solid border-primary1  box-border"
                cta={() => setShowView(false)}
              />
            )}
            <Button
              text="Cancel"
              color="text-primary1"
              className="!w-max tracking-[0.1px] !px-[24px] !py-[10px]  !rounded-[8px] overflow-hidden !border !border-solid border-primary1  box-border"
              cta={handleClose}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default IntakeSubmissionModal;

import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { auth } from "../../configs/firebase";
import {
  FeedInput,
  LabelFeed,
  Button,
  Heading,
  FeedLink,
  Spinner,
} from "../../utils";
import { Envelope } from "../../FeedLibraryIcon";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { useLocation } from "react-router-dom";

const SetPassword = () => {
  const [oobCode, setOobCode] = useState("");
  const [error, setError] = useState("");

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const oobCode = queryParams.get("oobCode");
    const mode = queryParams.get("mode");

    if (mode === "resetPassword" && oobCode) {
      verifyPasswordResetCode(auth, oobCode)
        .then(() => {
          setOobCode(oobCode);
        })
        .catch((error) => {
          setError("Invalid or expired action code.");
        });
    } else {
      setError("Invalid request.");
    }
  }, [location]);

  const submitHandler = async (values, { setSubmitting }) => {
    if (values.password !== values.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Password Mismatch",
        text: "The passwords do not match.",
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "!bg-primary1 !text-background",
        },
        background: "#030303",
      });
      setSubmitting(false);
      return;
    }

    try {
      await confirmPasswordReset(auth, oobCode, values.password);
      Swal.fire({
        icon: "success",
        title: "Password Reset Successful",
        text: "Your password has been reset successfully. You can now log in with your new password.",
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "!bg-primary1 !text-background",
        },
        background: "#030303",
      }).then(() => {
        // Redirect to login page or other desired action
      });
    } catch (error) {
      console.error("Password reset error:", error.message);
      Swal.fire({
        icon: "error",
        title: "Password Reset Failed",
        text: error.message,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "!bg-primary1 !text-background",
        },
        background: "#030303",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="h-[100vh] flex-center-center">
      <div className="w-[550px] rounded-[20px] flex-items-center-column bg-[#0E0E0E] px-[20px] py-[28px]">
        <div className="mb-[48px]">
          <Heading text="Set Your Password" className="" />
        </div>

        {error ? (
          <div className="text-red text-[14px] pt-[4px]">{error}</div>
        ) : (
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            enableReinitialize
            validate={(values) => {
              const errors = {};
              if (!values.password) {
                errors.password = "Required";
              } else if (values.password.length < 8) {
                errors.password = "Password must be at least 8 characters long";
              }
              if (!values.confirmPassword) {
                errors.confirmPassword = "Required";
              } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "Passwords do not match";
              }
              return errors;
            }}
            onSubmit={submitHandler}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <>
                <form onSubmit={handleSubmit} className="flex-column w-full">
                  <div className="w-full flex-items-start-column mb-[20px]">
                    <LabelFeed text="New Password" className={"mb-[12px]"} />
                    <FeedInput
                      className="w-full"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      setFieldValue={setFieldValue}
                      type="password"
                      icon={<Envelope />}
                      placeholder="Enter your new password"
                      name="password"
                    />
                    {errors.password && touched.password && (
                      <div className="text-red text-[14px] pt-[4px]">
                        {errors.password}
                      </div>
                    )}
                  </div>
                  <div className="w-full flex-items-start-column mb-[20px]">
                    <LabelFeed text="Confirm Password" className={"mb-[12px]"} />
                    <FeedInput
                      className="w-full"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      setFieldValue={setFieldValue}
                      type="password"
                      icon={<Envelope />}
                      placeholder="Confirm your new password"
                      name="confirmPassword"
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="text-red text-[14px] pt-[4px]">
                        {errors.confirmPassword}
                      </div>
                    )}
                  </div>
                  <Button
                    type="submit"
                    bg="bg-primary1"
                    text={isSubmitting ? <Spinner /> : "Submit"}
                    className="w-full rounded-lg px-[24px] py-[12px] mb-[32px]"
                    color="text-secondary1"
                  />
                  <div className="my-[32px] text-center">
                    <FeedLink path="/login" text={" Back to Login"} />
                  </div>
                </form>
              </>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default SetPassword;

import React from 'react';

export const Search = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.9424 17.0579L14.0306 13.1469C15.1644 11.7858 15.7298 10.0399 15.6091 8.2725C15.4884 6.50512 14.691 4.85229 13.3828 3.65785C12.0745 2.46341 10.3561 1.81932 8.5851 1.85957C6.81406 1.89982 5.12671 2.62131 3.87407 3.87395C2.62143 5.12659 1.89994 6.81394 1.85969 8.58498C1.81944 10.356 2.46353 12.0744 3.65797 13.3827C4.85241 14.6909 6.50524 15.4883 8.27262 15.609C10.04 15.7297 11.7859 15.1643 13.1471 14.0305L17.058 17.9422C17.1161 18.0003 17.185 18.0464 17.2609 18.0778C17.3367 18.1092 17.4181 18.1254 17.5002 18.1254C17.5823 18.1254 17.6636 18.1092 17.7395 18.0778C17.8154 18.0464 17.8843 18.0003 17.9424 17.9422C18.0004 17.8842 18.0465 17.8152 18.0779 17.7394C18.1093 17.6635 18.1255 17.5822 18.1255 17.5001C18.1255 17.4179 18.1093 17.3366 18.0779 17.2607C18.0465 17.1849 18.0004 17.1159 17.9424 17.0579ZM3.12518 8.75005C3.12518 7.63753 3.45508 6.55 4.07316 5.62497C4.69124 4.69995 5.56975 3.97898 6.59758 3.55323C7.62542 3.12749 8.75642 3.0161 9.84756 3.23314C10.9387 3.45018 11.941 3.98591 12.7277 4.77258C13.5143 5.55925 14.0501 6.56153 14.2671 7.65267C14.4841 8.74381 14.3727 9.87481 13.947 10.9026C13.5213 11.9305 12.8003 12.809 11.8753 13.4271C10.9502 14.0452 9.8627 14.3751 8.75018 14.3751C7.25884 14.3734 5.82906 13.7802 4.77453 12.7257C3.72 11.6712 3.12683 10.2414 3.12518 8.75005Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};

import { Formik } from "formik";
import React, { useState } from "react";
import { Loader } from "rsuite";
import {
  Button,
  FeedDropdown,
  FeedInput,
  LabelFeed,
  Text,
} from "../../../utils";
import { CrossIcon, ExportIcon } from "../../../FeedLibraryIcon";
import Avatar from "../../../assets/images/Avatar.png";
import { updateCoachProfileContext } from "../../../Services/commonContext";
import { updateUserDetailsAction } from "../../../store/actions/permissions";
import { toast } from "react-toastify";

const UserEditFormik = ({ data, setOpenModal }) => {
  const { name, email, phoneNumber, status, userType, avatar, uid } = data;
  const [loading, setloading] = useState(false);
  const [changeFields, setChangeFields] = useState("");

  function removeSecondPlus(number) {
    let firstPlusIndex = number.indexOf("+");
    let secondPlusIndex = number.indexOf("+", firstPlusIndex + 1);

    if (secondPlusIndex !== -1) {
      return (
        number.slice(0, secondPlusIndex) + number.slice(secondPlusIndex + 1)
      );
    } else {
      return number;
    }
  }

  return (
    <div className="w-full h-full  px-[20px] py-[16px]">
      <Formik
        initialValues={{
          name: "" || name,
          email: "" || email,
          phoneNumber: "" || phoneNumber,
          userType: "" || userType,
          status: "" || status,
          countryCode: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = "Required";
          }
          if (!values.email) {
            errors.email = "Required";
          }
          if (!values.phoneNumber || !values.countryCode) {
            errors.phoneNumber = "Required";
          }
          if (!values.userType) {
            errors.userType = "Required";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          // let selectedImage = selectedThumbs;
          // if (profileImage?.name) {
          //   const storage = getStorage();
          //   const storageRef = ref(storage, `images/${profileImage.name}`);
          //   try {
          //     const snapshot = await uploadBytes(storageRef, profileImage, {
          //       // You can add metadata here if needed
          //     });
          //     const url = await getDownloadURL(snapshot.ref);
          //     selectedImage = url;
          //     setprofileImage(null);
          //   } catch (error) {
          //     console.error("Error uploading file: ", error);
          //     setprofileImage(null);
          //   }
          // }
          await updateUserDetailsAction(uid, {
            ...data,
            ...values,
            // avatar: selectedImage,
          });
          setSubmitting(false);
          toast("User updated successfully");
          setOpenModal("");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="w-full grid grid-cols-1 gap-[16px]">
              <div
                className={`w-full flex items-center justify-between gap-4 p-[16px] rounded-lg border border-solid ${
                  changeFields.includes("updateAvatar")
                    ? "!border-activeBackground"
                    : "!border-background2"
                }`}
              >
                <img
                  src={avatar || Avatar}
                  alt="avater"
                  className="w-[55px] h-[55px] rounded-full bg-black"
                />

                {changeFields.includes("updateAvatar") ? (
                  <div className="flex items-center  ">
                    <Button
                      text="Cancel"
                      type="button"
                      className="w-auto rounded-[8px]  !px-[14px]  !py-[7px] font-medium text-sm"
                      color="text-textPrimary"
                      cta={() => setChangeFields("")}
                    />

                    <Button
                      text="Update"
                      type="button"
                      bg="bg-primary1"
                      className="!px-[14px] !py-[7px] !h-[44px] rounded-[8px] font-medium text-sm "
                      color="text-background"
                      cta={() => setChangeFields("")}
                    />
                  </div>
                ) : (
                  <Button
                    text="Change avatar"
                    type="button"
                    cta={() => setChangeFields("updateAvatar")}
                    className=" text-primary2 !text-sm bg-transparent !h-auto  !p-0 "
                  />
                )}
              </div>
              <div
                className={`w-full flex items-center justify-between gap-4 p-[16px] rounded-lg border border-solid ${
                  changeFields.includes("updateName")
                    ? "!border-activeBackground"
                    : "!border-background2"
                }`}
              >
                <div className="w-full flex-column  gap-[4px]  ">
                  <LabelFeed
                    text={"Name"}
                    fontSize={"!text-[14px]"}
                    color="text-background9"
                    className="leading-[22px] "
                  />

                  {changeFields.includes("updateName") ? (
                    <div className="w-full flex items-center justify-between">
                      <FeedInput
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        crossicon={values?.name ? <CrossIcon /> : ""}
                        placeholder="User full name"
                        type="text"
                        setFieldValue={setFieldValue}
                        className="!text-sm !rounded-[4px] !w-[344px]"
                      />
                      <div className="flex items-center  ">
                        <Button
                          text="Cancel"
                          type="button"
                          className="w-auto rounded-[8px]  !px-[14px]  !py-[7px] font-medium text-sm"
                          color="text-textPrimary"
                          cta={() => setChangeFields("")}
                        />

                        <Button
                          text="Update"
                          type="button"
                          bg="bg-primary1"
                          className="!px-[14px] !py-[7px] !h-[44px] rounded-[8px] font-medium text-sm "
                          color="text-background"
                          cta={() => setChangeFields("")}
                        />
                      </div>
                    </div>
                  ) : (
                    <Text
                      text={values?.name}
                      color="text-primary1"
                      className="text-base text-textPrimary m-0"
                    />
                  )}
                  {errors.name && touched.name && (
                    <LabelFeed
                      text={errors.name}
                      color="text-red"
                      className="!text-sm"
                    />
                  )}
                </div>
                {changeFields.includes("updateName") ? null : (
                  <Button
                    text="Edit name"
                    type="button"
                    cta={() => setChangeFields("updateName")}
                    className=" text-primary2 flex-shrink-0 !text-sm bg-transparent !h-auto  !p-0 "
                  />
                )}
              </div>
              <div
                className={`w-full flex items-center justify-between gap-4 p-[16px] rounded-lg border border-solid ${
                  changeFields.includes("updateEmail")
                    ? "!border-activeBackground"
                    : "!border-background2"
                }`}
              >
                <div className=" w-full flex-column  gap-[4px]  ">
                  <LabelFeed
                    text={"Email"}
                    fontSize={"!text-[14px]"}
                    color="text-background9"
                    className="leading-[22px] "
                  />
                  {changeFields.includes("updateEmail") ? (
                    <div className="w-full flex items-center justify-between">
                      <FeedInput
                        name="email"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        value={values.email}
                        crossicon={values?.email ? <CrossIcon /> : ""}
                        placeholder="email@example.com"
                        className="!text-sm !rounded-[4px] !w-[344px] "
                      />
                      <div className=" flex items-center  ">
                        <Button
                          text="Cancel"
                          type="button"
                          className="w-auto rounded-[8px]  !px-[14px]  !py-[7px] font-medium text-sm"
                          color="text-textPrimary"
                          cta={() => setChangeFields("")}
                        />

                        <Button
                          text="Update"
                          type="button"
                          bg="bg-primary1"
                          className="!px-[14px] !py-[7px] !h-[44px] rounded-[8px] font-medium text-sm "
                          color="text-background"
                          cta={() => setChangeFields("")}
                        />
                      </div>
                    </div>
                  ) : (
                    <Text
                      text={values?.email}
                      color="text-primary1"
                      className="text-base  text-textPrimary m-0"
                    />
                  )}

                  {errors.email && touched.email && (
                    <LabelFeed
                      text={errors.email}
                      color="text-red"
                      className="!text-sm"
                    />
                  )}
                </div>
                {changeFields.includes("updateEmail") ? null : (
                  <Button
                    text="Edit email"
                    type="button"
                    cta={() => setChangeFields("updateEmail")}
                    className=" text-primary2  flex-shrink-0 !text-sm bg-transparent !h-auto  !p-0 "
                  />
                )}
              </div>
              <div
                className={`w-full flex items-center justify-between gap-4 p-[16px] rounded-lg border border-solid ${
                  changeFields.includes("updatePhoneNumber")
                    ? "!border-activeBackground"
                    : "!border-background2"
                }`}
              >
                <div className="w-full flex flex-column  gap-[4px]  ">
                  <LabelFeed
                    text={"Phone Number"}
                    fontSize={"!text-[14px]"}
                    color="text-background9"
                    className="leading-[22px] "
                  />

                  {changeFields.includes("updatePhoneNumber") ? (
                    <div className="w-full flex items-center justify-between">
                      <div className="grid grid-cols-[20%_78%] gap-[8px] w-[344px]">
                        <FeedDropdown
                          name="countryCode"
                          type="select"
                          options={[
                            { id: "+1", name: "+1" },
                            { id: "+44", name: "+44" },
                            { id: "+61", name: "+61" },
                            { id: "+49", name: "+49" },
                            { id: "+33", name: "+33" },
                            { id: "+86", name: "+86" },
                          ]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          value={values.countryCode}
                          placeholder="+1"
                          toggleBtnClass={`!text-sm !text-text2 !h-[44px] !rounded-[4px]`}
                        />
                        <FeedInput
                          name="phoneNumber"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          value={values.phoneNumber}
                          crossicon={values?.phoneNumber ? <CrossIcon /> : ""}
                          placeholder="xxx xxx xxxx"
                          className="w-full !text-sm !rounded-[4px]"
                        />
                      </div>
                      <div className=" flex items-center  ">
                        <Button
                          text="Cancel"
                          type="button"
                          className="w-auto rounded-[8px]  !px-[14px]  !py-[7px] font-medium text-sm"
                          color="text-textPrimary"
                          cta={() => setChangeFields("")}
                        />

                        <Button
                          text="Update"
                          type="button"
                          bg="bg-primary1"
                          className="!px-[14px] !py-[7px] !h-[44px] rounded-[8px] font-medium text-sm "
                          color="text-background"
                          cta={() => setChangeFields("")}
                        />
                      </div>
                    </div>
                  ) : (
                    <Text
                      text={removeSecondPlus(
                        `${values?.countryCode} ${values?.phoneNumber}`,
                      )}
                      color="text-primary1"
                      className="text-base text-textPrimary m-0"
                    />
                  )}

                  {errors.phoneNumber && touched.phoneNumber && (
                    <LabelFeed
                      text={errors.phoneNumber}
                      color="text-red"
                      className="!text-sm"
                    />
                  )}
                </div>

                {changeFields.includes("updatePhoneNumber") ? null : (
                  <Button
                    text="Edit Phone Number"
                    type="button"
                    cta={() => setChangeFields("updatePhoneNumber")}
                    className=" text-primary2 flex-shrink-0 !text-sm bg-transparent !h-auto  !p-0 "
                  />
                )}
              </div>
              <div
                className={`w-full flex items-center justify-between gap-4 p-[16px] rounded-lg border border-solid ${
                  changeFields.includes("updateStatus")
                    ? "!border-activeBackground"
                    : "!border-background2"
                }`}
              >
                <div className="w-full flex flex-col gap-[4px]  ">
                  <LabelFeed
                    text={"Status"}
                    fontSize={"!text-[14px]"}
                    color="text-background9"
                    className="leading-[22px] "
                  />

                  {changeFields.includes("updateStatus") ? (
                    <div className="w-full flex items-center justify-between">
                      <FeedDropdown
                        name="status"
                        type="select"
                        options={[
                          { id: "Active", name: "Active" },
                          { id: "Pending", name: "Pending" },
                        ]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        value={values.status}
                        crossicon={values?.status ? <CrossIcon /> : ""}
                        placeholder="Select user status"
                        className="!text-sm !rounded-[4px]  "
                        toggleBtnClass={`!w-[344px] !text-sm !text-text2
                      `}
                      />
                      <div className="flex items-center">
                        <Button
                          text="Cancel"
                          type="button"
                          className="w-auto rounded-[8px]  !px-[14px]  !py-[7px] font-medium text-sm"
                          color="text-textPrimary"
                          cta={() => setChangeFields("")}
                        />

                        <Button
                          text="Update"
                          type="button"
                          bg="bg-primary1"
                          className="!px-[14px] !py-[7px] !h-[44px] rounded-[8px] font-medium text-sm "
                          color="text-background"
                          cta={() => setChangeFields("")}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`w-fit flex-center-center gap-[5px] px-[6px] py-[3px] rounded-[26px] ${
                        values?.status?.includes("Active")
                          ? "bg-activeBackground"
                          : values?.status?.includes("Pending")
                          ? "bg-text9"
                          : "bg-text9"
                      }`}
                    >
                      <div
                        className={`w-[6px] h-[6px] rounded-full ${
                          values?.status?.includes("Active")
                            ? "bg-primary1"
                            : values?.status?.includes("Pending")
                            ? "bg-background9"
                            : "bg-text9"
                        }`}
                      />
                      <Text
                        text={values?.status || "N/A"}
                        color="text-textPrimary"
                        className={"font-normal leading-5"}
                      />
                    </div>
                  )}

                  {errors.role && touched.role && (
                    <LabelFeed
                      text={errors.role}
                      color="text-red"
                      className="!text-sm"
                    />
                  )}
                </div>
                {changeFields.includes("updateStatus") ? null : (
                  <Button
                    text="Edit Role"
                    type="button"
                    cta={() => setChangeFields("updateStatus")}
                    className=" text-primary2 flex-shrink-0 !text-sm bg-transparent !h-auto  !p-0 "
                  />
                )}
              </div>
              <div
                className={`w-full flex items-center justify-between gap-4 p-[16px] rounded-lg border border-solid ${
                  changeFields.includes("updateRole")
                    ? "!border-activeBackground"
                    : "!border-background2"
                }`}
              >
                <div className="w-full flex flex-col gap-[4px]  ">
                  <LabelFeed
                    text={"Role"}
                    fontSize={"!text-[14px]"}
                    color="text-background9"
                    className="leading-[22px] "
                  />

                  {changeFields.includes("updateRole") ? (
                    <div className="w-full flex items-center justify-between">
                      <FeedDropdown
                        name="userType"
                        type="select"
                        options={[
                          { id: "client", name: "Client" },
                          { id: "coach", name: "Coach" },
                        ]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        value={values.userType}
                        crossicon={values?.userType ? <CrossIcon /> : ""}
                        placeholder="Select user role"
                        className="!text-sm !rounded-[4px] role-dropdown "
                        toggleBtnClass={`!w-[344px] !text-sm ${
                          values.userType.includes("client")
                            ? "!text-primary1"
                            : values.userType.includes("coach")
                            ? "!text-purpalBg"
                            : // : values.role.includes("coach")
                              // ? "!text-successColor2"
                              "!text-text2"
                        }`}
                      />
                      <div className="flex items-center">
                        <Button
                          text="Cancel"
                          type="button"
                          className="w-auto rounded-[8px]  !px-[14px]  !py-[7px] font-medium text-sm"
                          color="text-textPrimary"
                          cta={() => setChangeFields("")}
                        />

                        <Button
                          text="Update"
                          type="button"
                          bg="bg-primary1"
                          className="!px-[14px] !py-[7px] !h-[44px] rounded-[8px] font-medium text-sm "
                          color="text-background"
                          cta={() => setChangeFields("")}
                        />
                      </div>
                    </div>
                  ) : (
                    <Text
                      text={values?.userType}
                      color={
                        values?.userType?.includes("client")
                          ? "!text-primary1"
                          : values?.userType?.includes("coach")
                          ? "!text-purpalLight2"
                          : // : values?.role?.includes("Admin")
                            // ? "!text-successColor2 "
                            ""
                      }
                      className={"font-normal leading-5"}
                    />
                  )}

                  {errors.userType && touched.userType && (
                    <LabelFeed
                      text={errors.userType}
                      color="text-red"
                      className="!text-sm"
                    />
                  )}
                </div>
                {changeFields.includes("updateRole") ? null : (
                  <Button
                    text="Edit Role"
                    type="button"
                    cta={() => setChangeFields("updateRole")}
                    className=" text-primary2 flex-shrink-0 !text-sm bg-transparent !h-auto  !p-0 "
                  />
                )}
              </div>
              <div className="w-full flex items-center justify-between">
                <Button
                  text="Update Permissions"
                  type="button"
                  Icon={<ExportIcon />}
                  className="!w-max rounded-[8px] !flex-row-reverse gap-3 !p-[16px] border !border-background2  text-sm"
                  color="text-background9"
                  cta={() => setOpenModal("EditPermissions")}
                />

                <Button
                  text={
                    loading ? (
                      <Loader content="Save Changes" size="sm" />
                    ) : (
                      "Save Changes"
                    )
                  }
                  className="w-fit px-[12px] py-[7px] font-medium rounded-xl text-[14px] leading-6"
                  bg="bg-primary1"
                  color="text-background"
                  type="submit"
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UserEditFormik;

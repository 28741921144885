import React from 'react';

export const LinearScaleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_757_5489)">
        <path
          d="M23.4864 11.9979C23.4874 11.267 23.2215 10.5608 22.7387 10.012C22.2559 9.46322 21.5893 9.1096 20.8642 9.01755C20.1391 8.92549 19.4053 9.10134 18.8006 9.51206C18.196 9.92279 17.7621 10.5401 17.5805 11.2481L6.41835 11.2481C6.25129 10.6044 5.87543 10.0343 5.34965 9.62701C4.82387 9.21975 4.17787 8.99837 3.51281 8.99755C2.95175 8.99771 2.40197 9.1552 1.92593 9.45213C1.44988 9.74907 1.06664 10.1735 0.819742 10.6774C0.57284 11.1812 0.472168 11.7441 0.52916 12.3023C0.586153 12.8605 0.798527 13.3914 1.14216 13.835C1.48579 14.2785 1.94691 14.6167 2.47315 14.8113C2.99938 15.0059 3.56964 15.0491 4.11915 14.9358C4.66867 14.8226 5.17541 14.5575 5.58184 14.1707C5.98826 13.7839 6.27807 13.2909 6.41835 12.7476L17.5805 12.7477C17.7621 13.4557 18.196 14.073 18.8006 14.4837C19.4053 14.8945 20.1391 15.0703 20.8642 14.9782C21.5893 14.8862 22.2559 14.5326 22.7387 13.9838C23.2215 13.435 23.4874 12.7288 23.4864 11.9979ZM3.51082 13.5007C3.11277 13.5007 2.73103 13.3426 2.44957 13.0611C2.16811 12.7797 2.00999 12.3979 2.00999 11.9999C2.00999 11.6018 2.16811 11.2201 2.44957 10.9386C2.73103 10.6572 3.11277 10.4991 3.51082 10.4991C3.70783 10.499 3.90292 10.5378 4.08494 10.6132C4.26696 10.6886 4.43234 10.7991 4.57165 10.9384C4.71096 11.0777 4.82146 11.2431 4.89684 11.4251C4.97223 11.6071 5.01101 11.8022 5.01099 11.9992C5.01086 12.3966 4.85302 12.7778 4.57212 13.0589C4.29122 13.3401 3.91023 13.4982 3.51281 13.4987L3.51082 13.5007ZM20.4814 13.5007C20.1847 13.5007 19.8947 13.4127 19.6481 13.2479C19.4014 13.083 19.2092 12.8487 19.0957 12.5747C18.9822 12.3006 18.9525 11.999 19.0104 11.708C19.0683 11.4171 19.2111 11.1498 19.4209 10.9401C19.6307 10.7303 19.8979 10.5874 20.1889 10.5296C20.4798 10.4717 20.7814 10.5014 21.0555 10.6149C21.3296 10.7284 21.5638 10.9206 21.7287 11.1673C21.8935 11.4139 21.9815 11.7039 21.9816 12.0005C21.9809 12.3971 21.8233 12.7772 21.5432 13.0578C21.263 13.3384 20.8832 13.4967 20.4867 13.4981L20.4814 13.5007Z"
          fill="#C0F121"
        />
      </g>
      <defs>
        <clipPath id="clip0_757_5489">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import store from "../store";
import { getAllSupplementProducts } from "../../Services/supplementPlan";
import {
  setAllSupplementProduct,
  setAllSupplementTemplate,
  deleteSupplementReducer,
  updateSupplementTempReducer,
  addSupplementPlanReducer,
  addCustomSupplementReducer,
} from "../reducers/supplementPlan";
import {
  createDocContext,
  deletedocIDContext,
  getAllDocByCollectionContext,
  updatePlanContext,
} from "../../Services/commonContext";
import { createAndUpdatePlanAction } from "../../Services/workoutPlan";
import { addClientSupplement } from "../reducers/myClients";
import { sortedData } from "../../helper";
const getSupplementProducts = async (uid) => {
  const result = await getAllSupplementProducts();
  const custom = await getAllDocByCollectionContext(uid, "customSupplement");
  if (result?.status === 200) {
    store.dispatch(
      setAllSupplementProduct({ products: result.data, customs: custom || [] })
    );
    return result;
  }
};

const getSupplementTempalateAction = async (uid) => {
  const result = await getAllDocByCollectionContext(uid, "supplementPlan");
  if (result?.length) {
    store.dispatch(setAllSupplementTemplate(sortedData(result)));
    return result;
  }
};

const addSupplementTemplateAction = async (url, payload) => {
  const result = await createAndUpdatePlanAction(url, payload);
  if (result?.id) {
    if (payload?.isClientView) {
      store.dispatch(addClientSupplement(payload));
    } else {
      store.dispatch(addSupplementPlanReducer(payload));
    }
    return result;
  }
};
const addCustomSupplementAction = async (url, payload) => {
  const result = await createDocContext(url, payload);
  if (result?.id) {
    store.dispatch(addCustomSupplementReducer(payload));
    return result;
  }
};
const updateSupplementTempalateAction = async (id, payload) => {
  await updatePlanContext("supplementPlan", id, payload);
  store.dispatch(updateSupplementTempReducer(payload));
};

const deleteSupplementTemplateAction = async (docId) => {
  await deletedocIDContext("supplementPlan", docId);
  store.dispatch(deleteSupplementReducer(docId));
};

export {
  getSupplementProducts,
  getSupplementTempalateAction,
  deleteSupplementTemplateAction,
  updateSupplementTempalateAction,
  addSupplementTemplateAction,
  addCustomSupplementAction,
};

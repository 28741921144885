import React from "react";

import Navbar from "./navbar";
import "../style.scss";
const Index = ({
  children,
  logout,
  setsearchQuery,
  searchQuery,
  userProfile,
  loading,
}) => {
  return (
    <>
      <Navbar
        logout={logout}
        setsearchQuery={setsearchQuery}
        searchQuery={searchQuery}
        userProfile={userProfile}
        loading={loading}
      />
      {children}
    </>
  );
};

export default Index;

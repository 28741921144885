import React, { useRef } from "react";

import UserHeader from "./userHeader";
import UserChats from "./userChats";

import { Heading } from "../../../utils";
import { ChatsIcon } from "../../../FeedLibraryIcon";
import WriteSMS from "./writeSMS";

const Index = ({
  activeChat,
  fetchChatsAndMessagesForUser,
  setactiveChat,
  common,
  userChats,
}) => {
  const scroll = useRef();
  return (
    <>
      <div className="w-full h-full flex-between-column   gap-[28px] bg-secondary1 rounded-[20px] py-[20px]">
        <>
          <div className="w-full h-full flex-items-center-column user-chats-about">
            {!!activeChat?.id && (
              <UserHeader
                activeChat={activeChat}
                setactiveChat={setactiveChat}
                userChats={userChats}
              />
            )}
            {!!activeChat?.messages?.length ? (
              <div
                ref={scroll}
                className="w-full user-chats-about h-[650px]  overflow-y-scroll"
              >
                {activeChat?.messages?.map((message, key) => (
                  <UserChats
                    key={key}
                    message={message}
                    reciever={activeChat?.reciever}
                    common={common}
                  />
                ))}
              </div>
            ) : (
              <div className="flex-center-column  m-auto  gap-[20px]">
                <ChatsIcon />
                <Heading
                  text="Select a Client on the left to see your chat history."
                  fontweight="!font-[400]"
                  fontSize="!text-[16px]"
                  className="leading-[28px]"
                  color="text-text6"
                />
              </div>
            )}
          </div>

          {!!activeChat?.id && (
            <WriteSMS
              scroll={scroll}
              activeChat={activeChat}
              fetchChatsAndMessagesForUser={fetchChatsAndMessagesForUser}
              setactiveChat={setactiveChat}
              common={common}
            />
          )}
        </>
      </div>
    </>
  );
};

export default Index;

// libbraries
import {AuthContextProvider} from './context/AuthContext';
import {CustomProvider} from 'rsuite';
import {SkeletonTheme} from 'react-loading-skeleton';
// custom components
import AppRouter from './Routers';

// assets
import 'sweetalert2/src/sweetalert2.scss';
import 'rsuite/dist/rsuite.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/tailwind.css';
import './assets/css/global.scss';

function App() {
  return (
    <AuthContextProvider>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <CustomProvider theme="dark">
          <AppRouter />
        </CustomProvider>
      </SkeletonTheme>
    </AuthContextProvider>
  );
}

export default App;

import React, { useEffect } from "react";
import {
  CirclePlus,
  CopyIcon,
  CrossIcon,
  DeleteIcon,
} from "../../../FeedLibraryIcon";
import {
  Text,
  FeedDropdown,
  LabelFeed,
  FeedInput,
  ToggleButton,
} from "../../../utils";
import { questionOptions } from "../../../schema/menus";

const QuestionType = (props) => {
  const {
    setallQuestions,
    setTempQuestions,
    tempQuestions,
    viewOnly,
    formTitle,
  } = props;

  useEffect(() => {
    if (tempQuestions?.length) {
      const filteredQuestions = tempQuestions?.map((field) => {
        let fieldOpt = "";
        if (
          field.type === "control_dropdown" ||
          field.type === "control_radio" ||
          field.type === "control_checkbox"
        ) {
          field?.options?.map((opt, counter) => {
            return (fieldOpt =
              field?.options?.length === counter + 1
                ? fieldOpt + opt.value
                : fieldOpt + opt.value + " |");
          });
          return {
            name: field.name,
            text: field.text,
            type: field.type,
            order: field.order,
            required: field.required,
            options: fieldOpt,
          };
        } else if (field.type === "control_fileupload") {
          return {
            name: "file_upload",
            text: field.text,
            type: "control_textbox",
            order: field.order,
            required: field.required,
            maxFileSize: field.maxFileSize,
            limitFileSize: true,
          };
        } else {
          return {
            ...field,
          };
        }
      });
      setallQuestions([
        ...filteredQuestions,
        {
          name: "clientId",
          text: "",
          type: "control_textbox",
          order: filteredQuestions?.length + 1,
          hidden: "Yes",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempQuestions]);

  const onHandleChangeLabel = (name, keyName, newValue) => {
    setTempQuestions(
      tempQuestions.map((item) => {
        if (item.name === name) {
          return { ...item, [keyName]: newValue };
        } else {
          return item;
        }
      }),
    );
  };

  const onHandleChangeOption = (name, optName, keyName, newValue) => {
    setTempQuestions(
      tempQuestions?.map((item) => {
        if (item.name === name) {
          return {
            ...item,
            options: item.options?.map((opt) => {
              if (opt.name === optName) {
                return { ...opt, [keyName]: newValue };
              } else {
                return opt;
              }
            }),
          };
        } else {
          return item;
        }
      }),
    );
  };
  const addOptionToQuestion = (quest) => {
    setTempQuestions(
      tempQuestions?.map((item) => {
        if (item.name === quest.name) {
          const newKey = quest?.options?.length + 1;
          return {
            ...item,
            options: [
              ...item.options,
              { name: `option${newKey}`, value: ``, order: newKey },
            ],
          };
        } else {
          return item;
        }
      }),
    );
  };
  function deleteAndUpdateQuestion(arr, orderToDelete) {
    const filteredItems = arr.filter((item) => item.order !== orderToDelete);
    for (let i = 0; i < filteredItems.length; i++) {
      filteredItems[i].order = i + 1;
      filteredItems[i].name = "name" + (i + 1);
    }

    return filteredItems;
  }
  return (
    <div className="flex-column gap-[24px] w-full">
      {tempQuestions?.map((item, index) => {
        return (
          <div
            kye={index}
            className={`flex gap-[20px] w-full ${
              viewOnly ? "pointer-events-none" : "pointer-events-auto"
            }`}
          >
            <div className="flex-column w-full">
              <div className="bg-secondary1 rounded-t-[20px] p-[20px] w-full">
                <div className="mb-[24px] w-full flex-between">
                  <Text
                    text={`Create Question For This ${formTitle}`}
                    color={"text-white"}
                    fontSize="text-[18px]"
                    className="leading-6 "
                  />
                  <div className="flex-items-center gap-[16px]">
                    <div
                      className="flex-items-center gap-[8px] cursor-pointer"
                      onClick={() => {
                        const totalquestion = tempQuestions?.length + 1;
                        setTempQuestions([
                          ...tempQuestions,
                          {
                            ...item,
                            order: totalquestion,
                            name: `name${totalquestion}`,
                          },
                        ]);
                      }}
                    >
                      <span>
                        <CopyIcon />
                      </span>
                      <Text
                        text="Duplicate Question"
                        color={"text-textPrimary"}
                        className={"leading-4"}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-column  w-full">
                  <LabelFeed
                    text={`Question: ${item.order}`}
                    color={"text-text1"}
                    className={"mb-[16px]"}
                  />
                  <div className="flex gap-[16px] w-full">
                    <div className="w-full">
                      <FeedInput
                        className="w-full bg-background pl-[20px] text-textPrimary text-[14px]  leading-6 mb-[5px]"
                        onChange={(e) => {
                          onHandleChangeLabel(
                            item.name,
                            "text",
                            e.target.value,
                          );
                        }}
                        value={item.text}
                        type="text"
                        placeholder="Type your question here"
                        name={`name${item.order}`}
                      />
                    </div>
                    <FeedDropdown
                      placeholder="Select a Question Type"
                      className="w-[253px]"
                      options={questionOptions}
                      name="type"
                      value={item.type}
                      setFieldValueFull={(e) => {
                        onHandleChangeLabel(item.name, "type", e.value);
                      }}
                    />
                  </div>
                  {!item.text && (
                    <div className="text-lightRed text-[14px] pl-1">
                      {"Required"}
                    </div>
                  )}
                  {(item?.type === "control_dropdown" ||
                    item?.type === "control_radio" ||
                    item.type === "control_checkbox") && (
                    <>
                      <div className="grid grid-cols-3 gap-3 mt-[12px]">
                        {item?.options?.map((opt, index) => (
                          <div className="flex items-center gap-2" key={index}>
                            <FeedInput
                              className="w-full pl-[20px] text-textPrimary text-[16px] leading-6"
                              name={opt.name}
                              value={opt.value}
                              onChange={(e) => {
                                onHandleChangeOption(
                                  item.name,
                                  opt.name,
                                  "value",
                                  e.target.value,
                                );
                              }}
                              type="text"
                              placeholder={`Option ${opt.order}`}
                            />
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                setTempQuestions(
                                  tempQuestions?.map((quest) => {
                                    if (quest.name === item.name) {
                                      return {
                                        ...quest,
                                        options: deleteAndUpdateQuestion(
                                          quest?.options,
                                          opt.order,
                                        ),
                                      };
                                    } else {
                                      return quest;
                                    }
                                  }),
                                );
                              }}
                            >
                              <CrossIcon />
                            </span>
                          </div>
                        ))}
                      </div>
                      <div
                        className="flex-items-center  w-[110px] gap-1 mt-3 cursor-pointer"
                        onClick={() => addOptionToQuestion(item)}
                      >
                        <CirclePlus />
                        <Text text={"Add Option"} color={"text-primary1"} />
                      </div>
                    </>
                  )}
                  {item?.type === "control_fileupload" && (
                    <div className="max-w-[320px] flex-center-between  mt-[24px]">
                      <LabelFeed
                        text={`Select File Size(MB)`}
                        color={"text-text1"}
                        fontSize={"text-[14px]"}
                        className={"w-[150px]"}
                      />
                      <FeedDropdown
                        toggleBtnClass={"bg-transparent !w-[40px] p-0"}
                        options={[
                          { id: "5000", name: "5" },
                          { id: "10000", name: "10" },
                          { id: "15000", name: "15" },
                        ]}
                        name="maxFileSize"
                        value={
                          item.maxFileSize === "5000" ? "5" : item.maxFileSize
                        }
                        setFieldValueFull={(e) => {
                          onHandleChangeLabel(item.name, "maxFileSize", e.id);
                        }}
                      />
                    </div>
                  )}
                  {item?.type === "control_scale" && (
                    <div className="max-w-[320px] flex-column ">
                      <div className="flex-center-between gap-[40px] mt-[24px]">
                        <LabelFeed
                          text={`Enter from text`}
                          color={"text-text1"}
                          fontSize={"text-[14px]"}
                          className={" w-[150px]"}
                        />
                        <FeedInput
                          className="!w-[150px] pl-[20px]  text-textPrimary text-[16px] leading-6 float-right"
                          name={"fromText"}
                          value={item.fromText}
                          onChange={(e) => {
                            onHandleChangeLabel(
                              item.name,
                              "fromText",
                              e.target.value,
                            );
                          }}
                          type="text"
                        />
                      </div>
                      <div className="flex-center-between gap-[40px] mt-[24px]">
                        <LabelFeed
                          text={`Select Scale From`}
                          color={"text-text1"}
                          fontSize={"text-[14px]"}
                          className={"mb-[16px]"}
                        />
                        <FeedDropdown
                          options={[
                            { id: "1", name: "1" },
                            { id: "2", name: "2" },
                            { id: "3", name: "3" },
                            { id: "4", name: "4" },
                            { id: "5", name: "5" },
                          ]}
                          name="scaleFrom"
                          value={item.scaleFrom}
                          setFieldValueFull={(e) => {
                            onHandleChangeLabel(item.name, "scaleFrom", e.id);
                          }}
                          toggleBtnClass={"bg-transparent !w-[40px] p-0"}
                        />
                      </div>
                      <div className="flex-center-between gap-[40px] mt-[24px]">
                        <LabelFeed
                          text={`Enter To text`}
                          color={"text-text1"}
                          fontSize={"text-[14px]"}
                          className={" w-[150px]"}
                        />
                        <FeedInput
                          className="!w-[150px] pl-[20px] text-textPrimary text-[16px] leading-6 float-right"
                          name={"toText"}
                          value={item.toText}
                          onChange={(e) => {
                            onHandleChangeLabel(
                              item.name,
                              "toText",
                              e.target.value,
                            );
                          }}
                          type="text"
                        />
                      </div>
                      <div className="flex-center-between gap-[40px] mt-[24px]">
                        <LabelFeed
                          text={`Select Scale Amount`}
                          color={"text-text1"}
                          fontSize={"text-[14px]"}
                          className={"mb-[16px]"}
                        />
                        <FeedDropdown
                          name="scaleAmount"
                          options={[
                            { id: "1", name: "1" },
                            { id: "2", name: "2" },
                            { id: "3", name: "3" },
                            { id: "4", name: "4" },
                            { id: "5", name: "5" },
                            { id: "6", name: "6" },
                            { id: "7", name: "7" },
                            { id: "8", name: "8" },
                            { id: "9", name: "9" },
                            { id: "10", name: "10" },
                          ]}
                          value={item.scaleAmount}
                          setFieldValueFull={(e) => {
                            onHandleChangeLabel(item.name, "scaleAmount", e.id);
                          }}
                          toggleBtnClass={"bg-transparent !w-[40px] p-0"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-center-between py-[40px] px-[20px] bg-secondary1 rounded-b-[20px] p-[20px] border-t border-[rgba(200, 200, 200, 1)]">
                <div className=""></div>
                <div className="flex items-center ">
                  <span
                    className="mr-[20px] cursor-pointer"
                    onClick={() => {
                      if (tempQuestions?.length !== 1) {
                        const newArray = deleteAndUpdateQuestion(
                          tempQuestions,
                          item.order,
                        );
                        setTempQuestions(newArray);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </span>
                  <div className="flex-items-center">
                    <LabelFeed
                      text={`Required`}
                      color={"text-text1"}
                      fontSize={"text-[18px]"}
                      className={"mr-3 "}
                    />
                    <ToggleButton
                      onChange={(e) => {
                        onHandleChangeLabel(item.name, "required", e);
                      }}
                      name={`name${item.order}`}
                      checked={item.required}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default QuestionType;

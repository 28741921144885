import React from 'react';
import CreateTemplateForm from '../../components/createTemplateForm/templateForm';
export const IntakeForm = () => {
  return (
    <div className="main-container">
      <CreateTemplateForm formTitle="Intake Form" />
    </div>
  );
};

export const CheckInForm = () => {
  return (
    <div className="main-container">
      <CreateTemplateForm formTitle="Check-In Form" />
    </div>
  );
};

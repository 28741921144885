/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Heading } from "../../utils";
import { Plus } from "../../FeedLibraryIcon";
import ClientTable from "./clientTable";
import { Tab, Tabs } from "react-bootstrap";
import ClientTypes from "./clientTable/clientTypes";
import CreateClientForm from "./createClientForm";
import AddClientType from "./addClientType";

const Index = ({ searchQuery, uid, loading }) => {
  const { common, uiSettings } = useSelector((state) => state);
  const { selectedClient } = useSelector((state) => state.myClient);
  const [addClient, setAddClient] = useState(
    uiSettings?.formName === "edit-client" ? true : false
  );
  const [show, setShow] = useState(false);
  const [clientUser, setclientUser] = useState([]);
  const [clientTyps, setClientTyps] = useState([]);
  const [selectType, setSelectType] = useState("All");
  const [pendingClient, setPendingClient] = useState(
    uiSettings?.formName === "edit-client" ? selectedClient : {}
  );
  const [selectedFoodName, setSelectedFoodName] = useState({});
  
  useEffect(() => {
    console.log("common ===> ", common);
    console.log("uiSettings ===> ", uiSettings);
    setclientUser(common?.allmyClients);
    setClientTyps(common?.clientType);
  }, [common?.clientType, common?.allmyClients, addClient]);

  useEffect(() => {
    if (selectType === "All") {
      setclientUser(common?.allmyClients);
    } else {
      setclientUser(
        common?.allmyClients?.filter(
          (item) => item.clientType?.id === selectType?.id
        )
      );
    }
  }, [selectType, common?.clientType]);

  useEffect(() => {
    if (!!searchQuery.length) {
      setclientUser(
        common?.allmyClients?.filter((item) =>
          item?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
        )
      );
    } else {
      setclientUser(common?.allmyClients);
    }
  }, [searchQuery]);

  return (
    <>
      {addClient ? (
        <CreateClientForm
          setAddClient={setAddClient}
          clientUser={clientUser}
          pendingClient={pendingClient}
        />
      ) : (
        <>
          <div className="w-full flex-center-between gap-[16px] mb-[48px]">
            <Heading
              text="My Clients"
              fontSize="text-[40px]"
              fontweight="!font-[700]"
              color="text-textPrimary"
              className="leading-[48px]"
            />

            <Button
              text={"Add Client"}
              rightmargin="mr-[10px]"
              className="!h-auto !px-[20px] !py-[7px] !font-normal rounded-xl "
              bg="bg-primary1"
              color="text-background"
              Icon={<Plus w="20px" color="#202020" />}
              cta={() => {
                setAddClient(true);
                setPendingClient({});
              }}
            />
          </div>
          <div className="mb-[48px] get-started-tabs w-full">
            <Tabs
              defaultActiveKey="active"
              id="uncontrolled-tab-example"
              className="w-full !max-w-fit"
            >
              <Tab
                eventKey="active"
                title={`Active (${clientUser?.length || 0})`}
              >
                <ClientTypes
                  data={clientTyps}
                  selectType={selectType}
                  setSelectType={setSelectType}
                  setShow={setShow}
                  setSelectedFoodName={setSelectedFoodName}
                />
                <ClientTable
                  clientUser={clientUser}
                  allClientTypes={clientTyps}
                  loading={loading}
                  setPendingClient={setPendingClient}
                  setAddClient={setAddClient}
                  addClient={addClient}
                />
              </Tab>
            </Tabs>
          </div>
        </>
      )}

      <AddClientType
        show={show}
        handleClose={() => setShow(false)}
        uid={uid}
        selectedFoodName={selectedFoodName}
        clientTyps={clientTyps}
      />
    </>
  );
};

export default Index;

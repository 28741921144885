import React, { useRef } from "react";
import { Modal } from "rsuite";
import { Button, FeedInput, Heading, LabelFeed } from "../../../utils";
import { CrossIcon } from "../../../FeedLibraryIcon";
import { Formik } from "formik";

const VideoModal = ({
  open,
  handleClose,
  selectedMovement,
  handleTextValues,
}) => {
  const formRef = useRef();

  return (
    <Modal open={open} size={"md"} onClose={handleClose} className="foodModal">
      <Modal.Body className="w-full !p-0  !mt-0 ">
        <Formik
          initialValues={{
            videoUrl: selectedMovement?.videoUrl || "",
          }}
          innerRef={formRef}
          enableReinitialize
          validate={(values) => {
            const errors = {};

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            handleTextValues(selectedMovement?.ID, "videoUrl", values.videoUrl);
            handleClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} className="w-full">
              <Heading
                text={"Movement Video Instructions"}
                color="text-textPrimary"
                fontSize="!text-[22px]"
                className="!font-bold leading-[40px] mb-6 "
              />
              <div className="flex-column  gap-[8px] w-full ">
                <LabelFeed
                  text="Video Url"
                  fontSize={"!text-[14px]"}
                  color="text-textPrimary"
                  className="leading-[22px] "
                />
                <FeedInput
                  name="videoUrl"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.videoUrl}
                  crossicon={values?.videoUrl ? <CrossIcon /> : ""}
                  placeholder="Video Url"
                  type="text"
                  setFieldValue={setFieldValue}
                  className="!text-sm !rounded-[4px]"
                />
              </div>
            </form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer className="w-full flex-between">
        <Button
          text="Cancel"
          type="button"
          bg=" border border-textPrimary"
          className="w-[102px] rounded-[8px] !py-[7px] font-medium text-sm"
          color="text-textPrimary"
          cta={handleClose}
        />

        <Button
          text="Add Link"
          type="submit"
          bg="bg-primary1"
          className="!px-[14px] !py-[7px] rounded-[8px] font-medium text-sm "
          color="text-background"
          cta={() => formRef?.current?.submitForm()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default VideoModal;

import React from "react";

import { Dropdown } from "react-bootstrap";
import { DeleteIcon, DotsThreeVerticalIcon } from "../../../FeedLibraryIcon";

const ActionDropdown = ({ item, handleDeleteMovements }) => {
  return (
    <div className="!pointer-events-auto">
      <Dropdown className="navbar-dropdown  workout-dropdown">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="nav-link nav-dropdown !p-0 !w-8 !h-8 bg"
        >
          <DotsThreeVerticalIcon />
        </Dropdown.Toggle>

        <Dropdown.Menu className="!w-max">
          <Dropdown.Item
            className="nav-link flex items-center gap-[14px]"
            onClick={() => handleDeleteMovements(item.id)}
          >
            <DeleteIcon />
            Delete Movement
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ActionDropdown;

import React from "react";

export const Stepper = ({ stepper, total = 3 }) => {
  return (
    <div className="w-full  flex-center-between">
      {[...Array(total)]?.map((step, index) => (
        <div
          className={`flex-center-start  ${
            index + 1 !== total ? "w-full" : "w-auto"
          }`}
        >
          <div
            className={`${
              stepper?.includes(index + 1)
                ? "stepper-active-class"
                : "stepper-non-active-class"
            }`}
          >
            <span className="text-[16px] font-medium leading-6 not-italic text-background">
              {index + 1}
            </span>
          </div>
          {index + 1 !== total && (
            <div
              className={`border-b ${
                stepper?.includes(index + 2)
                  ? "border-primary1"
                  : "border-textPrimary"
              } w-full`}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

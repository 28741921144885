import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Heading, Button, Text } from "../../utils";
import { MainHeading } from "../../components";
import { FileIcon } from "../../FeedLibraryIcon";
import { useNavigate } from "react-router-dom";
import ProfileModal from "../../components/model/profileModal";
const getStartedFeeds = [
  {
    id: "onboarding",
    title: "Book Onboarding Call",
    desc: "Let our onboarding specialists show you how to maximize your Kaboodle experience!",
    btnText: "Book a Call",
    link: "https://onboarding.getkaboodle.com/explainer-video",
  },
  {
    id: "personlize",
    title: "Personalize Coach Profile",
    desc: "Update your profile to introduce your clients to you and your policies. Here, you’ll be able to manage your internal team, adjust preferences for what is displayed to clients, and see your private templates.",
    btnText: "Update Profile",
    link: "",
  },
  {
    id: "invite-clients",
    title: "Invite Clients",
    desc: "It's time to invite your clients to Kaboodle! Here you can assign meal plans, establish check-in frequencies, and more!",
    btnText: "Invite Client",
    link: "",
  },
];
const Index = () => {
  const navigate = useNavigate();

  const [openProfile, setOpenProfile] = useState(false);
  return (
    <>
      <div className="get-started-feed">
        <div className="main-container ">
          <div className="pt-[40px] pb-[48px]">
            <MainHeading title="Hey there, Coach" />
          </div>
          <div className="bg-[#030303] p-[20px] rounded-[20px] mb-[48px]">
            <Heading
              text="Get Started Here!"
              className="mb-[16px] leading-6"
              color="text-white"
              fontweight="font-medium"
              fontSize="text-[28px]"
            />
            <div className="mb-[48px]">
              <Text
                text="Complete these steps first so you get the most out of Kaboodle"
                className="!leading-5 !font-light"
                fontSize="!text-[16px]"
                color="text-whiteGray"
              />
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[16px] ">
              {getStartedFeeds?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex-between-column gap-[16px] bg-background max-w-[429px] p-[20px] rounded-lg  md:mb-0 xs:mb-[16px]"
                  >
                    <div className="w-full ">
                      <Heading
                        text={item.title}
                        fontSize="text-[16px]"
                        color="text-textPrimary"
                        className="!leading-6 !font-medium mb-[16px]"
                      />

                      <Text
                        text={item.desc}
                        className="!leading-5  !font-light !tracking-[0.25px]"
                        color="text-whiteGray"
                        fontSize="!text-[14px]"
                      />
                    </div>
                    <Button
                      text={item.btnText}
                      bg="bg-primary1"
                      className="!w-max !py-[10px] !px-[20px] rounded-lg"
                      color="text-background"
                      cta={() => {
                        if (item.id === "onboarding") {
                          window.open(
                            "https://api.leadconnectorhq.com/widget/bookings/kaboodle-demo",
                            "_blank",
                          );
                        }
                        if (item.id === "personlize") {
                          setOpenProfile(true);
                        }
                        if (item.id === "invite-clients") {
                          navigate("/my-clients");
                        }
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mb-[48px] relative  get-started-tabs  home-tab w-full">
            <div className="absolute top-0 right-0">
              <Button
                text={"+ Add Client"}
                bg="bg-primary1"
                className="!w-max !py-[10px] !px-[20px] rounded-lg"
                color="text-background"
                cta={() => navigate("/my-clients")}
              />
            </div>
            <Tabs
              defaultActiveKey="all"
              id="uncontrolled-tab-example"
              className="w-full"
            >
              <Tab eventKey="all" title="All">
                <div className="w-full bg-secondary1 min-h-[320px] rounded-[20px] flex-center-center ">
                  <div className="flex flex-col gap-2  items-center">
                    <FileIcon />
                    <Text
                      text="You don’t have any active clients yet."
                      color="text-whiteGray"
                      className="!text-base"
                    />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="messages" title="Messages">
                <div className="w-full bg-secondary1 min-h-[320px] rounded-[20px] flex-center-center ">
                  <div className="flex flex-col gap-2  items-center">
                    <FileIcon />
                    <Text
                      text="You don’t have any message yet."
                      color="text-whiteGray"
                      className="!text-base"
                    />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Intake Form" title="Intake Form">
                <div className="w-full bg-secondary1 min-h-[320px] rounded-[20px] flex-center-center ">
                  <div className="flex flex-col gap-2  items-center">
                    <FileIcon />
                    <Text
                      text="You don’t have any active intake yet."
                      color="text-whiteGray"
                      className="!text-base"
                    />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="check-in" title="Daily Check-in">
                <div className="w-full bg-secondary1 min-h-[320px] rounded-[20px] flex-center-center ">
                  <div className="flex flex-col gap-2  items-center">
                    <FileIcon />
                    <Text
                      text="You don’t have any active check-in yet."
                      color="text-whiteGray"
                      className="!text-base"
                    />
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>

      {openProfile && (
        <ProfileModal
          open={openProfile}
          handleClose={() => {
            setOpenProfile(false);
          }}
        />
      )}
    </>
  );
};

export default Index;

import React from "react";
import Skeleton from "react-loading-skeleton";

export const TemplateSkeleton = () => {
  return (
    <div className="flex flex-wrap align-content-start gap-[19px] h-full ">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((index) => (
        <div
          key={index}
          className="teritary-grid !bg-background2 !p-[16px] w-[333px] flex flex-col h-fit "
        >
          <Skeleton height={8} width={80} />
          <Skeleton height={8} width={160} />
          <Skeleton count={4} height={6} />
          <div className="flex gap-[8px] items-center w-full"></div>
        </div>
      ))}
    </div>
  );
};

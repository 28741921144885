import React from 'react';

export const FoodFavIcon = () => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9989 6.5L13.3679 9.94147C13.1964 10.0906 13.0689 10.2837 12.9989 10.5C12.929 10.7163 12.9472 10.7787 12.9989 11L13.9989 16L9.39992 13.8314C9.20526 13.7128 8.98174 13.6501 8.75383 13.6501C8.52591 13.6501 8.19358 13.7128 7.99892 13.8314L4.49892 16H3.49892L4.49892 11C4.5506 10.7787 4.56885 10.7163 4.49892 10.5C4.42899 10.2837 4.30143 10.0906 4.12994 9.94147L0.5 7C0.499714 6.99766 0.746955 5.76097 1.5 6H5.5C5.72672 5.98002 5.90071 5.66023 6.08451 5.52599C6.26831 5.39176 6.41197 5.20989 6.5 5L8.75383 0.5L10.956 5.23828C11.044 5.44817 11.1876 5.63004 11.3714 5.76427C11.5552 5.89851 11.7722 5.98002 11.9989 6L16.9989 6.5Z"
        fill="#C0F121"
        fill-opacity="0.2"
      />
      <path
        d="M17.4414 6.35063C17.3657 6.11733 17.2231 5.91146 17.0312 5.75865C16.8394 5.60584 16.6068 5.51285 16.3625 5.49125L11.7227 5.09125L9.90392 0.76625C9.80923 0.53931 9.64951 0.345459 9.44487 0.209108C9.24024 0.0727563 8.99983 0 8.75392 0C8.50802 0 8.26761 0.0727563 8.06298 0.209108C7.85834 0.345459 7.69862 0.53931 7.60392 0.76625L5.79064 5.09125L1.14533 5.49359C0.900045 5.51421 0.666305 5.60678 0.473422 5.7597C0.280539 5.91262 0.137099 6.11909 0.0610892 6.35321C-0.0149204 6.58733 -0.0201157 6.83868 0.0461548 7.07574C0.112425 7.3128 0.247212 7.52502 0.433611 7.68578L3.95783 10.7655L2.90158 15.3389C2.84573 15.5782 2.86166 15.8287 2.9474 16.059C3.03313 16.2894 3.18486 16.4893 3.38361 16.6339C3.58236 16.7784 3.81931 16.8612 4.06484 16.8718C4.31037 16.8824 4.55359 16.8204 4.76408 16.6936L8.74846 14.2717L12.7414 16.6936C12.9519 16.8204 13.1951 16.8824 13.4407 16.8718C13.6862 16.8612 13.9231 16.7784 14.1219 16.6339C14.3206 16.4893 14.4724 16.2894 14.5581 16.059C14.6438 15.8287 14.6598 15.5782 14.6039 15.3389L13.5485 10.7608L17.0719 7.68578C17.2583 7.52446 17.3928 7.31164 17.4585 7.07408C17.5243 6.83651 17.5183 6.58481 17.4414 6.35063ZM16.2524 6.74125L12.7289 9.81625C12.5574 9.96542 12.4299 10.1585 12.3599 10.3748C12.29 10.591 12.2804 10.8223 12.332 11.0436L13.3906 15.6248L9.4008 13.203C9.20614 13.0844 8.98262 13.0217 8.75471 13.0217C8.52679 13.0217 8.30327 13.0844 8.10861 13.203L4.12424 15.6248L5.1758 11.0467C5.22748 10.8254 5.21784 10.5942 5.14791 10.3779C5.07798 10.1616 4.95041 9.96854 4.77892 9.81938L1.25392 6.74594C1.25364 6.7436 1.25364 6.74124 1.25392 6.73891L5.89767 6.33734C6.12439 6.31736 6.34136 6.23585 6.52516 6.10162C6.70895 5.96738 6.85262 5.78551 6.94064 5.57563L8.75392 1.25609L10.5664 5.57563C10.6545 5.78551 10.7981 5.96738 10.9819 6.10162C11.1657 6.23585 11.3827 6.31736 11.6094 6.33734L16.2539 6.73891C16.2539 6.73891 16.2539 6.74359 16.2539 6.74438L16.2524 6.74125Z"
        fill="#C0F121"
      />
    </svg>
  );
};

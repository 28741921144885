import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import FeedBreadcrumbs from "../common/breadcrumbs";
import MainHeading from "../common/MainHeading";
import { CirclePlus } from "../../FeedLibraryIcon";
import {
  Text,
  FeedInput,
  Heading,
  Stepper,
  Button,
  LabelFeed,
  TextArea,
  DatePicker,
} from "../../utils";
import QuestionType from "./formComponents/questionType";
import { customDateFormat, getQuestionLabel } from "../../helper";
import { toast } from "react-toastify";
import AssignToClient from "../common/assignToClient";
import {
  createTemplateFormAction,
  deleteTemplateFormAction,
} from "../../store/actions/template";
import { setbtnLoaderReducer } from "../../store/reducers/uiSettings";
import { useNavigate } from "react-router-dom";

const QuestionaireData = [
  {
    order: 1,
    text: "",
    name: "name1",
    type: "control_textbox",
    required: false,
    options: [],
    fromText: "Worst",
    toText: "Best",
    scaleFrom: "1",
    scaleAmount: "10",
    maxFileSize: "5000",
    limitFileSize: true,
    // initialValue: '',
    // value: '',
    // placeholder: 'Write Here',
  },
];
const CreateTemplateForm = ({ setactiveTemplateState, formTitle }) => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { common, uiSettings } = useSelector((state) => state);
  const { editForm } = uiSettings;
  const { selectedTemplateName } = uiSettings?.templateName;
  const [stepper, setStepper] = useState([1]);
  const [allQuestions, setallQuestions] = useState([]);
  const [tempQuestions, setTempQuestions] = useState([]);
  const [selectedClient, setselectedClient] = useState([]);
  const [startDate, setstartDate] = useState(
    editForm?.start_date || "yyyy-mm-dd",
  );

  useEffect(() => {
    if (QuestionaireData?.length && !editForm?.id) {
      setTempQuestions(QuestionaireData);
    }
    if (editForm?.id) {
      setTempQuestions(editForm?.fields);
    }
    if (editForm?.clientIds) {
      const allclients = common?.allClients?.filter((f) =>
        editForm?.clientIds?.includes(f.uid),
      );
      setselectedClient(
        allclients?.map((item) => {
          return {
            ...item,
            id: item?.uid,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editForm]);
  const createFormFieldsSubmission = async (values) => {
    dispatch(setbtnLoaderReducer(true));

    if (uiSettings?.formName === "edit-template") {
      await deleteTemplateFormAction(editForm?.docId, editForm?.id);
    }
    const formData = new FormData();
    allQuestions.forEach((question, index) => {
      for (const key in question) {
        formData.append(`questions[${index}][${key}]`, question[key]);
      }
    });
    formData.append("properties[title]", values.name);
    formData.append("properties[height]", 600);
    formData.append("hipaa", true);
    const payload = {
      ...values,
      start_date: customDateFormat(startDate),
      usedCount: selectedClient?.length,
      templateType: editForm?.templateType || "intake",
      clientIds: selectedClient
        ?.filter?.((d) => d.uid)
        ?.map((client) => client.uid),
      coachId: common?.user?.uid,
      fields: tempQuestions,
      isClientView: editForm?.isClientView ? true : false,
      createdAt:
        uiSettings?.formName === "edit-template"
          ? editForm?.createdAt
          : values.createdAt,
    };
    const result = await createTemplateFormAction(formData, payload);
    if (result?.responseCode === 200) {
      toast(
        `${
          editForm?.templateType === "check-in" ? "Check-In" : "Intake"
        } 'Form ${
          uiSettings?.formName === "edit-template" ? "updated" : "created"
        } Successfully'`,
        {
          type: "success",
          autoClose: 5000,
        },
      );
      if (editForm?.templateType === "check-in") {
        navigate(editForm?.closer || `/template?type=check-in`);
      } else {
        navigate(editForm?.closer || `/template?type=intake`);
      }
    }

    dispatch(setbtnLoaderReducer(false));
  };
  return (
    <>
      <div className="mb-[32px] pt-[24px]">
        <Stepper stepper={stepper} total={2} />
      </div>
      <div className="py-[36px]">
        <FeedBreadcrumbs
          backClick={() => {
            if (editForm?.templateType === "check-in") {
              navigate(editForm?.closer || "/template?type=check-in");
            } else {
              navigate(editForm?.closer || "/template?type=intake");
            }
          }}
          selectedTemplateName={selectedTemplateName}
          formRef={formRef}
          crumbText={
            editForm?.templateType === "check-in"
              ? "CheckIn Form"
              : "Intake Form"
          }
        />
        <MainHeading
          title={` ${
            uiSettings?.templateName?.name || `Create a ${formTitle}`
          }`}
          description={`Create Questions for this ${formTitle}`}
          secCTA={() => setactiveTemplateState()}
        />
      </div>

      <Formik
        initialValues={{
          name: editForm?.name ? editForm?.name : "",
          clientNote: editForm?.clientNote || "",
          start_date: startDate,
          createdAt: new Date().toLocaleString(),
          updatedAt: new Date().toLocaleString(),
        }}
        innerRef={formRef}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = "Required";
          }
          // if (
          //   startDate === "yyyy-mm-dd" ||
          //   startDate === "" ||
          //   startDate === undefined
          // ) {
          //   errors.start_date = "Required";
          // }
          return errors;
        }}
        onSubmit={async (values) => {
          createFormFieldsSubmission(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div
              className={`w-full ${
                editForm?.templateType === "check-in" &&
                "grid grid-cols-2 gap-[16px]"
              }`}
            >
              <div className="w-full flex-column  mb-[24px] autofill-fix">
                <Text
                  text={`${formTitle || "Template"} Name`}
                  fontSize={"text-[18px]"}
                  color="text-textPrimary"
                />
                <FeedInput
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="w-full pl-[20px] text-textPrimary bg-secondary1 text-[16px] leading-6 mt-[12px] "
                  value={values?.name}
                  setFieldValue={setFieldValue}
                  placeholder="Add Name"
                  disabled={uiSettings?.formName === "view-template"}
                />
                {errors.name && touched.name && (
                  <div className="text-red text-[14px] pl-1 pt-[5px]">
                    {errors.name}
                  </div>
                )}
              </div>
              {editForm?.templateType === "check-in" && (
                <div
                  className={`flex-column gap-[12px] w-full ${
                    uiSettings?.formName === "view-template"
                      ? "pointer-events-none"
                      : "pointer-events-auto"
                  }`}
                >
                  <LabelFeed
                    text={
                      <>
                        Start Date{" "}
                        {/* <span className="text-primary1">*</span> */}
                      </>
                    }
                    fontSize={"!text-[18px]"}
                    color="text-textPrimary"
                    className="leading-[27px] tracking-[0.5px]"
                  />
                  <DatePicker
                    value={startDate}
                    placeholder="Select start date"
                    onChange={(e) => {
                      setstartDate(e);
                    }}
                    className="react-date-black"
                  />
                  {/* {errors.start_date && (
                            <LabelFeed
                              text="Required"
                              color="text-red"
                              fontSize={"text-[14px]"}
                              className="!leading-[22px]"
                            />
                          )} */}
                </div>
              )}
            </div>
            <div className="flex gap-[24px] w-full relative">
              <div
                className={`h-[674px] sticky top-[54px] w-[340px] bg-secondary1 overflow-y-auto p-[20px] rounded-[20px] ${
                  uiSettings?.formName === "view-template"
                    ? "pointer-events-none"
                    : "pointer-events-auto"
                } `}
              >
                <div className="flex-column mb-[24px] gap-[8px] cursor-pointer relative ">
                  {allQuestions?.map(
                    (field) =>
                      field.name !== "clientId" && (
                        <div
                          key={field.name}
                          className={`flex-items-center bg-[#202020] p-[12px] rounded-[12px] `}
                        >
                          <span className={`mr-[12px]`}>
                            {getQuestionLabel(field.type)?.icon}
                          </span>
                          <Heading
                            text={
                              getQuestionLabel(field.type, field.name)?.name
                            }
                            fontSize="text-[16px]"
                            color="text-[#fff]"
                            fontweight="font-normal"
                            className={`leading-6 tracking-[0.5px]`}
                          />
                        </div>
                      ),
                  )}
                  {!stepper?.includes(2) && (
                    <div
                      className={`flex-items-center bg-[#202020] p-[12px] rounded-[12px] `}
                      onClick={() => {
                        const totalquestion = tempQuestions?.length + 1;
                        setTempQuestions([
                          ...tempQuestions,
                          {
                            ...QuestionaireData?.[0],
                            order: totalquestion,
                            name: `name${totalquestion}`,
                          },
                        ]);
                      }}
                    >
                      <span className={`mr-[12px]`}>{<CirclePlus />}</span>
                      <Heading
                        text={"Add Question"}
                        fontSize="text-[16px]"
                        color="text-[#fff]"
                        fontweight="font-normal"
                        className={`leading-6 tracking-[0.5px]`}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full">
                {stepper?.includes(2) ? (
                  <>
                    <AssignToClient
                      options={common?.allClients?.map((client) => {
                        return {
                          ...client,
                          id: client.uid,
                          name: client.name,
                        };
                      })}
                      error={
                        errors.selectedClientIds && touched.selectedClientIds
                          ? errors.selectedClientIds
                          : ""
                      }
                      setselectedClient={setselectedClient}
                      selectedClient={selectedClient}
                      disabled={
                        uiSettings?.formName === "view-template" ||
                        editForm?.isClientView
                          ? true
                          : false
                      }
                      formTitle={formTitle}
                    />

                    <div className="p-[20px] rounded-[20px] bg-secondary1 w-full mt-[16px]">
                      <LabelFeed
                        text="Client Notes"
                        fontweight={"font-medium"}
                        className={"mb-[20px]"}
                      />
                      <TextArea
                        placeholder="Add notes here"
                        rows={9}
                        cols={5}
                        name="clientNote"
                        className="p-[16px] rounded-[20px]"
                        value={values.clientNote}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        handleBlur={handleBlur}
                        disabled={uiSettings?.formName === "view-template"}
                      />
                    </div>
                  </>
                ) : (
                  <QuestionType
                    formTitle={formTitle}
                    setallQuestions={setallQuestions}
                    allQuestions={allQuestions}
                    setTempQuestions={setTempQuestions}
                    tempQuestions={tempQuestions}
                    uiSettings={uiSettings}
                    viewOnly={
                      uiSettings?.formName === "view-template" ? true : false
                    }
                  />
                )}
                <div className="flex-center-between pt-[24px]">
                  <Button
                    text="Back"
                    type="button"
                    bg="bg-background border border-[#E6E6E6]"
                    className="px-[30px] rounded-[12px] leading-6 text-[14px]"
                    color="text-textPrimary"
                    cta={() => {
                      if (stepper?.includes(2)) {
                        setStepper([1]);
                      } else {
                        navigate(editForm?.closer || "/template");
                      }
                    }}
                  />
                  {stepper?.includes(2) &&
                  uiSettings?.formName === "view-template" ? (
                    ""
                  ) : (
                    <Button
                      text={stepper?.includes(2) ? "Save & Exit" : "Next"}
                      type="button"
                      bg="bg-primary1"
                      className="px-[40px]  rounded-[12px] leading-6 text-[16px]"
                      color="text-background"
                      cta={() => {
                        if (stepper?.includes(2)) {
                          formRef.current?.submitForm();
                          window.scrollTo(0, 0);
                        }
                        const emptyQuestions = allQuestions.some(
                          (obj) =>
                            obj.hasOwnProperty("text") &&
                            obj.text.trim() === "" &&
                            obj.name !== "clientId",
                        );
                        if (!emptyQuestions) {
                          setStepper([1, 2]);
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default CreateTemplateForm;

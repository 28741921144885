import React, { useState } from "react";
import { Formik } from "formik";
import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "../../configs/firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import {
  FeedInput,
  LabelFeed,
  Button,
  Heading,
  FeedLink,
  FeedCheckbox,
  Spinner,
} from "../../utils";
import { Password, Envelope, EyeOn, EyeOff } from "../../FeedLibraryIcon";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  return (
    <div className=" h-[100vh] flex-center-center">
      <div className="w-[550px] rounded-[20px] flex-items-center-column bg-[#0E0E0E]  px-[20px] py-[30px]">
        <div className="mb-[48px]">
          <Heading text="Welcome Back!" className="" />
        </div>

        <Formik
          initialValues={{ email: "", password: "" }}
          enableReinitialize
          validate={(values) => {
            const errors = {};
            setError("");
            if (!values.email) {
              errors.email = "Required";
            }
            if (!values.password) {
              errors.password = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            // firebase auth login
            try {
              let coach;
              const q = query(
                collection(db, "users"),
                where("email", "==", values.email)
              );
              const querySnapshot = await getDocs(q);
              querySnapshot.forEach((doc) => {
                coach = doc.data();
              });

              // check that only coach and admin can login
              console.log("user from firestore ===> ", coach);
              if (coach?.userType === "client") {
                setError("auth/invalid-login-credentials");
                throw new Error("Clients are not allowed to login here.");
              }

              const response = await login(values.email, values.password, {
                setSubmitting,
                setError,
              });

              if (response?.user?.accessToken) {
                const user = response?.user;
                if (coach?.userType === "coach" || coach?.userType === "admin") {
                  localStorage.setItem(
                    "f4f-token",
                    response?.user?.accessToken
                  );
                  localStorage.setItem("kaboodle-Uid", user?.uid);
                  navigate("/");
                } else {
                  setError("auth/invalid-login-credentials");
                }
              }
              // User is logged in successfully
            } catch (error) {
              console.error("Login error:", error.message);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <>
              <form onSubmit={handleSubmit} className="flex-column  w-full">
                <div className="w-full flex-items-start-column mb-[20px]">
                  <LabelFeed text="Email Address" className={"mb-[12px]"} />
                  <FeedInput
                    className="w-full"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    setFieldValue={setFieldValue}
                    type="text"
                    icon={<Envelope />}
                    placeholder="Enter Your Email"
                    name="email"
                  />
                  {errors.email && touched.email && (
                    <div className="text-red text-[14px]  pt-[4px]">
                      {errors.email}
                    </div>
                  )}
                </div>

                <div className="w-full flex-items-start-column mb-[36px] relative">
                  <LabelFeed text="Password" className={"mb-[12px]"} />
                  <FeedInput
                    className="w-full"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    setFieldValue={setFieldValue}
                    type={showPassword ? "text" : "password"} // Toggle input type
                    icon={<Password />}
                    placeholder="Enter Your Password"
                    name="password"
                    // helperText={'Must Be at least 8 Characters '}
                  />
                  <span 
                    className="absolute right-3 top-[50px] cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeOff /> : <EyeOn />}
                  </span>
                  {errors.password && touched.password && (
                    <div className="text-red text-[14px]  pt-[4px]">
                      {errors.password}
                    </div>
                  )}
                  <div className="flex-center-between mt-[12px] w-full">
                    <div className="flex items-center">
                      <FeedCheckbox
                        text="Remember me"
                        id="rememberMe"
                        className="text-[14px]"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.checked}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    {/* <Text
                      className=""
                      color="text-text3"
                      text="Must Be at least 8 Characters"
                    /> */}
                  </div>
                </div>
                {error && (
                  <div className="text-red text-[14px] text-center mb-[36px]">
                    {error?.includes("auth/invalid-login")
                      ? "Invalid username/password"
                      : error}
                  </div>
                )}
                <Button
                  type="submit"
                  bg="bg-primary1"
                  text={
                    isSubmitting ? (
                      <Spinner className="!text-secondary2" />
                    ) : (
                      "Login"
                    )
                  }
                  className=" w-full rounded-lg px-[24px] py-[12px]"
                  color="text-background"
                />

                <div className=" my-[32px] text-center">
                  <FeedLink path="/forget-password" text={" Forgot Password"} />
                </div>

                {/* <div className="text-center flex justify-center items-center">
                  <Text color="text-text3" text="Don,t Have an Account?" className="mr-1" />
                  <FeedLink path="/register" text="Sign up" />
          </div>*/}
              </form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from "react";
import {
  ArrowDown,
  ArrowUp,
  CrossIcon,
  LinkIcon,
} from "../../../FeedLibraryIcon";
import { Button, FeedInput, LabelFeed, TextArea } from "../../../utils";
import ActionDropdown from "./actionDropdown";
import { Animation } from "rsuite";

export const ExcercisePanel = (props) => {
  const {
    index,
    item,
    handleBlur,
    values,
    setFieldValue,
    handleUpdateMovements,
    handleTextValues,
    uiSettings,
    handleDeleteMovements,
    setselectedMovement,
    setOpen,
  } = props;
  const [openNotes, setOpenNotes] = useState();

  return (
    <div key={index} className="w-full flex-column gap-6 p-[20px] rounded-xl bg-secondary1">
      <div className="w-full flex-items-center gap-2">
        <Button
          Icon={openNotes === index ? <ArrowUp color={"#C0F121"} /> : <ArrowDown />}
          bg="bg-activeBackground"
          className="!rounded-lg !w-[44px] !h-[44px] !p-3 flex-shrink-0"
          type="button"
          cta={() => setOpenNotes(openNotes === index ? null : index)}
        />

        <FeedInput
          onBlur={handleBlur}
          disabled
          value={"Order " + (index + 1)}
          setFieldValue={setFieldValue}
          type="text"
          placeholder="Movement Order"
          name="movementOrder"
          crossicon={values?.movementOrder ? <CrossIcon /> : ""}
          width={"!w-[188px] flex-shrink-0"}
          className="w-full h-[44px] !font-light !text-base !rounded-lg !px-3 !py-2 !bg-background"
        />
        <FeedInput
          onChange={(e) => handleTextValues(item.ID, "ExerciseName", e.target.value)}
          onBlur={handleBlur}
          value={item.ExerciseName}
          setFieldValue={setFieldValue}
          type="text"
          placeholder="Movement Name"
          name="movementName"
          crossicon={values?.movementName ? <CrossIcon /> : ""}
          className="!w-full h-[44px] !font-light !text-base !rounded-lg !px-3 !py-2 !bg-background"
        />
        {uiSettings?.formName !== "view-template" && (
          <>
            <Button
              Icon={<LinkIcon />}
              bg="bg-activeBackground"
              className="!rounded-lg !w-[44px] !h-[44px] !p-3 flex-shrink-0"
              type="button"
              cta={() => {
                setselectedMovement(item);
                setOpen(true);
              }}
            />
            <ActionDropdown item={item} handleDeleteMovements={handleDeleteMovements} />
          </>
        )}
      </div>
      <div className="w-full flex-center-between gap-3">
        <div className="flex-items-end gap-[16px]">
          <div className="w-full flex-items-start-column mb-6">
            <LabelFeed text="Sets" className={"mb-2 !text-sm"} />
            <FeedInput
              onChange={(e) => handleUpdateMovements(item.ID, "Sets", e.target.value)}
              onBlur={handleBlur}
              value={item.Sets}
              setFieldValue={setFieldValue}
              type="number"
              placeholder="0"
              name="sets"
              width={"!w-[125px] flex-shrink-0"}
              className="w-full !h-[48px] !text-sm !rounded-lg !px-3 !py-[11px] !bg-background"
            />
          </div>
          <div className="w-full flex-items-start-column mb-6">
            <LabelFeed text="Repetitions" className={"mb-2 !text-sm"} />
            <FeedInput
              onChange={(e) => handleUpdateMovements(item.ID, "Reps", e.target.value)}
              onBlur={handleBlur}
              value={item.Reps}
              setFieldValue={setFieldValue}
              type="number"
              placeholder="0"
              name="repetitions"
              width={"!w-[125px] flex-shrink-0"}
              className="w-full !h-[48px] !text-sm !rounded-lg !px-3 !py-[11px] !bg-background"
            />
          </div>
          <div className="w-full flex-items-start-column mb-6">
            <LabelFeed text="Rest" className={"mb-2 !text-sm"} />
            <FeedInput
              onChange={(e) => handleTextValues(item.ID, "Rest", e.target.value)}
              onBlur={handleBlur}
              value={item.Rest}
              setFieldValue={setFieldValue}
              type="text"
              placeholder="0"
              name="rest"
              width={"!w-[125px] flex-shrink-0"}
              className="w-full !h-[48px] !text-sm !rounded-lg !px-3 !py-[11px] !bg-background"
            />
          </div>
        </div>

        <div className="flex items-end gap-[16px]">
          <div className="w-full flex-items-start-column mb-6">
            <LabelFeed text="Tempo" className={"mb-2 !text-sm"} />
            <FeedInput
              onChange={(e) => handleUpdateMovements(item.ID, "Tempo1", e.target.value)}
              onBlur={handleBlur}
              value={item.Tempo1}
              setFieldValue={setFieldValue}
              type="number"
              placeholder="0"
              name="tempo"
              width={"!w-[125px] flex-shrink-0"}
              className="w-full !h-[48px] !text-sm !rounded-lg !px-3 !py-[11px] !bg-background"
            />
          </div>
          <div className="w-full flex-items-center-column mb-6">
            <FeedInput
              onChange={(e) => handleUpdateMovements(item.ID, "Tempo2", e.target.value)}
              onBlur={handleBlur}
              value={item.Tempo2}
              setFieldValue={setFieldValue}
              type="number"
              placeholder="0"
              name="tempo2"
              width={"!w-[125px] flex-shrink-0"}
              className="w-full !h-[48px] !text-sm !rounded-lg !px-3 !py-[11px] !bg-background"
            />
          </div>
          <div className="w-full flex-items-start-column mb-6">
            <FeedInput
              onChange={(e) => handleUpdateMovements(item.ID, "Tempo3", e.target.value)}
              onBlur={handleBlur}
              value={item.Tempo3}
              setFieldValue={setFieldValue}
              type="number"
              placeholder="0"
              name="tempo3"
              width={"!w-[125px] flex-shrink-0"}
              className="w-full !h-[48px] !text-sm !rounded-lg !px-3 !py-[11px] !bg-background"
            />
          </div>
          <div className="w-full flex-items-center-column mb-6">
            <FeedInput
              onChange={(e) => handleUpdateMovements(item.ID, "Tempo4", e.target.value)}
              onBlur={handleBlur}
              value={item.Tempo4}
              setFieldValue={setFieldValue}
              type="number"
              placeholder="0"
              name="tempo4"
              width={"!w-[125px] flex-shrink-0"}
              className="w-full !h-[48px] !text-sm !rounded-lg !px-3 !py-[11px] !bg-background"
            />
          </div>
        </div>
      </div>

      <Animation.Collapse in={openNotes === index} dimension="height">
        <div className="w-full">
          <div className={`w-full flex-items-center-column`}>
            <LabelFeed text="Note for this exercise" className={"mb-[12px] !font-medium !text-lg"} />
            <TextArea
              placeholder="Add Note"
              rows={5}
              name={"exerciseNote"}
              setFieldValue={setFieldValue}
              onChange={(e) => handleTextValues(item.ID, "Note", e.target.value)}
              value={item.Note}
              onBlur={handleBlur}
              className="leading-[24px] h-full !px-3 !py-[11px] text-[16px] font-normal text-text6 rounded-lg !outline-none !border-none !bg-background"
            />
          </div>
        </div>
      </Animation.Collapse>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import ReactApexChart from "react-apexcharts";
import { useDispatch } from "react-redux";
import {
  FeedInput,
  LabelFeed,
  ToggleButton,
  Text,
  Heading,
} from "../../../../utils";

import { CrossIcon } from "../../../../FeedLibraryIcon";
import FeedBreadcrumbs from "../../../../components/common/breadcrumbs";
import { MainHeading } from "../../../../components";
import { seteditFormReducer } from "../../../../store/reducers/uiSettings";
import { mealList } from "../../../../schema/mealPlan";

const MacroTargets = (props) => {
  const {
    formRef,
    uiSettings,
    setplanScreenState,
    setStepper,
    closePlanHandler,
  } = props;
  const dispatch = useDispatch();
  const { editForm } = uiSettings;
  const [ratioUnit, setratioUnit] = useState(
    editForm?.macroTargets?.unit === "perc" ? true : false,
  );
  const [carbohydrates, setCarbohydrates] = useState(
    editForm?.macroTargets?.carbohydrates || 0,
  );
  const [protein, setProtein] = useState(editForm?.macroTargets?.proteins || 0);
  const [fat, setFat] = useState(editForm?.macroTargets?.fat || 0);
  const [totalCalories, setTotalCalories] = useState(
    editForm?.macroTargets?.totalTargetCalories || 0,
  );
  const [carbPerc, setCarbPerc] = useState(
    editForm?.macroTargets?.carbPerc || 0,
  );
  const [proteinPerc, setProteinPerc] = useState(
    editForm?.macroTargets?.protienPerc || 0,
  );
  const [fatPerc, setFatPerc] = useState(editForm?.macroTargets?.fatPerc || 0);

  useEffect(() => {
    if (!ratioUnit) {
      const carbCalories = carbohydrates ? carbohydrates * 4 : 0;
      const proteinCalories = protein ? protein * 4 : 0;
      const fatCalories = fat ? fat * 9 : 0;
      const total = carbCalories + proteinCalories + fatCalories;
      setTotalCalories(total.toFixed(2));
    }
    // Calculate percentages
    const totalFromMacros = carbohydrates + protein + fat;
    const carb_perc = (carbohydrates / totalFromMacros) * 100;
    setCarbPerc(carb_perc ? Math.round(carb_perc) : 0);
    const protin_perc = (protein / totalFromMacros) * 100;
    setProteinPerc(protin_perc ? Math.round(protin_perc) : 0);
    let fat_perc = (fat / totalFromMacros) * 100;
    const totalPerc =
      Math.round(carb_perc) + Math.round(protin_perc) + Math.round(fat_perc);
    if (totalPerc > 100) {
      fat_perc = Math.round(fat_perc) - 1;
    } else if (totalPerc < 100) {
      fat_perc = Math.round(fat_perc) + 1;
    } else if (totalPerc === 100) {
      fat_perc = Math.round(fat_perc);
    }
    setFatPerc(fat_perc ? fat_perc : 0);
  }, [carbohydrates, protein, fat, ratioUnit]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current?.validateForm();
    }
  }, [ratioUnit]);

  const chartData = {
    series: [protein, carbohydrates, fat],

    options: {
      chart: {
        width: 800,
        type: "donut",
      },
      stroke: {
        width: 0,
      },

      dataLabels: {
        showAlways: false,
        style: {
          colors: ["#487AFA", "#67E2F4", "#F93DC0"],
        },
      },
      fill: {
        colors: ["#487AFA", "#67E2F4", "#F93DC0"],
      },
      colors: ["#487AFA", "#67E2F4", "#F93DC0"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                color: "#FFFFFF",
                showAlways: true,
                show: true,
              },
            },
          },
        },
      },
      labels: [
        `Proteins - ${ratioUnit ? protein + "%" : protein + "g"}`,
        `Carbohydrates - ${
          ratioUnit ? carbohydrates + "%" : carbohydrates + "g"
        }`,
        `Fat - ${ratioUnit ? fat + "%" : fat + "g"}`,
      ],
      legend: {
        position: "bottom",
      },

      states: {
        hover: {
          filter: "none",
        },
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 600,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <div className="">
        <FeedBreadcrumbs
          backClick={closePlanHandler}
          selectedTemplateName={{ name: "Meal plan" }}
          formRef={() => {}}
          cancelBtnClass="!border-none !p-2"
          submitBtnClass="!rounded-[8px]"
          marginBottom="!mb-0"
          disabled
          crumbText="Meal Plan"
        />
        <MainHeading title="Macro Targets" description={"Description"} />
      </div>
      <div className="mt-6 ">
        <Formik
          initialValues={{
            name: editForm?.name || "",
            proteins: protein || "",
            carbohydrates: carbohydrates || "",
            fat: fat || "",
            totalTargetCalories: totalCalories,
          }}
          innerRef={formRef}
          enableReinitialize
          validate={(values) => {
            const errors = {};
            const total = values.fat + values.carbohydrates + values.proteins;
            if (!values.name) {
              errors.name = "Required";
            }

            if (!values.proteins) {
              errors.proteins = "Required";
            }

            if (!values.carbohydrates) {
              errors.carbohydrates = "Required";
            }
            if (!values.fat) {
              errors.fat = "Required";
            }
            if (values.proteins >= 100 && ratioUnit) {
              errors.proteins = "Cannot exceed 100%.";
            }
            if (values.carbohydrates >= 100 && ratioUnit) {
              errors.carbohydrates = "Cannot exceed 100%.";
            }
            if (values.fat >= 100 && ratioUnit) {
              errors.fat = "Cannot exceed 100%.";
            }
            if (ratioUnit & (total !== 100)) {
              errors.fat =
                "Sum of protiens,fat and carbohydrates Cannot exceed 100%.";
            }
            if (!values.totalTargetCalories) {
              errors.totalTargetCalories = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            dispatch(
              seteditFormReducer({
                type: uiSettings?.formName || "create-meal",
                data: {
                  ...editForm,
                  macroTargets: {
                    ...values,
                    protienPerc: proteinPerc,
                    carbPerc: carbPerc,
                    fatPerc: fatPerc,
                    unit: ratioUnit ? "perc" : "gram",
                  },
                  mealList: mealList,
                },
              }),
            );
            setStepper([1, 2, 3]);
            setplanScreenState("MealPlanSummary");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <>
              <form
                onSubmit={handleSubmit}
                className={`w-full grid grid-cols-1 lg:grid-cols-2 gap-[17px] ${
                  uiSettings?.formName === "view-template"
                    ? "pointer-events-none"
                    : "pointer-events-auto"
                }`}
              >
                <div className="w-full">
                  <div className="w-full flex-items-start-column mb-4 autofill-fix">
                    <LabelFeed
                      text={
                        <>
                          Meal Plan Name
                          <span className="text-primary1"> *</span>
                        </>
                      }
                      className={"mb-[10px] !font-medium !text-lg"}
                    />
                    <FeedInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      setFieldValue={setFieldValue}
                      type="text"
                      placeholder="Add Name"
                      name="name"
                      crossicon={values?.name ? <CrossIcon /> : ""}
                      className="w-full  !px-5 !bg-secondary1"
                    />
                    {errors.name && touched.name && (
                      <div className="text-red text-[14px]  pt-[4px]">
                        {errors.name}
                      </div>
                    )}
                  </div>
                  <div className="w-full px-[20px] py-6 rounded-[20px] bg-secondary1">
                    <div className="w-full flex-center-between gap-1 mb-6">
                      <Text
                        text="Target Diet Ratio"
                        fontSize="18px"
                        color="text-white"
                      />

                      <div className="w-max flex-items-center  gap-4 ">
                        <Text
                          text="Gram Based"
                          fontSize="18px"
                          color={!ratioUnit ? "text-primary1" : "text-white"}
                        />
                        <div className="">
                          <ToggleButton
                            checked={ratioUnit}
                            onChange={(e) => {
                              setProtein(0);
                              setFat(0);
                              setCarbohydrates(0);
                              setTotalCalories(0);
                              setratioUnit(e);
                            }}
                          />
                        </div>

                        <Text
                          text="Percentage Based "
                          fontSize="18px"
                          color={ratioUnit ? "text-primary1" : "text-white"}
                        />
                      </div>
                    </div>

                    <div className="flex-column gap-4">
                      <div className="w-full flex-items-start-column ">
                        <LabelFeed text="Proteins" className={"mb-4 "} />
                        <FeedInput
                          onChange={(e) => {
                            handleChange(e);
                            setProtein(parseFloat(e.target.value || 0));
                          }}
                          onBlur={handleBlur}
                          value={values.proteins}
                          setFieldValue={setFieldValue}
                          type="number"
                          placeholder={ratioUnit ? "00%" : "00.00 grams"}
                          name="proteins"
                          className="w-full  !text-sm"
                        />
                        {errors.proteins && touched.proteins && (
                          <div className="text-red text-[14px]  pt-[4px]">
                            {errors.proteins}
                          </div>
                        )}
                      </div>
                      <div className="w-full flex-items-start-column ">
                        <LabelFeed text="Carbohydrates" className={"mb-4 "} />
                        <FeedInput
                          onChange={(e) => {
                            handleChange(e);
                            setCarbohydrates(parseFloat(e.target.value || 0));
                          }}
                          onBlur={handleBlur}
                          value={values.carbohydrates}
                          setFieldValue={setFieldValue}
                          type="number"
                          placeholder={ratioUnit ? "00%" : "00.00 grams"}
                          name="carbohydrates"
                          className="w-full !text-sm"
                        />
                      </div>
                      {errors.carbohydrates && touched.carbohydrates && (
                        <div className="text-red text-[14px]  pt-[4px]">
                          {errors.carbohydrates}
                        </div>
                      )}

                      <div className="w-full flex-items-start-column ">
                        <LabelFeed text="Fat" className={"mb-4 "} />
                        <FeedInput
                          onChange={(e) => {
                            handleChange(e);
                            setFat(parseFloat(e.target.value || 0));
                          }}
                          onBlur={handleBlur}
                          value={values.fat}
                          setFieldValue={setFieldValue}
                          type="number"
                          placeholder={ratioUnit ? "00%" : "00.00 grams"}
                          name="fat"
                          className="w-full !text-sm"
                        />
                        {errors.fat && touched.fat && (
                          <div className="text-red text-[14px]  pt-[4px]">
                            {errors.fat}
                          </div>
                        )}
                      </div>

                      <div className="w-full flex-items-start-column ">
                        <LabelFeed
                          text="Total Target Calories"
                          className={"mb-4 "}
                        />
                        <FeedInput
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          value={values.totalTargetCalories}
                          setFieldValue={setFieldValue}
                          type="number"
                          disabled={ratioUnit ? false : true}
                          placeholder="00.00 cal"
                          name="totalTargetCalories"
                          className="w-full !text-sm"
                        />
                        {errors.totalTargetCalories &&
                          touched.totalTargetCalories &&
                          ratioUnit && (
                            <div className="text-red text-[14px]  pt-[4px]">
                              {errors.totalTargetCalories}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full px-[20px] py-6 rounded-[20px] mt-[110px] bg-secondary1">
                  {/* <Heading text="Chart" fontSize="!text-[18px]" color={'text-white'} className="!leading-6 mb-2 " />
                  <Text text="Description" fontSize="!text-[16px]" color={'text-whiteGray'} className="!leading-[20px] !font-light mb-2  " /> */}
                  <div id="chart " className="meal-chart ">
                    <ReactApexChart
                      options={chartData.options}
                      series={chartData.series}
                      type="donut"
                      // width={342}
                    />
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default MacroTargets;

import React from "react";
import { Button, Text } from "../../../utils";
import { Plus } from "../../../FeedLibraryIcon";

const ClientTypes = ({
  data,
  selectType,
  setSelectType,
  setShow,
  setSelectedFoodName,
}) => {
  return (
    <div className="w-full flex-center-wrap gap-[12px] my-[48px] ">
      <Text text="Client Type" color="text-textPrimary" className="mr-[12px]" />
      <Button
        text={"All"}
        type="button"
        bg={selectType === "All" ? "bg-primary1" : "bg-background "}
        className={`px-[20px] rounded-[12px] leading-6 text-[14px] border ${
          selectType === "All" ? "!border-primary1" : "border-textPrimary"
        }`}
        color={`${
          selectType === "All" ? " text-background" : "text-textPrimary"
        }`}
        cta={() => setSelectType("All")}
      />
      {data?.map((item, index) => (
        <Button
          key={index}
          text={item.name}
          type="button"
          bg={selectType?.id === item?.id ? "bg-primary1" : "bg-background "}
          className={`px-[20px] rounded-[12px] leading-6 text-[14px] border ${
            selectType?.id === item?.id
              ? "!border-primary1"
              : "border-textPrimary"
          }`}
          color={`${
            selectType?.id === item?.id
              ? " text-background"
              : "text-textPrimary"
          }`}
          cta={() => {
            if (selectType?.id === item?.id) {
              setShow(true);
              setSelectedFoodName(item);
            } else {
              setSelectType(item);
            }
          }}
        />
      ))}
      <Button
        text="New Client Type"
        type="button"
        bg="bg-background border !border-dashed border-textPrimary"
        className="px-[20px] rounded-[12px] leading-6 text-[14px]"
        color="text-textPrimary"
        rightmargin="mr-[10px]"
        Icon={<Plus />}
        cta={() => {
          setShow(true);
          setSelectedFoodName({});
        }}
      />
    </div>
  );
};

export default ClientTypes;

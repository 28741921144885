import React from "react";

export const SendIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        opacity="0.2"
        d="M20.971 3.95437L15.5128 21.9544C15.4712 22.101 15.3858 22.2315 15.2681 22.3284C15.1504 22.4253 15.0059 22.4841 14.854 22.4968C14.7021 22.5095 14.5498 22.4757 14.4177 22.3997C14.2855 22.3237 14.1796 22.2092 14.1141 22.0716L10.3125 14.0447C10.2384 13.8884 10.1125 13.7626 9.95627 13.6884L1.92939 9.88594C1.7917 9.82045 1.67724 9.71455 1.60127 9.58236C1.5253 9.45017 1.49142 9.29796 1.50414 9.14603C1.51687 8.99409 1.57561 8.84964 1.67252 8.73193C1.76943 8.61423 1.89991 8.52885 2.04658 8.48719L20.0466 3.02906C20.1748 2.99312 20.3103 2.99197 20.4391 3.02574C20.568 3.0595 20.6855 3.12695 20.7796 3.22117C20.8737 3.31539 20.941 3.43297 20.9747 3.56183C21.0083 3.69069 21.007 3.82618 20.971 3.95437Z"
        fill="#202020"
      />
      <path
        d="M21.3113 2.68875C21.1226 2.50025 20.8872 2.36544 20.6291 2.29821C20.3711 2.23098 20.0998 2.23375 19.8431 2.30625H19.8291L1.83469 7.76625C1.54178 7.85049 1.28147 8.02196 1.08844 8.25783C0.895411 8.4937 0.778814 8.78278 0.754177 9.08657C0.72954 9.39036 0.798032 9.69445 0.950532 9.95834C1.10303 10.2222 1.3323 10.4334 1.60782 10.5637L9.63469 14.3653L13.4363 22.3922C13.5563 22.6492 13.7473 22.8666 13.9869 23.0186C14.2264 23.1706 14.5044 23.2509 14.7881 23.25C14.8313 23.25 14.8744 23.2481 14.9175 23.2444C15.2202 23.2199 15.5082 23.1034 15.7428 22.9105C15.9774 22.7176 16.1474 22.4576 16.23 22.1653L21.6863 4.17094C21.6863 4.16625 21.6863 4.16156 21.6863 4.15688C21.7597 3.9009 21.7637 3.62999 21.6978 3.37198C21.6319 3.11397 21.4985 2.87815 21.3113 2.68875ZM14.7966 21.7359L14.7919 21.7491L11.1019 13.9594L15.5306 9.52969C15.6653 9.38789 15.7393 9.19908 15.7368 9.00351C15.7343 8.80794 15.6555 8.62108 15.5172 8.48278C15.3789 8.34448 15.1921 8.26567 14.9965 8.26317C14.8009 8.26067 14.6121 8.33466 14.4703 8.46937L10.0406 12.8981L2.25001 9.20812H2.26313L20.25 3.75L14.7966 21.7359Z"
        fill="#202020"
      />
    </svg>
  );
};

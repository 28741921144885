export const mealList = [
  {
    id: 1,
    title: "Meal 1",
    mealName: "",
    mealNote: "",
    favorite: false,
    foodList: [],
  },
];

export const FoodListColumn = [
  {
    id: "name",
    label: "Food Name",
    flexGrow: 2,
  },
  {
    id: "total_serving",
    label: "Total Serving",
    flexGrow: 1,
  },
  {
    id: "calories",
    label: "Calories",
    flexGrow: 1,
  },
  {
    id: "protein",
    label: "Protein",
    flexGrow: 1,
  },
  {
    id: "carbs",
    label: "Carbs",
    flexGrow: 1,
  },
  {
    id: "fat",
    label: "Fat",
    flexGrow: 1,
  },
  // {
  //   id: 'note',
  //   label: 'Note',
  //   flexGrow: 1,
  // },

  {
    id: "serving_qty",
    label: "Normalized Serving",
    flexGrow: 1,
  },
  {
    id: "label",
    label: "Label Serving",
    flexGrow: 1,
  },

  {
    id: "fiber",
    label: "Fiber",
    flexGrow: 1,
  },
  {
    id: "sodium",
    label: "Sodium",
    flexGrow: 1,
  },
  {
    id: "potassium",
    label: "Potassium",
    flexGrow: 1,
  },
];

export const FoodTypeListColumn = [
  {
    id: "name",
    label: "Food Name",
    flexGrow: 4,
  },
  {
    id: "label",
    label: "Label Serving",
    flexGrow: 3,
  },
  {
    id: "calories",
    label: "Calories",
    flexGrow: 1,
  },
  {
    id: "protein",
    label: "Protein",
    flexGrow: 1,
  },
  {
    id: "carbs",
    label: "Carbs",
    flexGrow: 1,
  },
  {
    id: "fat",
    label: "Fat",
    flexGrow: 1,
  },
  {
    id: "fiber",
    label: "Fiber",
    flexGrow: 1,
  },
  {
    id: "sodium",
    label: "Sodium",
    flexGrow: 1,
  },
  {
    id: "potassium",
    label: "Potassium",
    flexGrow: 1,
  },
  {
    id: "favorite",
    label: "Favorite",
    flexGrow: 1,
  },
];

export const mealTypesList = [
  {
    id: 1,
    name: "Egg",
    serving: "N/A",
    calories: "220 kcal",
    protein: "5.4g",
    carbs: "5.4g",
    fat: "3g",
    fiber: "1g",
    sodium: "1g",
    potassium: "1g",
    favorite: "s",
  },
  {
    id: 2,
    name: "Egg 2",
    serving: "N/A",
    calories: "220 kcal",
    protein: "5.4g",
    carbs: "5.4g",
    fat: "3g",
    fiber: "1g",
    sodium: "1g",
    potassium: "1g",
    favorite: "s",
  },
];

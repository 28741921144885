import React from 'react';

export const Envelope = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path opacity="0.2" d="M17.5 4.375L10 11.25L2.5 4.375H17.5Z" fill="#C0F121" />
      <path
        d="M17.5 3.75H2.5C2.33424 3.75 2.17527 3.81585 2.05806 3.93306C1.94085 4.05027 1.875 4.20924 1.875 4.375V15C1.875 15.3315 2.0067 15.6495 2.24112 15.8839C2.47554 16.1183 2.79348 16.25 3.125 16.25H16.875C17.2065 16.25 17.5245 16.1183 17.7589 15.8839C17.9933 15.6495 18.125 15.3315 18.125 15V4.375C18.125 4.20924 18.0592 4.05027 17.9419 3.93306C17.8247 3.81585 17.6658 3.75 17.5 3.75ZM10 10.4023L4.10703 5H15.893L10 10.4023ZM7.71172 10L3.125 14.2039V5.79609L7.71172 10ZM8.63672 10.8477L9.57422 11.7109C9.68953 11.8168 9.84035 11.8755 9.99687 11.8755C10.1534 11.8755 10.3042 11.8168 10.4195 11.7109L11.357 10.8477L15.8883 15H4.10703L8.63672 10.8477ZM12.2883 10L16.875 5.79531V14.2047L12.2883 10Z"
        fill="#C0F121"
      />
    </svg>
  );
};

/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
import { removeDuplicateArrayItem } from "../../helper";
const initialState = {
  supplementProducts: [],
  supplementTemplate: [],
};

export const supplementPlan = createSlice({
  name: "supplementPlan",
  initialState,
  reducers: {
    setAllSupplementProduct: (state, action) => {
      const allProducts = action.payload?.products;
      const allCustoms = action.payload?.customs;
      const supplements = removeDuplicateArrayItem(allProducts);
      const filteredArray = [];
      console.log("customs", allCustoms);
      supplements.forEach((product) => {
        if (product.variants && product.variants.length > 0) {
          product.variants.forEach((variant) => {
            const combinedObject = {
              ...product,
              ...variant,
              variantId: variant.id,
            };
            filteredArray.push(combinedObject);
          });
        }
      });
      let combinedArray = [...filteredArray, ...allCustoms];
      state.supplementProducts = removeDuplicateArrayItem(combinedArray);
    },
    addCustomSupplementReducer: (state, action) => {
      state.supplementProducts = [action.payload, ...state.supplementProducts];
    },
    setAllSupplementTemplate: (state, action) => {
      state.supplementTemplate = action.payload;
    },

    deleteSupplementReducer: (state, action) => {
      state.supplementTemplate = state.supplementTemplate?.filter(
        (f) => f.docId !== action.payload
      );
    },
    addSupplementPlanReducer: (state, action) => {
      state.supplementTemplate = [action.payload, ...state.supplementTemplate];
    },

    updateSupplementTempReducer: (state, action) => {
      state.supplementTemplate = state.supplementTemplate?.map((temp) => {
        if (temp.docId === action.payload?.docId) {
          return action.payload;
        } else {
          return temp;
        }
      });
    },
  },
});

export const {
  setAllSupplementProduct,
  setAllSupplementTemplate,
  deleteSupplementReducer,
  updateSupplementTempReducer,
  setCustomSupplementReducer,
  addSupplementPlanReducer,
  addCustomSupplementReducer,
} = supplementPlan.actions;

export default supplementPlan.reducer;

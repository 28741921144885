import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../configs/firebase";

const getAllUsersContext = async (uid, collectName) => {
  try {
    const userFavoritesRef = collection(db, collectName);
    let q;
    if (uid) {
      q = query(userFavoritesRef, where("coachId", "==", uid));
    } else {
      q = query(userFavoritesRef);
    }
    const querySnapshot = await getDocs(q);
    const results = [];
    querySnapshot.forEach((doc) => {
      const ctype = doc.data();
      results.push({ ...ctype, docId: doc.id });
    });
    return results;
  } catch (error) {
    console.error("Error searching for plan data:", error);
  }
};

const getTotalEntityCount = async (collectNames) => {
  const totals = {};
  try {
    for (const coll of collectNames) {
      const userFavoritesRef = collection(db, coll);
      const q = query(userFavoritesRef);
      const querySnapshot = await getDocs(q);
      console.log("querySnapshot.size", querySnapshot.size);
      totals[coll] = querySnapshot.size;
    }
    totals.mealplan = 10;
    return totals;
  } catch (error) {
    console.error("Error getting total count:", error);
    return {};
  }
};

export { getAllUsersContext, getTotalEntityCount };

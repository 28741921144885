import React from "react";
import { CrossIcon, Search } from "../../FeedLibraryIcon";
const Index = ({ searchQuery, handlesearchQuery, placeholder, className }) => {
  return (
    <div className="relative">
      <span className="absolute top-[13px] left-[13px]">
        <Search />
      </span>
      <input
        type="text"
        value={searchQuery}
        placeholder={placeholder}
        onChange={(e) => handlesearchQuery(e.target.value)}
        className={`py-[10px] px-[44px]  bg-background !outline-none rounded w-full text-text1 font-normal ${className}`}
      />
      {searchQuery && (
        <span
          onClick={() => handlesearchQuery("")}
          className="absolute top-[13px] right-[13px] cursor-pointer"
        >
          <CrossIcon />
        </span>
      )}
    </div>
  );
};

export default Index;

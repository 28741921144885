import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { diceBearThumbs } from "../../../schema";
import { Container, Dropdown } from "react-bootstrap";
import FeedSearch from "../../search";
import NotificationsModal from "../../model/notification";
import { ProfileCircle, ArrowDown } from "../../../FeedLibraryIcon";

import ProfileModal from "../../model/profileModal";
import { useDispatch, useSelector } from "react-redux";
import { seteditFormReducer } from "../../../store/reducers/uiSettings";

const Navbarf4f = ({
  logout,
  searchQuery,
  setsearchQuery,
  userProfile,
  loading,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allClients } = useSelector((state) => state.common);
  const [show, setShow] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [diceBearAvatar, setdiceBearAvatar] = useState([]);
  const [searchAllClients, setsearchAllClients] = useState([]);
  useEffect(() => {
    (() => {
      const thumbsList = diceBearThumbs?.map((thumb) => {
        return {
          name: thumb,
          avatar: `https://api.dicebear.com/7.x/thumbs/svg?seed=${thumb}&translateY=8`,
        };
      });
      setdiceBearAvatar(thumbsList);
    })();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      setsearchAllClients(
        allClients?.filter((item) =>
          item?.name.toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      );
    } else {
      setsearchAllClients(allClients);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
  return (
    <>
      {[
        "/login",
        "/register",
        "/email-verification",
        "/forget-password",
      ].includes(location.pathname?.toLocaleLowerCase()?.trim()) ? (
        <div className="f4f-navbar h-[84px] flex-center-between">
          <NavLink to="/" className="no-underline w-[100px] m-auto">
            <div className="flex-items-center text-center ">
              <span className="text-primary1 text-[28px] leading-6	 font-medium">
                Kaboodle
              </span>
            </div>
          </NavLink>
          <div className="w-[70px]"></div>
        </div>
      ) : (
        <div
          className={`f4f-navbar h-[84px] w-full top-0 left-0 z-[444] ${
            loading ? "hidden" : ""
          }`}
        >
          <Navbar expand="lg">
            <Container fluid>
              <div className="flex-items-center gap-[50px]">
                <NavLink to="/" className="no-underline">
                  <div className="flex-items-center ">
                    <span className="text-primary1  align-left text-[28px] leading-6	 font-medium">
                      Kaboodle
                    </span>
                  </div>
                </NavLink>
                <div className="hidden md:w-[300px] lg:inline-block relative ">
                  <FeedSearch
                    handlesearchQuery={setsearchQuery}
                    searchQuery={searchQuery}
                    placeholder={"Search Clients"}
                  />
                  {searchQuery && (
                    <div className="absolute min-w-[300px] bg-background rounded-md mt-2 shadow-lg z-10 ">
                      <ul>
                        {searchAllClients?.map((client) => (
                          <li
                            onClick={() => {
                              navigate(`/client-detail/${client.uid}`);
                              setsearchQuery("");
                            }}
                            className="px-4  py-2 text-white flex-items-center hover:bg-secondary1 hover:!text-primary1 cursor-pointer  mb-1"
                          >
                            <img
                              className="w-[32px] h-[32x] rounded-lg object-cover mr-3"
                              src={client?.avatar}
                              alt="client"
                            />
                            {client.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="flex-items-center gap-[60px]">
                  <NavLink to="/my-clients" className="nav-link">
                    Clients
                  </NavLink>
                  <NavLink to="/inbox" className="nav-link">
                    Inbox
                  </NavLink>
                  <NavLink to="/template" className="nav-link">
                    Template
                  </NavLink>

                  <Dropdown className="navbar-dropdown">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="nav-link nav-dropdown flex-items-baseline gap-2"
                    >
                      Plan <ArrowDown />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <NavLink
                        onClick={() => {
                          dispatch(seteditFormReducer());
                        }}
                        to="/meal-plan"
                        className="nav-link "
                      >
                        Create Meal Plan
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          dispatch(seteditFormReducer());
                        }}
                        to="/workout-plan"
                        className="nav-link "
                      >
                        Create Workout Plan
                      </NavLink>
                      <NavLink
                        onClick={() => {
                          dispatch(seteditFormReducer());
                        }}
                        to="/supplement-plan"
                        className="nav-link "
                      >
                        Create Supplement Plan
                      </NavLink>
                    </Dropdown.Menu>
                  </Dropdown>
                  <NavLink
                    to="#"
                    className="nav-link logout-link"
                    onClick={async () => {
                      await logout();
                    }}
                  >
                    Logout
                  </NavLink>

                  <div className="flex ml-[12px]">
                    <Dropdown className="navbar-dropdown profile-dropdown">
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="nav-link nav-dropdown flex-items-baseline gap-2"
                      >
                        <span>
                          {userProfile ? (
                            <img
                              src={userProfile?.avatar}
                              className="w-[36px] h-[36px] rounded-lg   object-cover"
                              alt="avatar"
                            />
                          ) : (
                            <ProfileCircle />
                          )}
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <NavLink
                          to="#"
                          className="nav-link "
                          onClick={() => {
                            setOpenProfile(true);
                          }}
                        >
                          My Profile
                        </NavLink>

                        <NavLink
                          to="#"
                          className="nav-link "
                          onClick={async () => {
                            await logout();
                          }}
                        >
                          Logout
                        </NavLink>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      )}
      {show && <NotificationsModal handleClose={() => setShow(false)} />}

      {openProfile && (
        <ProfileModal
          open={openProfile}
          handleClose={() => {
            setOpenProfile(false);
          }}
        />
      )}
    </>
  );
};

export default Navbarf4f;

import React from 'react';
import {Link} from 'react-router-dom';
const FeedLink = (props) => {
  const {text, path, className} = props;
  return (
    <Link {...props} to={path} className={`text-[14px] font-normal leading-6 text-primary1 not-italic hover:text-primary1 ${className}`}>
      {text}
    </Link>
  );
};

export default FeedLink;

import React from 'react';

export const CheckRadioIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="11.25" fill="#202020" stroke="#C0F121" strokeWidth="1.5" />
      <circle cx="12" cy="12" r="6" fill="#C0F121" />
    </svg>
  );
};

import React from "react";
import { useNavigate } from "react-router-dom";
import ClienImage from "../../../assets/images/client-img.jpg";
import { Heading, Text } from "../../../utils";
import { MediaIcon } from "../../../FeedLibraryIcon";
import Skeleton from "react-loading-skeleton";

const Index = ({
  clientUser,
  loading,
  setPendingClient,
  setAddClient,
  allClientTypes,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <table className="w-full text-sm text-left rounded-[20px]  text-textPrimary  bg-secondary1 overflow-hidden">
        <thead className="h-[72px] w-full border-b border-text2">
          <tr>
            <th scope="col" className="w-[275px] pl-[15px] py-[27px]">
              <Text text="Name" className="!font-medium" />
            </th>
            <th scope="col" className="w-[366px] px-[10px] py-[27px]">
              <Text text="Client Type" className="!font-medium" />
            </th>
            <th scope="col" className="w-[356px]  pr-[10px]  py-[27px]">
              <Text text="Last Check-in" className="!font-medium" />
            </th>

            <th scope="col" className=" pr-[15px] py-[27px]">
              <Text text="Status" className="!font-medium" />
            </th>
          </tr>
        </thead>
        {!loading ? (
          <tbody>
            {clientUser?.length > 0 ? (
              clientUser?.map((client, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    if (client.status === "Pending") {
                      setPendingClient(client);
                      setAddClient(true);
                    } else {
                      navigate(`/client-detail/${client.uid}`);
                    }
                  }}
                  className="cursor-pointer he-[] group hover:bg-background3"
                >
                  <th
                    scope="row"
                    className="flex-items-center pl-[15px] py-[18px]  whitespace-nowrap "
                  >
                    <div className="w-full flex-items-center gap-[16px]">
                      <div className="w-[36px] h-[36px] rounded-lg flex-start-center shadow-clientImgShadow overflow-hidden ">
                        <img
                          className="w-full h-full object-cover "
                          src={client?.avatar || ClienImage}
                          alt="client"
                        />
                      </div>
                      <div>
                        <Text text={client?.name} />
                      </div>
                    </div>
                  </th>
                  <td className="px-[10px] py-[18px]">
                    <div className="w-max px-[20px] py-[7px] rounded-md bg-activeBackground">
                      <Text
                        text={
                          allClientTypes?.filter(
                            (f) => f.id === client.clientType?.id,
                          )?.[0]?.name || "N/A"
                        }
                      />
                    </div>
                  </td>
                  <td className="py-[18px]">
                    <Text text={client.lastCheck || "N/A"} />
                  </td>
                  <td className="pl-[10px] pr-[15px] py-[18px]">
                    <div className="w-full flex-center-between ">
                      <Text
                        text={client.status || "---"}
                        color={
                          client.status === "Pending"
                            ? "text-red"
                            : "!text-successColor1"
                        }
                        className="!font-medium"
                      />
                      <span className="hidden group-hover:block">
                        <MediaIcon />
                      </span>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="h-full text-center">
                <th colspan="5" className=" pl-[15px] py-[27px]">
                  <div className="max-w-full h-[300px] flex-center-center">
                    <Heading
                      text="No Client Found."
                      fontSize="text-[18px]"
                      fontweight="!font-[400]"
                      color="text-textPrimary"
                      className="leading-[20px]"
                    />
                  </div>
                </th>
              </tr>
            )}
          </tbody>
        ) : (
          <tbody>
            {[1, 2, 3, 4, 5, 6]?.map((index) => (
              <tr key={index}>
                <td className="p-[6px] pl-[10px] w-full flex-items-center gap-[8px]">
                  <Skeleton className=" w-[46px] h-[46px] bg-background rounded-full" />
                  <div>
                    <Skeleton width={50} height={8} />
                    <Skeleton width={100} height={8} />
                  </div>
                </td>
                <td className="p-[4px]">
                  <Skeleton width={170} height={8} />
                </td>
                <td className="p-[4px]">
                  <Skeleton width={170} height={8} />
                </td>
                <td className="p-[4px]">
                  <Skeleton width={170} height={8} />
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </>
  );
};

export default Index;

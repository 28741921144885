import { toast } from "react-toastify";
import axios from "axios";

const getAllSupplementProducts = async (data) => {
  try {
    const response = await axios.get(
      "https://www.practitionerdepot.com/api/getAllProducts",
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    toast(error?.message, {
      type: "error",
      autoClose: 5000,
    });
  }
};

export { getAllSupplementProducts };

import React from "react";

const FeedInput = (props) => {
  const {
    className,
    icon,
    name,
    value,
    setFieldValue,
    clearState,
    disabled,
    width,
  } = props;
  return (
    <div className={`relative ${width ? width : "w-full"}`}>
      {icon && <span className="absolute top-[12px] left-[12px]">{icon}</span>}

      <input
        value={value}
        {...props}
        className={`w-full ${
          icon ? "pl-[46px] " : ""
        } p-[12px] font-normal rounded-lg bg-background !outline-none text-textPrimary ${className}`}
      ></input>
      {props.crossicon && !disabled && (
        <span
          onClick={() => {
            if (!disabled) {
              if (clearState) {
                clearState();
              }
              if (setFieldValue) {
                setFieldValue(name, "");
              }
            }
          }}
          className="absolute z-[3] cursor-pointer top-[12px] right-[12px]"
        >
          {props.crossicon}
        </span>
      )}
      {props.helperText && (
        <p className="mt-[18px] text-text3 font-normal leading-3">
          {props.helperText}
        </p>
      )}
    </div>
  );
};

export default FeedInput;

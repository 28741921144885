import React from "react";
import { Formik } from "formik";
import { Modal, Spinner } from "react-bootstrap";
import { Button, FeedInput, Heading } from "../../utils";
import { generateUniqueId } from "../../helper";
import {
  createCLientTypeAction,
  deleteClientTypeAction,
  updateClientTypeAction,
} from "../../store/actions/common";
import { Cross } from "../../FeedLibraryIcon";
import { toast } from "react-toastify";
import { setbtnLoaderReducer } from "../../store/reducers/uiSettings";
import { useDispatch, useSelector } from "react-redux";
const AddClientType = ({
  show,
  handleClose,
  uid,
  selectedFoodName,
  clientTyps,
}) => {
  const dispatch = useDispatch();
  const { btnloader } = useSelector((state) => state.uiSettings);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName="meal-multiselect-modal"
    >
      <Modal.Header className="w-full  flex-center-between">
        <Heading
          text={selectedFoodName ? "Edit Client Type" : "Add New Client Type"}
          fontSize="text-[22px]"
          fontweight="font-normal"
          color="text-textPrimary"
          className="font-medium !leading-10"
        />
        <span onClick={handleClose} className="cursor-pointer">
          <Cross />
        </span>
      </Modal.Header>
      <Modal.Body className="!w-full">
        <Formik
          initialValues={{
            name: selectedFoodName ? selectedFoodName?.name : "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (
              values.name &&
              clientTyps?.find((client) => client.name === values.name)
            ) {
              errors.name = "Client Type already taken";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            console.log("clientTyps", clientTyps);
            if (selectedFoodName?.id) {
              await updateClientTypeAction({
                ...selectedFoodName,
                name: values.name,
              });
              toast("Client type updated Successfully");
            } else {
              const res = await createCLientTypeAction({
                id: generateUniqueId(),
                name: values.name,
                coachId: uid,
              });
              if (res.id) {
                toast("Client type created Successfully");
              }
            }
            setSubmitting(false);
            handleClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form className="w-full" onSubmit={handleSubmit}>
              <div className="w-full flex-items-start-column gap-2">
                <FeedInput
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  className="!w-full"
                  placeholder="Add Client Type"
                />
                {errors.name && touched.name && (
                  <div className="text-red align-left !leading-[22px]">
                    {errors.name}
                  </div>
                )}
              </div>

              <div className="w-full flex-center-between gap-4  mt-8">
                <Button
                  text={
                    btnloader ? (
                      <Spinner />
                    ) : selectedFoodName?.id ? (
                      "Delete"
                    ) : (
                      "Cancel"
                    )
                  }
                  className="w-full px-[12px] py-[7px] font-medium rounded-xl text-[14px] leading-6 border border-solid border-textPrimary "
                  bg="!bg-transparent"
                  color="text-textPrimary "
                  cta={async () => {
                    dispatch(setbtnLoaderReducer(true));
                    if (selectedFoodName?.docId) {
                      await deleteClientTypeAction(selectedFoodName?.docId);
                      toast("Client type has been deleted Successfully");
                    }
                    dispatch(setbtnLoaderReducer(false));
                    handleClose();
                  }}
                  type="button"
                />
                <Button
                  text={
                    isSubmitting ? (
                      <Spinner />
                    ) : selectedFoodName?.id ? (
                      "Save"
                    ) : (
                      "Next"
                    )
                  }
                  className="w-full px-[12px] py-[7px] font-medium rounded-xl text-[14px] leading-6"
                  bg="bg-primary1"
                  color="text-background"
                  type="submit"
                  disabled={isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddClientType;

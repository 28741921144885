// libbraries
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

// custom components
import Layout from "../components/Layout";
import OpenRoute from "./openRouter";
import Login from "../pages/login";
import Home from "../pages/Home/index";
import Template from "../pages/template";

import MyClients from "../pages/myclients";
import UserManagemenet from "../pages/userManagement";
import NewCLientDetail from "../pages/newClientDetail";
import Chat from "../pages/chat";
import ForgetPassword from "../pages/login/forgetPassword";
import PrivateRoute from "./privateRouter";
import NewMealPlan from "../pages/newMealPlan";
import NewSupplementsPlan from "../pages/newSupplementsPlan";
import { IntakeForm, CheckInForm } from "../pages/template/createForm";
import NewWorkoutPlan from "../pages/newWorkoutPlan";
import SetPassword from "../pages/login/setPassword";

function App() {
  const { user } = useSelector((state) => state.common);
  const [searchQuery, setsearchQuery] = useState("");
  const [userProfile, setuserProfile] = useState(user);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setuserProfile(user);
  }, [user]);
  return (
    <Layout
      setsearchQuery={setsearchQuery}
      searchQuery={searchQuery}
      userProfile={userProfile}
      loading={loading}
    >
      <div className="bg-background">
        <Routes>
          <Route
            path="/login"
            element={
              <OpenRoute>
                <Login />
              </OpenRoute>
            }
          />
          <Route
            path="/forget-password"
            element={
              <OpenRoute>
                <ForgetPassword />
              </OpenRoute>
            }
          />
          <Route
            path="/set-password"
            element={
              <OpenRoute>
                <SetPassword />
              </OpenRoute>
            }
          />
          <Route
            path="/user-management"
            element={
              <PrivateRoute setLoading={setLoading} loading={loading}>
                <UserManagemenet />
              </PrivateRoute>
            }
          />

          <Route
            path="/"
            element={
              <PrivateRoute setLoading={setLoading} loading={loading}>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/intake-form"
            element={
              <PrivateRoute setLoading={setLoading} loading={loading}>
                <IntakeForm />
              </PrivateRoute>
            }
          />
          <Route
            path="/checkin-form"
            element={
              <PrivateRoute setLoading={setLoading} loading={loading}>
                <CheckInForm />
              </PrivateRoute>
            }
          />

          <Route
            path="/template"
            element={
              <PrivateRoute setLoading={setLoading} loading={loading}>
                <Template />
              </PrivateRoute>
            }
          />
          <Route
            path="/meal-plan"
            element={
              <PrivateRoute setLoading={setLoading} loading={loading}>
                <NewMealPlan />
                {/* <MealPlans /> */}
              </PrivateRoute>
            }
          />
          <Route
            path="/workout-plan"
            element={
              <PrivateRoute setLoading={setLoading} loading={loading}>
                <NewWorkoutPlan />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-workout-plan"
            element={
              <PrivateRoute setLoading={setLoading} loading={loading}>
                <NewWorkoutPlan />
              </PrivateRoute>
            }
          />
          <Route
            path="/supplement-plan"
            element={
              <PrivateRoute setLoading={setLoading} loading={loading}>
                {/* <SupplementPlan /> */}
                <NewSupplementsPlan />
              </PrivateRoute>
            }
          />

          {/* client pages */}
          <Route
            path="/my-clients"
            element={
              <PrivateRoute setLoading={setLoading} loading={loading}>
                <MyClients
                  searchQuery={searchQuery}
                  setsearchQuery={setsearchQuery}
                />
              </PrivateRoute>
            }
          />
          <Route
            path="/client-detail/:cid"
            element={
              <PrivateRoute setLoading={setLoading} loading={loading}>
                <NewCLientDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/inbox"
            element={
              <PrivateRoute setLoading={setLoading} loading={loading}>
                <Chat searchQuery={searchQuery} />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </Layout>
  );
}

export default App;

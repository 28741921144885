import React from "react";

const ParaText = ({ text, color, fontSize, className }) => {
  return (
    <span
      className={`${
        fontSize ? fontSize : "text-[14px]"
      } font-normal not-italic whitespace-break-spaces  ${
        color ? color : "text-textPrimary"
      }  ${className}`}
    >
      {text}
    </span>
  );
};

export default ParaText;

import React from "react";

export const AiIcon = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.12984 4.41278L9.2759 4.4851C9.86625 4.78645 10.3471 5.26258 10.6514 5.8472L10.7244 5.99185C11.0165 6.56441 11.8442 6.56441 12.1424 5.99185L12.2155 5.8472C12.5198 5.26258 13.0006 4.78645 13.5909 4.4851L13.737 4.41278C14.3152 4.12348 14.3152 3.30381 13.737 3.00849L13.5909 2.93617C13.0006 2.63482 12.5198 2.15869 12.2155 1.57407L12.1424 1.42942C11.8503 0.856859 11.0226 0.856859 10.7244 1.42942L10.6514 1.57407C10.3471 2.15869 9.86625 2.63482 9.2759 2.93617L9.12984 3.00849C8.55166 3.29779 8.55166 4.11746 9.12984 4.41278Z"
        fill="#C0F121"
        fill-opacity="0.4"
      />
      <path
        d="M10.2801 10.0601C10.8765 9.75871 10.8765 8.92096 10.2801 8.61961L9.39154 8.16759C8.60035 7.76378 7.95522 7.12492 7.54746 6.34141L7.091 5.46147C6.7867 4.87083 5.94073 4.87083 5.63643 5.46147L5.17997 6.34141C4.77221 7.12492 4.12708 7.76378 3.33589 8.16759L2.44733 8.61961C1.85089 8.92096 1.85089 9.75871 2.44733 10.0601L3.33589 10.5121C4.12708 10.9159 4.77221 11.5548 5.17997 12.3383L5.63643 13.2182C5.94073 13.8088 6.7867 13.8088 7.091 13.2182L7.54746 12.3383C7.95522 11.5548 8.60035 10.9159 9.39154 10.5121L10.2801 10.0601Z"
        fill="#C0F121"
      />
      <path
        d="M21.6976 13.6642L20.7968 13.2062C19.7074 12.6577 18.8371 11.7958 18.2833 10.717L17.8208 9.82501C17.413 9.02945 16.5975 8.53524 15.6967 8.53524C14.796 8.53524 13.9804 9.02945 13.5727 9.82501L13.1101 10.717C12.5563 11.7958 11.686 12.6577 10.5966 13.2062L9.69584 13.6642C8.89248 14.068 8.39342 14.8756 8.39342 15.7676C8.39342 16.6596 8.89248 17.4672 9.69584 17.871L10.5966 18.3291C11.686 18.8775 12.5563 19.7394 13.1101 20.8182L13.5727 21.7102C13.9804 22.5058 14.796 23 15.6967 23C16.5975 23 17.413 22.5058 17.8208 21.7102L18.2833 20.8182C18.8371 19.7394 19.7074 18.8775 20.7968 18.3291L21.6976 17.871C22.5009 17.4672 23 16.6596 23 15.7676C23 14.8756 22.5009 14.068 21.6976 13.6642ZM20.8699 16.2618L19.9691 16.7199C18.5328 17.4431 17.3886 18.5762 16.6583 19.9986L16.1958 20.8906C16.0558 21.1618 15.8002 21.1919 15.6967 21.1919C15.5932 21.1919 15.3376 21.1618 15.1977 20.8906L14.7351 19.9986C14.0048 18.5762 12.8606 17.4431 11.4243 16.7199L10.5235 16.2618C10.2497 16.1232 10.2192 15.8701 10.2192 15.7676C10.2192 15.6652 10.2497 15.412 10.5235 15.2734L11.4243 14.8154C12.8606 14.0921 14.0048 12.959 14.7351 11.5367L15.1977 10.6447C15.3376 10.3735 15.5932 10.3433 15.6967 10.3433C15.8002 10.3433 16.0558 10.3735 16.1958 10.6447L16.6583 11.5367C17.3886 12.959 18.5328 14.0921 19.9691 14.8154L20.8699 15.2734C21.1437 15.412 21.1742 15.6652 21.1742 15.7676C21.1742 15.8701 21.1437 16.1232 20.8699 16.2618Z"
        fill="#C0F121"
      />
    </svg>
  );
};

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Pagination } from "rsuite";
import React, { useEffect, useRef, useState } from "react";
import { Text } from "../../../utils";
import { Dropdown } from "react-bootstrap";
import {
  DeleteIcon,
  DotsThreeVerticalIcon,
  PermissionsIcon,
  UserProfile,
} from "../../../FeedLibraryIcon";
import UserDrawer from "./userDrawer";
import UserEditFormik from "./userEditFormik";
import PermissionsFormik from "./permissionsFormik";
import { useSelector } from "react-redux";
import { deleteUserAction } from "../../../store/actions/permissions";
import Skeleton from "react-loading-skeleton";

const UserTable = (props) => {
  const { data, openModal, setOpenModal, userProfile, setUserProfile } = props;
  const formRef = useRef();
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { uiloader } = useSelector((state) => state.uiSettings);

  useEffect(() => {
    setUsers(data);
  }, [data]);

  const handleChangeLimit = (dataKey) => {
    console.log(dataKey);
    setPage(1);
    setLimit(dataKey);
  };
  const paginationData = data.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  const nameBodyTemplate = (rowData) => {
    const SortName = rowData?.name.split(" ")?.map((word) => word.charAt(0));

    return rowData ? (
      <div className="w-fit flex-center-center gap-2   ">
        <div className="flex-center-center w-[26px] h-[26px] rounded-full bg-background2">
          <Text
            text={
              rowData?.firstName || rowData?.lastName
                ? `${rowData?.firstName?.charAt(0)}${rowData?.lastName?.charAt(
                    0
                  )}`
                : SortName?.slice(0, 2)
            }
            color="text-textPrimary"
            className={"font-normal text-[10px] leading-3  uppercase "}
          />
        </div>
        <Text
          text={rowData.name}
          color="text-textPrimary"
          className={"font-normal text-xs"}
        />
      </div>
    ) : (
      "skeltion"
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <div
        className={`w-fit flex-center-center gap-[5px] px-[6px] py-[3px] rounded-[26px] ${
          rowData?.status?.includes("Active")
            ? "bg-activeBackground"
            : rowData?.status?.includes("Pending")
            ? "bg-text9"
            : rowData?.status?.includes("N/A")
            ? "bg-text10"
            : "bg-text9"
        }`}
      >
        <div
          className={`w-[6px] h-[6px] rounded-full ${
            rowData?.status?.includes("Active")
              ? "bg-primary1"
              : rowData?.status?.includes("Pending")
              ? "bg-background9"
              : ""
          }`}
        />
        <Text
          text={rowData?.status || "N/A"}
          color="text-textPrimary"
          className={"font-normal leading-5"}
        />
      </div>
    );
  };

  const roleBodyTemplate = (rowData) => {
    return (
      <Text
        text={rowData.userType}
        color={
          rowData?.userType?.includes("client")
            ? "!text-primary1"
            : rowData?.userType?.includes("coach")
            ? "!text-purpalLight2"
            : ""
          // : rowData?.userType?.includes("Admin")
          // ? "!text-successColor2 "
          // : ""
        }
        className={"font-normal leading-5"}
      />
    );
  };

  const phoneNumberTemplate = (rowData) => {
    return (
      <Text
        text={rowData?.phoneNumber || "N/A"}
        className={"font-normal leading-5"}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Dropdown className="navbar-dropdown table-dropdown">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="nav-link nav-dropdown flex-items-baseline gap-2"
        >
          <DotsThreeVerticalIcon w="24px" h="24px" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            className="nav-link flex-items-center gap-[14px]"
            onClick={() => {
              setOpenModal("UserProfile");
              setUserProfile(rowData);
            }}
          >
            <UserProfile />
            User Profile
          </Dropdown.Item>
          <Dropdown.Item
            className="nav-link flex-items-center gap-[14px]"
            onClick={() => {
              setOpenModal("EditPermissions");
              setUserProfile(rowData);
            }}
          >
            <PermissionsIcon />
            Edit Permissions
          </Dropdown.Item>
          <Dropdown.Item
            className="nav-link flex-items-center gap-[14px]"
            onClick={() => deleteUserAction(rowData?.docId)}
          >
            <DeleteIcon color="#71717A" />
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };
  return (
    <div className="w-full">
      <div className="w-full mb-[12px]">
        {!uiloader ? (
          <DataTable
            value={paginationData}
            stripedRows
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column field="name" header="Name" body={nameBodyTemplate}></Column>
            <Column field="email" header="Email"></Column>
            <Column
              field="phoneNumber"
              header="Phone"
              body={phoneNumberTemplate}
            ></Column>
            <Column
              field="status"
              header="Status"
              body={statusBodyTemplate}
            ></Column>
            <Column field="role" header="Role" body={roleBodyTemplate}></Column>
            <Column field="activeClients" header="Active Clients"></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>
        ) : (
          <div className="bg-background w-full max-w-[1360px] overflow-x-hidden">
            <table className="w-ful bg-background3 rounded p-4">
              {[1, 2, 3, 4, 5, 6]?.map((index) => (
                <tr key={index} className="w-full">
                  <td className="p-[6px] pl-[10px] w-full flex-items-center gap-[8px]">
                    <Skeleton className=" w-[46px] h-[46px] bg-background rounded-full" />
                    <div>
                      <Skeleton width={50} height={8} />
                      <Skeleton width={100} height={8} />
                    </div>
                  </td>
                  <td className="p-[4px]">
                    <Skeleton width={170} height={8} />
                  </td>
                  <td className="p-[4px]">
                    <Skeleton width={170} height={8} />
                  </td>
                  <td className="p-[4px]">
                    <Skeleton width={170} height={8} />
                  </td>
                  <td className="p-[4px]">
                    <Skeleton width={170} height={8} />
                  </td>
                  <td className="p-[4px]">
                    <Skeleton width={170} height={8} />
                  </td>
                  <td className="p-[4px]">
                    <Skeleton width={170} height={8} />
                  </td>
                  <td className="p-[4px]">
                    <Skeleton width={110} height={8} />
                  </td>
                </tr>
              ))}
            </table>
          </div>
        )}
      </div>

      <div className="w-full flex-justify-end gap-[16px] ">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="sm"
          layout={["-", "limit", "|", "pager", "skip"]}
          total={uiloader ? [] : users?.length}
          limitOptions={[10, 20, 60]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
        {/* <Text
          text="Page 1 of 10"
          color="text-background9"
          className={"font-normal leading-5"}
        />
        <div className="flex-items-center gap-2">
          <Button
            text={"Previous"}
            className="!w-fit !px-[12px] !py-[7px] rounded-lg "
            bg="bg-background2"
            color="text-background9"
            cta={() => {}}
          />
          <Button
            text={"Next"}
            className="!w-fit !px-[18px] !py-[7px] rounded-lg border !border-background9 "
            color="text-background9"
            cta={() => {}}
          />
        </div> */}
      </div>
      <UserDrawer
        heading={userProfile?.name}
        isOpen={openModal}
        footerButton={openModal?.includes("EditPermissions") ? true : false}
        isClose={() => setOpenModal("")}
        formRef={formRef}
      >
        {openModal.includes("UserProfile") ? (
          <UserEditFormik data={userProfile} setOpenModal={setOpenModal} />
        ) : (
          <PermissionsFormik formRef={formRef} setOpenModal={setOpenModal} />
        )}
      </UserDrawer>
    </div>
  );
};

export default UserTable;

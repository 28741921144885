import store from '../store';
import {createTemplateContext, deleteTemplateContext, assignTemplateFormContext} from '../../Services/templateContext';
import {addTemplateFormReducer, setAllTemplateFormReducer, updateTemplateFormReducer, deleteTemplateFormReducer} from '../reducers/template';
import {getAllDocByCollectionContext} from '../../Services/commonContext';
import {updateSupplementTempReducer} from '../reducers/supplementPlan';
import {updateMealPlanReducer} from '../reducers/mealPlan';
import {addClientCheckInReducer, addClientIntakeReducer} from '../reducers/myClients';
import {updateWorkoutPlanReducer} from '../reducers/workoutPlan';
import {sortedData} from '../../helper';

const getTemplateFormAction = async (uid) => {
  const result = await getAllDocByCollectionContext(uid, 'templates');
  if (!!result?.length) {
    store.dispatch(setAllTemplateFormReducer(sortedData(result)));
    return result;
  }
};
const createTemplateFormAction = async (data, payload) => {
  const result = await createTemplateContext(data, payload);
  if (result?.responseCode === 200) {
    if (payload?.isClientView) {
      if (payload?.templateType === 'intake') {
        store.dispatch(addClientIntakeReducer(result?.data));
      } else {
        store.dispatch(addClientCheckInReducer(result?.data));
      }
    } else {
      store.dispatch(addTemplateFormReducer(result?.data));
    }
    return result;
  }
};
const deleteTemplateFormAction = async (docId, formId) => {
  const result = await deleteTemplateContext(docId, formId);
  if (result.responseCode === 200) {
    store.dispatch(deleteTemplateFormReducer(formId));
    return result;
  }
};
const assignTemplateFormAction = async (docId, url, payload) => {
  await assignTemplateFormContext(docId, url, payload);
  if (url === 'templates') {
    store.dispatch(updateTemplateFormReducer(payload));
  } else if (url === 'supplementPlan') {
    store.dispatch(updateSupplementTempReducer(payload));
  } else if (url === 'mealplan') {
    store.dispatch(updateMealPlanReducer(payload));
  } else if (url === 'workoutplan') {
    store.dispatch(updateWorkoutPlanReducer(payload));
  }
  // }
};

export {getTemplateFormAction, createTemplateFormAction, deleteTemplateFormAction, assignTemplateFormAction};

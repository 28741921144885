/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  allClients: null,
  clientType: [],
  allmyClients: null,
};

export const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.user = action.payload;
    },
    setAllMyClientReducer: (state, action) => {
      state.allmyClients = [...action.payload];
      state.allClients = action.payload?.filter((f) => f.status === "Active");
    },
    setClientTypeReducer: (state, action) => {
      state.clientType = action.payload;
    },
    addClientTypeReducer: (state, action) => {
      state.clientType = [action.payload, ...state.clientType];
    },
    updateClientTypeReducer: (state, action) => {
      state.clientType = state.clientType?.map((type) => {
        if (type.id === action.payload?.id) {
          return action.payload;
        } else {
          return type;
        }
      });
    },
    updateClientProfileReducer: (state, action) => {
      state.allmyClients = state.allmyClients?.map((client) => {
        if (client.docId === action.payload?.docId) {
          return action.payload;
        } else {
          return client;
        }
      });
    },
    deleteCLientTypeReducer: (state, action) => {
      state.clientType = state.clientType?.filter(
        (f) => f.docId !== action.payload
      );
    },
    deleteClientReducer: (state, action) => {
      state.allmyClients = state.allmyClients?.filter(
        (f) => f.docId !== action.payload
      );
    },
    updateProfileAction: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export const {
  setUserDetails,
  addClientTypeReducer,
  updateProfileAction,
  deleteClientReducer,
  updateClientProfileReducer,
  setClientTypeReducer,
  deleteCLientTypeReducer,
  updateClientTypeReducer,
  setAllMyClientReducer,
} = common.actions;

export default common.reducer;

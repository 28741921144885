import React from 'react';

const Heading = (props) => {
  const { text, className, fontweight, fontSize, color } = props;
  return (
    <span className={`${color ? color : 'text-textPrimary'} block ${fontweight ? fontweight : 'font-semibold '} not-italic ${fontSize ? fontSize : 'text-[30px]'}   ${className} `}>
      {text}
    </span>
  );
};

export default Heading;

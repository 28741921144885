import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Button, Heading, Text } from "../../utils";
import AddUser from "./component/addUser";
import ExchangeCard from "./component/exchangeCard";
import UserTable from "./component/userTable";
import { userDetail } from "../../schema/userTotalWorks";
import SearchFilter from "./component/searchFilter";

import {
  getAllUserDetails,
  getTotalEntityForUser,
} from "../../store/actions/permissions";
import { setUILoaderReducer } from "../../store/reducers/uiSettings";

const UserManagemenet = () => {
  const { allTypeUsers, totalEntity } = useSelector(
    (state) => state.permissions
  );
  const userDetails = useSelector((state) => state.common.user);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [openModal, setOpenModal] = useState("");
  const [userProfile, setUserProfile] = useState();
  const [allUsersData, setallUsersData] = useState([]);

  useEffect(() => {
    (async () => {
      dispatch(setUILoaderReducer(true));
      let coachId = userDetails.uid;
      if (userDetails.userType === "admin") coachId = undefined;
      await getAllUserDetails(coachId);
      await getTotalEntityForUser();
      dispatch(setUILoaderReducer(false));
    })();
  }, []);

  return (
    <div className="main-container !py-[40px]">
      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-[16px] mb-6">
        {userDetail(totalEntity).map((item, i) => (
          <ExchangeCard key={i} data={item} />
        ))}
      </div> */}
      <div className="w-full flex-center-between gap-[16px] ">
        <div className="flex items-center gap-[10px]">
          <Heading
            text="Users"
            color="text-textPrimary"
            className="!text-2xl !leading-5"
          />
          <div className="flex-center-center px-[6px] py-[3px] rounded-[26px] bg-activeBackground ">
            <Text
              text={allUsersData?.length}
              color="text-primary1"
              className={"font-normal leading-5"}
            />
          </div>
        </div>
        <Button
          text={"+ Add user"}
          rightmargin="mr-[10px]"
          className=" !px-3 !py-[7px] rounded-xl "
          bg="bg-primary1"
          color="text-background"
          cta={() => setShow(true)}
        />
      </div>
      {/* tabs */}

      <div className="mt-4 w-full relative">
        <SearchFilter
          data={allTypeUsers}
          setallUsersData={setallUsersData}
          setOpenModal={setOpenModal}
          setUserProfile={setUserProfile}
        />

        {/* table */}
        <div className="pt-[16px] get-started-tabs  with-management-tabs w-full ">
          <Tabs defaultActiveKey="1">
            <Tab eventKey="1" title="All users">
              <UserTable
                data={allUsersData}
                openModal={openModal}
                setOpenModal={setOpenModal}
                userProfile={userProfile}
                setUserProfile={setUserProfile}
              />
            </Tab>
            <Tab eventKey="2" title="Teams">
              <UserTable
                data={allUsersData?.filter((t) => t.userType === "coach")}
                openModal={openModal}
                setOpenModal={setOpenModal}
                userProfile={userProfile}
                setUserProfile={setUserProfile}
              />
            </Tab>
            <Tab eventKey="3" title="Clients">
              <UserTable
                data={allUsersData?.filter((c) => c.userType === "client")}
                openModal={openModal}
                setOpenModal={setOpenModal}
                userProfile={userProfile}
                setUserProfile={setUserProfile}
              />
            </Tab>
          </Tabs>
        </div>
      </div>

      <AddUser show={show} handleClose={() => setShow(false)} />
    </div>
  );
};

export default UserManagemenet;

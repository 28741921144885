import React from "react";
import { useDispatch } from "react-redux";
import { FeedInput, LabelFeed, TextArea } from "../../../../utils";
import { Formik } from "formik";
import { CrossIcon } from "../../../../FeedLibraryIcon";
import FeedBreadcrumbs from "../../../../components/common/breadcrumbs";
import { MainHeading } from "../../../../components";
import { seteditFormReducer } from "../../../../store/reducers/uiSettings";

const CreateMealPlan = (props) => {
  const {
    setStepper,
    setplanScreenState,
    formRef,
    uiSettings,
    closePlanHandler,
  } = props;
  const dispatch = useDispatch();
  return (
    <div>
      <div className="">
        <FeedBreadcrumbs
          backClick={closePlanHandler}
          selectedTemplateName={{ name: "Meal plan" }}
          formRef={formRef}
          cancelBtnClass="!border-none !p-2"
          submitBtnClass="!rounded-[8px]"
          marginBottom="!mb-0"
          disabled={true}
          crumbText="Meal Plan"
        />
        <MainHeading
          title="Create Meal Plan"
          description={
            "Welcome to the Kaboodle Meal Plan Builder! Please follow the prompts below, and click NEXT when finished, to move on to the next page."
          }
        />
      </div>

      <div className="mt-6 w-full lg:w-[692px] ">
        <Formik
          initialValues={{
            name: uiSettings?.editForm?.name || "",
            clientNote: uiSettings?.editForm?.clientNote || "",
          }}
          // enableReinitialize
          innerRef={formRef}
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = "Required";
            }
            // if (!values.clientNote) {
            //   errors.clientNote = "Required";
            // }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            dispatch(
              seteditFormReducer({
                type: uiSettings?.formName || "create-meal",
                data: { ...uiSettings.editForm, ...values },
              }),
            );
            setStepper([1, 2]);
            setplanScreenState("MacroTargets");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <>
              <form
                onSubmit={handleSubmit}
                className={`flex-columnl w-full ${
                  uiSettings?.formName === "view-template"
                    ? "pointer-events-none"
                    : "pointer-events-auto"
                }`}
              >
                <div className="w-full flex-items-start-column mb-6 autofill-fix">
                  <LabelFeed
                    text={
                      <>
                        Meal Plan Name <span className="text-primary1">*</span>
                      </>
                    }
                    className={"mb-[10px] !font-medium !text-lg"}
                  />
                  <FeedInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    setFieldValue={setFieldValue}
                    type="text"
                    placeholder="Add Name"
                    name="name"
                    crossicon={values?.name ? <CrossIcon /> : ""}
                    className="w-full  !px-5 !bg-secondary1"
                  />
                  {errors.name && touched.name && (
                    <div className="text-red text-[14px]  pt-[4px]">
                      {errors.name}
                    </div>
                  )}
                </div>

                <div className="w-full flex-items-start-column mb-[48px]">
                  <LabelFeed
                    text="Instructions (Optional)"
                    className={"mb-[12px] !font-medium !text-lg"}
                  />
                  <TextArea
                    placeholder="Instructions"
                    rows={10}
                    name={"clientNote"}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    value={values.clientNote}
                    onBlur={handleBlur}
                    className="pt-[20px] px-[15px] pb-[12px] rounded-[8px] text-[14px] !bg-secondary1"
                  />
                  {/* {errors.clientNote && touched.clientNote && (
                    <div className="text-red text-[14px]  pt-[4px]">
                      {errors.clientNote}
                    </div>
                  )} */}
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateMealPlan;

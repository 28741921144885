import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Stepper, Text } from "../../utils";
import { CreateMealPlan, MacroTargets, MealPlanSummary } from "./plansSteppers";
import StepTitle from "./plansSteppers/assignStep";
import { useDispatch, useSelector } from "react-redux";
import { getallMyClientAction } from "../../store/actions/common";
import { seteditFormReducer } from "../../store/reducers/uiSettings";
import { getFoodListItemAction } from "../../store/actions/mealPlan";

const NewMealPlan = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uiSettings = useSelector((state) => state.uiSettings);
  const { user } = useSelector((state) => state.common);
  const [stepper, setStepper] = useState([1]);
  const [mealsData, setMealsData] = useState(
    uiSettings?.editForm?.mealList || []
  );
  const [planScreenState, setplanScreenState] = useState("CreateMealPlan");

  useEffect(() => {
    (async () => {
      await getallMyClientAction(user?.uid);
      // await getFoodListItemAction();
    })();
  }, []);
  // useEffect(() => {
  //   if (uiSettings?.formName === 'view-mealplan') {
  //     setStepper([1, 2, 3]);
  //     setplanScreenState('MealPlanSummary');
  //   }
  // }, [uiSettings?.editForm]);
  const closePlanHandler = () => {
    navigate(uiSettings?.editForm?.closer || "/template?type=meal-plan");
  };

  const backButtonHandler = () => {
    if (planScreenState === "MacroTargets") {
      setStepper([1]);
      setplanScreenState("CreateMealPlan");
    } else if (planScreenState === "MealPlanSummary") {
      setStepper([1, 2]);
      setplanScreenState("MacroTargets");
    } else if (planScreenState === "step4Title") {
      setStepper([1, 2, 3]);
      setplanScreenState("MealPlanSummary");
    } else if (planScreenState === "CreateMealPlan") {
      navigate(uiSettings?.editForm?.closer || "/template");
    }
  };
  const nextButtonHandler = () => {
    if (planScreenState === "CreateMealPlan") {
      formRef.current?.submitForm();
    } else if (planScreenState === "MacroTargets") {
      formRef.current?.submitForm();
    } else if (planScreenState === "MealPlanSummary") {
      dispatch(
        seteditFormReducer({
          type: uiSettings?.formName || "create-meal",
          data: {
            ...uiSettings?.editForm,
            mealList: [...mealsData],
          },
        })
      );
      setStepper([1, 2, 3, 4]);
      setplanScreenState("step4Title");
    } else {
      formRef.current?.submitForm();
    }
  };

  return (
    <div className="main-container !py-[26px]">
      <div className="mb-[16px]">
        <Stepper stepper={stepper} total={4} />
      </div>
      {planScreenState === "CreateMealPlan" && (
        <CreateMealPlan
          setStepper={setStepper}
          setplanScreenState={setplanScreenState}
          formRef={formRef}
          uiSettings={uiSettings}
          closePlanHandler={closePlanHandler}
        />
      )}
      {planScreenState === "MacroTargets" && (
        <MacroTargets
          setStepper={setStepper}
          setplanScreenState={setplanScreenState}
          formRef={formRef}
          uiSettings={uiSettings}
          closePlanHandler={closePlanHandler}
        />
      )}
      {planScreenState === "MealPlanSummary" && (
        <MealPlanSummary
          formRef={formRef}
          setMealsData={setMealsData}
          mealsData={mealsData}
          closePlanHandler={closePlanHandler}
        />
      )}
      {planScreenState === "step4Title" && (
        <StepTitle
          formRef={formRef}
          closePlanHandler={closePlanHandler}
          setStepper={setStepper}
          setplanScreenState={setplanScreenState}
        />
      )}

      <div className="w-full flex-center-between py-[48px]">
        <Button
          text="Back"
          type="button"
          bg="bg-background border border-textPrimary"
          className="px-[30px] rounded-[12px] leading-6 text-[14px]"
          color="text-textPrimary"
          cta={backButtonHandler}
        />
        {/* <Text text="Next: Review the plan" color="text-textPrimary" fontSize={'text-[15px]'} className={'leading-[21px]'} /> */}
        {uiSettings?.formName === "view-template" && stepper.includes(4) ? (
          ""
        ) : (
          <Button
            text={!stepper.includes(4) ? "Next" : "Save & Exit"}
            type="button"
            bg="bg-primary1"
            className="px-[40px]  rounded-[12px] leading-6 text-[16px]"
            color="text-background"
            cta={nextButtonHandler}
          />
        )}
      </div>
    </div>
  );
};

export default NewMealPlan;

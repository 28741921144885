import React from "react";

export const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path
        d="M13.8168 1.06754L7.5668 7.31754C7.50876 7.37565 7.43983 7.42175 7.36395 7.4532C7.28808 7.48465 7.20675 7.50084 7.12462 7.50084C7.04248 7.50084 6.96115 7.48465 6.88528 7.4532C6.8094 7.42175 6.74047 7.37565 6.68243 7.31754L0.432428 1.06754C0.315152 0.95026 0.249268 0.7912 0.249268 0.625347C0.249268 0.459495 0.315152 0.300435 0.432428 0.18316C0.549703 0.0658843 0.708763 0 0.874615 0C1.04047 0 1.19953 0.0658843 1.3168 0.18316L7.12462 5.99175L12.9324 0.18316C12.9905 0.125091 13.0594 0.0790281 13.1353 0.0476015C13.2112 0.0161748 13.2925 0 13.3746 0C13.4567 0 13.5381 0.0161748 13.6139 0.0476015C13.6898 0.0790281 13.7587 0.125091 13.8168 0.18316C13.8749 0.241229 13.9209 0.310167 13.9524 0.386037C13.9838 0.461908 14 0.543226 14 0.625347C14 0.707469 13.9838 0.788787 13.9524 0.864658C13.9209 0.940528 13.8749 1.00947 13.8168 1.06754Z"
        fill="#E6E6E6"
      />
    </svg>
  );
};

import React from "react";
import { updateReadStatusContext } from "../../../Services/inboxContext";
import { useDispatch } from "react-redux";
import { Heading, Text } from "../../../utils";
import { setActiveChatReducer } from "../../../store/reducers/inbox";
import { formatChatDate } from "../../../helper";
import { RightArrow } from "../../../FeedLibraryIcon";

import ClienImage from "../../../assets/images/client-img.jpg";

const UserChartCard = ({ userChat, activeChat, uid }) => {
  const dispatch = useDispatch();
  const unReadCount = userChat?.messages?.filter(
    (msg) => msg?.isRead === false && msg?.senderId !== uid,
  )?.length;
  const message = userChat?.messages?.length
    ? userChat?.messages?.[userChat?.messages?.length - 1]?.text?.slice(0, 70)
    : "";
  const totalMessage = userChat?.messages?.length;

  return (
    <div
      className={`w-full flex-items-start gap-[16px] ${
        activeChat?.id === userChat?.id ? "bg-background" : "bg-background4"
      } rounded-[20px] p-[20px]`}
      onClick={() => {
        updateReadStatusContext(activeChat?.id, userChat?.reciever?.uid);
        dispatch(setActiveChatReducer(userChat));
      }}
    >
      <div className="w-[46px] h-[46px] rounded-lg flex-start-center shadow-clientImgShadow overflow-hidden  flex-shrink-0">
        <img
          className="w-full h-full object-cover "
          src={userChat?.reciever?.avatar || ClienImage}
          alt="client"
        />
      </div>
      <div className="w-full flex-column gap-[12px]">
        <div className="w-full flex-center-between gap-[16px]">
          <Heading
            text={userChat?.reciever?.name}
            fontweight="!font-[400]"
            fontSize="!text-[16px]"
            className="leading-normal tracking-[-0.064px]"
            color="text-textPrimary"
          />

          <div className="flex-items-center gap-[8px]">
            <Heading
              text={
                totalMessage
                  ? formatChatDate(
                      userChat?.messages?.[totalMessage - 1]?.timestamp,
                    )
                  : ""
              }
              fontweight="!font-[400]"
              fontSize="!text-[16px]"
              className="leading-normal tracking-[-0.064px]"
              color="text-textPrimary"
            />
            <RightArrow />
          </div>
        </div>

        <Text
          text={`${message?.length > 70 ? message + "..." : message}`}
          className="!text-[#8C8C8C] m-0"
        />
        <div className="w-full flex-center-between gap-[16px]">
          <span className="w-max px-[8px] py-[6px] rounded-[6px] bg-activeBackground">
            <Text
              text={userChat?.reciever?.clientType?.name || "N/A"}
              className="!text-white m-0 tracking-[-0.056px]"
            />
          </span>
          {unReadCount !== 0 && (
            <span className="w-max px-[10.5px] py-[6px] rounded-[6px] bg-primary1">
              <Text
                text={unReadCount}
                className="!text-background m-0 tracking-[-0.056px]"
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserChartCard;

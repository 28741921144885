import store from "../store";
import {
  getAllUsersContext,
  getTotalEntityCount,
} from "../../Services/permissions";

import {
  addUserDetailReducer,
  deleteUserActionReducer,
  setAllUserDetailReducer,
  setTotalEnityCount,
  updateUserDetailsReducer,
} from "../reducers/permissions";
import { updateCoachProfileContext } from "../../Services/commonContext";
import { setbtnLoaderReducer } from "../reducers/uiSettings";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const getAllUserDetails = async (uid) => {
  const result = await getAllUsersContext(uid, "users");
  store.dispatch(setAllUserDetailReducer(result));
  return result;
};
const addUserDetailsAction = async (payload) => {
  store.dispatch(addUserDetailReducer(payload));
};
const updateUserDetailsAction = async (payload) => {
  const result = await updateCoachProfileContext("users", payload);
  if (result) {
    store.dispatch(updateUserDetailsReducer(payload));
    return result;
  }
};
const deleteUserAction = async (docId) => {
  Swal.fire({
    title: "Delete User?",
    html: "<p>Are you sure?<br/> Once the User is deleted, it will not be able to login.</p>",
    showCancelButton: true,
    confirmButtonText: "Yes",
    customClass: {
      confirmButton: "!bg-primary1 !text-background",
    },
    background: "#030303",
    showLoaderOnConfirm: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      store.dispatch(deleteUserActionReducer(docId));
      store.dispatch(setbtnLoaderReducer(false));
      toast("User has been delete successfully");
    }
  });
};
const getTotalEntityForUser = async (uid) => {
  const totals = await getTotalEntityCount([
    "mealplan",
    "workoutplan",
    "supplementPlan",
    "users",
  ]);
  store.dispatch(setTotalEnityCount(totals));
};

export {
  getAllUserDetails,
  getTotalEntityForUser,
  deleteUserAction,
  addUserDetailsAction,
  updateUserDetailsAction,
};

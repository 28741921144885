import React, { useEffect, useState } from "react";
import FeedInput from "../FormElements/FeedInput";
import {
  CrossIcon,
  Plus,
  SearchIcon,
  SubtractIcon,
} from "../../FeedLibraryIcon";
import Button from "../Button";
import SpinLoader from "../Spinner";
import Text from "../ParaText";
import LabelFeeed from "../FormElements/Label";
import NoImage from "../../assets/images/no-image.svg";

const SearchDropdown = ({
  options,
  setselectedProduct,
  selectedProduct,
  disabled,
}) => {
  const [search, setSearch] = useState("");
  const [dropOptions, setdropOptions] = useState([]);
  const [searchloader, setsearchloader] = useState(true);

  useEffect(() => {
    if (options?.length && !disabled) {
      setdropOptions(
        options?.map((itm) => {
          if (selectedProduct?.some((obj) => obj.id === itm.id)) {
            return {
              ...itm,
              timeScheduler: [],
              selected: true,
            };
          } else {
            return {
              ...itm,
              timeScheduler: [],
              selected: false,
            };
          }
        }),
      );
      setsearchloader(false);
    } else {
      setdropOptions([]);
    }
  }, [selectedProduct, options, search, disabled]);

  return (
    <div className="w-full flex flex-col gap-[20px] autofill-fix">
      <LabelFeeed
        text="Search Supplement "
        fontSize={"text-[20px]"}
        color="text-textPrimary"
        className="leading-[22px]"
      />
      <FeedInput
        name="search"
        type="text"
        value={search}
        placeholder="Supplement Name"
        crossicon={
          search ? searchloader ? <SpinLoader size="sm" /> : <CrossIcon /> : ""
        }
        icon={<SearchIcon />}
        clearState={() => setSearch("")}
        setFieldValue={() => {}}
        disabled={disabled}
        onChange={(e) => {
          setsearchloader(true);
          setSearch(e.target.value);
        }}
        className="w-full placeholder:!text-text2 !bg-secondary1"
      />
      {search && (
        <div className="w-full  inherit overflow-y-auto max-h-[396px] flex flex-col  rounded-lg bg-secondary1">
          {dropOptions
            ?.filter(
              (prod) =>
                prod?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                prod?.vendor?.toLowerCase()?.includes(search?.toLowerCase()),
            )
            ?.map((item) =>
              item?.availableForSale ? (
                <div
                  key={item?.id}
                  className="flex items-center gap-[10px] justify-between m-0  flex-1 self-stretch grow  h-[58px]  px-[24px] py-[17px]  border-b [border-bottom-style:solid] border-[#c8c8c833]  last:!border-none"
                >
                  <div className="flex items-center gap-[16px]">
                    <div className="w-[36px] h-[36px] rounded-lg p-[3px] flex items-center justify-center bg-background  ">
                      <img
                        src={
                          item?.images?.[0]?.src ||
                          item?.featureImage?.src ||
                          NoImage
                        }
                        alt="brand"
                        className="w-full h-full object-contain"
                      />
                    </div>

                    <Text
                      text={`${item?.name} / ${item?.vendor} ${
                        item?.variants?.length > 1 ? "/" + item?.title : ""
                      }  / $${item?.price?.amount}`}
                      fontSize="!text-[16px]"
                      color="text-textPrimary"
                      className={"leading-[24px] tracking-[0.5px]  "}
                    />
                  </div>
                  <Button
                    className="!w-[24px] !h-[24px] flex-shrink-0 !p-0 rounded-[4px] bg-primary1"
                    Icon={
                      selectedProduct?.some((obj) => obj.id === item.id) ? (
                        <SubtractIcon color="#202020" />
                      ) : (
                        <Plus color="#202020" w="20" h="20" />
                      )
                    }
                    cta={() => {
                      const selected = dropOptions?.map((opt) => {
                        if (opt?.id === item?.id) {
                          return {
                            ...opt,
                            price: { amount: item?.price?.amount },
                            variants: { ...item },
                            selected: !item.selected,
                          };
                        } else {
                          return { ...opt };
                        }
                      });
                      setselectedProduct(selected?.filter((f) => f.selected));
                    }}
                    type={"button"}
                  />
                </div>
              ) : (
                <div className="flex items-center gap-[10px] justify-between m-0  flex-1 self-stretch grow  h-[58px]  px-[24px] py-[17px]  border-b [border-bottom-style:solid] border-[#c8c8c833]  last:!border-none">
                  <div className="flex items-center gap-[16px]">
                    <div className="w-[36px] h-[36px] rounded-lg p-[3px] flex items-center justify-center bg-background  ">
                      <img
                        src={
                          item?.images?.[0]?.src ||
                          item?.featureImage?.src ||
                          NoImage
                        }
                        alt="brand"
                        className="w-full h-full object-contain"
                      />
                    </div>

                    <Text
                      text={`${item?.vendor} / ${item?.name} / ${item?.title} / $${item?.price?.amount}`}
                      fontSize="!text-[16px]"
                      color="text-text5"
                      className={
                        " !font-medium leading-[24px] tracking-[0.5px]  "
                      }
                    />
                  </div>
                  <Text
                    text="Out of stock"
                    color="text-red2"
                    fontSize="!text-[16px]"
                    className="!font-normal leading-[24px] tracking-[0.5px] !whitespace-nowrap "
                  />
                </div>
              ),
            )}
          {dropOptions?.filter(
            (prod) =>
              prod?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
              prod?.vendor?.toLowerCase()?.includes(search?.toLowerCase()),
          )?.length === 0 && (
            <div className="flex items-center gap-[10px] justify-between m-0  flex-1 self-stretch grow  h-[58px]  px-[24px] py-[17px]  border-b [border-bottom-style:solid] border-[#c8c8c833]  last:!border-none">
              <div className="flex items-center gap-[16px]">
                <Text
                  text={`No Product Found`}
                  fontSize="!text-[16px]"
                  color="text-text5"
                  className={" !font-medium leading-[24px] tracking-[0.5px]  "}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";

import FeedBreadcrumbs from "../../../components/common/breadcrumbs";
import { MainHeading } from "../../../components";
import { Button, FeedInput, LabelFeed, Text, TextArea } from "../../../utils";
import { CrossIcon } from "../../../FeedLibraryIcon";
import { seteditFormReducer } from "../../../store/reducers/uiSettings";
import { guidedWorkoutQuestions } from "../../../schema";
import { getallMyClientAction } from "../../../store/actions/common";
import { auth } from "../../../configs/firebase";
import { generateUniqueId } from "../../../helper";
import VideoModal from "./videoModal";
import { ExcercisePanel } from "./excercisePanel";
import { Panel, PanelGroup } from "rsuite";

const movementObj = {
  order: "B1",
  excerciseName: "Excersise Name",
  sets: 1,
  repititions: 1,
  rest: "1min",
  temp1: 1,
  temp2: 2,
  temp3: 3,
  temp4: 4,
  note: "",
  videoUrl: "",
};

const SteppeThree = ({
  closePlanHandler,
  formRef,
  setStepper,
  setplanScreenState,
  selectedPlan,
}) => {
  const uiSettings = useSelector((state) => state.uiSettings);
  const { movements } = uiSettings?.editForm;
  const { uid } = auth.currentUser;
  const [guidedquestions, setguidedquestions] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedMovement, setselectedMovement] = useState({});
  const [movementType, setMovementType] = useState("Daily");
  const [dailyExcercies, setDailyExcercises] = useState([]);
  const [weeklyExcercies, setWeeklyExcercises] = useState([]);

  const dispatch = useDispatch();
  // all functions
  const addMovementCTA = () => {
    setDailyExcercises((prevClickData) => [
      ...prevClickData,
      { ...movementObj, id: generateUniqueId() },
    ]);
  };

  const handleTextValues = (moveId, key, value) => {
    if (movementType === "Daily") {
      setDailyExcercises(
        dailyExcercies?.map((move) => {
          if (move?.id === moveId) {
            return {
              ...move,
              [key]: value,
            };
          } else {
            return move;
          }
        })
      );
    } else if (movementType === "Weekly") {
      setWeeklyExcercises((prevWeeklyExcercises) => {
        const updatedWeeklyExcercises = { ...prevWeeklyExcercises };
        Object.keys(updatedWeeklyExcercises).forEach((day) => {
          updatedWeeklyExcercises[day] = updatedWeeklyExcercises[day].map(
            (exercise) => {
              if (exercise?.id === moveId) {
                alert("raja");
                return {
                  ...exercise,
                  [key]: value,
                };
              } else {
                return exercise;
              }
            }
          );
        });
        return updatedWeeklyExcercises;
      });
    }
  };
  const handleUpdateMovements = (moveId, key, value) => {
    let pattern = /^(?:0|\d*(?:\.\d+)?)$/;
    if (movementType === "Daily") {
      setDailyExcercises(
        dailyExcercies?.map((move) => {
          if (move?.id === moveId && pattern.test(value)) {
            return {
              ...move,
              [key]: value,
            };
          } else {
            return move;
          }
        })
      );
    } else if (movementType === "Weekly") {
      setWeeklyExcercises((prevWeeklyExcercises) => {
        const updatedWeeklyExcercises = { ...prevWeeklyExcercises };
        Object.keys(updatedWeeklyExcercises).forEach((day) => {
          updatedWeeklyExcercises[day] = updatedWeeklyExcercises[day].map(
            (exercise) => {
              if (exercise?.id === moveId && pattern.test(value)) {
                alert("raja");
                return {
                  ...exercise,
                  [key]: value,
                };
              } else {
                return exercise;
              }
            }
          );
        });
        return updatedWeeklyExcercises;
      });
    }
  };
  const handleDeleteMovements = (moveId) => {
    setDailyExcercises(dailyExcercies?.filter((f) => f.id !== moveId));
  };
  function addIdstoMovements(data) {
    if (Object.keys(data).length) {
      const weekly = {};

      for (const week in data) {
        weekly[week] = {};

        for (const day in data[week]) {
          console.log("data ===>", data);
          console.log("weekly ===>", weekly);
          console.log("week ===>", week);
          console.log("day ===>", day);

          if (Array.isArray(data[week][day].exercises)) {
            weekly[week][day] = {
              exercises: data[week][day].exercises.map((exercise) => ({
                ...exercise,
                id: generateUniqueId(),
              })),
            };
          } else if (Array.isArray(data[week][day])) {
            weekly[week][day] = data[week][day].map((exercise) => ({
              ...exercise,
              id: generateUniqueId(),
            }));
          } else {
            weekly[week][day] = data[week][day];
          }
        }
      }

      console.log("weekly ===>", weekly);
      setWeeklyExcercises(weekly);
    }
  }

  // all side effect
  useEffect(() => {
    getallMyClientAction(uid);
    setguidedquestions(
      guidedWorkoutQuestions?.map((question, index) => {
        return {
          ...question,
          questionsOption: question?.questionsOption?.map((q, key) => {
            return {
              ...q,
              checked: key === 0 ? true : false,
            };
          }),
        };
      })
    );
    if (selectedPlan === "AI") {
      addIdstoMovements(movements?.weekly);
    }
    if (movements?.daily?.length > 0) {
      setDailyExcercises(
        movements?.daily?.map((item) => {
          return {
            ...item,
            id: generateUniqueId(),
          };
        })
      );
    }
    console.log("In useEffect, dailyExcercies ===> ", dailyExcercies);
  }, [movements]);

  console.log("dailyExcercies", dailyExcercies);
  console.log("weeklyExcercies", weeklyExcercies);
  return (
    <>
      <div className="">
        <FeedBreadcrumbs
          backClick={closePlanHandler}
          selectedTemplateName={{ name: "Workouts" }}
          formRef={formRef}
          cancelBtnClass="!border-none !p-2"
          submitBtnClass="!rounded-[8px]"
          marginBottom="!mb-0"
          disabled={true}
          crumbText="Workout Plan"
        />
        <MainHeading
          title="Add Movements"
          description={
            "Welcome to the Kaboodle Workout Plan Builder. Please complete all steps and proceed using the buttons at the bottom of each screen."
          }
        />
      </div>

      <div className="mt-6 w-full  ">
        <Formik
          initialValues={{
            name: uiSettings?.editForm?.name || "",
            description: uiSettings?.editForm?.description || "",
          }}
          // enableReinitialize
          innerRef={formRef}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            dispatch(
              seteditFormReducer({
                type: uiSettings?.formName || "create-meal",
                data: {
                  ...uiSettings.editForm,
                  ...values,
                  movements: {
                    daily: dailyExcercies,
                    weekly: selectedPlan === "AI" ? weeklyExcercies : [],
                  },
                },
              })
            );
            setStepper([1, 2, 3, 4]);
            setplanScreenState("review&Save");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <>
              <form
                onSubmit={handleSubmit}
                className={`flex-column w-full ${
                  uiSettings?.formName === "view-template"
                    ? "pointer-events-none"
                    : "pointer-events-auto"
                }`}
              >
                <div className="w-full h-full mb-6">
                  <div className="w-full flex-items-start-column mb-6 autofill-fix">
                    <LabelFeed
                      text={
                        <>
                          Workout Plan Name{" "}
                          <span className="text-primary1">*</span>
                        </>
                      }
                      className={"mb-[10px] !font-medium !text-lg"}
                    />
                    <FeedInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      setFieldValue={setFieldValue}
                      type="text"
                      placeholder="Add Name"
                      name="name"
                      crossicon={values?.name ? <CrossIcon /> : ""}
                      className="w-full  !px-5 !bg-secondary1"
                    />
                    {errors.name && touched.name && (
                      <div className="text-red text-[14px]  pt-[4px]">
                        {errors.name}
                      </div>
                    )}
                  </div>

                  <div className="w-full h-full flex-items-start-column ">
                    <LabelFeed
                      text="Description"
                      className={"mb-[12px] !font-medium !text-lg"}
                    />
                    <div className="w-full  min-h-[114px] px-[12px] py-6 rounded-[20px] bg-secondary1">
                      <TextArea
                        placeholder="Add Instruction"
                        rows={5}
                        name={"description"}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        value={values.description}
                        onBlur={handleBlur}
                        className="leading-[24px] h-full !p-0 text-[16px] font-normal text-text6 !outline-none !border-none !bg-transparent"
                      />
                    </div>
                  </div>
                </div>
                {/* d */}
                <div className="w-full flex-center-between mb-[10px]">
                  <Text
                    text="Movements"
                    fontSize={"!text-[18px]"}
                    color="text-white"
                    className="!font-medium !leading-[22px] "
                  />
                  {/* "Daily", "Weekly" */}

                  {selectedPlan === "AI" && (
                    <div className="flex items-center  border-b-[2px] border-solid border-background2 ">
                      {["Daily", "Weekly"].map((item) => (
                        <Button
                          key={item}
                          text={item}
                          type="button"
                          className={`!w-full !h-auto !py-[12px] !px-[24px] !rounded-0 !text-base !font-normal  border-b-[2px] border-solid ${
                            movementType === item
                              ? "text-primary1 border-primary1"
                              : "text-textPrimary  border-transparent"
                          } !bg-transparent`}
                          cta={() => setMovementType(item)}
                        />
                      ))}
                    </div>
                  )}
                </div>
                <div className="w-full flex-column gap-[10px]">
                  {movementType === "Daily" ? (
                    dailyExcercies?.length > 0 ? (
                      dailyExcercies?.map((item, index) => (
                        <ExcercisePanel
                          index={index}
                          item={item}
                          handleBlur={handleBlur}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleUpdateMovements={handleUpdateMovements}
                          handleTextValues={handleTextValues}
                          uiSettings={uiSettings}
                          handleDeleteMovements={handleDeleteMovements}
                          setselectedMovement={setselectedMovement}
                          setOpen={setOpen}
                        />
                      ))
                    ) : (
                      <div className="text-primary2 text-center">
                        There is no excercise for today, Click to add excercise
                      </div>
                    )
                  ) : (
                    Object.keys(weeklyExcercies).map((week) =>
                      Object.keys(weeklyExcercies[week]).map((day, counter) => (
                        <PanelGroup accordion defaultActiveKey={0}>
                          <Panel
                            key={counter}
                            header={`${week} - ${day}`}
                            collapsible
                            eventKey={counter}
                            className="capitalize bg-black rounded-xl border-none"
                          >
                            {Array.isArray(
                              movements?.weekly[week]?.[day]?.exercises
                            ) &&
                            movements?.weekly[week]?.[day]?.exercises.length > 0 ? (
                              movements?.weekly[week]?.[day]?.exercises.map(
                                (item, index) => (
                                  <div className="mb-[10px]" key={index}>
                                    <ExcercisePanel
                                      index={index}
                                      item={item}
                                      handleBlur={handleBlur}
                                      values={values}
                                      setFieldValue={setFieldValue}
                                      handleUpdateMovements={
                                        handleUpdateMovements
                                      }
                                      handleTextValues={handleTextValues}
                                      uiSettings={uiSettings}
                                      handleDeleteMovements={
                                        handleDeleteMovements
                                      }
                                      setselectedMovement={setselectedMovement}
                                      setOpen={setOpen}
                                    />
                                  </div>
                                )
                              )
                            ) : Array.isArray(movements?.weekly[week]?.[day]) &&
                              movements?.weekly[week]?.[day]?.length > 0 ? (
                              movements?.weekly[week]?.[day].map((item, index) => (
                                <div className="mb-[10px]" key={index}>
                                  <ExcercisePanel
                                    index={index}
                                    item={item}
                                    handleBlur={handleBlur}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    handleUpdateMovements={
                                      handleUpdateMovements
                                    }
                                    handleTextValues={handleTextValues}
                                    uiSettings={uiSettings}
                                    handleDeleteMovements={
                                      handleDeleteMovements
                                    }
                                    setselectedMovement={setselectedMovement}
                                    setOpen={setOpen}
                                  />
                                </div>
                              ))
                            ) : (
                              <div className="text-center text-primary2">
                                There is no exercise for today, Take some
                                rest..!!
                              </div>
                            )}
                          </Panel>
                        </PanelGroup>
                      ))
                    )
                  )}
                </div>
                {movementType === "Daily" && (
                  <Button
                    text={"+ Add Movement"}
                    type="button"
                    bg="bg-activeBackground3"
                    className="!w-full !h-[64px] !p-[20px] !rounded-[12px] !text-base mt-6"
                    color="text-primary1"
                    cta={addMovementCTA}
                  />
                )}
              </form>
            </>
          )}
        </Formik>
      </div>
      <VideoModal
        open={open}
        handleClose={() => setOpen(false)}
        selectedMovement={selectedMovement}
        handleTextValues={handleTextValues}
      />
    </>
  );
};

export default SteppeThree;

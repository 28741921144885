import React, { useRef } from "react";
import { Loader, Modal } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FeedDropdown,
  FeedInput,
  Heading,
  LabelFeed,
  Text,
} from "../../../../../../utils";
import { CrossIcon } from "../../../../../../FeedLibraryIcon";
import { Formik } from "formik";
import { addFoodItemList } from "../../../../../../store/actions/mealPlan";
import { setbtnLoaderReducer } from "../../../../../../store/reducers/uiSettings";
import { toast } from "react-toastify";
import { generateUniqueId } from "../../../../../../helper";
const measuringUnits = [
  {
    id: 1,
    name: "Cup",
  },
  {
    id: 2,
    name: "Grams",
  },
  {
    id: 3,
    name: "Bag",
  },
  {
    id: 4,
    name: "ML",
  },
  {
    id: 5,
    name: "L",
  },
];
const AddFoodModal = ({ open, setOpen }) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.common);
  const uiSettings = useSelector((state) => state.uiSettings);
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className="w-full max-w-[926px] foodModal"
    >
      <Modal.Body className="w-full !pt-0 !pb-0 !pl-0 !pr-[16px]  !mt-0 ">
        <Heading
          text={"Add a Food Item"}
          color="text-textPrimary"
          fontSize="text-[22px]"
          fontweight="font-bold"
          className="leading-6 mb-[16px]"
        />
        <Text
          text={
            "Enter foods exactly as the label appears.\nBE SURE TO ADD ALL THE SERVING SIZES CONVERSIONS shown on the label.\nSpecifically enter the serving size in grams (or fluid ounces/ml if it’s a liquid or doesn’t show grams).\nA saved food will be added into your database and will be sent to the admin for approval to be added."
          }
          color="text-whiteGray"
          className="font-normal "
        />
        {/*  */}
        <div className=" mt-6 mb-9">
          <Heading
            text={"Product Information"}
            color="text-textPrimary"
            fontSize="text-[20px]"
            fontweight="font-medium"
            className="leading-[22px] mb-[16px] "
          />
          <Text
            text={"*Fields are required"}
            color="text-primary1"
            className="font-normal "
          />
          <Formik
            initialValues={{
              name: "",
              food_type: "",
              brand_name: "",
              variety: "",
              link: "",
              tags: "",
              notes: "",
              serving_qty: "",
              serving_measurement: "",
              alt_serving_qty: "",
              alt_serving_measurement: "",
              specificMeasurement: "",
              calories: "",
              carbs: "",
              protein: "",
              fat: "",
              sodium: "",
              potassium: "",
              fiber: "",
            }}
            innerRef={formRef}
            enableReinitialize
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = "Required";
              }
              if (!values.food_type) {
                errors.food_type = "Required";
              }
              if (!values.notes) {
                errors.notes = "Required";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const measureUnit =
                measuringUnits?.filter(
                  (f) => f.id === values.specificMeasurement,
                )?.[0]?.name || "N/A";
              const capitalizeFoodName =
                values.name.charAt(0).toUpperCase() + values.name.slice(1);
              dispatch(setbtnLoaderReducer(true));
              const res = await addFoodItemList("foodItems", {
                ...values,
                id: generateUniqueId(),
                docId: generateUniqueId(),
                name: capitalizeFoodName,
                specificMeasurement: measureUnit,
                calories: 1,
                carbs: 1,
                protein: 1,
                fat: 1,
                sodium: 1,
                potassium: 1,
                fiber: 1,
                coachId: user?.uid,
                instaCartId: null,
                clientId: "all",
                companyId: "all",
                barCodeId: null,
              });
              if (res) {
                setOpen(false);
                dispatch(setbtnLoaderReducer(false));
                toast(`Food Item added Successfully`);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className="w-full">
                <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-[16px]">
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text={
                        <>
                          Food Name <span className="text-primary1"> *</span>
                        </>
                      }
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      crossicon={values?.name ? <CrossIcon /> : ""}
                      placeholder="Food Name"
                      type="text"
                      setFieldValue={setFieldValue}
                      className="!text-sm !rounded-[4px]"
                    />
                    {errors.name && touched.name && (
                      <LabelFeed
                        text={errors.name}
                        color="text-red"
                        className="!leading-[22px] !text-[14px]"
                      />
                    )}
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text={
                        <>
                          Food Type <span className="text-primary1"> *</span>
                        </>
                      }
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="food_type"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.food_type}
                      crossicon={values?.food_type ? <CrossIcon /> : ""}
                      placeholder="Type the food type"
                      className="!text-sm !rounded-[4px]"
                    />
                    {errors.food_type && touched.food_type && (
                      <LabelFeed
                        text={errors.food_type}
                        color="text-red"
                        className="!leading-[22px] !text-[14px]"
                      />
                    )}
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Brand"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="brand_name"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.brand_name}
                      crossicon={values?.brand_name ? <CrossIcon /> : ""}
                      placeholder="Name"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Flavor/Variety (If no flavor/variety, type N/A)"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="variety"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.variety}
                      crossicon={values?.variety ? <CrossIcon /> : ""}
                      placeholder="Flavor/Variety"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Product Link"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="link"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.link}
                      crossicon={values?.link ? <CrossIcon /> : ""}
                      placeholder="Link"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Tags (seperated by a comma)"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="tags"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.tags}
                      crossicon={values?.tags ? <CrossIcon /> : ""}
                      placeholder="Tags"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full col-span-2">
                    <LabelFeed
                      text="Notes"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="notes"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.notes}
                      crossicon={values?.notes ? <CrossIcon /> : ""}
                      placeholder="Notes"
                      className="!text-sm !rounded-[4px]"
                    />
                    {errors.notes && touched.notes && (
                      <LabelFeed
                        text={errors.notes}
                        color="text-red"
                        className="!leading-[22px] !text-[14px]"
                      />
                    )}
                  </div>
                </div>

                {/*  */}
                <div className="flex items-center gap-1 mb-6  mt-9">
                  <Heading
                    text={"Nutrition Facts"}
                    color="text-textPrimary"
                    fontSize="text-[20px]"
                    fontweight="font-medium"
                    className="leading-[22px]  "
                  />
                  <Heading
                    text={"(Per Serving)"}
                    color="text-text6"
                    fontSize="text-[20px]"
                    fontweight="font-medium"
                    className="leading-[22px]  "
                  />
                </div>

                <div className="w-full grid grid-cols-4 gap-x-6 gap-y-[16px] ">
                  <div className="flex flex-col gap-[8px] w-full col-span-2">
                    <LabelFeed
                      text="Serving Quantity"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="serving_qty"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.serving_qty}
                      crossicon={values?.serving_qty ? <CrossIcon /> : ""}
                      placeholder="Serving Quantity"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full col-span-2">
                    <LabelFeed
                      text="Serving Measurement"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      type="text"
                      name="serving_measurement"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.serving_measurement}
                      crossicon={
                        values?.serving_measurement ? <CrossIcon /> : ""
                      }
                      placeholder="Serving Measurement"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full col-span-2">
                    <LabelFeed
                      text="Related Serving Quantity"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      type="text"
                      name="alt_serving_qty"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.alt_serving_qty}
                      crossicon={values?.alt_serving_qty ? <CrossIcon /> : ""}
                      placeholder="Related Serving Quantity"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Related Serving Measurement"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] !whitespace-nowrap"
                    />
                    <FeedInput
                      name="alt_serving_measurement"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.alt_serving_measurement}
                      crossicon={
                        values?.alt_serving_measurement ? <CrossIcon /> : ""
                      }
                      placeholder="Type here"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Specific Measurement"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedDropdown
                      name="specificMeasurement"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={measuringUnits}
                      setFieldValue={setFieldValue}
                      value={values.specificMeasurement}
                      crossicon={
                        values?.specificMeasurement ? <CrossIcon /> : ""
                      }
                      placeholder="Select"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Calories"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="calories"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.calories}
                      crossicon={values?.calories ? <CrossIcon /> : ""}
                      placeholder="Kcal"
                      type="number"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Carbs"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="carbs"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.carbs}
                      crossicon={values?.carbs ? <CrossIcon /> : ""}
                      placeholder="00.00 grams"
                      type="number"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Protein"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="protein"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.protein}
                      type="number"
                      crossicon={values?.protein ? <CrossIcon /> : ""}
                      placeholder="00.00 grams"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Fat"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="fat"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={values.fat}
                      type="number"
                      crossicon={values?.fat ? <CrossIcon /> : ""}
                      placeholder="00.00 grams"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Sodium"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="sodium"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      setFieldValue={setFieldValue}
                      value={values.sodium}
                      crossicon={values?.sodium ? <CrossIcon /> : ""}
                      placeholder="00.00 grams"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Potassium"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="potassium"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      setFieldValue={setFieldValue}
                      value={values.potassium}
                      crossicon={values?.potassium ? <CrossIcon /> : ""}
                      placeholder="00.00 grams"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text="Fiber"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[22px] "
                    />
                    <FeedInput
                      name="fiber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      setFieldValue={setFieldValue}
                      value={values.fiber}
                      crossicon={values?.fiber ? <CrossIcon /> : ""}
                      placeholder="00.00 grams"
                      className="!text-sm !rounded-[4px]"
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
      <Modal.Footer className="w-full flex justify-between">
        <Button
          text="Cancel"
          type="button"
          bg=" border border-textPrimary"
          className="w-[102px] rounded-[8px] !py-[7px] font-medium text-sm"
          color="text-textPrimary"
          cta={() => setOpen(false)}
        />

        <Button
          text={
            uiSettings?.btnloader ? (
              <Loader size="sm" content="Request this Food" />
            ) : (
              "Request this Food"
            )
          }
          type="submit"
          bg="bg-primary1"
          className="!px-[14px] !py-[7px] rounded-[8px] font-medium text-sm "
          color="text-background"
          cta={() => {
            formRef.current?.submitForm();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AddFoodModal;

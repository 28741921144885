export const selectTemplate = [{id: 1, name: 'Test Template'}];

export const guidedWorkoutQuestions = [
  {
    id: 69,
    key: 'age',
    questionsName: 'What is your age?',
    questionsOption: [
      {
        id: 70,
        value: '18-24',
        label: '18 to 24'
      },
      {
        id: 71,
        value: '25-34',
        label: '25 to 34'
      },
      {
        id: 72,
        value: '35-44',
        label: '35 to 44'
      },
      {
        id: 79,
        value: '45-54',
        label: '45 to 54'
      },
      {
        id: 80,
        value: '55-64',
        label: '55 to 64'
      },
      {
        id: 81,
        value: '65-100',
        label: '65 or over'
      }
    ]
  },
  {
    id: 73,
    key: 'gender',
    questionsName: 'What is your gender',
    questionsOption: [
      {
        id: 74,
        value: 'Male'
      },
      {
        id: 75,
        value: 'Female'
      }
    ]
  },
  {
    id: 0,
    key: 'fitnessGoal',
    questionsName: 'What Are Your Fitness Goals?',
    questionsOption: [
      {
        id: 1,
        value: 'Lose Fat'
      },
      {
        id: 2,
        value: 'Gain Muscle'
      },
      {
        id: 3,
        value: 'Gain Strength'
      }
    ]
  },
  {
    id: 36,
    key: 'commitDays',
    questionsName: 'How Many Days a Week Can You Commit to Exercise?',
    questionsOption: [
      {
        id: 37,
        value: '3 days a week'
      },
      {
        id: 38,
        value: '5 days a week'
      },
      {
        id: 39,
        value: '2 days a week'
      }
    ]
  },
  {
    id: 4,
    key: 'fitnessLevel',
    questionsName: 'What Is Your Current Fitness Level?',
    questionsOption: [
      {
        id: 5,
        value: 'Cardiovascular Endurance'
      },
      {
        id: 6,
        value: 'Muscular Strength'
      },
      {
        id: 7,
        value: 'Muscular Endurance'
      },
      {
        id: 8,
        value: 'Flexibility'
      },
      {
        id: 9,
        value: 'Body Composition'
      },
      {
        id: 10,
        value: 'Balance and Coordination'
      },
      {
        id: 11,
        value: 'Agility and Speed'
      },
      {
        id: 12,
        value: 'Power'
      },
      {
        id: 13,
        value: 'Reaction Time'
      },
      {
        id: 14,
        value: 'Stamina'
      }
    ]
  },
  {
    id: 15,
    key: 'exerciseType',
    questionsName: 'What Is Your Preferred Exercise Type?',
    questionsOption: [
      {
        id: 16,
        value: 'Strength Training'
      },
      {
        id: 17,
        value: 'Cardiovascular Exercise'
      },
      {
        id: 18,
        value: 'Yoga'
      },
      {
        id: 19,
        value: 'Pilates'
      },
      {
        id: 20,
        value: 'High-Intensity Interval Training (HIIT)'
      },
      {
        id: 21,
        value: 'Aerobics'
      },
      {
        id: 22,
        value: 'Swimming'
      },
      {
        id: 23,
        value: 'Cycling'
      },
      {
        id: 24,
        value: 'Dance'
      },
      {id: 25, value: 'Martial Arts'},
      {id: 26, value: 'Hiking'},
      {id: 27, value: 'Team Sports'},
      {id: 28, value: 'Barre'},
      {id: 29, value: 'CrossFit'},
      {id: 30, value: 'Functional Fitness'},
      {id: 31, value: 'TRX Suspension Training'},
      {id: 32, value: 'Calisthenics'},
      {id: 33, value: 'Rock Climbing'},
      {id: 34, value: 'Golf'},
      {id: 35, value: 'Tai Chi'}
    ]
  },

  {
    id: 40,
    key: 'healthIssues',
    questionsName: 'Do You Have Any Health Considerations?',
    questionsOption: [
      {
        id: 41,
        value: 'Heart disease'
      },
      {
        id: 42,
        value: 'Hypertension (high blood pressure)'
      },
      {
        id: 43,
        value: 'Diabetes'
      },
      {
        id: 44,
        value: 'Asthma'
      },
      {
        id: 45,
        value: 'Arthritis'
      },
      {
        id: 46,
        value: 'Osteoporosis'
      },
      {
        id: 47,
        value: 'Chronic obstructive pulmonary disease (COPD)'
      },
      {
        id: 48,
        value: 'Previous joint injuries (e.g., knee, ankle)'
      },
      {
        id: 49,
        value: 'Back injuries'
      },
      {
        id: 40,
        value: 'Recent surgeries'
      },
      {
        id: 41,
        value: 'Sprains or strains'
      },
      {
        id: 42,
        value: 'Tendonitis'
      },
      {
        id: 43,
        value: 'Aging-related muscle loss (sarcopenia)'
      },
      {
        id: 44,
        value: 'Bone health concerns (osteoporosis risk)'
      },
      {
        id: 45,
        value: 'Pregnancy-related restrictions'
      },
      {
        id: 46,
        value: 'Postpartum considerations'
      },
      {
        id: 47,
        value: 'Obesity'
      },
      {
        id: 48,
        value: 'Underweight or malnutrition'
      },
      {
        id: 49,
        value: 'None'
      }
    ]
  },

  // {
  //   id: 50,
  //   key: "timeFrameForOutput",
  //   questionsName: "What Is Your Time Frame for Seeing Results?",
  //   questionsOption: [
  //     {
  //       id: 51,
  //       value: "2 months",
  //     },
  //     {
  //       id: 52,
  //       value: "3 months",
  //     },
  //     {
  //       id: 53,
  //       value: "5 months",
  //     },
  //     {
  //       id: 54,
  //       value: "6 months",
  //     },
  //   ],
  // },
  {
    id: 55,
    key: 'equipment',
    questionsName: 'What Equipment Do You Have Access To?',
    questionsOption: [
      {id: 56, value: 'Treadmill'},
      {id: 57, value: 'Stationary bike'},
      {id: 58, value: 'Elliptical trainer'},
      {id: 59, value: 'Dumbbells'},
      {id: 60, value: 'Kettlebells'},
      {id: 61, value: 'Resistance bands'},
      {id: 62, value: 'Weight bench'},
      {id: 63, value: 'Yoga mat'},
      {id: 64, value: 'Foam roller'},
      {id: 65, value: 'None'}
    ]
  },
  {
    id: 50,
    key: 'timeFrameForOutput',
    questionsName: 'What Is Your Time Frame for Seeing Results?',
    questionsOption: [
      {
        id: 51,
        value: '2 months'
      },
      {
        id: 52,
        value: '3 months'
      },
      {
        id: 53,
        value: '5 months'
      },
      {
        id: 54,
        value: '6 months'
      }
    ]
  },
  {
    id: 66,
    key: 'programType',
    questionsName: 'Are You Looking for a Structured Program or Flexibility?',
    questionsOption: [
      {
        id: 67,
        value: 'Structured Program'
      },
      {
        id: 68,
        value: ' Flexibile'
      }
    ]
  }
];
export const guidedMealPlanQuestions = [
  {
    no: '01',
    label: 'What is your age?',
    name: 'age',
    initialValue: '',
    placeholder: 'Write Here',
    required: true,
    example: 'e.g (any numeric value)'
  },
  {
    no: '02',
    label: 'What is your gender?',
    name: 'gender',
    initialValue: '',
    placeholder: 'Write Here',
    required: true,
    example: 'e.g (male,female)'
  },
  {
    no: '03',
    label: 'What is your height?',
    name: 'height',
    initialValue: '',
    placeholder: 'Write Here',
    required: true,
    example: 'e.g (167,169) in cm'
  },
  {
    no: '04',
    label: 'What is your goal?',
    name: 'goal',
    initialValue: '',
    placeholder: 'Write Here',
    required: true,
    example: 'e.g (stay healthy, build muscle, lose fat)'
  },
  {
    no: '05',
    label: 'What diet type you want?',
    name: 'dietType',
    initialValue: '',
    placeholder: 'Write Here',
    required: true,
    example: 'e.g (keto, mediterranean, gluten free)'
  },
  {
    no: '06',
    label: 'What is your ratio for meals per day?',
    name: 'mealsPerDay',
    initialValue: '',
    placeholder: 'Write Here',
    required: true,
    example: 'e.g (any numeric value like 2,3)'
  },
  {
    no: '07',
    label: 'What is serving per meal?',
    name: 'servingPerMeal',
    initialValue: '',
    placeholder: 'Write Here',
    required: true,
    example: 'e.g (any numeric value like 2,3)'
  },
  {
    no: '08',
    label: 'Do you have any food alergy?',
    name: 'additionalInfo',
    initialValue: '',
    placeholder: 'Write Here',
    required: true,
    example: 'e.g (any wording)'
  }
];

export const advancedMealPlanQuestions = [
  // {
  //   no: '01',
  //   label: 'What is your age?',
  //   name: 'age',
  //   initialValue: '30',
  //   placeholder: 'Write Here',
  //   required: true,
  //   example: 'e.g (any numeric value)',
  // },
  // {
  //   no: '02',
  //   label: 'What is your gender?',
  //   name: 'gender',
  //   initialValue: 'male',
  //   placeholder: 'Write Here',
  //   required: true,
  //   example: 'e.g (male,female)',
  // },
  // {
  //   no: '03',
  //   label: 'What is your height?',
  //   name: 'height',
  //   initialValue: '167',
  //   placeholder: 'Write Here',
  //   required: true,
  //   example: 'e.g (167,169) in cm',
  // },
  // {
  //   no: '04',
  //   label: 'What is your plan for number of days?',
  //   name: 'planForNoOfDays',
  //   initialValue: '7',
  //   placeholder: 'Write Here',
  //   required: true,
  //   example: 'e.g (2,3.6,7)',
  // },
  {
    no: '01',
    label: '',
    name: 'additionalInfo',
    initialValue: '',
    placeholder: 'Write Here',
    required: true,
    example:
      'e.g (Rocco Scavetta is aiming to lose 30 lbs in 4 months. His diet will be a maximum of 2000 calories and he is currently eating against the Mediterranean diet. He doesnt like shellfish and prefers to eat chicken based dishes. He will eat 2 meals a day and 1 snack starting from 12noon to 8pm. His diet will contain mostly vegetables and plant based protein, fruits, foods rish in potassium, and low sodium intake)'
  }
];

export const dummyMealPLan = {
  macroValues: {
    protein: 120,
    carbs: 20,
    fat: 180,
    sugar: 0,
    saturatedFat: 114,
    totalCalories: 2140
  },
  mealList: {
    daily: [
      {
        mealName: 'Meal 1',
        mealTotalCalories: 713.33,
        carbs: 6.67,
        fats: 64.78,
        proteins: 30,
        recipes: 'Scrambled eggs with avocado and bacon'
      },
      {
        mealName: 'Meal 2',
        mealTotalCalories: 713.33,
        carbs: 6.67,
        fats: 64.78,
        proteins: 30,
        recipes: 'Grilled chicken breast with broccoli and cauliflower'
      },
      {
        mealName: 'Meal 3',
        mealTotalCalories: 713.33,
        carbs: 6.67,
        fats: 64.78,
        proteins: 30,
        recipes: 'Salmon with asparagus and green beans'
      }
    ],
    weekly: {
      day1: [
        {
          mealName: 'Meal 1',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Scrambled eggs with avocado and bacon'
        },
        {
          mealName: 'Meal 2',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Grilled chicken breast with broccoli and cauliflower'
        },
        {
          mealName: 'Meal 3',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Salmon with asparagus and green beans'
        }
      ],
      day2: [
        {
          mealName: 'Meal 1',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Scrambled eggs with avocado and bacon'
        },
        {
          mealName: 'Meal 2',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Grilled chicken breast with broccoli and cauliflower'
        },
        {
          mealName: 'Meal 3',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Salmon with asparagus and green beans'
        }
      ],
      day3: [
        {
          mealName: 'Meal 1',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Scrambled eggs with avocado and bacon'
        },
        {
          mealName: 'Meal 2',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Grilled chicken breast with broccoli and cauliflower'
        },
        {
          mealName: 'Meal 3',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Salmon with asparagus and green beans'
        }
      ],
      day4: [
        {
          mealName: 'Meal 1',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Scrambled eggs with avocado and bacon'
        },
        {
          mealName: 'Meal 2',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Grilled chicken breast with broccoli and cauliflower'
        },
        {
          mealName: 'Meal 3',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Salmon with asparagus and green beans'
        }
      ],
      day5: [
        {
          mealName: 'Meal 1',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Scrambled eggs with avocado and bacon'
        },
        {
          mealName: 'Meal 2',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Grilled chicken breast with broccoli and cauliflower'
        },
        {
          mealName: 'Meal 3',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Salmon with asparagus and green beans'
        }
      ],
      day6: [
        {
          mealName: 'Meal 1',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Scrambled eggs with avocado and bacon'
        },
        {
          mealName: 'Meal 2',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Grilled chicken breast with broccoli and cauliflower'
        },
        {
          mealName: 'Meal 3',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Salmon with asparagus and green beans'
        }
      ],
      day7: [
        {
          mealName: 'Meal 1',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Scrambled eggs with avocado and bacon'
        },
        {
          mealName: 'Meal 2',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Grilled chicken breast with broccoli and cauliflower'
        },
        {
          mealName: 'Meal 3',
          mealTotalCalories: 713.33,
          carbs: 6.67,
          fats: 64.78,
          proteins: 30,
          recipes: 'Salmon with asparagus and green beans'
        }
      ]
    }
  }
};

export const supplementFreq = [
  {id: 1, name: 'Once a day'},
  {id: 2, name: 'Twice a day'},
  {id: 3, name: 'Once a week'}
];
export const supplementMeasure = [
  {id: 1, name: 'Half tablet/pill'},
  {id: 2, name: '1 tablet/pill'},
  {id: 3, name: '2 tablet/pill'},
  {id: 4, name: '3 tablet/pill'}
];

export const dummyWorkoutExcercises = {
  macroValues: {
    protein: 150,
    carbs: 200,
    fat: 60,
    sugar: 40,
    saturatedFat: 20,
    totalCalories: 1900
  },
  exerciselist: {
    daily: [
      {
        ExerciseName: 'Treadmill Running',
        ExerciseType: 'Cardiovascular Exercise',
        Time: '30 minutes'
      },
      {
        ExerciseName: 'Dumbbell Squats',
        ExerciseType: 'Strength Training',
        Sets: 3,
        Reps: 12,
        lbs: 20
      },
      {
        ExerciseName: 'Push-ups',
        ExerciseType: 'Strength Training',
        Sets: 3,
        Reps: 12
      },
      {
        ExerciseName: 'Plank',
        ExerciseType: 'Strength Training',
        Time: '1 minute'
      },
      {
        ExerciseName: 'Dumbbell Lunges',
        ExerciseType: 'Strength Training',
        Sets: 3,
        Reps: 12,
        lbs: 15
      },
      {
        ExerciseName: 'Bicycle Crunches',
        ExerciseType: 'Strength Training',
        Sets: 3,
        Reps: 15
      }
    ],
    weekly: {
      day1: [
        {
          ExerciseName: 'Treadmill Running',
          ExerciseType: 'Cardiovascular Exercise',
          Time: '30 minutes'
        },
        {
          ExerciseName: 'Dumbbell Squats',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 20
        },
        {
          ExerciseName: 'Push-ups',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12
        },
        {
          ExerciseName: 'Plank',
          ExerciseType: 'Strength Training',
          Time: '1 minute'
        },
        {
          ExerciseName: 'Dumbbell Lunges',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 15
        },
        {
          ExerciseName: 'Bicycle Crunches',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 15
        }
      ],
      day2: [
        {
          ExerciseName: 'Treadmill Running',
          ExerciseType: 'Cardiovascular Exercise',
          Time: '30 minutes'
        },
        {
          ExerciseName: 'Dumbbell Bench Press',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 25
        },
        {
          ExerciseName: 'Bent Over Rows',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 20
        },
        {
          ExerciseName: 'Russian Twists',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 15
        },
        {
          ExerciseName: 'Hammer Curls',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 15
        },
        {
          ExerciseName: 'Leg Press',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 100
        }
      ],
      day3: [
        {
          ExerciseName: 'Treadmill Running',
          ExerciseType: 'Cardiovascular Exercise',
          Time: '30 minutes'
        },
        {
          ExerciseName: 'Dumbbell Shoulder Press',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 20
        },
        {
          ExerciseName: 'Tricep Dips',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12
        },
        {
          ExerciseName: 'Side Plank',
          ExerciseType: 'Strength Training',
          Time: '1 minute'
        },
        {
          ExerciseName: 'Calf Raises',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 15,
          lbs: 50
        },
        {
          ExerciseName: 'Romanian Deadlifts',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 25
        }
      ],
      day4: [
        {
          ExerciseName: 'Treadmill Running',
          ExerciseType: 'Cardiovascular Exercise',
          Time: '30 minutes'
        },
        {
          ExerciseName: 'Dumbbell Squats',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 20
        },
        {
          ExerciseName: 'Push-ups',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12
        },
        {
          ExerciseName: 'Plank',
          ExerciseType: 'Strength Training',
          Time: '1 minute'
        },
        {
          ExerciseName: 'Dumbbell Lunges',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 15
        },
        {
          ExerciseName: 'Bicycle Crunches',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 15
        }
      ],
      day5: [
        {
          ExerciseName: 'Treadmill Running',
          ExerciseType: 'Cardiovascular Exercise',
          Time: '30 minutes'
        },
        {
          ExerciseName: 'Dumbbell Bench Press',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 25
        },
        {
          ExerciseName: 'Bent Over Rows',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 20
        },
        {
          ExerciseName: 'Russian Twists',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 15
        },
        {
          ExerciseName: 'Hammer Curls',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 15
        },
        {
          ExerciseName: 'Leg Press',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 100
        }
      ],
      day6: [
        {
          ExerciseName: 'Treadmill Running',
          ExerciseType: 'Cardiovascular Exercise',
          Time: '30 minutes'
        },
        {
          ExerciseName: 'Dumbbell Shoulder Press',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 20
        },
        {
          ExerciseName: 'Tricep Dips',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12
        },
        {
          ExerciseName: 'Side Plank',
          ExerciseType: 'Strength Training',
          Time: '1 minute'
        },
        {
          ExerciseName: 'Calf Raises',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 15,
          lbs: 50
        },
        {
          ExerciseName: 'Romanian Deadlifts',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 25
        }
      ],
      day7: [
        {
          ExerciseName: 'Treadmill Running',
          ExerciseType: 'Cardiovascular Exercise',
          Time: '30 minutes'
        },
        {
          ExerciseName: 'Dumbbell Squats',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 20
        },
        {
          ExerciseName: 'Push-ups',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12
        },
        {
          ExerciseName: 'Plank',
          ExerciseType: 'Strength Training',
          Time: '1 minute'
        },
        {
          ExerciseName: 'Dumbbell Lunges',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 12,
          lbs: 15
        },
        {
          ExerciseName: 'Bicycle Crunches',
          ExerciseType: 'Strength Training',
          Sets: 3,
          Reps: 15
        }
      ]
    }
  }
};

export const diceBearThumbs = ['Fluffy', 'Scooter', 'Baby', 'Bear', 'Boo', 'Snuggles', 'Zoe', 'Lily', 'Maggie', 'Callie', 'Max', 'Jack', 'Willow', 'Tinkerbell', 'Coco', 'Buddy', 'Rocky', 'Boots', 'Simon', 'Mia'];

import React from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Heading, Text } from "../../../utils";
import { deleteChatContext } from "../../../Services/inboxContext";
import { setActiveChatReducer } from "../../../store/reducers/inbox";
import { setbtnLoaderReducer } from "../../../store/reducers/uiSettings";
import { DotsThreeVerticalIcon } from "../../../FeedLibraryIcon";
import ClienImage from "../../../assets/images/client-img.jpg";

const UserHeader = ({ activeChat, userChats }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("activeChat", activeChat);
  return (
    <div className="w-full flex-items-center gap-[28px]  px-[20px] pb-[20px] mb-[20px] border-b border-b-solid border-text4">
      <div className="w-full flex-items-center gap-[16px]">
        <div className="w-[46px] h-[46px] rounded-lg flex-start-center shadow-clientImgShadow overflow-hidden  flex-shrink-0">
          <img
            className="w-full h-full object-cover "
            src={activeChat?.reciever?.avatar || ClienImage}
            alt="client"
          />
        </div>

        <div className="">
          <Heading
            text={activeChat?.reciever?.name}
            fontweight="!font-[500]"
            fontSize="!text-[24px]"
            className="leading-[28px] "
            color="text-textPrimary mb-[8px]"
          />
          <Text
            text={activeChat?.reciever?.clientType?.name}
            className="!text-primary1 m-0 tracking-[-0.056px]"
          />
        </div>
      </div>

      <Dropdown className="navbar-dropdown">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="nav-link nav-dropdown flex items-baseline gap-2"
        >
          <DotsThreeVerticalIcon w="24px" h="24px" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            className="nav-link "
            onClick={async () => {
              dispatch(setbtnLoaderReducer(true));
              await deleteChatContext(activeChat?.id);
              dispatch(setbtnLoaderReducer(false));
              dispatch(setActiveChatReducer(userChats?.[0]));
            }}
          >
            Delete Chat
          </Dropdown.Item>
          <Dropdown.Item
            className="nav-link "
            onClick={async () => {
              navigate(`/client-detail/${activeChat?.reciever?.uid}`);
            }}
          >
            View Profile
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default UserHeader;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MainHeading, FeedSearch, SideNavigations } from "../../components";
import { Plus } from "../../FeedLibraryIcon";
import { Heading, Text, FeedDropdown, TemplateSkeleton } from "../../utils";

import TemplateCard from "../../components/common/templateCard";
import { getallMyClientAction } from "../../store/actions/common";
import { Menus } from "../../schema/menus";
import { CreateTempo } from "./createTempo";

import { TemplateModal } from "../../components/model/templateModal";
import {
  setFormModal,
  seteditFormReducer,
  setTemplateNameReducer,
} from "../../store/reducers/uiSettings";
import { getTemplateFormAction } from "../../store/actions/template";
import { getSupplementTempalateAction } from "../../store/actions/supplementPlan";
import { getWorkoutTemplateAction } from "../../store/actions/workoutPlan";
import { getMealPlanTemplateAction } from "../../store/actions/mealPlan";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // all internal state
  const [searchParams] = useSearchParams();
  const [loading, setloading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [selectedClient, setselectedClient] = useState([]);
  const [searchQuery, setsearchQuery] = useState("");
  const [selectedTemplateName, setselectedTemplateName] = useState();
  const [activeTemplateState, setactiveTemplateState] = useState("");
  const [alltypeTemplates, setalltypeTemplates] = useState([]);
  const [cta, setCTA] = useState();
  // all redux
  const { user } = useSelector((state) => state.common);
  const uiSettings = useSelector((state) => state.uiSettings);
  const { allTemplateForm } = useSelector((state) => state.template);
  const { supplementTemplate } = useSelector((state) => state.supplementPlan);
  const { allworkout } = useSelector((state) => state.workoutPlan);
  const { mealPlan } = useSelector((state) => state.mealPlan);

  const createTemplateCTA = () => {
    if (selectedTemplateName?.path === "meal-plan") {
      dispatch(setTemplateNameReducer({ name: "Create a New Meal Plan" }));
      dispatch(
        seteditFormReducer({
          type: "",
          data: { closer: "/template?type=meal-plan" },
        })
      );
      navigate("/meal-plan");
    } else if (selectedTemplateName?.path === "workout-plan") {
      dispatch(
        seteditFormReducer({
          type: "",
          data: { closer: "/template?type=workout-plan" },
        })
      );
      navigate("/workout-plan");
    } else if (selectedTemplateName?.path === "supplement-plan") {
      dispatch(
        setTemplateNameReducer({ name: "Create a New Supplement Plan" })
      );
      dispatch(
        seteditFormReducer({
          type: "",
          data: { closer: "/template?type=supplement-plan" },
        })
      );
      navigate("/supplement-plan");
    } else if (selectedTemplateName?.path === "intake") {
      dispatch(
        setTemplateNameReducer({
          name: "Create a Intake Form",
          selectedTemplateName: selectedTemplateName,
        })
      );
      dispatch(
        seteditFormReducer({
          type: "create-template",
          data: {
            closer: "/template?type=intake",
            templateType: selectedTemplateName?.path,
          },
        })
      );
      navigate("/intake-form");
    } else if (selectedTemplateName?.path === "check-in") {
      dispatch(
        setTemplateNameReducer({
          name: "Create a Check-In Form",
          selectedTemplateName: selectedTemplateName,
        })
      );
      dispatch(
        seteditFormReducer({
          type: "create-template",
          data: {
            closer: "/template?type=check-in",
            templateType: selectedTemplateName?.path,
          },
        })
      );
      navigate("/checkin-form");
    }
  };
  // all sde effect and api calling
  useEffect(() => {
    const tabToOpen = searchParams.get("type");
    const activeTab = Menus?.filter((menu) => menu.path === tabToOpen)?.[0];
    if (activeTab?.id) {
      setselectedTemplateName(activeTab);
    } else {
      setselectedTemplateName(Menus[0]);
    }
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true);
        await Promise.all([
          getallMyClientAction(user?.uid),
          getTemplateFormAction(user?.uid),
          getSupplementTempalateAction(user?.uid),
          getMealPlanTemplateAction(user?.uid, "mealplan"),
          getWorkoutTemplateAction(user?.uid),
        ]);
        setloading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (user?.uid) {
      fetchData();
    }
    setTimeout(() => {
      setloading(false);
    }, 3000);

    return () => {
      setloading(false);
    };
  }, []);

  useEffect(() => {
    if (selectedTemplateName?.path === "intake") {
      setalltypeTemplates(
        allTemplateForm?.filter((temp) => temp.templateType === "intake")
      );
    } else if (selectedTemplateName?.path === "check-in") {
      setalltypeTemplates(
        allTemplateForm?.filter((temp) => temp.templateType === "check-in")
      );
    } else if (selectedTemplateName?.path === "supplement-plan") {
      setalltypeTemplates(supplementTemplate);
    } else if (selectedTemplateName?.path === "workout-plan") {
      setalltypeTemplates(allworkout);
    } else if (selectedTemplateName?.path === "meal-plan") {
      setalltypeTemplates(mealPlan);
    }
  }, [
    allTemplateForm,
    selectedTemplateName,
    supplementTemplate,
    allworkout,
    mealPlan,
  ]);
  useEffect(() => {
    if (selectedTemplateName?.path === "supplement-plan") {
      if (searchQuery) {
        setalltypeTemplates(
          supplementTemplate?.filter((f) =>
            f.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
          )
        );
      } else {
        setalltypeTemplates(supplementTemplate);
      }
    } else if (selectedTemplateName?.path === "meal-plan") {
      if (searchQuery) {
        setalltypeTemplates(
          mealPlan?.filter((f) =>
            f.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
          )
        );
      } else {
        setalltypeTemplates(mealPlan);
      }
    } else if (selectedTemplateName?.path === "workout-plan") {
      if (searchQuery) {
        setalltypeTemplates(
          allworkout?.filter((f) =>
            f.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
          )
        );
      } else {
        setalltypeTemplates(allworkout);
      }
    } else if (selectedTemplateName?.path === "check-in") {
      const allchecInForms = allTemplateForm?.filter(
        (temp) => temp.templateType === "check-in"
      );
      if (searchQuery) {
        setalltypeTemplates(
          allchecInForms?.filter((f) =>
            f.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
          )
        );
      } else {
        setalltypeTemplates(allchecInForms);
      }
    } else if (selectedTemplateName?.path === "intake") {
      const allIntakeForm = allTemplateForm?.filter(
        (temp) => temp.templateType === "intake"
      );
      if (searchQuery) {
        setalltypeTemplates(
          allIntakeForm?.filter((f) =>
            f.name?.toLowerCase()?.includes(searchQuery.toLowerCase())
          )
        );
      } else {
        setalltypeTemplates(allIntakeForm);
      }
    }
  }, [searchQuery, selectedTemplateName]);

  useEffect(() => {
    const dataArrayCopy = JSON.parse(JSON.stringify(alltypeTemplates));
    if (selectedFilter?.id === 1) {
      setalltypeTemplates(
        dataArrayCopy?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
    } else if (selectedFilter?.id === 2) {
      setalltypeTemplates(
        dataArrayCopy?.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      );
    } else if (selectedFilter?.id === 3) {
      setalltypeTemplates(
        dataArrayCopy?.slice().sort((a, b) => a.name.localeCompare(b.name))
      );
    } else if (selectedFilter?.id === 4) {
      setalltypeTemplates(
        dataArrayCopy?.slice().sort((a, b) => b.name.localeCompare(a.name))
      );
    }
  }, [selectedFilter]);

  return (
    <div className="main-container">
      <>
        <div className="pt-[40px] pb-[18px]">
          <MainHeading
            title={selectedTemplateName?.name}
            description={"Description"}
            primarbtnText={"Create a New Template"}
            primarybtnIcon={<Plus w="20px" color="#202020" />}
            // secondaryBtnText={'View Example'}
            primaryCTA={createTemplateCTA}
          />
        </div>

        <div className="flex-center-between p-[20px] gap-4 flex-wrap bg-background2 rounded-[20px] mb-[20px]">
          <Heading
            text={`Templates (${
              alltypeTemplates?.length ? alltypeTemplates?.length : "0"
            })`}
            fontSize="text-[24px]"
            fontweight="font-normal"
            className="leading-6"
          />
          <FeedSearch
            handlesearchQuery={setsearchQuery}
            searchQuery={searchQuery}
            placeholder={"Search Templates Name"}
            className="bg-background5 w-[400px]"
          />
          <div className="flex-justify-end">
            <div className="flex-items-center gap-[24px]">
              <Text text="Sort by:" />
              <div className=" w-[174px]">
                <FeedDropdown
                  placeholder={"Most Recent"}
                  value={selectedFilter?.id}
                  options={[
                    { id: 3, name: "A to Z" },
                    { id: 4, name: "Z to A" },
                    { id: 1, name: "Newest First " },
                    { id: 2, name: "Oldest First " },
                  ]}
                  setFieldValueFull={(e) => {
                    setSelectedFilter(e);
                  }}
                  name="filter"
                  className="!rounded-xl [&>button]:!bg-transparent !border !border-solid !border-whiteGray"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex-start-start gap-[20px]  ">
          <div className="min-h-[700px] w-[275px] bg-secondary1 sticky top-[10px] p-[16px] rounded-[20px]">
            <SideNavigations
              data={Menus}
              settemplateName={setselectedTemplateName}
              selectedTemplateName={selectedTemplateName}
            />
          </div>
          <div
            className={` w-[1104px] pt-[4px] overflow-y-auto rounded-[20px] ${
              alltypeTemplates?.length >= 6 || loading ? "!h-[698px]" : "h-full"
            } ${
              alltypeTemplates?.length
                ? "flex-start-start"
                : "flex-center-center"
            }`}
          >
            {!loading ? (
              alltypeTemplates?.length ? (
                <div
                  className={`w-full grid  grid-cols-2 lg2:grid-cols-3 gap-[19px] h-full ${
                    alltypeTemplates?.length >= 6 && "pr-[16px]"
                  } `}
                >
                  <div
                    className="h-full teritary-grid !bg-background2 !p-[16px]  flex-center-center  cursor-pointer"
                    onClick={createTemplateCTA}
                  >
                    <div className="flex-items-center-column">
                      <span>
                        <Plus color={"#7D7D7D"} w={"18px"} />
                      </span>
                      <Text
                        text={"Create a new template"}
                        color="text-whiteGray"
                      />
                    </div>
                  </div>
                  {alltypeTemplates?.map((tempo, index) => (
                    <TemplateCard
                      tempo={tempo}
                      key={index}
                      selectedTemplateName={selectedTemplateName}
                      dispatch={dispatch}
                      setCTA={setCTA}
                      setactiveTemplateState={setactiveTemplateState}
                      user={user?.uid}
                      selectedClient={selectedClient}
                      alltypeTemplates={alltypeTemplates}
                      setselectedClient={setselectedClient}
                    />
                  ))}
                </div>
              ) : (
                <div className="w-full h-[500px] flex-center-center ">
                  <CreateTempo
                    btnClick={createTemplateCTA}
                    selectedTemplateName={selectedTemplateName}
                  />
                </div>
              )
            ) : (
              <TemplateSkeleton />
            )}
          </div>
        </div>
      </>

      {uiSettings?.openModel && (
        <TemplateModal
          show={uiSettings?.openModel}
          handleClose={() => dispatch(setFormModal(false))}
          CTA={cta}
          setselectedClient={setselectedClient}
          selectedClient={selectedClient}
        />
      )}
    </div>
  );
};

export default Index;

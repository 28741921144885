import React from "react";
import { Button, Heading, Text } from "../../../utils";
const MainHeading = ({
  title,
  description,
  primarbtnText,
  primarybtnIcon,
  descriptionClasses,
  secondaryBtnText,
  secondaryBtnIcon,
  secbtnBorderColor,
  titleClasses,
  primaryCTA,
  secCTA,
  secbtnTextColor,
}) => {
  return (
    <>
      <div className="flex-center-between">
        <div>
          <Heading
            text={title}
            fontweight="font-bold"
            fontSize="text-[40px]"
            className={`leading-[48px] ${titleClasses}`}
          />
        </div>

        <div className="flex-items-center gap-[12px]">
          {secondaryBtnText && (
            <Button
              text={secondaryBtnText}
              rightmargin="mr-[10px]"
              className="px-[20px] py-[7px] font-normal rounded-lg"
              bg="bg-background"
              color={secbtnTextColor ? secbtnTextColor : "text-textPrimary"}
              border={secbtnBorderColor ? secbtnBorderColor : "border-primary1"}
              cta={() => {
                if (secCTA) {
                  secCTA();
                }
              }}
            />
          )}
          {primarbtnText && (
            <Button
              text={primarbtnText}
              rightmargin="mr-[10px]"
              Icon={primarybtnIcon}
              className="px-[20px] py-[7px] font-normal rounded-lg"
              bg="bg-primary1"
              color="text-background"
              cta={() => {
                if (primaryCTA) {
                  primaryCTA();
                }
              }}
            />
          )}
        </div>
      </div>
      {description && (
        <div className="mt-[14px]">
          <Text
            text={description}
            color="text-textPrimary"
            fontSize="text-[16px]"
            className={`leading-6 ${descriptionClasses}`}
          />
        </div>
      )}
    </>
  );
};

export default MainHeading;

import React from "react";

export const ChecksIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M11.6883 6.69607L4.68828 13.5711C4.57138 13.6861 4.41398 13.7505 4.25 13.7505C4.08603 13.7505 3.92862 13.6861 3.81172 13.5711L0.811721 10.625C0.75319 10.5674 0.706569 10.4989 0.67452 10.4233C0.64247 10.3478 0.62562 10.2666 0.62493 10.1845C0.624241 10.1024 0.639727 10.021 0.670503 9.94492C0.701279 9.86882 0.746743 9.79952 0.804299 9.74099C0.861855 9.68246 0.930375 9.63584 1.00595 9.60379C1.08152 9.57174 1.16267 9.55489 1.24475 9.5542C1.32684 9.55351 1.40826 9.569 1.48436 9.59977C1.56046 9.63055 1.62975 9.67601 1.68828 9.73357L4.25 12.2492L10.8125 5.80388C10.9308 5.68764 11.0905 5.62316 11.2563 5.62463C11.3384 5.62535 11.4196 5.64225 11.4952 5.67435C11.5708 5.70644 11.6393 5.75311 11.6969 5.8117C11.7544 5.87028 11.7999 5.93962 11.8306 6.01577C11.8614 6.09192 11.8769 6.17338 11.8761 6.2555C11.8754 6.33762 11.8585 6.4188 11.8264 6.49439C11.7943 6.56998 11.7476 6.63851 11.6891 6.69607H11.6883ZM19.1961 5.8117C19.1386 5.75307 19.07 5.70636 18.9944 5.67423C18.9188 5.64211 18.8377 5.6252 18.7555 5.62448C18.6734 5.62375 18.5919 5.63922 18.5158 5.67001C18.4396 5.70079 18.3703 5.74628 18.3117 5.80388L11.7492 12.2492L10.2781 10.8039C10.1598 10.6877 10.0002 10.6234 9.83444 10.6249C9.66866 10.6264 9.51028 10.6938 9.39414 10.8121C9.27801 10.9304 9.21363 11.09 9.21517 11.2558C9.2167 11.4216 9.28403 11.5799 9.40235 11.6961L11.3109 13.5711C11.4278 13.6861 11.5852 13.7505 11.7492 13.7505C11.9132 13.7505 12.0706 13.6861 12.1875 13.5711L19.1875 6.69607C19.2462 6.63859 19.293 6.57011 19.3251 6.49454C19.3573 6.41897 19.3743 6.3378 19.3751 6.25566C19.3759 6.17353 19.3605 6.09204 19.3298 6.01586C19.2991 5.93968 19.2536 5.8703 19.1961 5.8117Z"
        fill="#E6E6E6"
        fillOpacity="0.7"
      />
    </svg>
  );
};

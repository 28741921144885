import React from 'react';

export const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        opacity="0.2"
        d="M21 12.5C21 14.28 20.4722 16.0201 19.4832 17.5001C18.4943 18.9802 17.0887 20.1337 15.4442 20.8149C13.7996 21.4961 11.99 21.6743 10.2442 21.3271C8.49836 20.9798 6.89472 20.1226 5.63604 18.864C4.37737 17.6053 3.5202 16.0016 3.17294 14.2558C2.82567 12.51 3.0039 10.7004 3.68509 9.05585C4.36628 7.41131 5.51983 6.00571 6.99987 5.01677C8.47991 4.02784 10.22 3.5 12 3.5C14.387 3.5 16.6761 4.44821 18.364 6.13604C20.0518 7.82387 21 10.1131 21 12.5Z"
        fill="#C0F121"
      />
      <path
        d="M16.2806 9.71937C16.3504 9.78903 16.4057 9.87175 16.4434 9.96279C16.4812 10.0538 16.5006 10.1514 16.5006 10.25C16.5006 10.3486 16.4812 10.4462 16.4434 10.5372C16.4057 10.6283 16.3504 10.711 16.2806 10.7806L11.0306 16.0306C10.961 16.1004 10.8783 16.1557 10.7872 16.1934C10.6962 16.2312 10.5986 16.2506 10.5 16.2506C10.4014 16.2506 10.3038 16.2312 10.2128 16.1934C10.1218 16.1557 10.039 16.1004 9.96938 16.0306L7.71938 13.7806C7.57865 13.6399 7.49959 13.449 7.49959 13.25C7.49959 13.051 7.57865 12.8601 7.71938 12.7194C7.86011 12.5786 8.05098 12.4996 8.25 12.4996C8.44903 12.4996 8.6399 12.5786 8.78063 12.7194L10.5 14.4397L15.2194 9.71937C15.289 9.64964 15.3718 9.59432 15.4628 9.55658C15.5538 9.51884 15.6514 9.49941 15.75 9.49941C15.8486 9.49941 15.9462 9.51884 16.0372 9.55658C16.1283 9.59432 16.211 9.64964 16.2806 9.71937ZM21.75 12.5C21.75 14.4284 21.1782 16.3134 20.1068 17.9168C19.0355 19.5202 17.5127 20.7699 15.7312 21.5078C13.9496 22.2458 11.9892 22.4389 10.0979 22.0627C8.20656 21.6865 6.46928 20.7579 5.10571 19.3943C3.74215 18.0307 2.81355 16.2934 2.43735 14.4021C2.06114 12.5108 2.25422 10.5504 2.99218 8.76884C3.73013 6.98726 4.97982 5.46451 6.58319 4.39317C8.18657 3.32183 10.0716 2.75 12 2.75C14.585 2.75273 17.0634 3.78084 18.8913 5.60872C20.7192 7.43661 21.7473 9.91498 21.75 12.5ZM20.25 12.5C20.25 10.8683 19.7661 9.27325 18.8596 7.91655C17.9531 6.55984 16.6646 5.50242 15.1571 4.87799C13.6497 4.25357 11.9909 4.09019 10.3905 4.40852C8.79017 4.72685 7.32016 5.51259 6.16637 6.66637C5.01259 7.82015 4.22685 9.29016 3.90853 10.8905C3.5902 12.4908 3.75358 14.1496 4.378 15.6571C5.00242 17.1646 6.05984 18.4531 7.41655 19.3596C8.77326 20.2661 10.3683 20.75 12 20.75C14.1873 20.7475 16.2843 19.8775 17.8309 18.3309C19.3775 16.7843 20.2475 14.6873 20.25 12.5Z"
        fill="#C0F121"
      />
    </svg>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Progress, Animation } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import FeedBreadcrumbs from "../../../../components/common/breadcrumbs";
import { MainHeading } from "../../../../components";
import MealPlanModal from "../../../../components/model/mealPlanModal";
import {
  Button,
  FeedCheckbox,
  FeedInput,
  Heading,
  LabelFeed,
  ToggleButton,
  Text,
  DatePicker,
} from "../../../../utils";
import { Formik } from "formik";
import { CrossIcon } from "../../../../FeedLibraryIcon";
import { customDateFormat } from "../../../../helper";
import AssignToClient from "../../../../components/common/assignToClient";
import { setbtnLoaderReducer } from "../../../../store/reducers/uiSettings";
import {
  createMealPlanAction,
  updateMealPlanAction,
} from "../../../../store/actions/mealPlan";
import { toast } from "react-toastify";

const weeks = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
];

const StepTitle = ({
  formRef,
  closePlanHandler,
  setStepper,
  setplanScreenState,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const common = useSelector((state) => state.common);
  const uiSettings = useSelector((state) => state.uiSettings);
  const { editForm } = uiSettings;
  const [showPopupMessage, setshowPopupMessage] = useState("");
  const initiallySelectedDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [showPopup, setshowPopup] = useState(false);
  const [startDate, setstartDate] = useState(
    uiSettings?.editForm?.start_date || "yyyy-mm-dd",
  );
  const [selectedClient, setselectedClient] = useState([]);
  const [show, setShow] = useState(true);
  const [checked, setChecked] = useState(false);
  const [saveAsNewName, setsaveAsNewName] = useState();
  const [selectedDays, setSelectedDays] = useState(
    uiSettings?.editForm?.selectedDays || initiallySelectedDays,
  );

  const handleCheckboxChange = (value) => {
    const currentIndex = selectedDays.indexOf(value);
    const newSelectedDays = [...selectedDays];
    if (currentIndex === -1) {
      newSelectedDays.push(value);
    } else {
      newSelectedDays.splice(currentIndex, 1);
    }
    setSelectedDays(newSelectedDays);
  };
  useEffect(() => {
    setselectedClient(
      common?.allClients?.filter((f) => editForm?.clientIds?.includes(f.uid)),
    );
  }, [editForm?.clientIds]);

  useEffect(() => {
    if (formRef.current) formRef.current.validateForm();
  }, [selectedDays, startDate, selectedClient]);
  useEffect(() => {
    if (uiSettings?.formName !== "view-template") {
      const totalCalories = parseFloat(editForm?.nutritionLevel?.totalCalories);
      const totalTargetCalories = parseFloat(
        editForm?.macroTargets?.totalTargetCalories,
      );
      // let percentage = 0;
      if (totalCalories < totalTargetCalories) {
        // const difference = totalTargetCalories - totalCalories;
        // percentage = (difference / totalTargetCalories) * 100;
        setshowPopupMessage(
          `You are under your plan targets, Do you want to proceed anyways?`,
        );
        setshowPopup(true);
      } else if (totalCalories > totalTargetCalories) {
        // const difference = totalCalories - totalTargetCalories;
        // percentage = (difference / totalTargetCalories) * 100;
        setshowPopupMessage(
          `You have exceeded your plan targets, Do you want to proceed anyways?`,
        );
        setshowPopup(true);
      } else {
        setshowPopupMessage("Hurrah..!! You are on your target");
        setshowPopup(true);
      }
    }
  }, [
    editForm?.macroTargets?.totalTargetCalories,
    editForm?.nutritionLevel?.totalCalories,
    uiSettings?.formName,
  ]);

  return (
    <div>
      <div className="mb-6">
        <FeedBreadcrumbs
          backClick={closePlanHandler}
          selectedTemplateName={{ name: "Meal plan" }}
          formRef={formRef}
          cancelBtnClass="!border-none !p-2"
          submitBtnClass="!rounded-[8px]"
          marginBottom="!mb-0"
          crumbText="Meal Plan"
        />
        <MainHeading title={editForm?.name} description={"Description"} />
      </div>

      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-[16px]">
        <div className="w-full px-[20px] py-6 rounded-[20px] bg-secondary1">
          <Heading
            text={"Meals"}
            color="text-textPrimary"
            fontSize="text-[22px]"
            fontweight="font-medium"
            className="leading-[40px] mb-[10px] "
          />
          <div className="w-full flex-column gap-4">
            {uiSettings?.editForm?.mealList?.map((meal, index) => (
              <div
                key={index}
                className="w-full p-4 rounded-[8px] bg-background flex-column gap-[10px]"
              >
                <div className="w-full flex items-center gap-2">
                  <Text text={meal?.title} color="text-primary1" />
                  <Text text="." color="text-primary1" />
                  <Text
                    text={`${meal?.foodList
                      ?.reduce((sum, food) => sum + (food?.calories || 0), 0)
                      .toFixed(2)} Cal`}
                    color="text-primary1"
                  />
                </div>

                <Text
                  text={meal?.mealName || "Not Set"}
                  className={"tracking-[0.25px]"}
                />
                <div className="w-full grid grid-cols-3 gap-[15px]">
                  <div className="">
                    <Text
                      text={`Proteins (${meal?.foodList
                        .reduce((sum, food) => sum + (food.protein || 0), 0)
                        .toFixed(2)}/g)`}
                      color="text-whiteGray"
                      className={"!text-xs"}
                    />
                    <Progress.Line
                      className="!p-0 mt-[5px] progress-line"
                      percent={editForm?.macroTargets?.protienPerc}
                      showInfo={false}
                      strokeColor="#487AFA"
                    />
                  </div>
                  <div className="">
                    <Text
                      text={`Carbs (${meal?.foodList
                        .reduce((sum, food) => sum + (food.carbs || 0), 0)
                        .toFixed(2)}/g)`}
                      color="text-whiteGray"
                      className={"!text-xs"}
                    />
                    <Progress.Line
                      className="!p-0 mt-[5px] progress-line"
                      percent={editForm?.macroTargets?.carbPerc}
                      showInfo={false}
                      strokeColor="#67E2F4"
                    />
                  </div>
                  <div className="">
                    <Text
                      text={`Fat (${meal?.foodList
                        .reduce((sum, food) => sum + (food.fat || 0), 0)
                        .toFixed(2)}/g)`}
                      color="text-whiteGray"
                      className={"!text-xs"}
                    />
                    <Progress.Line
                      className="!p-0 mt-[5px] progress-line"
                      percent={editForm?.macroTargets?.fatPerc}
                      showInfo={false}
                      strokeColor="#F93DC0"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={`w-full flex-column gap-4  ${
            uiSettings?.formName === "view-template"
              ? "pointer-events-none"
              : "pointer-events-auto"
          }`}
        >
          <div className="w-full flex-column gap-6 px-[20px] py-6 rounded-[20px] bg-secondary1 ">
            <Heading
              text={
                <>
                  Assign to client <span className="text-primary1">*</span>
                </>
              }
              color="text-textPrimary"
              fontSize="text-[22px]"
              fontweight="font-medium"
              className="leading-[40px] "
            />
            <Formik
              initialValues={{
                clientIds: "",
                days: selectedDays,
                start_date: startDate,
              }}
              innerRef={formRef}
              enableReinitialize
              validate={(values) => {
                const errors = {};

                /* if (selectedClient?.length === 0) {
                  errors.clientIds = "Required";
                } */
                if (!values.days) {
                  errors.days = "Required";
                }
                if (
                  startDate === "yyyy-mm-dd" ||
                  startDate === "" ||
                  startDate === undefined ||
                  startDate === null
                ) {
                  errors.start_date = "Required";
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                dispatch(setbtnLoaderReducer(true));
                let res;
                const payload = {
                  ...uiSettings?.editForm,
                  name: saveAsNewName || editForm?.name,
                  selectedDays: selectedDays,
                  clientIds: selectedClient
                    ?.filter((f) => f.uid)
                    ?.map((client) => client.uid),
                  start_date: customDateFormat(startDate),
                  usedCount: selectedClient?.length || 0,
                  clientId: null,
                  coachId: common?.user?.uid,
                  createdAt:
                    uiSettings?.formName === "edit-mealplan"
                      ? editForm?.createdAt
                      : new Date().toLocaleString(),
                  updatedAt: new Date().toLocaleString(),
                };
                if (
                  uiSettings?.formName === "edit-mealplan" &&
                  !saveAsNewName
                ) {
                  res = await updateMealPlanAction("mealplan", payload);
                } else {
                  res = await createMealPlanAction("mealplan", payload);
                }

                if (res) {
                  dispatch(setbtnLoaderReducer(false));
                  navigate(
                    uiSettings?.editForm?.closer || "/template?type=meal-plan"
                  );
                  toast(
                    `Meal Plan ${
                      uiSettings?.formName === "edit-mealplan"
                        ? "Updated Successfully"
                        : "Created Successfully"
                    }`,
                  );
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  className="w-full flex-column gap-6"
                >
                  <div className="flex-column gap-[8px] w-full ">
                    <LabelFeed
                      text="Client Name"
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[24px] tracking-[0.5px]"
                    />

                    <AssignToClient
                      heading
                      setselectedClient={setselectedClient}
                      selectedClient={selectedClient}
                      options={common?.allClients?.map((client) => {
                        return { ...client, id: client.uid, name: client.name };
                      })}
                      disabled={
                        uiSettings?.formName === "view-template" ? true : false
                      }
                      error={errors.clientIds && errors.clientIds}
                    />
                    {/* {errors.clientIds && (
                      <div className="text-red text-[14px]  pt-[4px]">
                        {errors.clientIds}
                      </div>
                    )} */}
                  </div>
                  <div className="flex-column gap-[8px] w-full f4f-date-wrapper">
                    <LabelFeed
                      text={
                        <>
                          Start Date <span className="text-primary1">*</span>
                        </>
                      }
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[24px] tracking-[0.5px]"
                    />
                    <DatePicker
                      value={startDate}
                      placeholder="Select start date"
                      onChange={(e) => setstartDate(e)}
                    />
                    {errors.start_date && (
                      <LabelFeed
                        text="Required"
                        color="text-red"
                        fontSize={"text-[14px]"}
                        className="!leading-[22px]"
                      />
                    )}
                  </div>
                  <div className="flex flex-col gap-[8px] w-full ">
                    <LabelFeed
                      text={
                        <>
                          Frequency <span className="text-primary1">*</span>
                        </>
                      }
                      fontSize={"!text-[14px]"}
                      color="text-textPrimary"
                      className="leading-[24px] tracking-[0.5px]"
                    />
                    <div className="w-full rounded-lg bg-background">
                      <div className="w-full flex-center-between gap-3 pt-[11px] px-[12px] pb-[12px] ">
                        <LabelFeed
                          text={`${selectedDays.length} Days / Week`}
                          fontSize={"!text-[14px]"}
                          color="text-textPrimary"
                          className="leading-[24px] tracking-[0.5px]"
                        />
                        <Button
                          text={show ? "Save" : "Change"}
                          color="text-primary1"
                          className="!w-max !h-auto !p-0 !text-sm !leading-6 tracking-[0.5px] !border-none"
                          cta={() => setShow(!show)}
                        />
                      </div>
                      <Animation.Collapse in={show} dimension="height">
                        <div className="w-full pt-[12px] px-[12px] pb-[11px] border-t border-solid border-background2">
                          <LabelFeed
                            text="Select Days"
                            fontSize={"!text-[14px]"}
                            color="text-textPrimary"
                            className="leading-[24px] tracking-[0.5px] mb-[10px]"
                          />
                          <div
                            className="w-full grid grid-cols-4 gap-x-2 gap-y-[10px] "
                            role="group"
                            aria-labelledby="checkbox-group"
                          >
                            {weeks.map((day, index) => (
                              <div
                                key={index}
                                className="flex items-center px-[10px] py-2"
                              >
                                <FeedCheckbox
                                  name="tags"
                                  text={day.label}
                                  value={day.value}
                                  checked={selectedDays.includes(day.value)}
                                  onChange={() =>
                                    handleCheckboxChange(day.value)
                                  }
                                />
                              </div>
                            ))}
                          </div>
                          {errors.days && touched.days && (
                            <LabelFeed
                              text={errors.days}
                              color="text-red"
                              className="!leading-[22px] !text-[14px]"
                            />
                          )}
                        </div>
                      </Animation.Collapse>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          {uiSettings?.formName === "edit-mealplan" && (
            <div className="w-full flex-column gap-6 px-[20px] py-6 rounded-[20px] bg-secondary1 ">
              <div className="w-full flex-center-between">
                <Heading
                  text="Save As New Template"
                  color="text-textPrimary"
                  fontSize="text-[22px]"
                  fontweight="font-medium"
                  className="leading-[40px] "
                />
                <ToggleButton
                  checked={checked}
                  onChange={setChecked}
                  className="custom-check"
                />
              </div>
              <Formik
                initialValues={{
                  templateName: saveAsNewName || "",
                }}
                // innerRef={formRef}
                enableReinitialize
                validate={(values) => {
                  const errors = {};

                  return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  console.log(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="w-full flex-column  gap-6"
                  >
                    <div className="relative flex-column  gap-[8px] w-full ">
                      <LabelFeed
                        text="Template Name"
                        fontSize={"!text-[14px]"}
                        color="text-textPrimary"
                        className="leading-[24px] tracking-[0.5px]"
                      />
                      <FeedInput
                        name="templateName"
                        onChange={(e) => setsaveAsNewName(e.target.value)}
                        onBlur={handleBlur}
                        value={values.templateName}
                        disabled={false}
                        crossicon={values?.templateName ? <CrossIcon /> : ""}
                        placeholder="Template Name"
                        className="!text-sm"
                      />
                      {!checked && (
                        <div className=" w-full h-20 bg-black absolute top-0 left-0 opacity-70" />
                      )}
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
      <MealPlanModal
        show={showPopup}
        handleClose={() => {
          setStepper([1, 2, 3]);
          setplanScreenState("MealPlanSummary");
          setshowPopup(false);
        }}
        successCTA={() => setshowPopup(false)}
        children={<Text text={showPopupMessage} />}
      />
    </div>
  );
};

export default StepTitle;

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import FeedBreadcrumbs from "../../../components/common/breadcrumbs";
import { MainHeading } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import {
  Button,
  FeedInput,
  LabelFeed,
  SearchDropdown,
  Text,
  TextArea,
} from "../../../utils";
import { CrossIcon } from "../../../FeedLibraryIcon";
import CustomSupModal from "./customSupModal";
import { seteditFormReducer } from "../../../store/reducers/uiSettings";
import NoImage from "../../../assets/images/no-image.svg";
const SteppeOne = ({
  closePlanHandler,
  formRef,
  setStepper,
  setplanScreenState,
}) => {
  const { uiSettings, supplementPlan } = useSelector((state) => state);
  const common = useSelector((state) => state.common);
  const [selectedProduct, setselectedProduct] = useState([]);
  const [open, setOpen] = useState(false);
  const { editForm } = uiSettings;
  const dispatch = useDispatch();
  let cartItem = "";

  useEffect(() => {
    if (selectedProduct?.length) {
      cartItem = "";
      selectedProduct?.map((product) => {
        let variantID =
          product?.variants?.[0]?.id?.split("ProductVariant/")?.[1];
        variantID = variantID + ":1";
        if (!!cartItem?.length) {
          cartItem = cartItem.concat("," + variantID);
        } else {
          cartItem = variantID;
        }
      });
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (editForm?.supplementList?.length > 0) {
      setselectedProduct(editForm?.supplementList);
    } else {
      setselectedProduct([]);
    }
  }, [editForm]);
  return (
    <>
      <div className="">
        <FeedBreadcrumbs
          backClick={closePlanHandler}
          selectedTemplateName={{ name: "Supplements" }}
          formRef={formRef}
          cancelBtnClass="!border-none !p-2"
          submitBtnClass="!rounded-[8px]"
          marginBottom="!mb-0"
          disabled={true}
          crumbText="Supplemet Plan"
        />
        <MainHeading
          title="Create Supplement Plan Templates"
          description={
            "Welcome to the Kaboodle Supplement Plan Builder. Please complete all steps and proceed using the buttons at the bottom of each screen."
          }
        />
      </div>

      <div className="mt-6 w-full  ">
        <Formik
          initialValues={{
            name: uiSettings?.editForm?.name || "",
            description: uiSettings?.editForm?.description || "",
          }}
          // enableReinitialize
          innerRef={formRef}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            dispatch(
              seteditFormReducer({
                type: uiSettings?.formName || "create-supplement",
                data: {
                  ...uiSettings.editForm,
                  ...values,
                  supplementList: [...selectedProduct],
                },
              }),
            );
            setStepper([1, 2]);
            setplanScreenState("schedulerStep");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <>
              <form
                onSubmit={handleSubmit}
                className={`flex-column w-full ${
                  uiSettings?.formName === "view-template"
                    ? "pointer-events-none"
                    : "pointer-events-auto"
                }`}
              >
                <div className="w-full flex-start-start-column mb-6 autofill-fix">
                  <LabelFeed
                    text={
                      <>
                        Supplement Plan Name{" "}
                        <span className="text-primary1">*</span>
                      </>
                    }
                    className={"mb-[10px] !font-medium !text-lg"}
                  />
                  <FeedInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    setFieldValue={setFieldValue}
                    type="text"
                    placeholder="Add Name"
                    name="name"
                    crossicon={values?.name ? <CrossIcon /> : ""}
                    className="w-full  !px-5 !bg-secondary1"
                  />
                  {errors.name && touched.name && (
                    <div className="text-red text-[14px]  pt-[4px]">
                      {errors.name}
                    </div>
                  )}
                </div>

                <div className="w-full flex-start-start-column mb-[48px]">
                  <LabelFeed
                    text="Description"
                    className={"mb-[12px] !font-medium !text-lg"}
                  />
                  <TextArea
                    placeholder="Add Instruction"
                    rows={5}
                    name={"description"}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    value={values.description}
                    onBlur={handleBlur}
                    className="pt-[20px] px-[15px] pb-[12px] rounded-[8px] text-[14px] !bg-secondary1"
                  />
                </div>
                <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <SearchDropdown
                    options={supplementPlan?.supplementProducts}
                    setselectedProduct={setselectedProduct}
                    selectedProduct={selectedProduct}
                    disabled={
                      uiSettings?.formName !== "view-template" ? false : true
                    }
                    // value={values.templateId}
                  />
                  <div className="w-full">
                    <div className="w-full flex-center-between mb-[16px]">
                      <LabelFeed
                        text="Supplement List"
                        className={"!font-medium !text-lg"}
                      />
                      {uiSettings?.formName !== "view-template" && (
                        <Button
                          text={"+ Custom Product"}
                          type="button"
                          className={`!bg-transparent !h-auto !border-none  rounded-full !p-0 leading-6 text-[16px] `}
                          color="text-primary1"
                          cta={() => {
                            setOpen(true);
                          }}
                        />
                      )}
                    </div>
                    <div className="w-full   py-6 px-4   bg-black rounded-lg">
                      <div
                        className={`h-[414px] flex-column gap-4 ${
                          selectedProduct?.length >= 6 && "overflow-y-scroll"
                        }`}
                      >
                        {selectedProduct.map((item, index) => (
                          <div
                            key={index}
                            className="w-full flex-items-center gap-[16px]"
                          >
                            <Text
                              text={`${index + 1}.`}
                              color="text-textPrimary"
                              fontSize={"text-[16px]"}
                              className={"leading-[24px] tracking-[.5px]"}
                            />
                            <div
                              key={index}
                              className="w-[50px] h-[50px] rounded-lg p-[5px] flex-center-center bg-background  "
                            >
                              <img
                                src={
                                  item?.featureImage?.src ||
                                  item?.images?.[0]?.src ||
                                  NoImage
                                }
                                alt="brand"
                                className="w-full h-full object-contain"
                              />
                            </div>
                            <Text
                              text={`${item?.name}, ${item?.vendor}`}
                              color="text-text6"
                              fontSize={"text-[18px]"}
                              className={"leading-[24px] tracking-[.5px]"}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
      <CustomSupModal
        open={open}
        setOpen={setOpen}
        user={common?.user}
        setselectedProduct={setselectedProduct}
        selectedProduct={selectedProduct}
      />
    </>
  );
};

export default SteppeOne;

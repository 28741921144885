import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../../../configs/firebase";
import { setActiveChatReducer } from "../../../store/reducers/inbox";
import { Button } from "../../../utils";
import { SendIcon } from "../../../FeedLibraryIcon";

const WriteSMS = ({ scroll, activeChat, common }) => {
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const sendMessage = async () => {
    const { uid, displayName, photoURL } = auth.currentUser;
    if (message.trim() === "") {
      return;
    }
    const chatPayload = {
      senderId: uid,
      receiverId: activeChat?.reciever?.uid || "mzUCiv3nDCdrrwl3xJsh3TBPkK92",
      text: message,
      displayName: displayName,
      photoURL: photoURL,
      isRead: false,
      timestamp: serverTimestamp(),
    };

    if (activeChat?.reciever?.uid) {
      dispatch(
        setActiveChatReducer({
          ...activeChat,
          messages: [...activeChat.messages, chatPayload],
        }),
      );
      await addDoc(
        collection(db, `Chats/${activeChat?.id}/messages`),
        chatPayload,
      );

      setMessage("");
      if (scroll.current) {
        scroll.current.scrollTop = scroll.current.scrollHeight;
      }
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };
  console.log("scroll.current", scroll.current);
  return (
    <div className=" w-full px-[20px] flex-center-between gap-[16px]">
      <div className="w-[48px] h-[48px] flex-shrink-0 rounded-lg flex items-start justify-centers shadow-clientImgShadow overflow-hidden ">
        <img
          className="w-full h-full object-cover "
          src={common?.user?.avatar}
          alt="client"
        />
      </div>

      <input
        placeholder="Type Message"
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleEnterKeyPress}
        name="message"
        value={message}
        className={`w-full h-[48px] !outline-none text-sm leading-6 text-text3 px-[12px] py-[10px] rounded-lg bg-background `}
      />

      {/* <Button cta={() => {}} className="!h-[48px] rounded-lg !bg-activeBackground  p-[12px]" Icon={<MicrophoneIcon />} /> */}
      <Button
        disabled={message?.trim() !== "" ? false : true}
        cta={() => sendMessage()}
        className="p-[12px] rounded-lg !bg-primary1"
        Icon={<SendIcon />}
      />
    </div>
  );
};

export default WriteSMS;

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Loader, Modal } from "rsuite";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ComeraIcon, CrossIcon, PencilIcon } from "../../FeedLibraryIcon";
import { Button, FeedInput, LabelFeed, Spinner, Text } from "../../utils";
import { Formik } from "formik";
import { updateCoachProfileContext } from "../../Services/commonContext";

// import UpdateProfileAvatar from "./updateAvatar";
import { diceBearThumbs } from "../../schema";
import { toast } from "react-toastify";

const ProfileModal = ({ handleClose, open }) => {
  const formRef = useRef(null);
  const fileRef = useRef();
  const [loading, setloading] = useState(false);
  const common = useSelector((state) => state.common);
  const [showBtn, setShowBtn] = useState(false);
  const [showAvatar, setshowAvatar] = useState(false);
  const [selectedThumbs, setselectedThumbs] = useState({});
  const [tempThumbs, settempThumbs] = useState({});
  const [diceBearAvatar, setdiceBearAvatar] = useState([]);

  const [profileImage, setprofileImage] = useState({});
  const { user } = useSelector((state) => state.common);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setselectedThumbs(reader.result);
    };
    reader.readAsDataURL(file);
    setprofileImage(file);
  };

  const updatAvatarHandler = async () => {
    setselectedThumbs(tempThumbs?.avatar);
    setshowAvatar(false);
  };
  useEffect(() => {
    (() => {
      const thumbsList = diceBearThumbs?.map((thumb) => {
        const singlethumb = {
          name: thumb,
          avatar: `https://api.dicebear.com/7.x/thumbs/svg?seed=${thumb}&translateY=8`,
        };
        if (user.avatar.includes(thumb)) {
          setselectedThumbs(singlethumb?.avatar);
          settempThumbs(singlethumb);
        } else {
          setselectedThumbs(user?.avatar);
        }
        return singlethumb;
      });
      setdiceBearAvatar(thumbsList);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className="foodModal profile-modal w-full max-w-[581px]"
      >
        <Modal.Header closeButton={false} className="w-full">
          <div className="w-full flex-center-between">
            <span className="cursor-pointer" onClick={handleClose}>
              {<CrossIcon size="24" />}
            </span>
            <Text
              text={"My Profile"}
              color="text-textPrimary"
              className="!text-lg !leading-5 !font-medium "
            />
            {showBtn ? (
              <div />
            ) : (
              <Button
                Icon={<PencilIcon />}
                className="!w-fit !h-auto !p-0"
                cta={() => {
                  setShowBtn(true);
                }}
              />
            )}
          </div>
        </Modal.Header>
        <Modal.Body className=" w-full !m-0 !p-0">
          <div className="relative cursor-pointer flex-center-center w-[110px] h-[110px] mx-auto rounded-full  mb-8">
            <span
              onClick={() => {
                if (showBtn) {
                  setshowAvatar(!showAvatar);
                }
              }}
            >
              <img
                src={selectedThumbs || user?.avatar}
                className="w-[110px] h-[110px] object-cover rounded-full"
                alt="avatar"
              />
            </span>
            <input
              type="file"
              name="avatar"
              hidden
              ref={fileRef}
              onChange={handleFileChange}
            />
            {showBtn && (
              <span
                onClick={() => {
                  if (fileRef.current) {
                    fileRef.current?.click();
                  }
                }}
                className="absolute bottom-0 right-[-10px]"
              >
                <ComeraIcon />
              </span>
            )}
          </div>
          <div className="w-full rounded-xl px-[20px] py-4 bg-background">
            {showAvatar ? (
              <>
                <div className=" flex flex-wrap gap-x-4 gap-y-[12px] mb-10">
                  {diceBearAvatar?.map((thumb) => (
                    <span
                      className={`cursor-pointer rounded-full overflow-hidden w-[78px] h-[78px] ${
                        tempThumbs?.name === thumb.name &&
                        "border-3 border-primary1"
                      }`}
                      onClick={() => {
                        settempThumbs(thumb);
                      }}
                    >
                      <img
                        src={thumb.avatar}
                        className=" w-full h-full  object-cover "
                        alt="avatar"
                      />
                    </span>
                  ))}
                </div>

                <div className="flex-center-center ">
                  <Button
                    text={
                      loading ? <Spinner className="!w-6 !h-6" /> : "Select"
                    }
                    rightmargin="mr-[10px]"
                    className="!h-[44px] !px-[40px] !py-[7px] !font-medium !text-sm rounded-lg "
                    bg="bg-primary1"
                    color="text-background"
                    cta={updatAvatarHandler}
                  />
                </div>
              </>
            ) : (
              <Formik
                initialValues={{
                  displayName: common?.user?.displayName || "",
                  firstName:
                    common?.user?.firstName || common?.user?.displayName,
                  lastName: common?.user?.lastName || "N/A",
                  email: common?.user?.email || "N/A",
                  phoneNumber: common?.user?.phoneNumber || "N/A",
                }}
                innerRef={formRef}
                // enableReinitialize
                validate={(values) => {
                  const errors = {};
                  if (!values.displayName) {
                    errors.displayName = "Required";
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  setloading(true);
                  let selectedImage = selectedThumbs;
                  if (profileImage?.name) {
                    const storage = getStorage();
                    const storageRef = ref(
                      storage,
                      `images/${profileImage.name}`
                    );
                    try {
                      const snapshot = await uploadBytes(
                        storageRef,
                        profileImage,
                        {
                          // You can add metadata here if needed
                        }
                      );
                      const url = await getDownloadURL(snapshot.ref);
                      selectedImage = url;
                      setprofileImage(null);
                    } catch (error) {
                      console.error("Error uploading file: ", error);
                      setprofileImage(null);
                    }
                  }
                  await updateCoachProfileContext(common?.user?.uid, {
                    ...common?.user,
                    ...values,
                    avatar: selectedImage,
                  });
                  toast("Profile is updated Successfully");
                  setloading(false);
                  handleClose();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className=" w-full flex-column  gap-[16px]"
                  >
                    <div className="flex-column  w-full border-b border-solid border-text4">
                      <LabelFeed
                        text="UserName"
                        fontSize={"!text-[14px]"}
                        color="text-whiteGray"
                        className="!leading-none"
                      />
                      <FeedInput
                        type="text"
                        name="displayName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.displayName}
                        disabled={showBtn ? false : true}
                        placeholder="Username"
                        className="!text-sm !leading-none px-0 !pt-3 !pb-4 focus-visible:!outline-none"
                      />
                    </div>
                    <div className="flex-column  w-full border-b border-solid border-text4">
                      <LabelFeed
                        text="First Name"
                        fontSize={"!text-[14px]"}
                        color="text-whiteGray"
                        className="!leading-none"
                      />
                      <FeedInput
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        disabled={showBtn ? false : true}
                        placeholder="First Name"
                        className="!text-sm !leading-none px-0 !pt-3 !pb-4 focus-visible:!outline-none"
                      />
                    </div>
                    <div className="flex-column  w-full border-b border-solid border-text4">
                      <LabelFeed
                        text="Last Name"
                        fontSize={"!text-[14px]"}
                        color="text-whiteGray"
                        className="!leading-none"
                      />
                      <FeedInput
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        disabled={showBtn ? false : true}
                        placeholder=""
                        className="!text-sm !leading-none px-0 !pt-3 !pb-4 focus-visible:!outline-none"
                      />
                    </div>
                    <div className="flex-column  w-full border-b border-solid border-text4">
                      <LabelFeed
                        text="Email"
                        fontSize={"!text-[14px]"}
                        color="text-whiteGray"
                        className="!leading-none"
                      />
                      <FeedInput
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        disabled={true}
                        placeholder="Email"
                        className="!text-sm !leading-none px-0 !pt-3 !pb-4 focus-visible:!outline-none"
                      />
                    </div>
                    <div className="flex-column  w-full ">
                      <LabelFeed
                        text="Phone number"
                        fontSize={"!text-[14px]"}
                        color="text-whiteGray"
                        className="!leading-none"
                      />
                      <FeedInput
                        type="number"
                        name="phoneNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        disabled={showBtn ? false : true}
                        placeholder="N/A"
                        className="!text-sm !leading-none px-0 !pt-3 !pb-4 focus-visible:!outline-none"
                      />
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </Modal.Body>
        {showBtn && (
          <Modal.Footer className="w-full flex justify-end gap-[16px]">
            <Button
              text="Cancel"
              type="button"
              bg=" border border-textPrimary"
              className="w-[91px] rounded-[8px] !py-[7px] !font-medium !text-sm"
              color="text-textPrimary"
              cta={handleClose}
            />

            <Button
              text={
                loading ? (
                  <Loader content="Save Changes" size="sm" />
                ) : (
                  "Save Changes"
                )
              }
              type="submit"
              bg="bg-primary1"
              className="!px-[19px] !py-[10px] rounded-[8px] !font-medium !text-sm "
              color="text-background"
              cta={() => {
                formRef.current?.submitForm();
              }}
            />
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default ProfileModal;

import React from 'react';

export const ErrorIcon = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill="#C0F121" stroke="none">
        <path
          d="M245 631 c-92 -24 -173 -90 -215 -176 -19 -41 -24 -66 -24 -135 -1
-78 2 -90 33 -148 38 -70 70 -100 145 -140 43 -22 64 -26 136 -26 72 0 93 4
136 26 75 40 107 70 145 140 31 58 34 70 33 148 0 72 -4 93 -26 136 -39 75
-70 107 -137 143 -65 34 -164 49 -226 32z m185 -62 c171 -77 215 -302 87 -438
-103 -108 -291 -108 -394 0 -101 107 -97 286 8 386 77 73 205 95 299 52z"
        />
        <path
          d="M190 445 c-10 -12 -3 -24 41 -70 l52 -55 -52 -54 c-28 -30 -51 -57
-51 -59 0 -3 7 -10 15 -17 12 -10 24 -3 70 41 l55 52 55 -52 c46 -44 58 -51
70 -41 8 7 15 14 15 17 0 2 -23 29 -51 59 l-52 54 52 55 c44 46 51 58 41 70
-7 8 -14 15 -17 15 -2 0 -29 -23 -59 -51 l-54 -52 -54 52 c-30 28 -57 51 -59
51 -3 0 -10 -7 -17 -15z"
        />
      </g>
    </svg>
  );
};

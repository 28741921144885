import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Heading, Spinner } from "../../../utils";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../../configs/firebase";
import { Cross, SearchIcon } from "../../../FeedLibraryIcon";
import { setActiveChatReducer } from "../../../store/reducers/inbox";

const AllUserModal = ({
  show,
  setShow,
  setsearchNewUser,
  allUsers,
  setactiveChat,
  uid,
  userChats,
}) => {
  const [newUserloader, setNewUserloader] = useState();
  const dispatch = useDispatch();
  return (
    <div
      className={`w-full h-full ${
        show ? "block" : "hidden"
      } absolute z-[2] top-0 left-0 `}
    >
      <div className=" w-full h-full flex-column gap-[28px] bg-secondary1 rounded-[20px] pt-[60px] p-[20px] ">
        <span
          onClick={() => setShow(false)}
          className="w-[32px] ml-auto absolute !right-[10px] top-[20px] z-[1] cursor-pointer"
        >
          <Cross />
        </span>
        <div className=" w-full h-full p-[2px] overflow-y-hidden ">
          <div className="relative w-full mb-[20px] ">
            <span className="absolute top-[12px] left-[12px]">
              {<SearchIcon />}
            </span>
            <input
              placeholder="Search New Client"
              onChange={(e) => setsearchNewUser(e.target.value)}
              className={`w-full  py-[12px] text-sm text-text3 pl-[46px] !outline-none pr-[10px] rounded-lg bg-background `}
            />
          </div>
          <div className=" h-full flex-column gap-[20px] pr-[12px] overflow-y-scroll">
            {allUsers?.length > 0 ? (
              allUsers?.map((user, counter) => (
                <div
                  key={counter}
                  onClick={async () => {
                    if (!newUserloader) {
                      setNewUserloader(counter);
                      try {
                        const existingUser = userChats?.find(
                          (f) => f.reciever?.uid === user?.uid,
                        );
                        if (existingUser?.id) {
                          dispatch(setActiveChatReducer(existingUser));
                          setNewUserloader();
                        } else {
                          // Create a new chat document
                          // eslint-disable-next-line no-unused-vars
                          const chatDocRef = await addDoc(
                            collection(db, "Chats"),
                            {
                              participants: [uid, user?.uid], // Replace with actual user IDs
                              createdAt: serverTimestamp(),
                            },
                          );
                          setNewUserloader();
                          console.log("Chat created successfully!");
                        }
                        setShow(false);
                      } catch (error) {
                        console.error("Error creating chat:", error);
                      }
                    }
                  }}
                  className="w-full cursor-pointer flex-items-center gap-[16px] bg-background rounded-[14px] p-[14px]"
                >
                  <div className="w-[32px] h-[32px] rounded-lg flex-start-center shadow-clientImgShadow overflow-hidden ">
                    <img
                      className="w-full h-full object-cover "
                      src={user?.avatar}
                      alt="client"
                    />
                  </div>
                  <div className="w-full flex-column gap-[12px]">
                    <div className="w-full flex-center-between gap-[16px]">
                      <Heading
                        text={user.name}
                        fontweight="!font-[400]"
                        fontSize="!text-[16px]"
                        className="leading-normal tracking-[-0.064px]"
                        color="text-textPrimary"
                      />
                    </div>
                  </div>
                  {newUserloader === counter && <Spinner size="sm" />}
                </div>
              ))
            ) : (
              <Heading
                text={"No User found"}
                fontweight="!font-[400]"
                fontSize="!text-[16px]"
                className="leading-normal text-center tracking-[-0.064px]"
                color="text-textPrimary"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllUserModal;
